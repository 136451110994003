import {
  FORM
} from '../../Config/actionTypes';

const initialState = {
  data: [],
  rowCount: 0,
  currentDoc: {},
  docFields: [],
  searchableFields: [],
  visibleFields: [],
  composedFields: [],
  docName: "",
  error: false,
  errorMessage: "",
  waitForResponse: true
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FORM.BEFORE_API_REQUEST:
      return {
        ...state,
        waitForResponse: true
      };
    case FORM.ERROR:
      return {
        ...state,
        waitForResponse: false,
          error: action.payload.error,
          errorMessage: action.payload.errorMessage
      };
    case FORM.DATA_RECEIVED: {
      return {
        ...state,
        waitForResponse: false,
        data: action.payload.rows,
        rowCount: action.payload.count
      }
    }
    case FORM.FIELDS_RECEIVED: {
      return {
        ...state,
        waitForResponse: false,
        docFields: action.payload.fields,
        docName: action.payload.name,
        searchableFields: action.payload.searchableFields,
        visibleFields: action.payload.visibleFields,
        composedFields: action.payload.composedFields
      }
    }
    case FORM.CLEAR_DATA: {
      return {
        ...initialState
      }
    }
    default:
      return state;
  }
}