import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './Styles';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import Utils from '../../Libs/utils';

class Login extends Component {
  state = {
    isValid: true,
    errorMessage: "",
    userName: this.props.usuarioNombreUsuario
  }

  handleChange = (event) => {
    this.setState({ userName: event.target.value.trim() })
  }

  validateUserName = () => {
    const validateText = Utils.validateText(this.state.userName, {
      minLength: 5,
      required: true
    });

    this.setState({
      isValid: validateText.isValid,
      errorMessage: validateText.errorMessage
    });

    if (validateText.isValid) {
      this.props.onClick(this.state.userName.trim());
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={5} className={classes.paper}>
        <Avatar src="/favicon.ico" className={classes.bigAvatar} />
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>
          <FormControl margin="normal" fullWidth>
            <TextField autoComplete="email" error={this.props.error || !this.state.isValid}
              label='Usuario'
              placeholder='usuario' value={this.state.userName}
              onChange={this.handleChange} disabled={this.props.waitForResponse}
              margin="normal" variant="outlined"
            />
            <FormHelperText error={true}>{this.props.errorMessage || this.state.errorMessage}</FormHelperText>
          </FormControl>

          <Button className={classes.submit}
            onClick={this.validateUserName} fullWidth
            variant='contained'
            color='primary' disabled={this.props.waitForResponse}>
            Siguiente
          </Button>
        </form>
      </Paper>
    );
  }
}

Login.propTypes = {
  onClick: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  waitForResponse: PropTypes.bool.isRequired,
  usuarioNombreUsuario: PropTypes.string
};


export default withStyles(Styles, { withTheme: true })(Login);