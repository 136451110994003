import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { getDocument } from '../../../../Actions/General';
import Buttoncontrol from "../../../GeneralUI/Buttoncontrol";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter, combineStyles } from '../../../../Libs/';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import PSelect from '../../../GeneralUI/PSelect'

const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: 'none',
  }
});

class FacturarConceptos extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    data: [],
    fields: {
      fechaInicial: null,
      fechaFinal: null,
      separador: '',
      ciudadCodigo: null,
      forpagCodigo: null,
      agruparPor: '',
      csvFile: null
    },
    responseForm: { Message: '', typeMessage: '' },
    formValidations: {
      view: ['ciudadCodigo', 'fechaInicial', 'fechaFinal', 'separador']
    },
    formErrors: {
      ciudadCodigo: {},
      fechaInicial: {},
      fechaFinal: {},
      separador: {}
    }
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'colorCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });

  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    fields[event.target.id] = event.target.value;
    this.setState({ fields });
  }

  handleCapture = ({ target }) => {
    let { fields } = this.state;
    fields['csvFile'] = target.files[0];

    this.setState({
      inputFileName: target.files[0].name,
      fields
    });
  }

  save = (response) => {
    let newState = {
      openSnackbar: true,
      responseForm: response.responseForm
    };

    if (newState.responseForm['typeMessage'] === 'success') {
      newState.data = response.data;
    }

    this.setState(newState);
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  render() {
    const { classes } = this.props;

    return (

      <Paper elevation={5} className={classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={classes.gridForm}>

            <InputLabel className={classes.tittleLabelForm}>
              Facturacion otros conceptos
            </InputLabel>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="fechaInicial"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["fechaInicial"]}
                type="date"
                label="Fecha inicial (*)"
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['fechaInicial'].error}
                helperText={this.state.formErrors['fechaInicial'].errorMessage}
              />
            </Grid>

            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="fechaFinal"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["fechaFinal"]}
                type="date"
                label="Fecha final (*)"
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['fechaFinal'].error}
                helperText={this.state.formErrors['fechaFinal'].errorMessage}
              />
            </Grid>

            <Grid item xs={3} className={classes.gridForm}>
              <PSelect
                id="separador"
                useChildren={true}
                value={this.state.fields["separador"]}
                onChange={this.handleChange}
                namelabel="Separador (*)"
              >
                <option value=";">;</option>
                <option value=",">,</option>
              </PSelect>
            </Grid>

          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={classes.gridForm}>
              <AutoComplete
                id="ciudadCodigo"
                tableName='Ciudad'
                fields={['ciudadCodigo', 'ciudadNombre']}
                primaryField='ciudadCodigo'
                aliases={['ID', 'Nombre']}
                searchField='ciudadNombre'
                placeHolder='Ciudad pago (*)'
                onSelect={this.handleChange}
                value={this.state.fields["ciudadCodigo"]}
                formErrors={this.state.formErrors['ciudadCodigo']}
              />
            </Grid>

            <Grid item xs={2} className={classes.gridForm}>
              <PSelect
                namelabel="Forma pago (*)"
                id="forpagCodigo"
                namelineInput="formapago"
                fieldVisible="forpagNombre"
                fieldCode="forpagCodigo"
                Table="FormaPago"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["forpagCodigo"]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>

            <Grid item xs={3} className={classes.gridForm}>
              <PSelect
                id="agruparPor"
                useChildren={true}
                value={this.state.fields["agruparPor"]}
                onChange={this.handleChange}
                namelabel="Agrupar por (*)"
              >
                <option value="REGISTRO">REGISTRO</option>
                <option value="CLIENTE_SUCURSAL">CLIENTE - SUCURSAL</option>
              </PSelect>
            </Grid>

            <Grid item xs={2} className={classes.gridForm}>
              <div className={classes.formControl}>
                <input
                  accept=".csv"
                  className={classes.input}
                  id="csvFile"
                  onChange={this.handleCapture}
                  type="file"
                />

                <label htmlFor="csvFile">
                  <Button raised variant="contained" component="span" className={classes.button}>
                    Seleccionar archivo
                </Button>
                </label>
              </div>
            </Grid>

            <Grid item xs={3} className={classes.gridForm}>
              <div className={classes.formControl}>
                <label>
                  {this.state.inputFileName}
                </label>
              </div>
            </Grid>
          </Grid>
        </form>

        {!this.state.data.length ? null :
          <Grid container spacing={10} xs={12} className={this.props.classes.gridResultSearch}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell align='left'>
                    <b>Factura codigo</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.getTableRows()}
              </TableBody>
            </Table>
          </Grid>
        }

        <div id='buttonContainer'>
          <Buttoncontrol
            saveData={this.state.fields}
            moduleName='factura'
            save={this.save}
            primaryFields={['facturaCodigo']}
            formValidations={this.state.formValidations}
            errorHandler={this.setErrors}
            url='/processes/factura_conceptos'
            isFormLite={true}
          />
        </div>

      </Paper>
    );
  }

  getTableRows = () => {
    return this.state.data.map((row, index) => {
      return (
        <TableRow hover key={200 + index} className={this.props.classes.tableRow}>
          <TableCell key={300 + 300} align='left'>
            <a
              href={`/home/factura/${row.facturaCodigo}/edit`}
              target='_blank'
              rel='noopener noreferrer'>
              {row.facturaCodigo}
            </a>
          </TableCell>
        </TableRow>
      );
    });
  }
}



FacturarConceptos.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(combineStyles(styles, Styles))(FacturarConceptos)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;