import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../Styles';
import PropTypes from 'prop-types';
import { Typography, Grid, TablePagination, Table, TableHead, TableRow, TableBody, TableCell, Popover, Button } from '@material-ui/core';
import { Menu, MenuItem } from '@material-ui/core';
import _ from 'lodash';
import { combineStyles } from '../../../Libs/';

const styles = theme => ({
  link: {
    color: '#000',
    textDecoration: 'underline',
    '&:hover': {
      color: '#000'
    }
  },
  typography: {
    margin: theme.spacing(2)
  }
});

class Visor extends Component {

  componentWillMount() {
    let anchors = {};
    let menuAnchors = {};
    for (let i = 0; i < 25; i++) {
      anchors[500 + i] = null;
      menuAnchors[500 + i] = null;
    }

    this.setState({
      anchorEl: anchors,
      menuAnchors
    });
  }


  state = {
    anchorEl: {}
  };

  getTablePagination = () => {
    if (!this.props.data.count) {
      return null;
    }

    return (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        className={this.props.classes.tablePagination}
        count={this.props.data.count}
        rowsPerPage={this.props.rowsPerPage}
        page={this.props.page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  };

  handleChangePage = (event, page) => {
    this.props.search(page, this.props.rowsPerPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.search(0, event.target.value);
  };

  getTableHeader = () => {
    if (!this.props.data.rows.length) {
      return;
    }

    return this.props.fields.map((row, index) => {
      return (
        <TableCell align='left' key={100 + index}>
          <b>{row.alias}</b>
        </TableCell>
      );
    });
  }

  getTableRows = () => {
    const fields = this.props.fields.map(field => field.name);
    const docFields = {};
    const stateKey = _.find(this.props.fields, { colorize: true });
    this.props.fields.forEach(field => docFields[field.name] = field);

    return this.props.data.rows.map((row, index) => {
      let stateValue = null;
      if (stateKey) {
        stateValue = row[stateKey.name];
      }
      return (
        <TableRow style={this.getRowStyle(stateValue)} hover key={200 + index} className={this.props.classes.tableRow}>
          {
            fields.map((field, idx) => {
              return (
                <TableCell key={300 + idx} align='left' size='small'>
                  {
                    /*(docFields[field] && docFields[field].isPrimary) ?
                      <Link to={this.getEditUrl(row[field])} target='_blank' className={this.props.classes.link}>
                        {row[field]}
                      </Link>
                      : row[field]*/
                    this.formatColumnContent(docFields[field], row[field], 500 + index, row)
                  }
                </TableCell>
              );
            })
          }
        </TableRow>
      );
    });
  }

  handleIdClick = (event) => {
    let anchors = this.state.menuAnchors;
    const currentAnchor = event.currentTarget.attributes['aria-owns'].nodeValue;

    anchors[currentAnchor] = event.currentTarget;
    this.setState({
      menuAnchors: anchors
    });
  }

  handlePopClick = (event) => {
    let anchors = this.state.anchorEl;
    const currentAnchor = event.currentTarget.attributes['aria-owns'].nodeValue;

    anchors[currentAnchor] = event.currentTarget;
    this.setState({
      anchorEl: anchors
    });
  }

  handlePopClose = () => {
    let anchors = this.state.anchorEl;
    let menuAnchors = this.state.menuAnchors;
    _.each(_.keys(anchors), (key) => {
      anchors[key] = null;
      menuAnchors[key] = null;
    });

    this.setState({
      anchorEl: anchors,
      menuAnchors
    });
  }

  openEditTab = (event) => {
    window.open(event.currentTarget.attributes['data-url'].nodeValue, '_blank');
  }

  getProcessColumn = (config, value, row) => {
    if (!config.processUrl) {
      return null;
    }

    let isValid = true;
    if (config.processWhen) {
      _.each(_.keys(config.processWhen), key => {
        if (row[key] !== config.processWhen[key]) {
          isValid = false;
        }
      });
    }

    if (!isValid) {
      return null;
    }

    return (
      <MenuItem onClick={this.openEditTab} data-url={`${config.processUrl}?${config.name}=${value}`}>
        {config.processTitle}
      </MenuItem>
    );
  }

  formatColumnContent = (config, value, idx, row) => {
    if (!config) {
      return value;
    }

    if (config.isPrimary) {
      const anchorEl = this.state.menuAnchors[idx];

      return (
        <div>
          <Button
            aria-owns={idx}
            aria-haspopup="true"
            onClick={this.handleIdClick}
          >
            {value}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handlePopClose}
          >
            <MenuItem onClick={this.openEditTab} data-url={this.getEditUrl(value)}>
              Ver documento
            </MenuItem>         

            {this.getProcessColumn(config, value, row)}
          </Menu>
        </div>
      );
    } else if (config.tooltip) {
      const anchorEl = this.state.anchorEl[idx];
      const open = Boolean(anchorEl);

      return (
        <div>
          <Button
            aria-owns={idx}
            aria-haspopup="true"
            variant="contained"
            onClick={this.handlePopClick}
          >
            Ver
          </Button>
          <Popover
            id={idx}
            open={open}
            anchorEl={anchorEl}
            onClose={this.handlePopClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography className={this.props.classes.typography}>
              {value}
            </Typography>
          </Popover>
        </div>
      );
    } else {
      return value;
    }
  }

  getRowStyle = (value) => {
    if (!value) {
      return {};
    }

    if (!this.props.colors || !this.props.colors[value]) {
      return {};
    }

    return {
      backgroundColor: this.props.colors[value]
    };
  };

  getEditUrl = (id) => {
    return `/home/${this.props.moduleUrl}/${id}/edit`;
  };

  render() {
    return (

      <Grid container spacing={8} className={this.props.classes.gridResultSearch}>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                {this.getTableHeader()}
              </TableRow>
            </TableHead>

            <TableBody>
              {this.getTableRows()}
            </TableBody>

          </Table>
        </Grid>

        <Grid container alignItems='center'>
          <Grid item xs={12} >
            {this.getTablePagination()}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

Visor.propTypes = {
  search: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  colors: PropTypes.object
};

export default withStyles(combineStyles(styles, Styles), { withTheme: true })(Visor);