import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Styles from './Styles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';

class Detalleformapago extends Component {
  state = {
    isWaiting: false,
    favorites: [],
    fields: {
      Concepto: '',
      FormaPago: '',
      Fecha: '',
      Valor: ''
    }
  };

  handleChange = (event) => {
    let { fields } = this.state;
    fields[event.target.id] = event.target.value;
    this.setState(fields);
  }

  componentDidMount() {
  }

  setData = () => {
    console.log("AQUIIIIII");
    console.log(this.state.fields);
    this.props.setData(this.state.fields);
  }

  render = () => {
    return (
      <Paper elevation={1} className={this.props.classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>
          <Grid container direction='row' className={this.props.classes.paperGridTitle}>
            <List component="nav">
              <ListItem>
                <ListItemText primary="Detalle de Forma de Pagos" classes={{ primary: this.props.classes.titlelabelformwhite }} />
              </ListItem>
            </List>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Concepto" label="Concepto(*)" type="text"
                fullWidth margin="normal" variant="outlined" onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="FormaPago" label="Forma Pago(*)" type="text"
                fullWidth margin="normal" variant="outlined" onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Fecha" label="Fecha (*)" type="text"
                fullWidth margin="normal" variant="outlined" onChange={this.handleChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Valor" label="Valor (*)" type="text"
                fullWidth margin="normal" variant="outlined" onChange={this.handleChange}
              />
            </Grid>

          </Grid>

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>

              <Button
                color='primary'
                onClick={this.setData}
              >
                Adicionar
              </Button>

            </Grid>
          </Grid>


        </form>
      </Paper>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(Detalleformapago);