import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Styles from './Styles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import PSelect from '../../../GeneralUI/PSelect'
import ListItemText from '@material-ui/core/ListItemText';
import { errorSetter } from '../../../../Libs';

class Detallecomercialcomision extends Component {

  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    errorForm: false,
    errorMessage: '',
    fields: {
      tipoTarifa: { 'value': null, 'key': null,'visible':true  },     
      tipoComision: { 'value': null, 'key': null,'visible':true  },
      Valor: { 'value': null, 'key': null,'visible':true  }
    },
    formValidations: {
      view: ['tipoTarifa', 'tipoComision', 'Valor']
    },
    formErrors: {
      tipoTarifa: {},
      tipoComision: {},
      Valor: {},
    }
  };


  componentDidMount = () => {
    console.log(this.props.editData);
    if (this.props.editData) {
      this.fillForm(this.props.editData, this.props.setIndex)
    }
    if (this.props.datosHeader) {
      this.setState(this.props.datosHeader);
    }
  }

  fillForm = (data, index) => {
    let { fields } = this.state;

    fields["tipoTarifa"] = ({ 'value': data[index].tipoTarifa.value, 'key': data[index].tipoTarifa.key,'visible':true });
    fields["tipoComision"] = ({ 'value': data[index].tipoComision.value, 'key': data[index].tipoComision.key,'visible':true });
    fields["Valor"] = ({ 'value': data[index].Valor.value ? data[index].Valor.value : null, 'key': data[index].Valor.key ,'visible':true});

    this.setState({ fields });
  }

  handleChange = (event) => {
    let { fields } = this.state;

    fields[event.target.id].value =event.target.value;
    fields[event.target.id].key =event.target.hasOwnProperty('text') ? event.target.text : event.target.value;    
    
    this.setState({ fields });

  }

 
  setDataValidation = (errorForm, errorMessage) => {
    this.setState({ errorForm: errorForm, errorMessage: errorMessage });
  }

  setData = async () => {
    let { fields } = this.state; 
    const validations = errorSetter.validateFormFields(this.state.fields, this.state.formValidations);

    this.setState({ formErrors: validations.errors,fields });

    this.props.setData(this.state.fields, this.props.editData ? this.props.setIndex : null);
  }

  render = () => {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>
          <Grid container direction='row' className={this.props.classes.paperGridTitle}>
            <List component="nav">
              <ListItem>
                <ListItemText primary="Detalle de Comisiones" classes={{ primary: this.props.classes.titlelabelformwhite }} />
              </ListItem>
              {(this.state.errorForm) ?
                <ListItem>
                  <ListItemText primary={this.state.errorMessage} classes={{ primary: this.props.classes.titlelabelerrorform }} />
                </ListItem> : ''
              }
            </List>
          </Grid>

            <Grid container spacing={2}>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                      <PSelect
                      namelabel="Tipo Tarifa"
                      id="tipoTarifa"
                      namelineInput="tipoTarifa"
                      fieldVisible="tiptarNombre"
                      fieldCode="tiptarCodigo"
                      Table="Tipotarifa"
                      Vista="true"
                      Where="true"
                      useChildren={false}
                      onChange={this.handleChange}
                      value={this.state.fields["tipoTarifa"].value}
                      estados={this.state}
                      InputLabelProps={{ shrink: true }}
                      error={this.state.formErrors['tipoTarifa'].error}
                      helperText={this.state.formErrors['tipoTarifa'].errorMessage}
                    />
                </Grid>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                      <PSelect
                      id="tipoComision"
                      useChildren={true}
                      value={this.state.fields["tipoComision"].value}
                      onChange={this.handleChange}
                      namelabel="Tipo Comision"
                      error={this.state.formErrors['tipoComision'].error}
                      helperText={this.state.formErrors['tipoComision'].errorMessage}
                    >
                      <option value="PORCENTAJE">PORCENTAJE</option>
                      <option value="VALOR">VALOR</option>
                    </PSelect>
                </Grid>        
                <Grid item xs={3} className={this.props.classes.gridForm}>
                    <TextField id="Valor" label="Valor" type="text"
                      fullWidth margin="normal" variant="outlined"
                      onChange={this.handleChange}
                      value={this.state.fields["Valor"].value}
                      InputLabelProps={{ shrink: true }}
                      error={this.state.formErrors['Valor'].error}
                      helperText={this.state.formErrors['Valor'].errorMessage}
                    />
              </Grid>  
          </Grid>

        
          <Grid container spacing={2} >
            <Grid item xs={3} className={this.props.classes.gridForm}>

              <Button
                color='primary'
                onClick={this.setData}
              >
                Adicionar
              </Button>

            </Grid>
          </Grid>


        </form>
      </Paper>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(Detallecomercialcomision);