import React, { Component } from 'react';
import Configuration from './../Icons/Configuration.jsx';
import Edit from './../Icons/Edit.jsx';

class Loadericonsvg extends Component {

  getComponentToRender = () => {
    const icon = this.props.icon;
    switch (icon) {
      case 'Configuration':
        return <Configuration size={this.props.size} color={this.props.color} />;
      case 'Edit':
        return <Edit size={this.props.size} color={this.props.color} />;
      default:
        return null;
    }
  }

  render() {
    return (
      <div>
        {this.getComponentToRender()}
      </div>
    );
  }
}

export default Loadericonsvg;