import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { InputBase } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import customStyles from './Styles';
import { Link } from "react-router-dom";
import { combineStyles } from '../../../Libs';
import { withStyles } from '@material-ui/core/styles';
import { searchPage } from '../../../Actions/General';


const styles = theme => ({
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  }
});

class IntegrationAutosuggest extends React.Component {
  state = {
    single: '',
    popper: '',
    suggestions: [],
    isWaiting: false
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    if (this.state.isWaiting) {
      return;
    }

    this.setState({ isWaiting: true });
    console.log('87 >  === ', value);
    searchPage(value).then(data => {
      this.setState({
        isWaiting: false,
        suggestions: data,
      });
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = name => (event, { newValue }) => {
    console.log('103 >  === ', newValue);
    this.setState({
      [name]: newValue,
    });
  };

  getSuggestionValue = (suggestion) => {
    return suggestion.label;
  }

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
      <Link to={`/home/${suggestion.label}`}>
        <MenuItem selected={isHighlighted} component="div">
          <div>

            {parts.map((part, index) =>
              part.highlight ? (
                <span key={String(index)} style={{ fontWeight: 500 }}>
                  {part.text}
                </span>
              ) : (
                  <strong key={String(index)} style={{ fontWeight: 300 }}>
                    {part.text}
                  </strong>
                ),
            )}
          </div>
        </MenuItem>
      </Link >
    );
  }

  renderInputComponent = (inputProps) => {
    const { classes, inputRef = () => { }, ref, ...other } = inputProps;
    const endAdornment = (
      <InputAdornment position="end" variant='filled' className={classes.adornment} >
        <SearchIcon className={classes.searchIcon} />
      </InputAdornment >
    );

    return (<InputBase
      fullWidth
      className={classes.textField}
      inputRef={(node) => {
        ref(node);
        inputRef(node);
      }}
      classes={{
        root: classes.inputRoot
      }}
      endAdornment={endAdornment}
      {...other}
    />);
  }

  getSuggestions = (suggestions, value) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
        const keep =
          count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
  }

  render() {
    const { classes } = this.props;

    const autosuggestProps = {
      renderInputComponent: this.renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion: this.renderSuggestion,
    };

    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder: 'Buscar',
            value: this.state.single,
            onChange: this.handleChange('single'),
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(combineStyles(styles, customStyles))(IntegrationAutosuggest);