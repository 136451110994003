import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { getDocument } from '../../../../Actions/General';
import Buttoncontrol from "../../../GeneralUI/Buttoncontrol";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {  Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import PSelect from '../../../GeneralUI/PSelect'

import { combineStyles } from '../../../../Libs/';

const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: 'none',
  }
});

class InformeGeneralTarifas extends Component {

  state = {
    data: [],
    fields: {
      CIUDAD_CODIGO_DESTINO: null,
      CIUDAD_CODIGO_ORIGEN: null,
      TARIFA_ESTADO: null,
      TIPTAR_CODIGO: null
    },
    paramsInforme: '/imprimir_informe/tarifa/informe_general_tarifas?CIUDAD_CODIGO_ORIGEN=' + null + '&CIUDAD_CODIGO_DESTINO=' + null + '&TARIFA_ESTADO=' + null + '&TIPTAR_CODIGO=' + null,
    responseForm: { Message: '', typeMessage: '' },
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'tarifaCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });

  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    let params = '';
    fields[event.target.id] = event.target.value;
    this.setState({ fields });
    params = '/imprimir_informe/tarifa/informe_general_tarifas?CIUDAD_CODIGO_ORIGEN=' + this.state.fields.CIUDAD_CODIGO_ORIGEN + '&CIUDAD_CODIGO_DESTINO=' + this.state.fields.CIUDAD_CODIGO_DESTINO + '&TARIFA_ESTADO=' + this.state.fields.TARIFA_ESTADO + '&TIPTAR_CODIGO=' + this.state.fields.TIPTAR_CODIGO;
    this.setState({ paramsInforme: params });
  }

  save = (response) => {
    let newState = {
      openSnackbar: true,
      responseForm: response.responseForm
    };

    if (newState.responseForm['typeMessage'] === 'success') {
      newState.data = response.data;
    }

    this.setState(newState);
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  render() {
    const { classes } = this.props;

    return (

      <Paper elevation={5} className={classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={classes.gridForm}>

            <InputLabel className={classes.tittleLabelForm}>
              Informe General Tarifas
            </InputLabel>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={classes.gridForm}>
              <AutoComplete
                id="CIUDAD_CODIGO_ORIGEN"
                tableName='Ciudad'
                fields={['ciudadCodigo', 'ciudadNombre']}
                primaryField='ciudadCodigo'
                aliases={['ID', 'Nombre']}
                searchField='ciudadNombre'
                placeHolder='Origen'
                onSelect={this.handleChange}
                value={this.state.fields["ciudadCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={classes.gridForm}>
              <AutoComplete
                id="CIUDAD_CODIGO_DESTINO"
                tableName='Ciudad'
                fields={['ciudadCodigo', 'ciudadNombre']}
                primaryField='ciudadCodigo'
                aliases={['ID', 'Nombre']}
                searchField='ciudadNombre'
                placeHolder='Destino'
                onSelect={this.handleChange}
                value={this.state.fields["ciudadCodigo"]}
              />
            </Grid>
          </Grid>    

          <Grid container spacing={4}>
          <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Tipo Tarifa"
                id="TIPTAR_CODIGO"
                namelineInput="tipotarifa"
                fieldVisible="tiptarNombre"
                fieldCode="tiptarCodigo"
                Table="Tipotarifa"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["tiptarCodigo"]}
                estados={this.state}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
            <PSelect
                id="TARIFA_ESTADO"
                useChildren={true}
                value={this.state.fields["tarifaEstado"]}
                onChange={this.handleChange}
                namelabel="Estado"
              >
                <option value="ACTIVO">ACTIVO</option>
                <option value="INACTIVO">INACTIVO</option>
              </PSelect>
            </Grid>
          </Grid>      
        </form>


        <div id='buttonContainer'>
          <Buttoncontrol
            saveData={this.state.fields}
            moduleName='informe_general_tarifas'
            paramsInforme={this.state.paramsInforme}
            save={this.save}
            primaryFields={['tarifaCodigo']}
            url='/imprimir_informe/tarifa/informe_general_tarifas'
            isFormLite={true}
          />
        </div>

      </Paper>
    );
  }

}



InformeGeneralTarifas.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(combineStyles(styles, Styles))(InformeGeneralTarifas)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;