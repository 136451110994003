import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Styles from './Styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Favorite from '@material-ui/icons/Favorite';
//import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InputLabel from '@material-ui/core/InputLabel';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AutoSuggest from './autosuggest';
import { getFavorites, deleteFavorite, saveFavorite } from '../../../../Actions/General';


class Modalfavouries extends Component {
  state = {
    isWaiting: false,
    favorites: []
  };

  componentDidMount() {
    if (this.state.isWaiting) {
      return;
    }

    this.setState({ isWaiting: true });
    getFavorites().then(rows => {
      this.setState({ favorites: rows, isWaiting: false })
    });
  }

  deleteFromFavorites = (event) => {
    console.log('38 >  === ', event.target);
    if (event.target.id === undefined || event.target.id === null) {
      return;
    }

    if (this.state.isWaiting) {
      return;
    }

    this.setState({ isWaiting: true });
    deleteFavorite(event.target.id).then(() => {
      getFavorites().then(rows => {
        this.props.onChange(rows);
        this.setState({ favorites: rows, isWaiting: false });
      });
    });
  }

  addFavorite = (id) => {
    console.log('59 > id === ', id);
    return new Promise((resolve, reject) => {
      if (this.state.isWaiting) {
        return resolve();
      }

      this.setState({ isWaiting: true });
      saveFavorite(id).then(() => {
        getFavorites().then(rows => {
          this.props.onChange(rows);
          this.setState({ favorites: rows, isWaiting: false });
        });
      });
    });
  }

  render = () => {
    const { classes } = this.props;

    return (
      <Paper elevation={1} className={this.props.classes.mainPaper}>
        <Grid container direction='row' className={this.props.classes.paperGridTitle}>
          <List component="nav">
            <ListItem>
              <ListItemIcon>
                <Favorite className={this.props.classes.iconFavorite} />
              </ListItemIcon>
              <ListItemText primary="Configuracion Mis Favoritas" classes={{ primary: this.props.classes.titlelabelformwhite }} />
            </ListItem>
          </List>
        </Grid>

        <Grid container direction='row' >
          <Grid item xs={6} className={this.props.classes.paperList}>
            <InputLabel id="adicionar" className={this.props.classes.titlelabelform}>
              Adicionar:
                      </InputLabel>
            <br /><br />
            <div className={classes.search}>
              <AutoSuggest addFavorite={this.addFavorite} />
            </div>
          </Grid>

          <Grid item xs={4} className={this.props.classes.paperList}>
            <InputLabel id="actuales" className={this.props.classes.titlelabelform}>
              Actuales:
                          </InputLabel>
            <br /><br />
            {
              this.state.favorites.map((item, idx) => {
                return (
                  <Grid key={idx} container direction='row'>
                    <Grid item xs={10}>
                      <Link to={`/home/${item['Pagina.paginaRuta']}`}
                        className={this.props.classes.link}>
                        <Button id={idx} className={this.props.classes.valuelabelform}>
                          {item['Pagina.paginaNombre']}
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item xs={1} className={this.props.classes.gridDelete} >
                      <ButtonBase id={item.usupagfavCodigo} size="small" onClick={this.deleteFromFavorites} className={this.props.classes.iconDelete}>
                        X
                      </ButtonBase>
                    </Grid>
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(Modalfavouries);