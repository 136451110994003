import React, { Component } from 'react'
import { AppBar, Toolbar, Avatar, IconButton } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './Styles';

class Footer extends Component {

  render = () => {
    const { classes } = this.props;
    return (
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          <IconButton>
            <a href="https://angular.io/" target="_blank" rel="noopener noreferrer">
              <Avatar src="/Images/angular.jpg" />
            </a>
          </IconButton>
          <IconButton>
            <a href="https://nodejs.org/es/" target="_blank" rel="noopener noreferrer">
              <Avatar src='/Images/nodejs.png' />
            </a>
          </IconButton>
          <IconButton>
            <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">
              <Avatar src='/Images/reactjs.png' />
            </a>
          </IconButton>
          <IconButton>
            <a href="https://developers.google.com/web/fundamentals/design-and-ux/ux-basics/?hl=es" target="_blank" rel="noopener noreferrer">
              <Avatar src='/Images/ux.png' />
            </a>
          </IconButton>
          <IconButton>
            <a href="https://aws.amazon.com/es/" target="_blank" rel="noopener noreferrer">
              <Avatar src='/Images/aws2.png' sizes="5" />
            </a>
          </IconButton>
          <IconButton>
            <a href="https://www.chartjs.org/samples/latest/" target="_blank" rel="noopener noreferrer">
              <Avatar src='/Images/chart.png' sizes="5" />
            </a>
          </IconButton>
          <IconButton>
            <a href="https://www.android.com/" target="_blank" rel="noopener noreferrer">
              <Avatar src='/Images/android.png' sizes="5" />
            </a>
          </IconButton>

          <IconButton >
            <a href="https://www.oracle.com/technetwork/java/javase/documentation/index.html" target="_blank" rel="noopener noreferrer">
              <Avatar src='/Images/java.png' sizes="5" />
            </a>
          </IconButton>
          <div className={classes.grow} />
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(Footer);