import { AUTH } from '../../Config/actionTypes';
import Cookies from 'universal-cookie';
const cookie = new Cookies(); // mala practica?
const initialState = {
  isValidated: false,
  usuarioNombreUsuario: cookie.get('crmpymes-usuarioNombreUsuario') || '',
  error: false,
  errorMessage: "",
  waitForResponse: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTH.BEFORE_VALIDATION:
      return {
        ...state,
        waitForResponse: true
      };
    case AUTH.AFTER_VALIDATION:
      return {
        ...state,
        waitForResponse: false
      };
    case AUTH.VALIDATE_USERNAME:
      return {
        ...state,
        isValidated: action.payload.isValidated,
        usuarioNombreUsuario: action.payload.usuarioNombreUsuario,
        waitForResponse: false,
        error: false,
        errorMessage: ""
      };
    case AUTH.LOGIN_SUCCESS: {
      return {
        ...state,
        waitForResponse: false,
        error: false,
        errorMessage: ""
      };
    }
    case AUTH.ERROR:
      return {
        ...state,
        waitForResponse: false,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage
      };
    case AUTH.INITIAL_STATE:
      console.log('44 >  === ');
      return { ...state, ...initialState }
    default:
      return state;
  }
}