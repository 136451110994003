import React, { Component } from 'react';
import Detallepedido from './../Detallepedido/';
import Detalleremesa from './../Detalleremesa/';
import Detalleformapago from './../Detalleformapago/';
import Detallepedidoreferencias from './../Detallepedidoreferencias/';
import Detallelistaempaque from './../Detallelistaempaque/'; 
import Detallemovimientoinventarioentrada from './../Detallemovimientoinventarioentrada/';  
import Detallemovimientoinventariosalida from './../Detallemovimientoinventariosalida/';  
import Detallefacturaotrosconceptos from './../Detallefacturaotrosconceptos/'; 
import Detallefacturaservicios from './../Detallefacturaservicios/'; 
import Detallecomercialcomision from './../Detallecomercialcomision/'; 
import Detallepedidocumplido from './../Detallepedidocumplido/'; 

class Loaderdetalle extends Component {

  getComponentToRender = () => {
    const detalle = this.props.setComponente;
    switch (detalle) {
      case 'pedido':
        return <Detallepedido setData={this.props.setData}
          datosHeader={this.props.datosHeader}
          editData={this.props.editData ? this.props.editData : null}
          setIndex={this.props.setIndex !== null ? this.props.setIndex : null} />;
      case 'facturaOtrosConceptos':
        return <Detallefacturaotrosconceptos setData={this.props.setData}
          datosHeader={this.props.datosHeader}
          editData={this.props.editData ? this.props.editData : null}
          setIndex={this.props.setIndex !== null ? this.props.setIndex : null} />;   
      case 'facturaServicios':
        return <Detallefacturaservicios setData={this.props.setData}
          datosHeader={this.props.datosHeader}
          editData={this.props.editData ? this.props.editData : null}
          setIndex={this.props.setIndex !== null ? this.props.setIndex : null} />;                    
      case 'pedidoReferencias':
        return <Detallepedidoreferencias setData={this.props.setData}
          datosHeader={this.props.datosHeader}
          editData={this.props.editData ? this.props.editData : null}
          setIndex={this.props.setIndex !== null ? this.props.setIndex : null} />;
    case 'listaEmpaque':
            return <Detallelistaempaque setData={this.props.setData}
              datosHeader={this.props.datosHeader}
              editData={this.props.editData ? this.props.editData : null}
              setIndex={this.props.setIndex !== null ? this.props.setIndex : null} />;  
    case 'pedidoCumplido':
      return <Detallepedidocumplido setData={this.props.setData}
        datosHeader={this.props.datosHeader}
        editData={this.props.editData ? this.props.editData : null}
        setIndex={this.props.setIndex !== null ? this.props.setIndex : null} />;               
    case 'comercial':
      return <Detallecomercialcomision setData={this.props.setData}
        datosHeader={this.props.datosHeader}
        editData={this.props.editData ? this.props.editData : null}
        setIndex={this.props.setIndex !== null ? this.props.setIndex : null} />;                
    case 'movimientoInventarioEntrada':
            return <Detallemovimientoinventarioentrada setData={this.props.setData}
              datosHeader={this.props.datosHeader}
              editData={this.props.editData ? this.props.editData : null}
              setIndex={this.props.setIndex !== null ? this.props.setIndex : null} />;   
    case 'movimientoInventarioSalida':
            return <Detallemovimientoinventariosalida setData={this.props.setData}
              datosHeader={this.props.datosHeader}
              editData={this.props.editData ? this.props.editData : null}
              setIndex={this.props.setIndex !== null ? this.props.setIndex : null} />;                                   
    case 'remesa':
      return <Detalleremesa setData={this.props.setData}
        datosHeader={this.props.datosHeader}
        editData={this.props.editData ? this.props.editData : null}
        setIndex={this.props.setIndex !== null ? this.props.setIndex : null} />;
    case 'formapago':
      return <Detalleformapago setData={this.props.setData} />;
    default:
      return null;
    }
  }

  render() {
    return (
      <div>
        {this.getComponentToRender()}
      </div>
    );
  }
}

export default Loaderdetalle;