import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Textfieldlabel from "../GeneralUI/Textfieldlabel";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import PSelect from '../GeneralUI/PSelect'
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import AutoComplete from '../GeneralUI/AutoComplete';

import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';

class Ordencargue extends Component {

  state = {
    fields: {
      ordcarCodigo: null,

      vehiculoCodigo: null,
      terceroCodigoConductor: null,
      terceroCodigoPoseedor: null,

      terceroCodigoPropietario: null,
      pedido_codigo: null,
      ordcarEstado: null,

      ordcarValor: null,

      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime()
    },
    responseForm: { Message: '', typeMessage: '' }
  };

  /*ordcardet_codigo bigint NOT NULL DEFAULT nextval('ordencargue_detalle_ordcardet_codigo_seq':: regclass),
    ordcar_codigo character varying(45) NOT NULL,
      tercero_codigo_auxiliar bigint NOT NULL,
        ordcardet_fechacreacion date NOT NULL,
          ordcardet_fechamodificacion date NOT NULL,
*/
  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'ordcarCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }
    this.setState({ fields });
  }

  save = (response) => {
    this.setState({
      fields: response.data,
      responseForm: response.responseForm
    });
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  handleAuto = (autoCompleteValue) => {
    this.setState({ colorAutoComplete: autoCompleteValue });
  }


  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>

            <InputLabel id="ordcarCodigo"
              className={this.props.classes.tittleLabelForm}>Orden Cargue
            </InputLabel>

            <Grid item xs={6} className={this.props.classes.chipTitle}>
              {(this.state.fields.ordcarCodigo !== null) ?
                (<Chip
                  label={this.state.fields["ordcarCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="vehiculoCodigo"
                tableName='Vehiculo'
                fields={['vehiculoCodigo', 'vehiculoPlaca']}
                primaryField='vehiculoCodigo'
                aliases={['ID', 'Nombre']}
                searchField='vehiculoPlaca'
                placeHolder='Seleccionar Placa'
                onSelect={this.handleChange}
                value={this.state.fields["vehiculoCodigo"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="terceroCodigoConductor"
                tableName='Tercero'
                fields={['terceroCodigo', 'terceroDocumento', 'terceroNombre1', 'terceroApellido1']}
                primaryField='terceroCodigo'
                aliases={['ID', 'Nombre']}
                searchField='terceroNombre1'
                placeHolder='Seleccionar Conductor'
                onSelect={this.handleChange}
                value={this.state.fields["terceroCodigoConductor"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="terceroCodigoPoseedor"
                tableName='Tercero'
                fields={['terceroCodigo', 'terceroDocumento', 'terceroNombre1', 'terceroApellido1']}
                primaryField='terceroCodigo'
                aliases={['ID', 'Nombre']}
                searchField='terceroNombre1'
                placeHolder='Seleccionar Poseedor'
                onSelect={this.handleChange}
                value={this.state.fields["terceroCodigoPoseedor"]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="terceroCodigoPropietario"
                tableName='Tercero'
                fields={['terceroCodigo', 'terceroDocumento', 'terceroNombre1', 'terceroApellido1']}
                primaryField='terceroCodigo'
                aliases={['ID', 'Nombre']}
                searchField='terceroNombre1'
                placeHolder='Seleccionar Propietario'
                onSelect={this.handleChange}
                value={this.state.fields["terceroCodigoPropietario"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="ordcarEstado"
                useChildren={true}
                value={this.state.fields["ordcarEstado"]}
                onChange={this.handleChange}
                namelabel="Estado (*)"
              >
                <option value="ACTIVO">ACTIVO</option>
                <option value="INACTIVO">INACTIVO</option>
              </PSelect>
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="pedidoCodigo"
                tableName='Pedido'
                fields={['pedidoCodigo', 'pedidoContactoNombre1', 'pedidoContactoApellido1']}
                primaryField='pedidoCodigo'
                aliases={['ID', 'Nombre']}
                searchField='pedidoContactoNombre1'
                placeHolder='Seleccionar Pedido'
                onSelect={this.handleChange}
                value={this.state.fields["pedidoCodigo"]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="ordcarValor" label="Valor (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["ordcarValor"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Usuario" value={Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Creacion" value={this.state.fields.createdAt} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Modificacion" value={this.state.fields.updatedAt} />
            </Grid>
          </Grid>


        </form>
        <div><Buttoncontrol
          saveData={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          edit={(this.state.fields.ordcarCodigo !== null) ? true : false}
          primaryFields={['ordcarCodigo']}
        /></div>
      </Paper>
    );
  }
}

Ordencargue.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(Ordencargue)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}

    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;