import createStyles from '@material-ui/core/styles/createStyles';

const styles = theme => createStyles({
  mainPaper: {
    padding: theme.spacing(),
    paddingBottom: 0,
    marginTop: theme.spacing(2.5),
    marginButton: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
    width: '82%',
    height: '100%',
    alignItems: 'left',
    backgroundColor: '#fff'
  },
  mainImagen: {
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    position: 'relative',
    paddingBottom: 0,
    display: 'solid'
  },
  paper: {
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(77),
    marginBottom: theme.spacing(30),
    position: 'absolute',
    width: '10%',
    height: '25%',
    display: 'solid',
    backgroundColor: '#fff',
    top: 0
  },
  bigAvatar: {
    margin: theme.spacing(),
    width: 120,
    height: 120,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(2),
  }
});

export default styles;