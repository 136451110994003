// import _ from 'lodash';
import ENDPOINTS from '../Config/endpoints';
import axios from 'axios';
import utils from './utils';
import _ from 'lodash';
//import Reactotron from 'reactotron-react-js';

class Api {

  doRequest(params, body, timeToWait) {
    return new Promise((resolve, reject) => {
      return axios({
        method: params.method,
        url: ENDPOINTS.BASE + params.endpoint,
        data: body
      }).then(response => {
        /*Reactotron.display({
          name: 'Api call',
          preview: ENDPOINTS.BASE + params.endpoint,
          value: {
            params: body,
            headers: response.headers,
            body: response.data,
            status: response.status,
            statusText: response.statusText
          }
        });*/


        if (response.status !== 200) {
          return reject(response.statusText);
        }

        if (response.data.error) {
          return reject({
            error: true,
            errorMessage: response.data.error
          });
        }
        return utils.waitFor(timeToWait || 0).then(() => {
          resolve(response.data.data)
        });
      }).catch(ex => {
        console.log("ERRROR:"+ex);
        reject({
          error: true,
          errorMessage: _.get(ex.response, 'data.error') || ex.toString()
        });
      });
    });
  }
}

export default new Api();