import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Textfieldlabel from "../GeneralUI/Textfieldlabel";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import PSelect from '../GeneralUI/PSelect'
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import AutoComplete from '../GeneralUI/AutoComplete';
import Datagrid from '../GeneralUI/PDatagrid/'
import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';
import { errorSetter } from '../../Libs';
import CustomizedExpansionPanel from './../GeneralUI/PExpansionpanels'
import _ from 'lodash';
import moment from 'moment';


class Pedido extends Component {

  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    dataDatagridpedido: [{
      Sucursal: { 'value': null, 'key': null,'visible':true  },
      Tarifa: { 'value': null, 'key': null,'visible':true  },
      UnidadNegocio: { 'value': null, 'key': null,'visible':true  },
      Vendedor: { 'value': null, 'key': null,'visible':true  },
      Cantidad:{ 'value': null, 'key': null,'visible':true  } ,
      VUnitario: { 'value': null, 'key': null,'visible':true  } ,
      descuentoAFacturar: { 'value': 0, 'key': 0,'visible':true  },
      VTotal: { 'value': null, 'key': null,'visible':true  } ,
      NombreBodega: { 'value': null, 'key': null,'visible':true  },
      NombreBodegaAlias: { 'value': null, 'key': null,'visible':true  },
      ValorSeguroBodega: { 'value': null, 'key': null,'visible':true  },
      ValorBodega: { 'value': null, 'key': null,'visible':true  },
      Cubicaje: { 'value': null, 'key': null,'visible':true  } ,
      DuracionBodega: { 'value': null, 'key': null,'visible':true  } ,
      ValorDeclarado:{ 'value': null, 'key': null,'visible':true  } ,
      Estado:{ 'value': null, 'key': null,'visible':false  },
      tipoPago: { 'value': null, 'key': null,'visible':false  },
      tipoTarifa: { 'value': null, 'key': null,'visible':false  }
    }],

    dataDatagridListaEmpaque:[
      {
        Producto: '',
        Cantidad: '',
        Unidad: ''
      }
    ],

    dataDatagridPedidoReferencia: [{
      TipoReferencia: { 'value': null, 'key': null,'visible':true  },
      Nombre: { 'value': null, 'key': null,'visible':true  },
      Documento: { 'value': null, 'key': null,'visible':true  },
      Ciudad: { 'value': null, 'key': null,'visible':true  },
      Direccion: { 'value': null, 'key': null,'visible':true  },      
      Telefono: { 'value': null, 'key': null,'visible':true  },
      Email: { 'value': null, 'key': null,'visible':true  },
      Sucesor: { 'value': null, 'key': null,'visible':true  }
    }],
    dataDatagridEstadoVisita: [{
      Estado: '',
      Observacion: '',
      Usuario: '',
      FechaCreacion: ''
    }],

    dataDatagridDocumentoAsociado: [{
      Sucursal: '',
      DocumentoNombre: '',
      Documento: '',
      FechaCreacion: ''
    }],

    dataDatagridformapago: [{
      Concepto: '',
      FormaPago: '',
      Fecha: '',
      Valor: ''
    }],

    dataDatagridTrazabilidad: [
      {
        Usuario: '',
        DocumentoPrincipal: '',
        DocumentoAsociado: '',
        Evento: '',
        FechaHora: '',
        UsuarioExterno: ''
      }
    ],
    
    dataDatagridPedidoPagos: [
      {
        Usuario: '',
        Concepto: '',
        Valor: '',
        FechaHora: '',
        Observacion: ''
      }
    ],

    fieldsCopy: {},
    fieldsDisabled: {
      pedidoFechaVisita: false,
      pedidoHoraVisita: false,
      pedidoVisita: false,
      comercialCodigo: false
    },
    fieldsVisible: {},

    fields: {
      pedidoCodigo: null,
      pedidoValorPrincipal: 0,
      pedidoValorServicios: 0,
      pedidoTotal: 0,
      clienteCodigo: null,
      pedidoContactoNombre1: null,
      pedidoContactoNombre2: null,
      pedidoContactoApellido1: null,
      pedidoContactoApellido2: null,
      pedidoContactoTelefono: null,
      ciudadCodigoRadicacionContacto: null,
      pedidoContactoDireccion: null,
      pedidoContactoEmail: null,
      pedidoContactoEmailFacturacion: null,
      pedidoEstadoVisita: null,
      pedidoEstadoVisitaObservacion: null,
      tipdocCodigoContacto: null,
      pedidoContactoNumeroDocumento: null,
      pedidoObservacion: null,
      pedidoObservacionCeroEstres: null,
      pedidoObservacionEmpaque: null,
      pedidoObservacionTransporte: null,
      pedidoObservacionMovimientoEspecial: null,
      pedidoObservacionGeneral: null,
      pedidoObservacionBodega: null,

      pedidoNovedadObservacion:null,
      sucursalCodigo: null,

      pedidoFechaServicio: null,
      pedidoFechaIngresoBodega: null,
      pedidoValorContratoBodega:0,
      pedidoValorSeguroContratoBodega:0,
      pedidoHoraServicio: null,
      remitenteCodigo: null,
      destinatarioCodigo: null,
      pedidoFechaVisita: null,
      pedidoHoraVisita: null,
      pedidoVisita: 'NO',
      pedidoPesoCaminadoCargue: 0,
      pedidoPesoCaminadoDescargue: 0,
      pedidoValorDeclarado: 0,
      pedidoValorSeguro: 0,
      tarifaCodigo: null,
      rutaCodigo: null,
      uninegCodigo: null,
      linnegCodigo: null,
      tipserCodigo: null,
      pedidoEstado: 'PENDIENTE',
      pedidoEstadoNovedad: null,
      facturaCodigo: null,
      manifiestoCodigo: null,
      ordcarCodigo: null,
      remesaCodigo: null,
      pedidoCargue: null,
      pedidoDescargue: null,
      comercialCodigo: null,
      forpagCodigo: null,
      pedidoCubicaje: 0,
      pedidoPisoOrigen: 0,
      pedidoPisoDestino: 0,
      productoCodigo: null,
      pedidoCodigoClone: null,
      pedidoTrm: 1,
      pedidoRangosDias: null,
      pedidoMoneda: null,
      ciudadCodigoDestinoInternacional: null,
      ciudadCodigoOrigenInternacional: null,

      pedidoNombreCompletoApoderado: null,
      pedidoApoderadoNumeroDocumento: null,
      tipdocCodigoApoderado: null,
      pedidoFechaEstadoVisita:Utils.getCurrentDate(),

      pedidoEnviarCorreo: 'NO',

      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime(),
    },

    responseForm: { Message: '', typeMessage: '' },

    formValidations: {
      view: ['pedidoContactoNombre1', 'tiprefCodigo', 'sucursalCodigo', 'tipserCodigo','pedidoFechaServicio']
    },

    formErrors: {
      pedidoContactoNombre1: {},
      tiprefCodigo: {},
      sucursalCodigo: {},
      tipserCodigo: {},
      pedidoFechaServicio: {},
    }

  };

  componentDidMount = () => {
    const params = this.props.match.params;
    const queryString = require('querystring');
    const query = queryString.parse(this.props.location.search.replace('?', ''));

    if (_.keys(query).length) {
      if (query.hasOwnProperty('clone') && query.hasOwnProperty('pedidoCodigo')) {
        params.id = parseInt(query.pedidoCodigo);
      }
    }
    
    if (!params.id) {
      return;
    }
   
    this.fillFormData(params.id);    
  }


  fillFormData = (id) => {
    const params = this.props.match.params;
    let clone = false;
    const queryString = require('querystring');
    const query = queryString.parse(this.props.location.search.replace('?', ''));
   
    if (_.keys(query).length) {
      if (query.hasOwnProperty('clone') && query.hasOwnProperty('pedidoCodigo') && this.state.fields.pedidoCodigo == null) {
        clone = true;
      }
    }

    if (!id) {
      return;
    }

    getDocument({
      documentId: id,
      fieldName: 'pedidoCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }

      if (clone) {
        document = this.formatClone(document);
      }

      document.pedidoEnviarCorreo='NO';
      this.setFieldDisabled(document);
      this.setState({ fields: document });
      
      if (!clone) {        
      getDocument({
        documentId: id,
        fieldName: 'pedido_codigo',
        moduleName: 'vw_detalle_pedido_interfaz',
        isView: true,
        fields: ['peddet_codigo',
          'peddet_cantidad',
          'sucursal_codigo',
          'tarifa_codigo',
          'peddet_valorunitario',
          'peddet_descuento',
          'peddet_valortotal',
          'pedido_codigo',
          'peddet_tipo_pago',
          'unineg_codigo',
          'comercial_codigo',
          'sucursal_nombre',
          'tarifa_nombre',
          'unineg_nombre',
          'comercial',
          'documento',
          'peddet_numero_bodega',
          'peddet_numero_bodega_alias',
          'peddet_cubicaje',
          'peddet_valor_declarado',
          'peddet_valor_contrato_bodega',
          'peddet_valor_seguro_contrato_bodega',
          'peddet_duracion_bodega',
          'peddet_estado_encabezado',
          'tiptar_codigo'
        ],
        Limit: 50
      }).then(document => {
        if (!document) {
          return;
        }
        this.builddetailInterface(document);
      }).catch(ex => {
        console.log('index.jsx -- 137 > ex === ', ex);
      });

      getDocument({
        documentId: id,
        fieldName: 'pedido_codigo',
        moduleName: 'vw_pedido_lista_empaque_detalle',
        isView: true,
        fields: ['pedido_codigo',
          'producto_codigo',
          'producto_nombre',
          'peddet_cantidad',
          'tarifa_unidad_medida'
        ],
        Limit: 50
      }).then(document => {
        if (!document) {
          return;
        }
        this.builddetailInterfaceListaEmpaque(document);
      }).catch(ex => {
        console.log('index.jsx -- 137 > ex === ', ex);
      });

      
        getDocument({
          documentId: id,
          fieldName: 'pedido_codigo',
          moduleName: 'vw_detalle_pedido_estados_visitas_interfaz',
          isView: true,
          fields: ['pedestvisdet_codigo',
            'observacion',
            'estado',
            'usuario',
            'pedido_codigo',
            'fechacreacion',
            'fecha_visita',
            'hora_visita',
            'fecha_estado_visita',
          ],
          Limit: 50
        }).then(document => {
          if (!document) {
            return;
          }
          this.builddetailInterfaceEstadosVisita(document);
        }).catch(ex => {
          console.log('index.jsx -- 137 > ex === ', ex);
        });

        getDocument({
          documentId: id,
          fieldName: 'pk',
          moduleName: 'vw_detalle_pedido_trazabilidad',
          isView: true,
          fields: ['docseg_codigo',
            'usuario',
            'usuario_externo',
            'fecha_hora',
            'pk',
            'fk',
            'evento'
          ],
          Limit: 100
        }).then(document => {
          if (!document) {
            return;
          }
          this.builddetailInterfaceTrazabilidad(document);
        }).catch(ex => {
          console.log('index.jsx -- 137 > ex === ', ex);
        });

        getDocument({
          documentId: id,
          fieldName: 'pedido_codigo',
          moduleName: 'vw_detalle_pedido_documentos_asociado_interfaz',
          isView: true,
          fields: ['peddocasodet_codigo',
            'pedido_codigo',
            'documento',
            'documento_nombre',
            'resolucion',
            'factura_valor',
            'fechacreacion'
          ],
          Limit: 50
        }).then(document => {
          if (!document) {
            return;
          }
          this.builddetailInterfaceDocumentoAsociado(document);
        }).catch(ex => {
          console.log('index.jsx -- 137 > ex === ', ex);
        })       
      };

      getDocument({
        documentId: id,
        fieldName: 'pedido_codigo',
        moduleName: 'vw_detalle_pedido_referencia',
        isView: true,
        fields: ['pedrefdet_codigo',
          'pedrefdet_tipo_referencia',
          'pedrefdet_documento',
          'ciudad_codigo',
          'ciudad_nombre',
          'pedido_codigo',
          'pedrefdet_direccion',
          'pedrefdet_telefono',
          'pedrefdet_email',
        ],
        Limit: 50
      }).then(document => {
        if (!document) {
          return;
        }
        this.builddetailInterfaceReferencias(document);
      }).catch(ex => {
        console.log('index.jsx -- 137 > ex === ', ex);
      });

      getDocument({
        documentId: id,
        fieldName: 'pedido_codigo',
        moduleName: 'vw_detalle_pedido_pagos',
        isView: true,
        fields: ['pedpagdet_codigo',
          'pedpagdet_concepto',
          'pedpagdet_valor',
          'pedpagdet_observacion',
          'usuario',
          'fechahora',
        ],
        Limit: 50
      }).then(document => {
        if (!document) {
          return;
        }
        this.builddetailInterfacePagos(document);
      }).catch(ex => {
        console.log('index.jsx -- 137 > ex === ', ex);
      });

    }).catch(ex => {
      console.log('index.jsx -- 140 > ex === ', ex);
    });

    this.calculoValores();    
    
  }

  setFieldDisabled = (document) => {
    var fieldsDisabled=this.state.fieldsDisabled;

    if ((document.pedidoVisita === 'PRESENCIAL' || document.pedidoVisita === 'VIRTUAL')
        && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'JENNY PAOLA ROCHA'
        && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'DIANA KATHERINE GONZALEZ OSORIO'
        && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'ALEX BONILLA'           
        && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'LUIS NABOR MARINEZ ANGULO'         
      ) {
      this.message('info', 'EL PEDIDO TIENE VISITA, SOLO PUEDE EDITAR LOS DATOS DE LA VISITA LA LIDER COMERCIAL Y LA GERENTE.'); 
      fieldsDisabled.pedidoFechaVisita=true;
      fieldsDisabled.pedidoHoraVisita=true;
      fieldsDisabled.pedidoVisita=true;
      fieldsDisabled.comercialCodigo=true;
      this.setState(fieldsDisabled);
    }
  }

  formatClone = (document) => {
    document.pedidoCodigoClone=document.pedidoCodigo;
    document.pedidoCodigo = null;
    document.pedidoEstado = 'PENDIENTE';
    document.ordcarCodigo = null;
    document.facturaCodigo = null;
    document.manifiestoCodigo = null;
    document.pedidoObservacion = null;
    document.remesaCodigo= null;
    document.pedidoVisita = null;
    document.pedidoFechaVisita = null;
    document.pedidoHoraVisita = null;
    document.comercialCodigo = null;
    document.pedidoEstadoVisita = null;
    document.pedidoFechaEstadoVisita = null;
    document.pedidoEstadoVisitaObservacion = null;
    document.productoCodigo= null;
    document.pedidoContactoDireccion= null;
    document.ciudadCodigoRadicacionContacto=null;
    //document.tiprefCodigo=null;

    document.pedidoValorPrincipal= 0;
    document.pedidoValorServicios= 0;
    document.pedidoTotal= 0;
    document.clienteCodigo= null;
    document.pedidoNovedadObservacion=null;
    //document.sucursalCodigo= null;
    document.pedidoFechaServicio= null;
    //document.pedidoFechaIngresoBodega= null;
    //document.pedidoValorContratoBodega= 0;
    //document.pedidoValorSeguroContratoBodega= 0;
    document.pedidoHoraServicio= null;
    document.remitenteCodigo= null;
    document.destinatarioCodigo= null;
    document.pedidoPesoCaminadoCargue= 0;
    document.pedidoPesoCaminadoDescargue= 0;
    document.pedidoValorDeclarado= 0;
    document.pedidoValorSeguro= 0;
    document.tarifaCodigo= null;
    document.rutaCodigo= null;
    //document.uninegCodigo= null;
    //document.linnegCodigo= null;
    //document.tipserCodigo= null;
    document.pedidoEstadoNovedad= null;
    
    document.pedidoCargue= null;
    document.pedidoDescargue= null;
    //document.forpagCodigo= null;
    document.pedidoCubicaje= 0;
    document.pedidoPisoOrigen= 0;
    document.pedidoPisoDestino= 0;
    
    document.pedidoCodigoClone= null;
    
    document.pedidoTrm= 1;
    document.pedidoRangosDias= null;
    document.pedidoMoneda= null;
    document.ciudadCodigoDestinoInternacional= null;
    document.ciudadCodigoOrigenInternacional= null;
    document.pedidoNombreCompletoApoderado= null;
    document.pedidoApoderadoNumeroDocumento= null;
    document.tipdocCodigoApoderado= null;

    document.createdAt = Utils.fullDateTime();
    document.updatedAt = Utils.fullDateTime();
    
    delete document.usuarioCodigoModificadoPor;
    delete document.usuarioCodigoCreadoPor;
    delete document.pedidoFechacreacion;
    delete document.uninegNombre;
    delete document.tipserNombre;
    delete document.usuarioNombreUsuario;
    delete document.origen;
    delete document.destino;
    delete document.pedido_codigo;
    delete document.pedido_razon_social;
    delete document.ruta;
    delete document.pedido_estado;
    delete document.comercialNombreCompleto;
    delete document.tiempoFaltante;
    delete document.ciudadNombreContacto;
  
    return document;
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {

    let { fields } = this.state;
    const caret = event.target.selectionStart;
    const element = event.target;
    if (element.hasOwnProperty('setSelectionRange')) {
      window.requestAnimationFrame(() => {
        element.setSelectionRange(caret, caret);
      });
    }

    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }

    if (fields[event.target.id] === '') {
      fields[event.target.id] = null;
    }

    this.setState(fields);
  }

  handleBlur = (event) => {
    if (event.target.id === 'porcentajeSeguro') {
      this.calculoSeguro();
    }
  }

  handleDatagrid = (data) => {
    const detalle = data.setComponente;

    switch (detalle) {
      case 'pedido':
        this.setState({ dataDatagridpedido: data });
        this.calculoValores();
        break;
      case 'listaEmpaque':
        this.setState({ dataDatagridListaEmpaque: data });
        break;
      case 'visita':
        this.setState({ dataDatagridvisita: data });
        break;
      case 'trazabilidad':
        this.setState({ dataDatagridTrazabilidad: data });
        break;
      case 'pagos':
        this.setState({ dataDatagridPedidoPagos: data });
        break;
      case 'formapago':
        this.setState({ dataDatagridformapago: data });
        break;
      case 'pedidoReferencias':
        this.setState({ dataDatagridPedidoReferencia: data });
        break;
      default:
        return null;
    }
  }

  builddetailInterface = (data) => {
    let rows = data.map(row => {
      return {
        Sucursal: { 'value': row.sucursal_codigo, 'key': row.sucursal_nombre, 'visible':true },
        Tarifa: { 'value': row.tarifa_codigo, 'key': row.tarifa_nombre, 'visible':true },
        UnidadNegocio: { 'value': row.unineg_codigo, 'key': row.unineg_nombre , 'visible':true},
        Vendedor: { 'value': row.comercial_codigo, 'key': row.comercial, 'visible':true },
        Cantidad: { 'value': row.peddet_cantidad, 'key': row.peddet_cantidad, 'visible':true },
        VUnitario: { 'value': row.peddet_valorunitario, 'key': parseFloat(row.peddet_valorunitario).toLocaleString(), 'visible':true },
        descuentoAFacturar: { 'value': row.peddet_descuento, 'key': parseFloat(row.peddet_descuento).toLocaleString(), 'visible':true },
        VTotal: { 'value': row.peddet_valortotal, 'key': parseFloat(row.peddet_valortotal).toLocaleString(), 'visible':true },
        NombreBodega: { 'value': row.peddet_numero_bodega, 'key': row.peddet_numero_bodega , 'visible':true },
        NombreBodegaAlias: { 'value': row.peddet_numero_bodega_alias, 'key': parseFloat(row.peddet_numero_bodega_alias).toLocaleString(), 'visible':true },
        
        ValorSeguroBodega: { 'value': row.peddet_valor_seguro_contrato_bodega, 'key': row.peddet_valor_seguro_contrato_bodega, 'visible':true },
        ValorBodega: { 'value': row.peddet_valor_contrato_bodega, 'key': row.peddet_valor_contrato_bodega, 'visible':true },

        Cubicaje: { 'value': row.peddet_cubicaje, 'key': row.peddet_cubicaje, 'visible':true },
        DuracionBodega: { 'value': row.peddet_duracion_bodega, 'key': row.peddet_duracion_bodega, 'visible':true },
        ValorDeclarado: { 'value': row.peddet_valor_declarado, 'key': row.peddet_valor_declarado, 'visible':true },
        Estado: { 'value': row.peddet_estado_encabezado, 'key': row.peddet_estado_encabezado, 'visible':false },
        tipoPago: { 'value': row.peddet_tipo_pago, 'key': row.peddet_tipo_pago, 'visible':false },
        tipoTarifa: { 'value': row.tiptar_codigo, 'key': row.tiptar_codigo, 'visible':false }

      };
    });
    this.setState({ dataDatagridpedido: rows });
  }

  
  builddetailInterfaceListaEmpaque = (data) => {
    let rows = data.map(row => {
      return {
        Producto: { 'value': row.producto_nombre, 'key': row.producto_nombre, 'visible':true },
        Cantidad: { 'value': row.peddet_cantidad, 'key': row.peddet_cantidad, 'visible':true },
        Unidad: { 'value': row.tarifa_unidad_medida, 'key': row.tarifa_unidad_medida, 'visible':true }
      };
    });
    this.setState({ dataDatagridListaEmpaque: rows });
  }

  builddetailInterfaceReferencias = (data) => {
    let rows = data.map(row => {
      return {
        TipoReferencia: { 'value': row.pedrefdet_tipo_referencia, 'key': row.pedrefdet_tipo_referencia, 'visible':true },
        Nombre: { 'value': row.pedrefdet_nombre, 'key': row.pedrefdet_nombre, 'visible':true },
        Documento: { 'value': row.pedrefdet_documento, 'key': row.pedrefdet_documento, 'visible':true },
        Ciudad: { 'value': row.ciudad_codigo, 'key': row.ciudad_nombre, 'visible':true },
        Direccion: { 'value': row.pedrefdet_direccion, 'key': row.pedrefdet_direccion, 'visible':true },
       
        Telefono: { 'value': row.pedrefdet_telefono, 'key': row.pedrefdet_telefono, 'visible':true },
        Email: { 'value': row.pedrefdet_email, 'key': row.pedrefdet_email, 'visible':true },
        Sucesor: { 'value': row.pedrefdet_sucesor, 'key': row.pedrefdet_sucesor, 'visible':true },
      };
    });
    this.setState({ dataDatagridPedidoReferencia: rows });
  }


  builddetailInterfacePagos = (data) => {
    let rows = data.map(row => {
      return {
        Usuario:  { 'value': row.usuario, 'key': row.usuario, 'visible':true },
        Concepto: { 'value': row.pedpagdet_concepto, 'key': row.pedpagdet_concepto, 'visible':true },
        Valor: { 'value': row.pedpagdet_valor, 'key': row.pedpagdet_valor, 'visible':true },
        FechaHora: { 'value': row.fechahora, 'key': row.fechahora, 'visible':true },
        Observacion: { 'value': row.pedpagdet_observacion, 'key': row.pedpagdet_observacion, 'visible':true }
      };
    });
    this.setState({ dataDatagridPedidoPagos: rows });
  }

  builddetailInterfaceTrazabilidad = (data) => {

    let linkModulePrint, linkModule = '', linkVisible=true, fechaHora='', rows;
    
    linkModulePrint = '';

    rows = data.map(row => {

      if (row.documento_codigo_fk===5) {
        linkModulePrint = '/#' + row.fk;
        linkModule = '/home/pedido/' + row.fk + '/edit';
        linkVisible=true;
      }else if(row.documento_codigo_fk===4){
        linkModulePrint = '/#' + row.fk;
        linkModule = '/home/ordencargue/' + row.fk + '/edit';
        linkVisible=true;
      }else if(row.documento_codigo_fk===3){
        linkModulePrint = '/#' + row.fk;
        linkModule = '/home/remesa/' + row.fk + '/edit';
        linkVisible=true;
      }else if(row.documento_codigo_fk===2){
        linkModulePrint = '/#' + row.fk;
        linkModule = '/home/manifiesto/' + row.fk + '/edit';
        linkVisible=true;
      }else if(row.documento_codigo_fk===1){
        linkModulePrint = '/#' + row.fk;
        linkModule = '/home/factura/' + row.fk + '/edit';
        linkVisible=true;  
      }else if(row.documento_codigo_fk===11){
        linkModulePrint = '/#' + row.fk;
        linkModule = '/home/cotizacion/' + row.fk + '/edit';
        linkVisible=true;               
      }else{
        linkModulePrint = '';
        linkModule = '';
        linkVisible=''; 
      }

      fechaHora = moment(row.fechacreacion+'').format('YYYY-MM-DD HH:mm:ss');

      return {
        Usuario: { 'value': row.usuario, 'key': row.usuario, 'visible':true },
        DocumentoPrincipal: { 'value': row.pk, 'key': row.pk , 'visible':true},
        DocumentoAsociado: { 'value': row.fk, 'key': row.fk,'link': linkVisible, 'href': linkModule, 'visible':true },
        Evento: { 'value': row.evento, 'key': row.evento, 'visible':true },
        FechaHora: { 'value': fechaHora, 'key': fechaHora, 'visible':true },
      };

    });

    this.setState({ dataDatagridTrazabilidad: rows });
  }

  builddetailInterfaceEstadosVisita = (data) => {

    let rows = data.map(row => {
      return {
        Estado: { 'value': row.estado, 'key': row.estado, 'visible':true },
        FechaVisita: { 'value': row.fecha_visita, 'key': row.fecha_visita, 'visible':true },
        HoraVisita: { 'value': row.hora_visita, 'key': row.hora_visita, 'visible':true },
        FechaEstadoVisita: { 'value': row.fecha_estado_visita, 'key': row.fecha_estado_visita, 'visible':true },
        Observacion: { 'value': row.observacion, 'key': row.observacion, 'visible':true },
        Usuario: { 'value': row.usuario, 'key': row.usuario, 'visible':true },
        FechaCreacion: { 'value': row.fechacreacion, 'key': row.fechacreacion, 'visible':true }
      };
    });
    this.setState({ dataDatagridEstadoVisita: rows });

  }

  builddetailInterfaceDocumentoAsociado = (data) => {

    let linkModulePrint, linkModule, rows = data.map(row => {
      if (row.documento_nombre === 'ORDENCARGUE') {
        linkModulePrint = '/imprimir?file=impresion_ordencargue_elhogar.json&ID=' + row.documento;
        linkModule = '/home/ordencargue/' + row.documento + '/edit';
      }
      if (row.documento_nombre === 'FACTURA') {
        linkModulePrint = '/imprimir?file=impresion_factura_media_carta_elhogar.json&ID=' + row.documento;
        linkModule = '/home/factura/' + row.documento + '/edit';

      }
      if (row.documento_nombre === 'MANIFIESTO') {
        linkModulePrint = '/imprimir?file=impresion_manifiesto_elhogar.json&ID=' + row.documento;
        linkModule = '/home/manifiesto/' + row.documento + '/edit';
      }
      if (row.documento_nombre === 'REMESA') {
        linkModulePrint = '/#' + row.documento;
        linkModule = '/home/remesa/' + row.documento + '/edit';
      }

      if (row.documento_nombre === 'CONTRATO BODEGA') {
        linkModulePrint = '/imprimir?file=impresion_contrato_bodega_bodehogar.json&ID=' + row.documento;
        linkModule = '/#' + row.documento;
      }

      if (row.documento_nombre === 'CONTRATO BODEGA EXCLUSIVO') {
        linkModulePrint = '/imprimir?file=impresion_contrato_bodega_exclusivo_bodehogar.json&ID=' + row.documento;
        linkModule = '/#' + row.documento;
      }

      if (row.documento_nombre === 'LISTA EMPAQUE') {
        linkModulePrint = '/imprimir?file=impresion_lista_empaque_elhogar.json&ID=' + row.documento;
        linkModule = '/home/lista_empaque/' + row.documento + '/edit';
      }

      if (row.documento_nombre === 'COTIZACION') {
        linkModulePrint = '/imprimir?file=impresion_cotizacion_nacional_x_version_elhogar.json&ID=' + row.documento;
        linkModule = '/home/cotizacion/' + row.documento + '/edit';
      }

      if (row.documento_nombre === 'CUMPLIDO_MANIFIESTO') {
        linkModulePrint = '/#' + row.documento;
        linkModule = '/home/pedido_cumplido/' + row.documento + '/edit';
      }

      return {
        Sucursal: { 'value': row.sucursal, 'key': row.sucursal, 'visible':true },
        DocumentoNombre: { 'value': row.documento_nombre, 'key': row.documento_nombre, 'visible':true },
        ConsultarDocumento: { 'value': row.documento, 'key': row.documento, 'link': true, 'href': linkModule, 'visible':true },
        Imprimir: { 'value': row.documento, 'key': row.documento, 'link': true, 'href': linkModulePrint, 'visible':true },
        FechaCreacion: { 'value': row.fechacreacion, 'key': row.fechacreacion, 'visible':true }
      };
    });
    this.setState({ dataDatagridDocumentoAsociado: rows });

  }


  calculoValores = () => {
    var valorServicio = 0;
    var total = 0;
    var auxField = this.state.fields;
    this.state.dataDatagridpedido.map((item) => {
      if (item.VTotal.value > 0) {
        valorServicio += parseFloat(item.VTotal.value);
      }
      return item;
    })

    auxField.pedidoValorServicios = parseFloat(valorServicio);

    total = parseFloat(auxField.pedidoValorServicios) + parseFloat(auxField.pedidoValorPrincipal) + parseFloat(auxField.pedidoValorSeguro);

    auxField.pedidoTotal = parseFloat(total);
    this.setState(auxField);
  }


  calculoSeguro = () => {
    var total = 0;
    var auxField = this.state.fields;
    total = parseFloat(auxField.pedidoValorDeclarado / 100) * (auxField.porcentajeSeguro ? auxField.porcentajeSeguro : 0);
    auxField.pedidoValorSeguro = parseFloat(total);
    this.setState(auxField);
    this.calculoValores();
  }



  messageValidation = (type, message) => {
    this.message(type, message);
  }

  save = (response) => {
    this.setState({
      fields: response.data,
      responseForm: response.responseForm
    }
    );

    if(this.state.responseForm['typeMessage']==='success'){
      this.fillFormData(this.state.fields.pedidoCodigo); 
    }

    this.message(this.state.responseForm['typeMessage'], this.state.responseForm['Message']);
  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }

  validaData = () => {
    let isvalid = true, bandera_tarifa = false, bandera_tarifa_seguro_bodega, cont_ref_suc=0, observacion='';
    let message = 'El registro no ha sido guardado.';
    let type = "warning";

    bandera_tarifa_seguro_bodega=false;
    this.calculoValores();

    if (this.state.fields.pedidoEstado === 'CONFIRMADO') {

      if(this.state.fields.tiprefCodigo === '13'){
        message = "El tipo de referencia WHATSAPP no es valido.";
        isvalid = false;
      }

      if ((this.state.fields.rutaCodigo == null || !String(this.state.fields.rutaCodigo).trim())) {
        message = "Debe diligenciar la RUTA.";
        isvalid = false;
      }

      if ((this.state.fields.forpagCodigo == null || !String(this.state.fields.forpagCodigo).trim())) {
        message = "Debe diligenciar la FORMA DE PAGO.";
        isvalid = false;
      }

      if ((this.state.fields.pedidoCargue == null || !String(this.state.fields.pedidoCargue).trim())) {
        message = "Debe diligenciar el CARGUE.";
        isvalid = false;
      }

      if ((this.state.fields.pedidoDescargue == null || !String(this.state.fields.pedidoDescargue).trim())) {
        message = "Debe diligenciar el DESCARGUE.";
        isvalid = false;
      }

      if (this.state.fields.pedidoPesoCaminadoCargue <= 0 || this.state.fields.pedidoPesoCaminadoDescargue <= 0
        || (this.state.fields.pedidoCubicaje <= 0 && this.state.fields.uninegCodigo === 2)) {
        message = "Debe diligenciar el CUBICAJE, METROS CAMINADOS CARGUE Y DESCARGUE.";
        isvalid = false;
      }

      if ((parseFloat(this.state.fields.pedidoTotal) <= 0 || this.state.fields.pedidoTotal == null) ||
        (parseFloat(this.state.fields.pedidoTotal) < parseFloat(this.state.fields.pedidoValorServicios)) ||
        (parseFloat(this.state.fields.pedidoTotal) < parseFloat(this.state.fields.pedidoValorPrincipal)) ||
        (parseFloat(this.state.fields.pedidoTotal) < (parseFloat(this.state.fields.pedidoValorPrincipal) + parseFloat(this.state.fields.pedidoValorServicios)))
      ) {
        message = "El valor total no es correcto. Grabe el registro nuevamente.";
        isvalid = false;
      }

      var regex = /^\S+@\S+\.\S+$/;
      if (!regex.test(this.state.fields.pedidoContactoEmail)) {
        message = "El campo email no es valido en DATOS DEL CLIENTE. VALIDAR QUE NO HAYAN ESPACIOS EN BLANCO AL FINAL O AL INICIO DEL EMAIL";
        isvalid = false;
      }

      
      /*var regex = /^\S+@\S+\.\S+$/;
      if (!regex.test(this.state.fields.pedidoContactoEmailFacturacion) && (this.state.fields.pedidoContactoNumeroDocumento !== null)) {
        message = "El campo email no es valido en DATOS DEL CLIENTE. VALIDAR QUE NO HAYAN ESPACIOS EN BLANCO AL FINAL O AL INICIO DEL EMAIL";
        isvalid = false;
      }*/

      regex = /^[a-zA-Z0-9]+$/;
      if (!regex.test(this.state.fields.pedidoContactoNumeroDocumento) || (this.state.fields.pedidoContactoNumeroDocumento == null || !String(this.state.fields.pedidoContactoNumeroDocumento).trim())) {
        message = "El Campo Documento no es valido en DATOS DEL CLIENTE, SOLO SE ACEPTAN NUMEROS Y LETRAS Y SIN DIGITO DE VERIFICACION, VALIDAR QUE NO HAYAN ESPACIOS EN BLANCO AL FINAL O AL INICIO DEL DOCUMENTO.";
        isvalid = false;
      }

      observacion=this.state.fields.pedidoObservacion+ ' '+this.state.fields.pedidoObservacionCeroEstres+' '+
      this.state.fields.pedidoObservacionEmpaque+' '+this.state.fields.pedidoObservacionTransporte+' '+
      this.state.fields.pedidoObservacionMovimientoEspecial+' '+this.state.fields.pedidoObservacionGeneral+' '+
      this.state.fields.pedidoObservacionBodega;
      if ((observacion == null || !String(observacion).trim())) {
        message = "La Observacion debe tener mas de 50 caracteres.";
        isvalid = false;      
      }else if(observacion.length < 50){
        message = "La Observacion debe tener mas de 50 caracteres.";
        isvalid = false;    
      }

      this.state.dataDatagridpedido.map((item) => {
        /*if (item.Tarifa.value === 2 || item.Tarifa.value === 839 || item.Tarifa.value === 840 || item.Tarifa.value === 831) {
          bandera_tarifa = true;
        }*/

        if (item.tipoTarifa.value === 2 || item.tipoTarifa.value === 13) {
          bandera_tarifa = true;
        }

        if (item.Tarifa.value === 1) {
          bandera_tarifa_seguro_bodega = true;
        }
        return item;
      });

      if (bandera_tarifa) {
        if (
          this.state.fields.tipdocCodigoContacto === 1 &&
          ((this.state.fields.pedidoNombreCompletoApoderado == null || !String(this.state.fields.pedidoNombreCompletoApoderado).trim()) ||
            (this.state.fields.tipdocCodigoApoderado == null || !String(this.state.fields.tipdocCodigoApoderado).trim()) ||
            (this.state.fields.pedidoApoderadoNumeroDocumento == null || !String(this.state.fields.pedidoApoderadoNumeroDocumento).trim()))) {
          message = "El representante legal o apoderado es obligatorio.";
          isvalid = false;
        }

        if ((this.state.fields.pedidoFechaIngresoBodega == null || !String(this.state.fields.pedidoFechaIngresoBodega).trim())) {
          message = "La fecha de ingreso de bodega debe ser obligatorio.";
          isvalid = false;
        }

        /*if ((this.state.fields.pedidoValorContratoBodega == null || !String(this.state.fields.pedidoValorContratoBodega).trim())) {
          message = "El valor del contrato de bodega debe ser obligatorio.";
          isvalid = false;
        }*/

        if (this.state.fields.tipdocCodigoContacto !== 1 && this.state.dataDatagridPedidoReferencia.length <= 1) {
          message = " Debe diligenciar minimo dos referencias.";
          isvalid = false;
        }

        this.state.dataDatagridPedidoReferencia.map((item) => {
          if (item.Sucesor.value === 'SI') {
            cont_ref_suc ++;
          }
          return item;
        });

        if(this.state.fields.tipdocCodigoContacto !== 1 && cont_ref_suc < 1){
          message = " Debe diligenciar minimo una referencia de Tipo sucesor.";
          isvalid = false;
        }

        if ((this.state.fields.pedidoContactoNumeroDocumento !== this.state.pedidoContactoNumeroDocumento ||
          this.state.fields.pedidoNombreCompletoApoderado !== this.state.pedidoNombreCompletoApoderado ||
          this.state.fields.tipdocCodigoApoderado !== this.state.tipdocCodigoApoderado ||
          this.state.fields.pedidoApoderadoNumeroDocumento !== this.state.pedidoApoderadoNumeroDocumento ||
          this.state.fields.pedidoContactoNombre1 !== this.state.pedidoContactoNombre1 ||
          this.state.fields.pedidoContactoNombre2 !== this.state.pedidoContactoNombre2 ||
          this.state.fields.pedidoContactoApellido1 !== this.state.pedidoContactoApellido1 ||
          this.state.fields.pedidoContactoApellido2 !== this.state.pedidoContactoApellido2 ||
          this.state.fields.tipdocCodigoContacto !== this.state.tipdocCodigoContacto ||
          this.state.fields.pedidoContactoDireccion !== this.state.pedidoContactoDireccion ||
          this.state.fields.ciudadCodigoRadicacionContacto !== this.state.ciudadCodigoRadicacionContacto ||
          this.state.fields.pedidoFechaServicio !== this.state.pedidoFechaServicio ||
          this.state.fields.pedidoFechaIngresoBodega !== this.state.pedidoFechaIngresoBodega ||
          this.state.fields.pedidoHoraServicio !== this.state.pedidoHoraServicio)
          && ((Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'JENNY PAOLA ROCHA'
            && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'LUIS NABOR MARINEZ ANGULO'
            && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'CARLOS ARTURO CARDONA GONZALEZ')

        ) {
          message = "No puede cambiar datos involucrados en el contrato de bodega ORIGEN:";
          isvalid = false;
        }
      }

     /* if (bandera_tarifa_seguro_bodega && (this.state.fields.pedidoValorSeguroContratoBodega == null || !String(this.state.fields.pedidoValorSeguroContratoBodega).trim())) {
        message = "El valor del seguro del contrato de bodega debe ser obligatorio.";
        isvalid = false;
      }*/

    }

    if ((this.state.fields.clienteCodigo === null || !String(this.state.fields.clienteCodigo).trim()) && this.state.fields.uninegCodigo === 3) {
      message = "Debe diligenciar el CLIENTE.";
      isvalid = false;
    }

    if (this.state.fields.forpagCodigo === '5' || this.state.fields.forpagCodigo === '6') {
      message = "La FORMA DE PAGO no es valida..";
      isvalid = false;
    }

    if (this.state.fields.pedidoVisita === 'PRESENCIAL' || this.state.fields.pedidoVisita === 'VIRTUAL') {

      if ((this.state.fields.pedidoFechaVisita == null || !String(this.state.fields.pedidoFechaVisita).trim())) {
        message = "Debe diligenciar la FECHA DE VISITA.";
        isvalid = false;
      }

      if ((this.state.fields.pedidoHoraVisita == null || !String(this.state.fields.pedidoHoraVisita).trim())) {
        message = "Debe diligenciar la HORA DE VISITA.";
        isvalid = false;
      }

      if ((this.state.fields.pedidoEstadoVisita == null || !String(this.state.fields.pedidoEstadoVisita).trim())) {
        message = "Debe diligenciar el ESTADO DE LA VISITA.";
        isvalid = false;
      }

    }

    if ((this.state.fields.pedidoEstado === 'FACTURADO' || this.state.fields.pedidoEstado === 'DESPACHADO'
      || this.state.fields.pedidoEstado === 'ANULADO')
      && ((Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'JENNY PAOLA ROCHA'
        && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'LUIS NABOR MARINEZ ANGULO'
        && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'CARLOS ARTURO CARDONA GONZALEZ'
        && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'APRENDIZ CONTABLE BODEHOGAR'
      )
    ) {
      message = "No se puede actualizar un servicio en estado:" + this.state.fields.pedidoEstado;
      isvalid = false;
      type = "error"
    }

    if ((this.state.fields.pedidoVisita === 'PRESENCIAL' || this.state.fields.pedidoVisita === 'VIRTUAL')
    && this.state.fields.pedidoEstadoVisita !== 'CUMPLIDA'
    && this.state.fields.pedidoEstadoVisita !== 'PROGRAMADA'
    && ((Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'JENNY PAOLA ROCHA'
    && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'LUIS NABOR MARINEZ ANGULO'
    && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'DIANA KATHERINE GONZALEZ OSORIO' 
    && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'ALEX BONILLA'           
    )
  ) {
    message = "Solo puedes programar o cumplir la visita.";
    isvalid = false;
    type = "error"
  }

    if ((this.state.fields.pedidoEstado === 'PENDIENTE' || this.state.fields.pedidoEstado === 'CONFIRMADO')
      && (this.state.fields.ordcarCodigo !== null && this.state.fields.ordcarCodigo !== '0')) {
      message = "No se puede actualizar el pedido ya se han generado documentos.";
      isvalid = false;
      type = "error"
    }

    if (this.state.fields.pedidoEstado === 'RECHAZADO' && (this.state.fields.pedidoEstadoNovedad == null || !String(this.state.fields.pedidoEstadoNovedad).trim())) {
      message = "Debe ingresar la novedad y/o observacion de rechazo.";
      isvalid = false;
      type = "error"
    }

    if (this.state.fields.pedidoEstado === 'RECHAZADO' && (this.state.fields.pedidoNovedadObservacion == null || !String(this.state.fields.pedidoNovedadObservacion).trim())) {
      message = "Debe ingresar la novedad y/o observacion de rechazo.";
      isvalid = false;
      type = "error"
    }

    if (this.state.fields.pedidoEstado === 'ANULADO') {
      message = "No se puede anular el pedido por esta pagina.";
      isvalid = false;
      type = "error"
    }

    if ((this.state.fields.tipdocCodigoContacto === 1 &&
      (this.state.fields.pedidoContactoApellido1 !== null || this.state.fields.pedidoContactoNombre2 !== null ||
        this.state.fields.pedidoContactoApellido2 !== null)) ||
      (this.state.fields.tipdocCodigoContacto === 2 &&
        this.state.fields.pedidoContactoApellido1 === null)
    ) {
      message = "Validar la informacion de contacto. Un NIT no puede tener apellido y una persona debe tener apellidos.";
      isvalid = false;
    }

    if (isvalid && ((Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'JENNY PAOLA ROCHA'
      && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'LUIS NABOR MARINEZ ANGULO'
      && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'CARLOS ARTURO CARDONA GONZALEZ')) {

      var current_date = new Date();
      current_date.setHours(0, 0, 0);
      var res = (this.state.fields.pedidoFechaServicio || '').split("-");

      var fechados = new Date(res[0], res[1] - 1, res[2], 0, 0, 0);

      if (!Utils.onlyComparateDate(current_date, fechados) && this.state.fields.pedidoEstado !== 'RECHAZADO' ) {
        message = "Verifique la FECHA DE SERVICIO.";
        isvalid = false;
      }

      if ((this.state.fields.pedidoVisita === 'VIRTUAL' || this.state.fields.pedidoVisita === 'PRESENCIAL') 
      && this.state.fields.pedidoEstado !== 'CONFIRMADO' && 
      ( this.state.fields.pedidoEstadoVisita === 'PROGRAMADA' ||  
      this.state.fields.pedidoEstadoVisita === 'REPROGRAMADA' )) {
       
        res = (this.state.fields.pedidoFechaVisita || '').split("-");
        fechados = new Date(res[0], res[1] - 1, res[2], 0, 0, 0);

        if (!Utils.onlyComparateDate(current_date, fechados)) {
          message = "Verifique la FECHA DE VISITA.";
          isvalid = false;
        }

        if(this.state.fields.pedidoEstadoVisita !== 'PROGRAMADA'){
          if ((this.state.fields.pedidoEstadoVisitaObservacion == null || !String(this.state.fields.pedidoEstadoVisitaObservacion).trim())) {
            message = "Verifique la OBSERVACION DE LA VISITA.";
            isvalid = false;
          }
        }
      }  
    }

    if ((this.state.fields.pedidoEstado === 'FACTURADO' || this.state.fields.pedidoEstado === 'DESPACHADO')
      && ((Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) === 'JENNY PAOLA ROCHA' || (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) === 'LUIS NABOR MARINEZ ANGULO'
        || (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) === 'CRISTIAN ANDRES SUAREZ MELO'
        || (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) === 'APRENDIZ CONTABLE BODEHOGAR')

    ) {
      isvalid = true;
    }

    if (!isvalid) {
      this.message(type, message);
    }
    return isvalid;
  }


  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>

            <InputLabel id="pedidoCodigo"
              className={this.props.classes.tittleLabelForm}>Pedido
            </InputLabel>

            <Grid item xs={3} className={this.props.classes.chipTitle}>
              {(this.state.fields.pedidoCodigo !== null) ?
                (<Chip
                  label={this.state.fields["pedidoCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>

          <Grid  >
            <CustomizedExpansionPanel tittle="Datos del Cliente"></CustomizedExpansionPanel>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoContactoNombre1" label="Nombre1 (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoContactoNombre1"] || undefined}
                error={this.state.formErrors['pedidoContactoNombre1'].error}
                autoFocus={true}
                helperText={this.state.formErrors['pedidoContactoNombre1'].errorMessage}
                InputLabelProps={{ shrink: true }}
                FormHelperTextProps={{ focused: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoContactoNombre2" label="Nombre2" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoContactoNombre2"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoContactoApellido1" label="Apellido1" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoContactoApellido1"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoContactoApellido2" label="Apellido2" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoContactoApellido2"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Tipo Documento"
                id="tipdocCodigoContacto"
                namelineInput="tipodocumento"
                fieldVisible="tipdocNombre"
                fieldCode="tipdocCodigo"
                Table="Tipodocumento"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["tipdocCodigoContacto"]}
                estados={this.state}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoContactoNumeroDocumento" label="Documento" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoContactoNumeroDocumento"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoContactoTelefono" label="Telefono" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoContactoTelefono"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="ciudadCodigoRadicacionContacto"
                tableName='vw_ciudad'
                fields={['ciudad_codigo','ciudad_nombre', 'departamento_nombre']}
                isView={true}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Ciudad', size: 5 }, { value: 'Departamento', size: 6 }]}
                size='xx'
                primaryField='ciudad_codigo'
                searchField='ciudad_nombre'
                placeHolder='Seleccionar Ciudad Radicacion'
                onSelect={this.handleChange}
                value={this.state.fields["ciudadCodigoRadicacionContacto"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoContactoDireccion" label="Direccion" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoContactoDireccion"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
          <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoContactoEmail" label="Email" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoContactoEmail"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoContactoEmailFacturacion" label="Email Facturacion" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoContactoEmailFacturacion"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="pedidoEnviarCorreo"
                useChildren={true}
                value={this.state.fields["pedidoEnviarCorreo"]}
                onChange={this.handleChange}
                namelabel="Enviar Correo"
              >
                <option value="NO">NO</option>
                <option value="SI">SI</option>
              </PSelect>
            </Grid>
          </Grid>



          <Grid  >
            <CustomizedExpansionPanel tittle="Referencias del Cliente +++">
              <Datagrid datosHeader={this.state.fields} dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridPedidoReferencia} setComponente="pedidoReferencias" />
            </CustomizedExpansionPanel>
          </Grid>

          <Grid  >
            <CustomizedExpansionPanel tittle="Datos Representante Legal o Apoderado Contrato de Bodega"></CustomizedExpansionPanel>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoNombreCompletoApoderado" label="Nombre Completo Apoderado" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoNombreCompletoApoderado"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Tipo Documento Apoderado"
                id="tipdocCodigoApoderado"
                namelineInput="tipodocumento"
                fieldVisible="tipdocNombre"
                fieldCode="tipdocCodigo"
                Table="Tipodocumento"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["tipdocCodigoApoderado"]}
                estados={this.state}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoApoderadoNumeroDocumento" label="Documento Apoderado" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoApoderadoNumeroDocumento"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid  >

          <Grid  >
            <CustomizedExpansionPanel tittle="Datos de Visita"></CustomizedExpansionPanel>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="pedidoVisita"
                useChildren={true}
                value={this.state.fields["pedidoVisita"]}
                onChange={this.handleChange}
                namelabel="Visita"
                disabled={this.state.fieldsDisabled["pedidoVisita"]}
              >
                <option value="PRESENCIAL">PRESENCIAL</option>
                <option value="VIRTUAL">VIRTUAL</option>
                <option value="NO">NO</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoFechaVisita"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoFechaVisita"] || undefined}
                type="date"
                label="Fecha Visita"
                InputLabelProps={{ shrink: true }}
                disabled={this.state.fieldsDisabled["pedidoFechaVisita"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoHoraVisita"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoHoraVisita"] || undefined}
                type="time"
                label="Hora Visita"
                InputLabelProps={{ shrink: true }}
                disabled={this.state.fieldsDisabled["pedidoHoraVisita"]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="VISITADOR (*)"
                id="comercialCodigo"
                namelineInput="Vendedor"
                fieldVisible="comercial_nombre_completo"
                fieldCode="comercial_codigo"
                Table="comercial"
                isView={true}
                where={{
                  comercial_estado: 'ACTIVO'
                }}
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["comercialCodigo"]}
                disabled={this.state.fieldsDisabled["comercialCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="pedidoEstadoVisita"
                useChildren={true}
                value={this.state.fields["pedidoEstadoVisita"]}
                onChange={this.handleChange}
                namelabel="Estado Visita"
              >
                <option value="PROGRAMADA">PROGRAMADA</option>
                <option value="REPROGRAMADA">REPROGRAMADA</option>
                <option value="CUMPLIDA">CUMPLIDA</option>
                <option value="INCUMPLIDA">INCUMPLIDA</option>
                <option value="CANCELADA">CANCELADA</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoFechaEstadoVisita"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoFechaEstadoVisita"] || undefined}
                type="date"
                label="Fecha Estado Visita"
                InputLabelProps={{ shrink: true }}
                disabled={this.state.fieldsDisabled["pedidoFechaEstadoVisita"]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4} >
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="pedidoEstadoVisitaObservacion" label="Descripcion" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["pedidoEstadoVisitaObservacion"] || undefined}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  rows="5"
                  cols="100"
                />
              </Grid>
          </Grid>

          <Grid  >
            <CustomizedExpansionPanel tittle="Trazabilidad Visita">
              <Datagrid dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridEstadoVisita} />
            </CustomizedExpansionPanel>
          </Grid>

          <Grid  >
            <CustomizedExpansionPanel tittle="Datos Generales del Pedido"></CustomizedExpansionPanel>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Medio de Contacto (*)"
                id="tiprefCodigo"
                namelineInput="tiporeferencia"
                fieldVisible="tiprefNombre"
                fieldCode="tiprefCodigo"
                Table="TipoReferencia"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["tiprefCodigo"]}
                formErrors={this.state.formErrors['tiprefCodigo']}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Tipo Servicio (*)"
                id="tipserCodigo"
                namelineInput="tiposervicio"
                fieldVisible="tipserNombre"
                fieldCode="tipserCodigo"
                Table="TipoServicio"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["tipserCodigo"]}
                formErrors={this.state.formErrors['tipserCodigo']}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="sucursalCodigo"
                tableName='Sucursal'
                fields={['sucursalCodigo', 'sucursalNombre']}
                primaryField='sucursalCodigo'
                aliases={['ID', 'Nombre']}
                searchField='sucursalNombre'
                placeHolder='Seleccionar Sucursal'
                onSelect={this.handleChange}
                value={this.state.fields["sucursalCodigo"]}
                formErrors={this.state.formErrors['sucursalCodigo']}

              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoFechaServicio"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoFechaServicio"] || undefined}
                type="date"
                label="Fecha Servicio"
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['pedidoFechaServicio'].error}
                helperText={this.state.formErrors['pedidoFechaServicio'].errorMessage}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoHoraServicio"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoHoraServicio"] || undefined}
                type="time"
                label="Hora Servicio"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoFechaIngresoBodega"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoFechaIngresoBodega"] || undefined}
                type="date"
                label="Fecha Ingreso Bodega"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>


          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>

              <AutoComplete
                id="clienteCodigo"
                tableName='cliente'
                fields={['clienteCodigo', 'clienteRazonSocial']}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
                size='xl'
                primaryField='clienteCodigo'
                searchField='clienteRazonSocial'
                placeHolder='Seleccionar Cliente'
                onSelect={this.handleChange}
                value={this.state.fields["clienteCodigo"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Forma Pago"
                id="forpagCodigo"
                namelineInput="formapago"
                fieldVisible="forpagNombre"
                fieldCode="forpagCodigo"
                Table="Formapago"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["forpagCodigo"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoCargue" label="Cargue" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoCargue"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoDescargue" label="Descargue" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoDescargue"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoPesoCaminadoCargue" label="Metros Cargue Caminado" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoPesoCaminadoCargue"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoPesoCaminadoDescargue" label="Metros Descargue Caminado" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoPesoCaminadoDescargue"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoPisoOrigen" label="Piso Origen" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoPisoOrigen"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoPisoDestino" label="Piso Destino" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoPisoDestino"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoCubicaje" label="Cubicaje" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoCubicaje"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="productoCodigo"
                tableName='producto'
                fields={['productoCodigo', 'productoCodigoMinisterio', 'productoNombre']}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Ministerio', size: 2 }, { value: 'Nombre', size: 9 }]}
                size='xx'
                primaryField='productoCodigo'
                searchField='productoNombre'
                placeHolder='Seleccionar Producto'
                onSelect={this.handleChange}
                value={this.state.fields["productoCodigo"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="destinatarioCodigo"
                tableName='RemitenteDestinatario'
                fields={['remdesCodigo', 'remdesDocumento', 'remdesRazonSocial']}
                aliases={[{ value: 'ID', size: 2 }, { value: 'Documento', size: 3 }, { value: 'Nombre', size: 7 }]}
                size='xl'
                primaryField='remdesCodigo'
                searchField='remdesRazonSocial'
                placeHolder='Seleccionar Destinatario'
                onSelect={this.handleChange}
                value={this.state.fields["destinatarioCodigo"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="rutaCodigo"
                tableName='Ruta'
                fields={['rutaCodigo', 'rutaIndicacion']}
                primaryField='rutaCodigo'
                aliases={[{ value: 'ID', size: 2 }, { value: 'Nombre', size: 10 }]}
                size='xl'
                searchField='rutaIndicacion'
                placeHolder='Seleccionar Ruta'
                onSelect={this.handleChange}
                value={this.state.fields["rutaCodigo"]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="pedidoEstado"
                useChildren={true}
                value={this.state.fields["pedidoEstado"] || undefined}
                onChange={this.handleChange}
                namelabel="Estado"
              >
                <option value="PENDIENTE">PENDIENTE</option>
                <option value="COTIZADO">COTIZADO</option>
                <option value="CONFIRMADO">CONFIRMADO</option>
                <option value="DESPACHADO">DESPACHADO</option>
                <option value="FACTURADO">FACTURADO</option>
                <option value="ANULADO">ANULADO</option>
                <option value="RECHAZADO">RECHAZADO</option>
              </PSelect>
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="pedidoEstadoNovedad"
                useChildren={true}
                value={this.state.fields["pedidoEstadoNovedad"] || undefined}
                onChange={this.handleChange}
                namelabel="Novedad"
              >
                <option value="INCUMPLIMIENTO BH">INCUMPLIMIENTO BH</option>
                <option value="INCUMPLIMIENTO SERVICIO AL CLIENTE">INCUMPLIMIENTO SERVICIO AL CLIENTE</option>
                <option value="PRECIO FUERA DE MERCADO">PRECIO FUERA DE MERCADO</option>
                <option value="SERVICIO NO SE AJUSTA">SERVICIO NO SE AJUSTA</option>
                <option value="IMAGEN CORPORATIVA">IMAGEN CORPORATIVA</option>
                <option value="CAMBIO DE OPINION">CAMBIO DE OPINION</option>
                <option value="EN SEGUIMIENTO">EN SEGUIMIENTO</option>
                <option value="CLIENTE CANCELO VISITA">CLIENTE CANCELO VISITA</option> 

                <option value="RETENCION DE CLIENTES">"RETENCION DE CLIENTES</option>  
                <option value="BODEGA EN OTRA CIUDAD">BODEGA EN OTRA CIUDAD"</option>  

                <option value="COTIZACION POR ENCIMA DE LA COMPETENCIA">COTIZACION POR ENCIMA DE LA COMPETENCIA</option>   
                <option value="NO RESPONDE">NO RESPONDE</option>   
                <option value="PROGRAMACION FUERA DE HORARIO">PROGRAMACION FUERA DE HORARIO</option>   
                <option value="NO SE CUBRE RUTA URBANO">NO SE CUBRE RUTA URBANO</option>   .
                <option value="NO SE CUBRE RUTA NACIONAL">NO SE CUBRE RUTA NACIONAL</option> 
                <option value="CUBICAJE, NO JUSTIFICA RUTA">CUBICAJE, NO JUSTIFICA RUTA</option>   
                <option value="CLIENTE DEJA INFORMACION INCOMPLETA">CLIENTE DEJA INFORMACION INCOMPLETA</option> 
                <option value="FALLA DEL SISTEMA">*FALLA DEL SISTEMA*</option>                  
              </PSelect>
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextareaAutosize id="pedidoNovedadObservacion" label="Observacion" type="text"
                placeholder="Observacion Novedad"
                onChange={this.handleChange}
                value={this.state.fields["pedidoNovedadObservacion"] || undefined}
                rows="4"
                cols="30"
              />
            </Grid>
          </Grid>

          <Grid  >
            <CustomizedExpansionPanel tittle="Datos Cotizacion Internacional"></CustomizedExpansionPanel>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="ciudadCodigoOrigenInternacional"
                tableName='vw_ciudad'
                fields={['ciudad_codigo','ciudad_nombre', 'departamento_nombre']}
                isView={true}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Ciudad', size: 5 }, { value: 'Departamento', size: 6 }]}
                size='xx'
                primaryField='ciudad_codigo'
                searchField='ciudad_nombre'
                placeHolder='CiudadOrigenInternacional'
                onSelect={this.handleChange}
                value={this.state.fields["ciudadCodigoOrigenInternacional"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="ciudadCodigoDestinoInternacional"
                tableName='vw_ciudad'
                fields={['ciudad_codigo','ciudad_nombre', 'departamento_nombre']}
                isView={true}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Ciudad', size: 5 }, { value: 'Departamento', size: 6 }]}
                size='xx'
                primaryField='ciudad_codigo'
                searchField='ciudad_nombre'
                placeHolder='CiudadDestinoInternacional'
                onSelect={this.handleChange}
                value={this.state.fields["ciudadCodigoDestinoInternacional"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoMoneda" label="Moneda Destino" type="text"
                fullWidth margin="normal" variant="outlined" placeholder="ejp: USD"
                onChange={this.handleChange}
                value={this.state.fields["pedidoMoneda"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoTrm" label="Pedido Trm" type="number"
                fullWidth margin="normal" variant="outlined" placeholder="ejp: 3549.98"
                onChange={this.handleChange}
                value={this.state.fields["pedidoTrm"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoRangosDias" label="Rango de Dias, Despacho Internacional" type="text"
                fullWidth margin="normal" variant="outlined" placeholder="ejp:8 a 11"
                onChange={this.handleChange}
                value={this.state.fields["pedidoRangosDias"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid  >
            <CustomizedExpansionPanel tittle="Totales"></CustomizedExpansionPanel>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoValorDeclarado" label="Valor Declarado" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoValorDeclarado"] || undefined}
                InputLabelProps={{ shrink: true }}
                onBlur={this.calculoSeguro}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoValorServicios" label="Valor Servicios" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoValorServicios"] || undefined}
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoTotal" label="Total" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoTotal"]}
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </Grid>
          </Grid>


          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <InputLabel className={this.props.classes.tittleLabelObservacion}>
                Observacion Cotizacion.
              </InputLabel>
              <FormControl variant="outlined" fullWidth >
                <TextareaAutosize id="pedidoObservacion" label="Observacion Cotizacion" type="text"
                   title="Observacion Cotizacion"
                  onChange={this.handleChange}
                  value={this.state.fields["pedidoObservacion"] || undefined}
                  rows="8"
                  cols="50"
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <InputLabel className={this.props.classes.tittleLabelObservacion}>
                Observacion Cero Estres.
              </InputLabel>
              <FormControl variant="outlined" fullWidth >
                <TextareaAutosize id="pedidoObservacionCeroEstres" label="Observacion Cero Estres" type="text"
                 title="Observacion Cero Estres"
                  onChange={this.handleChange}
                  value={this.state.fields["pedidoObservacionCeroEstres"] || undefined}
                  rows="8"
                  cols="50" 
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <InputLabel className={this.props.classes.tittleLabelObservacion}>
                Observacion Empaque.
              </InputLabel>
              <FormControl variant="outlined" fullWidth >
                <TextareaAutosize id="pedidoObservacionEmpaque" label="Observacion Empaque" type="text"
                 title="Observacion Empaque"
                  onChange={this.handleChange}
                  value={this.state.fields["pedidoObservacionEmpaque"] || undefined}
                  rows="8"
                  cols="50"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <InputLabel className={this.props.classes.tittleLabelObservacion}>
                Observacion Transporte.
              </InputLabel>
              <FormControl variant="outlined" fullWidth >
                <TextareaAutosize id="pedidoObservacionTransporte" label="Observacion Transporte" type="text"
                 title="Observacion Transporte"
                  onChange={this.handleChange}
                  value={this.state.fields["pedidoObservacionTransporte"] || undefined}
                  rows="8"
                  cols="50"
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <InputLabel className={this.props.classes.tittleLabelObservacion}>
                Observacion Movimiento Especial.
              </InputLabel>
              <FormControl variant="outlined" fullWidth >
                <TextareaAutosize id="pedidoObservacionMovimientoEspecial" label="Observacion Movimiento Especial" type="text"
                 title="Observacion Movimiento Especial"
                  onChange={this.handleChange}
                  value={this.state.fields["pedidoObservacionMovimientoEspecial"] || undefined}
                  rows="8"
                  cols="50"
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <InputLabel className={this.props.classes.tittleLabelObservacion}>
                Observacion Bodega.
              </InputLabel>
              <FormControl variant="outlined" fullWidth >
                <TextareaAutosize id="pedidoObservacionBodega" label="Observacion Bodega" type="text"
                  title="Observacion Bodega"
                  onChange={this.handleChange}
                  value={this.state.fields["pedidoObservacionBodega"] || undefined}
                  rows="8"
                  cols="50"
                />
              </FormControl> 
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <InputLabel className={this.props.classes.tittleLabelObservacion}>
                Observacion General.
              </InputLabel>
              <FormControl variant="outlined" fullWidth >
                <TextareaAutosize id="pedidoObservacionGeneral" label="Observacion General" type="text"
                 title="Observacion General"
                  onChange={this.handleChange}
                  value={this.state.fields["pedidoObservacionGeneral"] || undefined}
                  rows="8"
                  cols="50"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="manifiestoCodigo" label="Manifiesto" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["manifiestoCodigo"] || ''}
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </Grid>
          </Grid>

          <Grid container spacing={4} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Usuario" value={Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Creacion" value={this.state.fields.createdAt} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Modificacion" value={this.state.fields.updatedAt} />
            </Grid>
          </Grid>

          <Grid className={this.props.classes.gridForm}></Grid>

          <Grid>
            <CustomizedExpansionPanel tittle="Servicios Adicionales +++">
              <Datagrid datosHeader={this.state.fields} dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridpedido} setComponente="pedido" controlData={true} />
            </CustomizedExpansionPanel>
          </Grid>

          <CustomizedExpansionPanel tittle="Lista Empaque (NO OFICIAL)">
            <Datagrid dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridListaEmpaque} />
          </CustomizedExpansionPanel>

          <Grid  >
            <CustomizedExpansionPanel tittle="Documentos Asociados">
              <Datagrid dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridDocumentoAsociado} />
            </CustomizedExpansionPanel>
          </Grid>

          <CustomizedExpansionPanel tittle="Pagos">
            <Datagrid dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridPedidoPagos} />
          </CustomizedExpansionPanel>
          
          <CustomizedExpansionPanel tittle="Trazabilidad">
            <Datagrid dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridTrazabilidad} />
          </CustomizedExpansionPanel>


        </form>
        <div><Buttoncontrol
          saveData={this.state}
          saveDataValidation={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          validaData={this.validaData}
          messageValidation={this.messageValidation}
          edit={(this.state.fields.pedidoCodigo !== null) ? true : false}
          primaryFields={['pedidoCodigo']}
          url='/processes/pedido_save'
          formValidations={this.state.formValidations}
          errorHandler={this.setErrors}

        /></div>
      </Paper >
    );
  }
}

Pedido.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};


const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(Pedido)));

function IntegrationNotistack() {
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }
  return (

    <SnackbarProvider maxSnack={1} autoHideDuration={360000}
      ref={notistackRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      action={(key) => (
        <Button variant="text" color="primary" size="small" onClick={onClickDismiss(key)}>
          {'X'}
        </Button>
      )}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;