import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import AutoComplete from '../GeneralUI/AutoComplete';
import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';
import { errorSetter } from '../../Libs';

class Ruta extends Component {

  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    fields: {
      rutaCodigo: null,
      ciudadCodigoOrigen: null,
      ciudadCodigoDestino: null,
      rutaIndicacion: null,
      rutaKilometros:0,
      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime()
    },
    responseForm: { Message: '', typeMessage: '' },
    formValidations: {
      view: ['ciudadCodigoOrigen', 'ciudadCodigoDestino', 'rutaKilometros']
    },
    formErrors: {
      ciudadCodigoOrigen: {},
      ciudadCodigoDestino: {},
      rutaKilometros:{}
    }
  };

  componentDidMount() {
    const params = this.props.match.params;
    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'rutaCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;

    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }

    if (fields[event.target.id] === '') {
      fields[event.target.id] = null;
    }

    this.setState({ fields });
  }

  messageValidation = (type, message) => {
    this.message(type, message);
  }

  save = (response) => {
    this.setState({
      fields: response.data,
      responseForm: response.responseForm
    });
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }

  validaData = () => {
    let isvalid = true
    return isvalid;
  }


  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>

            <InputLabel id="rutaCodigo"
              className={this.props.classes.tittleLabelForm}>Ruta
            </InputLabel>

            <Grid item xs={6} className={this.props.classes.chipTitle}>
              {(this.state.fields.rutaCodigo !== null) ?
                (<Chip
                  label={this.state.fields["rutaCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>



          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="ciudadCodigoOrigen"
                tableName='vw_ciudad'
                fields={['ciudad_codigo','ciudad_nombre', 'departamento_nombre']}
                isView={true}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Ciudad', size: 5 }, { value: 'Departamento', size: 6 }]}
                size='xx'
                primaryField='ciudad_codigo'
                searchField='ciudad_nombre'
                placeHolder='Seleccionar CiudadOrigen'
                onSelect={this.handleChange}
                value={this.state.fields["ciudadCodigoOrigen"]}
                formErrors={this.state.formErrors['ciudadCodigoOrigen']}

              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="ciudadCodigoDestino"
                tableName='vw_ciudad'
                fields={['ciudad_codigo','ciudad_nombre', 'departamento_nombre']}
                isView={true}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Ciudad', size: 5 }, { value: 'Departamento', size: 6 }]}
                size='xx'
                primaryField='ciudad_codigo'
                searchField='ciudad_nombre'
                placeHolder='Seleccionar CiudadDestino'
                onSelect={this.handleChange}
                value={this.state.fields["ciudadCodigoDestino"]}
                formErrors={this.state.formErrors['ciudadCodigoDestino']}

              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="rutaKilometros" label="Kilometros (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["rutaKilometros"]}
                formErrors={this.state.formErrors['rutaKilometros']}
              />
            </Grid>
          </Grid>


        </form>
        <div><Buttoncontrol
          saveData={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          validaData={this.validaData}
          messageValidation={this.messageValidation}
          primaryFields={['rutaCodigo']}
          formValidations={this.state.formValidations}
          errorHandler={this.setErrors}
        /></div>
      </Paper>
    );
  }
}

Ruta.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(Ruta)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}

    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;