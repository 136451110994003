import React, { Component } from 'react';
import Header from './Header/index.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './Styles';
import Main from './Main.js';
import Paper from '@material-ui/core/Paper';
import Footer from './Footer/index.jsx';

class Landing extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div >
        <Header
          usuarioNombreCompleto=""
          usuarioAvatar=""
        />

        <Paper className={classes.mainImagen}>
          <img alt='background' src="/Images/landing.jpeg" width="100%" height="100%" />
          <Main />
        </Paper>
        <Footer className={classes.appBar}
          usuarioNombreCompleto=""
          usuarioAvatar=""
        />
      </div>
    );
  }
}
export default withStyles(Styles, { withTheme: true })(Landing);