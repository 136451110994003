import React, { Component } from 'react'
import { AppBar, Toolbar, Avatar, IconButton } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './Styles';
import InputLabel from '@material-ui/core/InputLabel';
import { Link } from "react-router-dom";

class Header extends Component {

  render = () => {
    const { classes } = this.props;
    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton>
            <Avatar src='https://crmpymes.co/coldesoft.png' />
          </IconButton>

          <div className={classes.grow} />

          <div className={classes.iconContainer}>

            <Link to="/login" target="_blank">
              <InputLabel className={this.props.classes.titlelabelform}>Anunciarse</InputLabel>
            </Link>

            <Link to="/login" target="_blank">
              <InputLabel className={this.props.classes.titlelabelform}>Ayuda</InputLabel>
            </Link>

            <Link to="/login" target="_blank">
              <InputLabel className={this.props.classes.titlelabelform}>Privacidad</InputLabel>
            </Link>

            <Link to="/login" target="_blank">
              <InputLabel className={this.props.classes.titlelabelform}>Terminos</InputLabel>
            </Link>

            <Link to="/login" target="_blank">
              <InputLabel className={this.props.classes.titlelabelform}>Nosotros</InputLabel>
            </Link>

          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(Header);