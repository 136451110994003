import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Textfieldlabel from "../GeneralUI/Textfieldlabel";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import AutoComplete from '../GeneralUI/AutoComplete';
import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';
import PSelect from '../GeneralUI/PSelect'
import { errorSetter } from '../../Libs';

import CustomizedExpansionPanel from './../GeneralUI/PExpansionpanels'
import Datagrid from '../GeneralUI/PDatagrid/'


class Remesa extends Component {

  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {

    fields: {
      remesaCodigo: null,
      remesaTarifa: 0,
      remesaTotalservicios: 0,
      remesaTotaldescuentos: 0,
      remesaTotalmanejo: 0,
      remesaTotal: 0,
      rutaCodigo: null,
      vehiculoCodigo: null,
      terceroCodigoConductor: null,
      terceroCodigoPoseedor: null,
      terceroCodigoPropietario: null,
      clienteCodigo: null,
      sucursalCodigo: null,
      tipserCodigo: null,
      remesaEstado: "PENDIENTE",
      tarifaCodigo: null,
      remesaConsecutivoMinisterio: null,
      remdesCodigoRemitente: null,
      remdesCodigoDestinatario: null,
      remesaConsecutivoManual: null,
      ciudadCodigoOrigen: null,
      ciudadCodigoDestino: null,
      remesaDocumento1: null,
      remesaDocumento2: null,
      remesaDocumento3: null,
      remesaDocumento4: null,
      remesaDocumento5: null,
      remesaDocumento6: null,
      remesaFechaCompromiso: null,
      remesaFechaFactura: null,
      remesaFechaCreacion: null,
      remesaTotalcostos: 0,
      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime(),
      dataDatagridremesa: [{
        Empaque: '',
        Producto: '',
        Cantidad: '',
        Peso: '',
        ValorDeclarado: '',
        Volumen: ''
      }],
    },
    responseForm: {
      Message: '',
      typeMessage: ''
    },
    formValidations: {
      view: ['clienteCodigo']
    },
    formErrors: {
      clienteCodigo: {}
    }
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'remesaCodigo',
      moduleName: params.module
    }).then(document => {

      if (!document) {
        return;
      }
      let fields = {
        ...document,
        dataDatagridremesa: [{
          Empaque: '',
          Producto: '',
          Cantidad: '',
          Peso: '',
          ValorDeclarado: '',
          Volumen: ''
        }]
      }

      this.setState({ fields });

    });

    getDocument({
      documentId: params.id,
      fieldName: 'remesa_codigo',
      moduleName: 'vw_detalle_remesa_interfaz',
      isView: true,
      fields: ['empaque_nombre',
        'producto_nombre',
        'remdet_cantidad',
        'remdet_peso',
        'remdet_valordeclarado',
        'remdet_volumen'
      ],
      Limit: 50
    }).then(document => {
      if (!document) {
        return;
      }
      this.builddetailInterface(document);
    }).catch(ex => {
      console.log('index.jsx -- 137 > ex === ', ex);
    });
  }

  async builddetailInterface(data) {
    let rows = await Promise.all(data.map(row => {
      return {
        Empaque: { 'value': row.empaque_codigo, 'key': row.empaque_nombre },
        Producto: { 'value': row.producto_codigo, 'key': row.producto_nombre },
        Cantidad: { 'value': row.remdet_cantidad, 'key': parseFloat(row.remdet_cantidad).toLocaleString() },
        Peso: { 'value': row.remdet_peso, 'key': parseFloat(row.remdet_peso).toLocaleString() },
        ValorDeclarado: { 'value': row.remdet_valordeclarado, 'key': parseFloat(row.remdet_valordeclarado).toLocaleString() },
        Volumen: { 'value': row.remdet_volumen, 'key': parseFloat(row.remdet_volumen).toLocaleString() }
      };
    }));

    let copy_state = this.state;
    copy_state.fields.dataDatagridremesa = rows;
    this.setState({ copy_state });
  }

  handleDatagrid = (data) => {
    const detalle = data.setComponente;
    let copy_state = this.state;
    switch (detalle) {
      case 'remesa':
        copy_state.fields.dataDatagridremesa = data;
        this.setState({ copy_state });
        break;
      default:
        return null;
    }
  }


  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;

    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }

    if (fields[event.target.id] === '') {
      fields[event.target.id] = null;
    }
    this.setState({ fields });
  }

  messageValidation = (type, message) => {
    this.message(type, message);
  }

  save = (response) => {
    let fields = response.data;

    this.setState({
      fields,
      responseForm: response.responseForm
    });

    this.props.enqueueSnackbar(this.state.responseForm['Message'], {
      variant: this.state.responseForm['typeMessage']
    });

  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }

  handleAuto = (autoCompleteValue) => {
    this.setState({
      colorAutoComplete: autoCompleteValue
    });
  }

  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>
            <InputLabel id="remesaCodigo"
              className={this.props.classes.tittleLabelForm}>Remesa
            </InputLabel>

            <Grid item xs={6} className={this.props.classes.chipTitle}>
              {(this.state.fields.remesaCodigo !== null) ?
                (<Chip
                  label={this.state.fields["remesaCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="clienteCodigo"
                tableName='cliente'
                fields={['clienteCodigo', 'clienteRazonSocial']}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
                size='xl'
                primaryField='clienteCodigo'
                searchField='clienteRazonSocial'
                placeHolder='Seleccionar Cliente'
                onSelect={this.handleChange}
                value={this.state.fields["clienteCodigo"]}
                formErrors={this.state.formErrors['clienteCodigo']}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="aliadoCodigo"
                tableName='aliado'
                fields={['aliadoCodigo', 'aliadoRazonSocial']}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
                size='xl'
                primaryField='aliadoCodigo'
                searchField='aliadoRazonSocial'
                placeHolder='Seleccionar Aliado'
                onSelect={this.handleChange}
                value={this.state.fields["aliadoCodigo"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="sucursalCodigo"
                tableName='Sucursal'
                fields={['sucursalCodigo', 'sucursalNombre']}
                primaryField='sucursalCodigo'
                aliases={['ID', 'Nombre']}
                searchField='sucursalNombre'
                placeHolder='Seleccionar Sucursal'
                onSelect={this.handleChange}
                value={this.state.fields["sucursalCodigo"]}
              />
            </Grid>

          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Tipo Servicio"
                id="tipserCodigo"
                namelineInput="tiposervicio"
                fieldVisible="tipserNombre"
                fieldCode="tipserCodigo"
                Table="Tiposervicio"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["tipserCodigo"]}
                estados={this.state}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="rutaCodigo"
                tableName='Ruta'
                fields={['rutaCodigo', 'rutaIndicacion']}
                primaryField='rutaCodigo'
                aliases={['ID', 'Nombre']}
                searchField='rutaIndicacion'
                placeHolder='Seleccionar Ruta'
                onSelect={this.handleChange}
                value={this.state.fields["rutaCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="ciudadCodigoOrigen"
                tableName='Ciudad'
                fields={['ciudadCodigo', 'ciudadNombre']}
                primaryField='ciudadCodigo'
                aliases={['ID', 'Nombre']}
                searchField='ciudadNombre'
                placeHolder='Seleccionar Ciudad Origen'
                onSelect={this.handleChange}
                value={this.state.fields["ciudadCodigoOrigen"]}
              />
            </Grid>

          </Grid>

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="remdesCodigoRemitente"
                tableName='RemitenteDestinatario'
                fields={['remdesCodigo', 'remdesRazonSocial']}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
                size='xl'
                primaryField='remdesCodigo'
                searchField='remdesRazonSocial'
                placeHolder='Seleccionar Remitente'
                onSelect={this.handleChange}
                value={this.state.fields["remdesCodigoRemitente"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="ciudadCodigoDestino"
                tableName='Ciudad'
                fields={['ciudadCodigo', 'ciudadNombre']}
                primaryField='ciudadCodigo'
                aliases={['ID', 'Nombre']}
                searchField='ciudadNombre'
                placeHolder='Seleccionar Ciudad Destino'
                onSelect={this.handleChange}
                value={this.state.fields["ciudadCodigoDestino"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="remdesCodigoDestinatario"
                tableName='RemitenteDestinatario'
                fields={['remdesCodigo', 'remdesRazonSocial']}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
                size='xl'
                primaryField='remdesCodigo'
                searchField='remdesRazonSocial'
                placeHolder='Seleccionar Destinatario'
                onSelect={this.handleChange}
                value={this.state.fields["remdesCodigoDestinatario"]}
              />
            </Grid>

          </Grid>

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="vehiculoCodigo"
                tableName='vw_vehiculo_activo'
                fields={['vehiculo_codigo', 'vehiculo_placa', 'conductor', 'configuracion']}
                isView={true}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Placa', size: 3 }, { value: 'Conductor', size: 6 }, { value: 'Configuracion', size: 2 }]}
                size='xx'
                primaryField='vehiculo_codigo'
                searchField='vehiculo_placa'
                placeHolder='Seleccionar PlacaViaje'
                onSelect={this.handleChange}
                value={this.state.fields["vehiculoCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="terceroCodigoPropietario"
                tableName='Tercero'
                fields={['terceroCodigo', 'terceroDocumento', 'terceroRazonSocial']}
                primaryField='terceroCodigo'
                aliases={[{ value: 'ID', size: 1 }, { value: 'Documento', size: 2 }, { value: 'terceroRazonSocial', size: 9 }]}
                searchField='terceroRazonSocial'
                placeHolder='Seleccionar TerceroPropietario'
                onSelect={this.handleChange}
                value={this.state.fields["terceroCodigoPropietario"]}
                size='xx'
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="terceroCodigoPoseedor"
                tableName='Tercero'
                fields={['terceroCodigo', 'terceroDocumento', 'terceroRazonSocial']}
                primaryField='terceroCodigo'
                aliases={[{ value: 'ID', size: 1 }, { value: 'Documento', size: 2 }, { value: 'terceroRazonSocial', size: 9 }]}
                searchField='terceroRazonSocial'
                placeHolder='Seleccionar TerceroPoseedor'
                onSelect={this.handleChange}
                value={this.state.fields["terceroCodigoPoseedor"]}
                size='xx'
              />
            </Grid>

          </Grid>

          <Grid container spacing={10} >

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="terceroCodigoConductor"
                tableName='Tercero'
                fields={['terceroCodigo', 'terceroDocumento', 'terceroRazonSocial']}
                primaryField='terceroCodigo'
                aliases={[{ value: 'ID', size: 1 }, { value: 'Documento', size: 2 }, { value: 'terceroRazonSocial', size: 9 }]}
                searchField='terceroRazonSocial'
                placeHolder='Seleccionar TerceroConductor'
                onSelect={this.handleChange}
                value={this.state.fields["terceroCodigoConductor"]}
                size='xx'
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaConsecutivoMinisterio" label="Consecutivo Ministerio" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaConsecutivoMinisterio"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaConsecutivoManual" label="Consecutivo Manual" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaConsecutivoManual"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

          </Grid>

          <Grid container spacing={10} >

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="remesaEstado"
                useChildren={true}
                value={this.state.fields["remesaEstado"]}
                onChange={this.handleChange}
                namelabel="Estado"
              >
                <option value="FACTURADO">FACTURADO</option>
                <option value="PENDIENTE">PENDIENTE</option>

              </PSelect>
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaDocumento1" label="Documento 1" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaDocumento1"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaDocumento2" label="Documento 2" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaDocumento2"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

          </Grid>

          <Grid container spacing={10} >

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaDocumento3" label="Documento 3" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaDocumento3"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaDocumento4" label="Documento 4" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaDocumento4"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaDocumento5" label="Documento 5" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaDocumento5"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

          </Grid>

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaDocumento6" label="Documento 6" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaDocumento6"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaFechaCompromiso"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaFechaCompromiso"]}
                type="date"
                label="Fecha Compromiso"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaFechaFactura"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaFechaFactura"]}
                type="date"
                label="Fecha Factura"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

          </Grid>

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaFechaCreacion"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaFechaCreacion"]}
                type="date"
                label="Fecha Creacion"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="tarifaCodigo"
                tableName='Tarifa'
                fields={['tarifaCodigo', 'tarifaNombre']}
                primaryField='tarifaCodigo'
                aliases={['ID', 'Nombre1']}
                searchField='tarifaNombre'
                placeHolder='Seleccionar Tarifa'
                onSelect={this.handleChange}
                value={this.state.fields["tarifaCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaTarifa" label="Flete" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaTarifa"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

          </Grid>

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaTotalservicios" label="Servicios Adicionales" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaTotalservicios"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaTotaldescuentos" label="Total Descuentos" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaTotaldescuentos"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaTotalmanejo" label="Total Manejo" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaTotalmanejo"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

          </Grid>

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaTotalcostos" label="Total Costos" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaTotalcostos"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="remesaTotal" label="Total" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["remesaTotal"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>


          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Usuario" value={Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Creacion" value={this.state.fields.createdAt} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Modificacion" value={this.state.fields.updatedAt} />
            </Grid>
          </Grid>

          <Grid  >
            <CustomizedExpansionPanel tittle="Servicios Adicionales">
              <Datagrid dataDatagrid={this.handleDatagrid} datos={this.state.fields.dataDatagridremesa} setComponente="remesa" />
            </CustomizedExpansionPanel>
          </Grid>

        </form>
        <div><Buttoncontrol
          saveData={this.state.fields}
          saveDataValidation={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          validaData={this.validaData}
          messageValidation={this.messageValidation}
          edit={(this.state.fields.remesaCodigo !== null) ? true : false}
          primaryFields={['remesaCodigo']}
          url='/processes/remesa_save'
          formValidations={this.state.formValidations}
          errorHandler={this.setErrors}
        /></div>
      </Paper >
    );
  }
}

Remesa.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(Remesa)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}

    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;