import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Styles from './Styles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import PSelect from '../../../GeneralUI/PSelect'
import ListItemText from '@material-ui/core/ListItemText';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import { saveCustom } from './../../../../Actions/General'
import { errorSetter } from '../../../../Libs';
import Utils from '../../../../Libs/utils';


class Detallepedidoreferencias extends Component {

  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    errorForm: false,
    errorMessage: '',
    fields: {
      TipoReferencia: { 'value': null, 'key': null,'visible':true },
      Nombre: { 'value': null, 'key': null,'visible':true },
      Documento: { 'value': null, 'key': null,'visible':true },
      Ciudad: { 'value': null, 'key': null,'visible':true },
      Direccion: { 'value': null, 'key': null,'visible':true },
      Telefono: { 'value': null, 'key': null,'visible':true },
      Email: { 'value': null, 'key': null,'visible':true },
      Sucesor: { 'value': null, 'key': null,'visible':true }
    },
    formValidations: {
      view: ['TipoReferencia', 'Nombre', 'Ciudad', 'Direccion', 'Telefono', 'Email', 'Sucesor']
    },
    formErrors: {
      TipoReferencia: {},
      Nombre: {},
      Ciudad: {},
      Direccion: {},
      Telefono: {},
      Email: {},
      Sucesor: {}
    }
  };


  componentDidMount = () => {
    console.log(this.props.editData);
    if (this.props.editData) {
      this.fillForm(this.props.editData, this.props.setIndex)
    }
    if (this.props.datosHeader) {
      this.setState(this.props.datosHeader);
    }
    console.log(this.props.datosHeader);
  }

  fillForm = (data, index) => {

    let { fields } = this.state;

    fields["TipoReferencia"] = ({ 'value': data[index].TipoReferencia.value, 'key': data[index].TipoReferencia.key,'visible':true });
    fields["Nombre"] = ({ 'value': data[index].Nombre.value, 'key': data[index].Nombre.key,'visible':true });
    fields["Documento"] = ({ 'value': data[index].Documento.value, 'key': data[index].Documento.key,'visible':true });
    fields["Ciudad"] = ({ 'value': data[index].Ciudad.value ? data[index].Ciudad.value : null, 'key': data[index].Ciudad.key,'visible':true });
    fields["Direccion"] = ({ 'value': data[index].Direccion.value, 'key': data[index].Direccion.key,'visible':true });
    fields["Telefono"] = ({ 'value': data[index].Telefono.value, 'key': data[index].Telefono.key,'visible':true });
    fields["Email"] = ({ 'value': data[index].Email.value, 'key': data[index].Email.key,'visible':true });
    fields["Sucesor"] = ({ 'value': data[index].Sucesor.value, 'key': data[index].Sucesor.key,'visible':true });
    

    this.setState({ fields });

  }

  handleChange = (event) => {
    let { fields } = this.state; this.setState({ fields });
    fields[event.target.id].value= event.target.value;
    fields[event.target.id].key= event.target.hasOwnProperty('text') ? event.target.text : event.target.value
    this.setState({ fields });
  }

  validateLogicaForm = async () => {
   /* if (this.props.datosHeader.pedidoCodigo) {
      await saveCustom({ value: this.props.datosHeader.pedidoCodigo }, '/processes/getPedido').then(data => {
        if (data.pedidoEstado === 'CONFIRMADO' &&
          Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto !== 'JENNY PAOLA ROCHA' &&
          Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto !== 'LUIS NABOR MARINEZ ANGULO' &&
          Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto !== 'CARLOS ARTURO CARDONA GONZALEZ' &&
          Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto !== 'DIANA KATHERINE GONZALEZ OSORIO') {
          this.setDataValidation(true, 'El pedido esta en estado confirmado y no se puede adicionar o editar datos de bodega.');
        }
      }).catch(error => {
        this.setDataValidation(true, 'Error inesperado.' + error);
      });
    }*/

    if(this.state.fields.Sucesor==='SI' && (this.state.fields.Documento == null || !String(this.state.fields.Documento).trim())){
      this.setDataValidation(true, 'La cedula es obligatoria para el tipo de referencia SUCESOR.');
    }
  }

  setDataValidation = (errorForm, errorMessage) => {
    this.setState({ errorForm: errorForm, errorMessage: errorMessage });
  }

  setData = async () => {
    const validations = errorSetter.validateFormFields(this.state.fields, this.state.formValidations);
    this.setState({ formErrors: validations.errors });
    await this.validateLogicaForm();
    if (validations.isValid && !this.state.errorForm) {
      this.props.setData(this.state.fields, this.props.editData ? this.props.setIndex : null);
    }
  }

  render = () => {
    return (
      <Paper elevation={1} className={this.props.classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>
          <Grid container direction='row' className={this.props.classes.paperGridTitle}>
            <List component="nav">
              <ListItem>
                <ListItemText primary="Detalle de Referencia" classes={{ primary: this.props.classes.titlelabelformwhite }} />
              </ListItem>
              {(this.state.errorForm) ?
                <ListItem>
                  <ListItemText primary={this.state.errorMessage} classes={{ primary: this.props.classes.titlelabelerrorform }} />
                </ListItem> : ''
              }
            </List>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="TipoReferencia"
                useChildren={true}
                value={this.state.fields["TipoReferencia"].value}
                onChange={this.handleChange}
                namelabel="TipoReferencia"
              >
                <option value="MADRE">MADRE</option>
                <option value="PADRE">PADRE</option>
                <option value="HERMANO">HERMANO</option>
                <option value="TIO">TIO</option>
                <option value="PRIMO">PRIMO</option>
                <option value="SOBRINO">SOBRINO</option>
                <option value="CONYUGUE">CONYUGUE</option>
                <option value="HIJO">HIJO</option>
                <option value="CONOCIDO">CONOCIDO</option>
                <option value="AMIGO">AMIGO</option>
                <option value="YERNO">YERNO</option>
                <option value="SUEGRO">SUEGRO</option>
                <option value="CUÑADO">CUÑADO</option>
                <option value="NOVIO">NOVIO</option>
                <option value="NIETO">NIETO</option>
                <option value="ABUELO">ABUELO</option>

              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="Nombre"
                label="Nombre"
                type="text"
                fullWidth margin="normal"
                onChange={this.handleChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={this.state.fields["Nombre"].value}
                error={this.state.formErrors['Nombre'].error}
                helperText={this.state.formErrors['Nombre'].errorMessage}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Documento" label="Documento" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Documento"].value}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>


          <Grid container spacing={2}>
          <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="Ciudad"
                tableName='vw_ciudad'
                fields={['ciudad_codigo','ciudad_nombre', 'departamento_nombre']}
                isView={true}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Ciudad', size: 5 }, { value: 'Departamento', size: 6 }]}
                size='xx'
                primaryField='ciudad_codigo'
                searchField='ciudad_nombre'
                placeHolder='Seleccionar Ciudad'
                onSelect={this.handleChange}
                value={this.state.fields["Ciudad"].value}
                error={this.state.formErrors['Ciudad'].error}
                helperText={this.state.formErrors['Ciudad'].errorMessage}

              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Direccion" label="Direccion" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Direccion"].value}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['Direccion'].error}
                helperText={this.state.formErrors['Direccion'].errorMessage}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Telefono" label="Telefono" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Telefono"].value}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['Telefono'].error}
                helperText={this.state.formErrors['Telefono'].errorMessage}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Email" label="Email" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Email"].value}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['Email'].error}
                helperText={this.state.formErrors['Email'].errorMessage}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="Sucesor"
                useChildren={true}
                value={this.state.fields["Sucesor"].value}
                onChange={this.handleChange}
                namelabel="Sucesor"
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </PSelect>
            </Grid>            
          </Grid>

          <Grid container spacing={2} >
            <Grid item xs={3} className={this.props.classes.gridForm}>

              <Button
                color='primary'
                onClick={this.setData}
              >
                Adicionar
              </Button>

            </Grid>
          </Grid>


        </form>
      </Paper>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(Detallepedidoreferencias);