import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { getDocument } from '../../../../Actions/General';
import Buttoncontrol from "../../../GeneralUI/Buttoncontrol";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter } from '../../../../Libs/';
import AutoComplete from '../../../GeneralUI/AutoComplete';

class ReenvioMinisterio extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    fields: {
      manifiestoCodigo: null
    },
    responseForm: { Message: '', typeMessage: '' },
    formValidations: {
      view: ['manifiestoCodigo']
    },
    formErrors: {
      manifiestoCodigo: {}    }
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'manifiestoCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });

  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    fields[event.target.id] = event.target.value.toUpperCase();
    this.setState({ fields });
  }

  save = (response) => {
    this.setState({
      // fields: response.data,
      openSnackbar: true,
      responseForm: response.responseForm
    });
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  render() {
    const { classes } = this.props;

    return (

      <Paper elevation={5} className={classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={32} className={classes.gridForm}>

            <InputLabel className={classes.tittleLabelForm}>
              Reenvio Manifiesto
            </InputLabel>
          </Grid>

          <Grid container spacing={32}>
            <Grid item xs={3} className={classes.gridForm}>
              <AutoComplete
                id='manifiestoCodigo'
                tableName='vw_manifiesto_anulacion'
                fields={['manifiesto_codigo', 'ruta']}
                primaryField='manifiesto_codigo'
                aliases={['ID', 'Ruta']}
                searchField='manifiesto_codigo'
                placeHolder='Manifiesto'
                onSelect={this.handleChange}
                value={this.state.fields["manifiestoCodigo"]}
                isView={true}
                where={{
                  manifiestoEstado: 'ACTIVO'
                }}
                formErrors={this.state.formErrors['manifiestoCodigo']}
              />

            </Grid>

          </Grid>
        </form>

        <div id='buttonContainer'>
          <Buttoncontrol
            saveData={this.state}
            saveDataValidation={this.state.fields}
            moduleName='manifiesto'
            save={this.save}
            validaData={this.validaData}
            messageValidation={this.messageValidation}
            primaryFields={['manifiestoCodigo']}
            formValidations={this.state.formValidations}
            errorHandler={this.setErrors}
            url='/processes/envio_ministerio'
            isFormLite={true}
          />
        </div>
      </Paper>
    );
  }
}

ReenvioMinisterio.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(ReenvioMinisterio)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;