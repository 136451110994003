import createStyles from '@material-ui/core/styles/createStyles';

const styles = theme => createStyles({
  paper: {
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(5),
    marginButton: theme.spacing(5),
    position: 'fixed',
    textAlign: 'center',
    paddingTop: '1.5%',
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 80,
      height: 245
    },
    alignItems: 'center',
    backgroundColor: '#fff',
    top: 40,
    left: '72%',
  },
  paperLite: {
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(5),
    marginButton: theme.spacing(5),
    position: 'fixed',
    textAlign: 'center',
    paddingTop: '1.5%',
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 80,
      height: 160
    },
    alignItems: 'center',
    backgroundColor: '#fff',
    top: 40,
    left: '72%',
  },
  paperGrid: {
    display: 'contents'
  },
  iconRed: {
    color: 'red',
    fontSize: 50,
    cursor: 'pointer',
  },
  iconBlue: {
    color: '#2196f3',
    fontSize: 50,
    cursor: 'pointer',
  },
  iconGreen: {
    color: '#11bbb7',
    fontSize: 50,
    cursor: 'pointer',
  },
  iconGray: {
    color: '#7f7f7f',
    fontSize: 50,
    cursor: 'pointer',
  }
});

export default styles;