class Utils {
    getSource(name) {
        switch(name){
            case 'bodega':
                return 'vw_bodega_search'
            case 'estructura':
                return 'vw_estructura_search'
            case 'almacen':
                return 'vw_almacen_search'
            case 'ubicacion':
                return 'vw_ubicacion_search' 
            case 'cliente':
                return 'vw_cliente_search'                 
            case 'lista_empaque':
                return 'vw_lista_empaque'  
            case 'pedido':
                return 'vw_pedido'    
            case 'movimientoinventario':
                return 'vw_movimiento_inventario_search'                                                                                                           
            default:
                return '';
        }
    }
}
export default (new Utils());