import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import Buttoncontrol from "../../GeneralUI/Buttoncontrol";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter, combineStyles } from '../../../Libs';
import AutoComplete from '../../GeneralUI/AutoComplete';
import PSelect from '../../GeneralUI/PSelect'

const styles = theme => ({

  button: {
    margin: theme.spacing(),
  },
  input: {
    display: 'none',
  }
});

class CargarRemesas extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    data: [],
    fields: {
      separador: '',
      clienteCodigo: null,
      sucursalCodigo: null,
      tipoArchivo: '',
      csvFile: null
    },
    responseForm: { Message: '', typeMessage: '' },
    formValidations: {
      view: ['clienteCodigo', 'sucursalCodigo', 'tipoArchivo', 'separador']
    },
    formErrors: {
      clienteCodigo: {},
      sucursalCodigo: {},
      tipoArchivo: {},
      separador: {}
    }
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    fields[event.target.id] = event.target.value;
    this.setState({ fields });
  }

  handleCapture = ({ target }) => {
    let { fields } = this.state;
    fields['csvFile'] = target.files[0];

    this.setState({
      inputFileName: target.files[0].name,
      fields
    });
  }

  save = (response) => {
    let newState = {
      openSnackbar: true,
      responseForm: response.responseForm
    };

    if (newState.responseForm['typeMessage'] === 'success') {
      newState.data = response.data;
    }

    this.setState(newState);
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  render() {
    const { classes } = this.props;

    return (

      <Paper elevation={5} className={classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={classes.gridForm}>

            <InputLabel className={classes.tittleLabelForm}>
              Cargar Remesa
            </InputLabel>
          </Grid>

          <Grid container spacing={4}>

            <Grid item xs={3} className={classes.gridForm}>
              <AutoComplete
                id="clienteCodigo"
                tableName='cliente'
                fields={['clienteCodigo', 'clienteRazonSocial']}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
                size='xl'
                primaryField='clienteCodigo'
                searchField='clienteRazonSocial'
                placeHolder='Seleccionar Cliente'
                onSelect={this.handleChange}
                value={this.state.fields["clienteCodigo"]}
              />
            </Grid>

            <Grid item xs={3} className={classes.gridForm}>
              <AutoComplete
                id="sucursalCodigo"
                tableName='Sucursal'
                fields={['sucursalCodigo', 'sucursalNombre']}
                primaryField='sucursalCodigo'
                aliases={['ID', 'Nombre']}
                searchField='sucursalNombre'
                placeHolder='Sucursal (*)'
                onSelect={this.handleChange}
                value={this.state.fields["sucursalCodigo"]}
                formErrors={this.state.formErrors['sucursalCodigo']}
              />
            </Grid>

            <Grid item xs={3} className={classes.gridForm}>
              <PSelect
                id="tipoArchivo"
                useChildren={true}
                value={this.state.fields["agruparPor"]}
                onChange={this.handleChange}
                namelabel="Tipo Archivo"
              >
                <option value="TCC">TCC</option>
                <option value="DEPRISA">DEPRISA</option>
              </PSelect>
            </Grid>


          </Grid>

          <Grid container spacing={4}>

            <Grid item xs={3} className={classes.gridForm}>
              <PSelect
                id="separador"
                useChildren={true}
                value={this.state.fields["separador"]}
                onChange={this.handleChange}
                namelabel="Separador (*)"
              >
                <option value=";">;</option>
                <option value=",">,</option>
              </PSelect>
            </Grid>

            <Grid item xs={2} className={classes.gridForm}>
              <div className={classes.formControl}>
                <input
                  accept=".csv"
                  className={classes.input}
                  id="csvFile"
                  onChange={this.handleCapture}
                  type="file"
                />

                <label htmlFor="csvFile">
                  <Button raised variant="contained" component="span" className={classes.button}>
                    Seleccionar archivo
                </Button>
                </label>
              </div>
            </Grid>

            <Grid item xs={3} className={classes.gridForm}>
              <div className={classes.formControl}>
                <label>
                  {this.state.inputFileName}
                </label>
              </div>
            </Grid>
          </Grid>
        </form>

        {!this.state.data.length ? null :
          <Grid container spacing={10} xs={12} className={this.props.classes.gridResultSearch}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell align='left'>
                    <b>Factura codigo</b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.getTableRows()}
              </TableBody>
            </Table>
          </Grid>
        }

        <div id='buttonContainer'>
          <Buttoncontrol
            saveData={this.state.fields}
            moduleName='remesa'
            save={this.save}
            primaryFields={['remesaCodigo']}
            formValidations={this.state.formValidations}
            errorHandler={this.setErrors}
            url='/processes/cargar_remesas'
            isFormLite={true}
          />
        </div>

      </Paper>
    );
  }

  getTableRows = () => {
    return this.state.data.map((row, index) => {
      return (
        <TableRow hover key={200 + index} className={this.props.classes.tableRow}>
          <TableCell key={300 + 300} align='left'>
            <a
              href={`/home/factura/${row.facturaCodigo}/edit`}
              target='_blank'
              rel='noopener noreferrer'>
              {row.facturaCodigo}
            </a>
          </TableCell>
        </TableRow>
      );
    });
  }
}



CargarRemesas.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(combineStyles(styles, Styles))(CargarRemesas)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;