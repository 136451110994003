import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import AutoComplete from '../GeneralUI/AutoComplete';
import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';
import { errorSetter } from '../../Libs';
import TextField from '@material-ui/core/TextField';
import PSelect from '../GeneralUI/PSelect'


class Tarifa extends Component {  

  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    fields: {
      tarifaCodigo: null,
      tarifaNombre: null,
      tarifaEstado: 'ACTIVO',
      tarifaTipoValorSeguro: 'PORCENTAJE' ,
      ciudadCodigoOrigen: null,
      ciudadCodigoDestino: null,
      productoCodigo: null,
      clavehCodigo: null,
      carroceriaCodigo: null,
      clienteCodigo: null,
      tarifaValor: 0,
      tarifaValorSeguro: 0,
      tiptarCodigo: null,
      empresaCodigo: 1,
      tarifaDescuento1: 0,
      tarifaDescuento2: 0,
      tarifaDescuento3:0,
      tarifaRangoCubicajeMenor: 0,
      tarifaRangoCubicajeMayor: 0,
      tarifaRangoPesoMenor: 0,
      tarifaRangoPesoMayor: 0,
      tarifaUnidadMedida: 'UND',
      tarifaListaEmpaque: 'NO',
      tarifaSumaImpresionListaEmpaque: 'NO',
      tarifaLiquidacionTercero: 'NO',
      tarifaFactorImpresionCajaListaEmpaque: 0,
      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime()
    },
    responseForm: { Message: '', typeMessage: '' },
    formValidations: {
      view: ['tarifaNombre', 'tarifaEstado', 'tarifaValor','tiptarCodigo', 'tarifaUnidadMedida'],
    },
    formErrors: {
      tarifaNombre: {},
      tarifaEstado: {},
      tarifaValor: {},
      tiptarCodigo: {},
      tarifaUnidadMedida: {},
    }
  };

  componentDidMount() {
    const params = this.props.match.params;
    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'tarifaCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;

    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }

    if (fields[event.target.id] === '') {
      fields[event.target.id] = null;
    }

    this.setState({ fields });
  }

  messageValidation = (type, message) => {
    this.message(type, message);
  }

  save = (response) => {
    this.setState({
      fields: response.data,
      responseForm: response.responseForm
    });
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }

  validaData = () => {
    let isvalid = true
    return isvalid;
  }


  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>

            <InputLabel id="tarifaCodigo"
              className={this.props.classes.tittleLabelForm}>Tarifa
            </InputLabel>

            <Grid item xs={6} className={this.props.classes.chipTitle}>
              {(this.state.fields.tarifaCodigo !== null) ?
                (<Chip
                  label={this.state.fields["tarifaCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="tarifaNombre" label="Nombre Tarifa" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["tarifaNombre"] || undefined}
                InputLabelProps={{ shrink: true }}
                formErrors={this.state.formErrors['tarifaNombre']}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="ciudadCodigoOrigen"
                tableName='vw_ciudad'
                fields={['ciudad_codigo','ciudad_nombre', 'departamento_nombre']}
                isView={true}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Ciudad', size: 5 }, { value: 'Departamento', size: 6 }]}
                size='xx'
                primaryField='ciudad_codigo'
                searchField='ciudad_nombre'
                placeHolder='Seleccionar CiudadOrigen'
                onSelect={this.handleChange}
                value={this.state.fields["ciudadCodigoOrigen"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="ciudadCodigoDestino"
                tableName='vw_ciudad'
                fields={['ciudad_codigo','ciudad_nombre', 'departamento_nombre']}
                isView={true}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Ciudad', size: 5 }, { value: 'Departamento', size: 6 }]}
                size='xx'
                primaryField='ciudad_codigo'
                searchField='ciudad_nombre'
                placeHolder='Seleccionar CiudadDestino'
                onSelect={this.handleChange}
                value={this.state.fields["ciudadCodigoDestino"]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                  id="tarifaUnidadMedida"
                  useChildren={true}
                  value={this.state.fields["tarifaUnidadMedida"]}
                  onChange={this.handleChange}
                  namelabel="Unidad Medida"
                  formErrors={this.state.formErrors['tarifaUnidadMedida']}
                >
                  <option value="UND">UND</option>
                  <option value="M2">M2</option>
                  <option value="M3">M3</option>
                </PSelect>
            </Grid>  
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Tipo Tarifa"
                id="tiptarCodigo"
                namelineInput="tipotarifa"
                fieldVisible="tiptarNombre"
                fieldCode="tiptarCodigo"
                Table="Tipotarifa"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["tiptarCodigo"]}
                estados={this.state}
                InputLabelProps={{ shrink: true }}
                formErrors={this.state.formErrors['tiptarCodigo']}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
            <PSelect
                id="tarifaEstado"
                useChildren={true}
                value={this.state.fields["tarifaEstado"]}
                onChange={this.handleChange}
                namelabel="Estado"
                formErrors={this.state.formErrors['tarifaEstado']}
              >
                <option value="ACTIVO">ACTIVO</option>
                <option value="INACTIVO">INACTIVO</option>
              </PSelect>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="productoCodigo"
                  tableName='producto'
                  fields={['productoCodigo', 'productoCodigoMinisterio', 'productoNombre']}
                  aliases={[{ value: 'ID', size: 1 }, { value: 'Ministerio', size: 2 }, { value: 'Nombre', size: 9 }]}
                  size='xx'
                  primaryField='productoCodigo'
                  searchField='productoNombre'
                  placeHolder='Seleccionar Producto'
                  onSelect={this.handleChange}
                  value={this.state.fields["productoCodigo"]}
                />
              </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="clienteCodigo"
                  tableName='cliente'
                  fields={['clienteCodigo', 'clienteRazonSocial']}
                  aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
                  size='xl'
                  primaryField='clienteCodigo'
                  searchField='clienteRazonSocial'
                  placeHolder='Seleccionar Cliente'
                  onSelect={this.handleChange}
                  value={this.state.fields["clienteCodigo"]}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="carroceriaCodigo"
                  tableName='Carroceria'
                  fields={['carroceriaCodigo', 'carroceriaNombre']}
                  primaryField='carroceriaCodigo'
                  aliases={['ID', 'Nombre']}
                  searchField='carroceriaNombre'
                  placeHolder='Seleccionar Carroceria'
                  onSelect={this.handleChange}
                  value={this.state.fields["carroceriaCodigo"]}
                />
              </Grid>
          </Grid>

          <Grid container spacing={4}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="convehCodigo"
                  tableName='ConfiguracionVehiculo'
                  fields={['convehCodigo', 'convehNombre', 'convehAlias']}
                  primaryField='convehCodigo'
                  aliases={['ID', 'Nombre', 'Alias']}
                  searchField='convehNombre'
                  placeHolder='Configuracion Vehiculo'
                  onSelect={this.handleChange}
                  value={this.state.fields["convehCodigo"]}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
               <PSelect
                id="tarifaTipoValorSeguro"
                useChildren={true}
                value={this.state.fields["tarifaTipoValorSeguro"]}
                onChange={this.handleChange}
                namelabel="Tipo Valor Seguro"
              >
                <option value="PORCENTAJE">PORCENTAJE</option>
                <option value="VALOR">VALOR</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="tarifaValorSeguro" label="Valor Seguro" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["tarifaValorSeguro"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
          <Grid item xs={3} className={this.props.classes.gridForm}>
               <PSelect
                id="tarifaLiquidacionTercero"
                useChildren={true}
                value={this.state.fields["tarifaLiquidacionTercero"]}
                onChange={this.handleChange}
                namelabel="Lista Liquidacion Tercero"
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
               <PSelect
                id="tarifaListaEmpaque"
                useChildren={true}
                value={this.state.fields["tarifaListaEmpaque"]}
                onChange={this.handleChange}
                namelabel="Lista Empaque"
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
               <PSelect
                id="tarifaSumaImpresionListaEmpaque"
                useChildren={true}
                value={this.state.fields["tarifaSumaImpresionListaEmpaque"]}
                onChange={this.handleChange}
                namelabel="Suma Impresion Lista Empaque"
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </PSelect>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="tarifaFactorImpresionCajaListaEmpaque" label="Factor Impresion Caja Lista Empaque" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["tarifaFactorImpresionCajaListaEmpaque"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="tarifaRangoCubicajeMayor" label="Cubicaje Mayor" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["tarifaRangoCubicajeMayor"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="tarifaRangoCubicajeMenor" label="Cubicaje Menor" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["tarifaRangoCubicajeMenor"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
           <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="tarifaRangoPesoMayor" label="Peso Mayor" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["tarifaRangoPesoMayor"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
           </Grid>
           <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="tarifaRangoPesoMenor" label="Peso Menor" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["tarifaRangoPesoMenor"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="tarifaDescuento1" label="Descuento 1" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["tarifaDescuento1"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
           <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="tarifaDescuento2" label="Descuento 2" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["tarifaDescuento2"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="tarifaDescuento3" label="Descuento 3" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["tarifaDescuento3"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="tarifaValor" label="Tarifa Valor" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["tarifaValor"] || undefined}
                InputLabelProps={{ shrink: true }}
                formErrors={this.state.formErrors['tarifaValor']}
              />
            </Grid>
          </Grid>
         
        </form>
        <div><Buttoncontrol
          saveData={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          validaData={this.validaData}
          messageValidation={this.messageValidation}
          primaryFields={['tarifaCodigo']}
          formValidations={this.state.formValidations}
          errorHandler={this.setErrors}
        /></div>
      </Paper>
    );
  }
}

Tarifa.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(Tarifa)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}

    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;