import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { search } from '../../../../Actions/General';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {  Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter } from '../../../../Libs';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import DataGridVisor from '../../../GeneralUI/DataGridVisor';
import to from 'await-to-js';
import _ from 'lodash';


const VISOR_FIELDS = [
{
  name: 'cliente',
  alias: 'Cliente'
},
{
  name: 'almacen_nombre',
  alias: 'Almacen'
},
{
  name: 'bodega_nombre',
  alias: 'Bodega'
},
{
  name: 'estructura_nombre',
  alias: 'Estructura'
},
{
  name: 'ubicacion_nombre',
  alias: 'Ubicacion'
},
{
  name: 'producto_nombre',
  alias: 'Producto'
},
{
  name: 'cantidad',
  alias: 'Cantidad'
},
{
  name: 'costo_unitario',
  alias: 'Costo'
},
{
  name: 'seguro',
  alias: 'Seguro'
},
{
  name: 'ubicacion_capacidad_volumen',
  alias: 'Area'
},
{
  name: 'area_contratada',
  alias: 'AreaContratada'
},
{
  name: 'fecha_ingreso',
  alias: 'FechaIngreso'
},
{
  name: 'duracion_contrato',
  alias: 'Duracion'
},

];
  
const VISOR_COLORS = {
ATRASADO: '#FF3933',
ATIEMPO: '#3380FF'
}

class InventarioVisor extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    page: 0,
    rowsPerPage: 10,
    data: {
      rows: [],
      count: 0
    },
    filters: {
      clienteCodigo: null,
      almacenCodigo: null,
      bodegaCodigo: null,
      ubicacionCodigo: null,
      productoCodigo: null,
    },
    operatorCustom: [],
    responseForm: { Message: '', typeMessage: '' },
    formErrors: {
    }
  };

  componentDidMount() {
    this.props.toggleFavorites();
  }

  componentWillUnmount() {
    this.props.toogleFavoritesShow();
  }

  search = async (page, rowsPerPage) => {
    if (page !== undefined) {
      await to(new Promise((resolve) => {
        this.setState({
          page,
          rowsPerPage
        }, resolve)
      }));
    }

    this.state.operatorCustom = [];
    let filters = {};

    if (this.state.filters.clienteCodigo !== null && this.state.filters.clienteCodigo !== '') {
      this.state.operatorCustom.push("\"clienteCodigo\" = " + this.state.filters.clienteCodigo + "");
    }

    if (this.state.filters.almacenCodigo !== null && this.state.filters.almacenCodigo !== '') {
      this.state.operatorCustom.push("\"almacenCodigo\" = " + this.state.filters.almacenCodigo + "");
    }

    if (this.state.filters.bodegaCodigo !== null && this.state.filters.bodegaCodigo !== '') {
      this.state.operatorCustom.push("\"bodegaCodigo\" = " + this.state.filters.bodegaCodigo + "");
    }

    if (this.state.filters.ubicacionCodigo !== null && this.state.filters.ubicacionCodigo !== '') {
      this.state.operatorCustom.push("\"ubicacionCodigo\" = " + this.state.filters.ubicacionCodigo + "");
    }

    if (this.state.filters.productoCodigo !== null && this.state.filters.productoCodigo !== '') {
      this.state.operatorCustom.push("\"productoCodigo\" = " + this.state.filters.productoCodigo + "");
    }

    _.each(_.keys(this.state.filters), (key) => {
      const value = this.state.filters[key];
      if (value !== null && value !== '') {
        filters[key] = value;
      }
    });

    const [err, data] = await to(search({
      fields: VISOR_FIELDS.map(row => row.name),
      filters: filters,
      operatorCustom: this.state.operatorCustom,
      isView: true,
      options: {
        limit: this.state.rowsPerPage,
        skip: this.state.page * this.state.rowsPerPage,
        order: { almacen_nombre: 'DESC' }
      }
    }, '/modules/vw_inventario/list'));

    if (err) {
      return;
    }

    this.setState({
      data: data
    });
  }

  handleChange = (event) => {
    let { filters } = this.state;
    filters[event.target.id] = event.target.value;
    this.setState({ filters });
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={5} className={`${classes.mainPaper} ${classes.fullWidthPaper}`}>
        <Grid container spacing={4}>
          <Grid item xs={3} className={classes.gridForm}>
            <InputLabel className={classes.tittleLabelForm}>
              Visor Inventario
              </InputLabel>
          </Grid>
        </Grid>

        <Grid container spacing={2}>   
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="almacenCodigo"
                tableName='Almacen'
                fields={['almacenCodigo', 'almacenNombre']}
                primaryField='almacenCodigo'
                aliases={['ID', 'Nombre']}
                searchField='almacenNombre'
                placeHolder='Seleccionar Almacen'
                onSelect={this.handleChange}
                value={this.state.filters["almacenCodigo"]}
              />
            </Grid>     
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="bodegaCodigo"
                  tableName='vw_bodega_search'
                  fields={['bodegaCodigo', 'bodegaNombre', 'almacenNombre']}
                  isView={true}
                  aliases={[{ value: 'ID', size: 1 }, { value: 'Bodega', size: 5 }, { value: 'Almacen', size: 6}]}
                  size='xx'
                  primaryField='bodegaCodigo'
                  searchField='bodegaNombre'
                  placeHolder='Seleccionar Bodega'
                  onSelect={this.handleChange}
                  value={this.state.filters["bodegaCodigo"]}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="ubicacionCodigo"
                  tableName='vw_ubicacion_search'
                  fields={['ubicacionCodigo', 'ubicacionNombre', 'almacenNombre', 'bodegaNombre']}
                  isView={true}
                  aliases={[{ value: 'ID', size: 1 }, { value: 'Ubicacion', size: 3 }, { value: 'Almacen', size: 4 }, { value: 'Bodega', size: 4 }]}
                  size='xx'
                  primaryField='ubicacionCodigo'
                  searchField='ubicacionNombre'
                  placeHolder='Seleccionar Ubicacion'
                  onSelect={this.handleChange}
                  value={this.state.filters["ubicacionCodigo"]}
                />
              </Grid>  
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="clienteCodigo"
                  tableName='cliente'
                  fields={['clienteCodigo', 'clienteRazonSocial']}
                  aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
                  size='xl'
                  primaryField='clienteCodigo'
                  searchField='clienteRazonSocial'
                  placeHolder='Seleccionar Cliente'
                  onSelect={this.handleChange}
                  value={this.state.filters["clienteCodigo"]}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="productoCodigo"
                  tableName='vw_producto_inventario'
                  fields={['producto_codigo','producto_nombre']}
                  isView={true}
                  aliases={[{ value: 'ID', size: 1 },{ value: 'Producto', size: 11 }]}
                  size='xx'
                  primaryField='producto_codigo'
                  searchField='producto_nombre'
                  placeHolder='Seleccionar Producto'
                  where={{
                    producto_tipo: 'INVENTARIO'
                  }}                  
                  onSelect={this.handleChange}
                  value={this.state.filters["productoCodigo"]}
                />
              </Grid>
        </Grid>

        <Grid container spacing={10} >
          <Grid item xs={3} className={this.props.classes.gridForm}>

            <Button
              color='primary'
              variant='contained'
              onClick={(event) => this.search()}
            >
              Buscar
              </Button>

          </Grid>
        </Grid>

        <DataGridVisor
          search={this.search}
          data={this.state.data}
          fields={VISOR_FIELDS}
          colors={VISOR_COLORS}
          moduleUrl='movimiento_inventario'
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
        />

      </Paper>
    );
  }
}

InventarioVisor.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  toggleFavorites: PropTypes.func.isRequired,
  toogleFavoritesShow: PropTypes.func.isRequired
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(InventarioVisor)));

function IntegrationNotistack(props) {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp {...props} />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;