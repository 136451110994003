import {
  connect
} from 'react-redux';
import EncuestaPedido from '../Components/EncuestaPedido';
import {
  loadUserInfo,
  toggleFavorites,
  showFavorites
} from '../Actions/User';

const mapStateToProps = (state) => ({
  usuarioNombreCompleto: state.user.usuarioNombreCompleto,
  usuarioAvatar: state.user.usuarioAvatar,
  showFavorites: state.user.showFavorites
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadUserInfo: () => dispatch(loadUserInfo()),
    toggleFavorites: () => dispatch(toggleFavorites()),
    toogleFavoritesShow: () => dispatch(showFavorites())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EncuestaPedido);