import createStyles from '@material-ui/core/styles/createStyles';

const styles = theme => createStyles({
  avatar: {
    margin: 'auto'
  },
  userName: {
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing()
    }
  },
  root: {
    flexGrow: 1,
    padding: 0,
    backgroundColor: 'pink'
  },
  appBar: {

  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(),
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(3),
    pointerEvents: 'none',
    marginLeft: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  adornment: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  valuelabelform: {
    borderSpacing: 2,
    borderBottom: 0,
    color: '#6e6e6e',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    verticalAlign: 'top',
  },
  link: {
    textDecoration: 'none',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  paperMenu: {
    flexGrow: 1,
    padding: 0,
    width: 205,
    backgroundColor: 'white',
    borderRadius: 4,
    display: 'solid',
    marginBottom: theme.spacing(3),
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    transition: theme.transitions.create('width'),
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 0
    }
  },
  iconContainer: {
    display: 'flex'
  },
  iconFavorite: {
    color: 'white'
  },
  titlelabelform: {
    marginRight: theme.spacing(5),
    borderSpacing: 0,
    borderBottom: 0,
    marginBottom: 2,
    color: '#fff',
    fontSize: '0.9rem',
    fontWeight: 'bold',
  }
});

export default styles;