import Cookies from 'universal-cookie';
import { AUTH as ACTIONS } from '../Config/actionTypes';
import { api, utils } from '../Libs';
import { USER as ENDPOINTS } from '../Config/endpoints';
import { history } from '../Libs/';
const { LOGIN, VALIDATE_USERNAME, VALIDATE_TOKEN } = ENDPOINTS;
const cookie = new Cookies();

export const validateUserName = (userName) => dispatch => {
  dispatch({
    type: ACTIONS.BEFORE_VALIDATION
  });

  api.doRequest({
    endpoint: VALIDATE_USERNAME.url,
    method: VALIDATE_USERNAME.method
  }, {
      usuarioNombreUsuario: userName
    }).then(data => {

      dispatch({
        type: ACTIONS.VALIDATE_USERNAME,
        payload: data
      });

    }).catch(error => {
      dispatch({
        type: ACTIONS.ERROR,
        payload: error
      });
    });
};

export const login = (userName, password) => dispatch => {
  dispatch({
    type: ACTIONS.BEFORE_VALIDATION
  });

  api.doRequest({
    endpoint: LOGIN.url,
    method: LOGIN.method
  }, {
      usuarioNombreUsuario: userName,
      usuarioContrasena: password
    }).then(data => {
      utils.saveInLocalStorage('userInfo', {
        usuarioNombreCompleto: data.usuarioNombreCompleto,
        usuarioAvatar: data.usuarioAvatar
      });
      dispatch({
        type: ACTIONS.LOGIN_SUCCESS,
        payload: data
      });
      history.push('/home/dashboard');
    }).catch(error => {
      dispatch({
        type: ACTIONS.ERROR,
        payload: error
      });
    });
};

export const validateToken = (timeToWait) => (dispatch) => {
  dispatch({
    type: ACTIONS.BEFORE_VALIDATION
  });
  if (!cookie.get('crmpymes-token')) {
    dispatch({
      type: ACTIONS.AFTER_VALIDATION
    });

    return dispatch({
      type: ACTIONS.INITIAL_STATE
    });
  }

  api.doRequest({
    endpoint: VALIDATE_TOKEN.url,
    method: VALIDATE_TOKEN.method
  }, {}, timeToWait).then((data) => {
    dispatch({
      type: ACTIONS.LOGIN_SUCCESS,
      payload: data
    });
    history.push('/home/dashboard');
  }).catch(error => {
    cookie.remove('crmpymes-token')
    dispatch({
      type: ACTIONS.AFTER_VALIDATION
    });
  });
};