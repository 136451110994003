import React, { Component } from "react";
import Login from "./Containers/Login";
import EncuestaBodega from "./Containers/EncuestaBodega";
import EncuestaPedido from "./Containers/EncuestaPedido";
import EncuestaTransporte from "./Containers/EncuestaTransporte";
import Home from "./Containers/Home";
import './assets/Styles/App.css';

// plain components
import Landing from "./Components/Landing";
import { Route, Switch, Router } from 'react-router-dom';
import { history } from './Libs';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './Components/GeneralUI/theme';

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Router history={history}>
          <div className="App">
            <Switch>
              <Route path='/' component={Landing} exact />
              <Route path='/home' component={Home} />
              <Route path='/login' component={Login} exact />
              <Route path='/encuesta_bodega' component={EncuestaBodega} exact />
              <Route path='/encuesta_pedido' component={EncuestaPedido} exact />
              <Route path='/encuesta_transporte' component={EncuestaTransporte} exact />

              <Route path='/home/:module' component={Home} exact />
              <Route path="/home/:module/:id/edit" component={Home} exact />
              <Route path='/home/:module/search' component={Home} exact />
              <Route component={Landing} />

            </Switch>
          </div>

        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;