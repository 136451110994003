import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";

/*import Header from "./../../../Components/dashboard/Header/Header.jsx";
import Footer from "./../../../Components/dashboard/Footer/Footer.jsx";
import Sidebar from "./../../../Components/dashboard/Sidebar/Sidebar.jsx";
import FixedPlugin from "./../../../Components/dashboard/FixedPlugin/FixedPlugin.jsx";*/
import dashboardRoutes from "./../../routes/dashboard.jsx";
import withStyles from '@material-ui/core/styles/withStyles';
import { Paper } from '@material-ui/core';

var ps;

const Styles = theme => ({
  mainPaper: {
    marginTop: theme.spacing(10),
    padding: theme.spacing(3),
    paddingBottom: 0,
    width: '98%',
    height: 'auto',
    minHeight: '60%',
    position: 'absolute',
    display: 'block',
    left: 7,
    alignItems: 'center',
    backgroundColor: '#fff',
  }
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
    }
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.refs.mainPanel.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  }
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className="wrapper" ref="mainPanel">
        <Paper elevation={5} className={classes.mainPaper}>
          <Switch>
            {dashboardRoutes.map((prop, key) => {
              if (prop.pro) {
                return null;
              }
              if (prop.redirect) {
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              }
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
        </Paper>
      </div>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(Dashboard);
