import { connect } from 'react-redux';
import Search from '../Components/Search';
import { search, getModuleDefinition, clearData } from '../Actions/Form';

const mapStateToProps = (state) => ({
  data: state.form.data,
  rowCount: state.form.rowCount,
  docFields: state.form.docFields,
  searchableFields: state.form.searchableFields,
  visibleFields: state.form.visibleFields,
  composedFields: state.form.composedFields,
  docName: state.form.docName,
  error: state.form.error,
  errorMessage: state.form.errorMessage,
  waitForResponse: state.form.waitForResponse
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearData: () => dispatch(clearData()),
    search: (params, moduleName) => dispatch(search(params, moduleName)),
    getModuleDefinition: (moduleName, callback) => dispatch(getModuleDefinition(moduleName, callback))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);