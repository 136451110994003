import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Textfieldlabel from "../GeneralUI/Textfieldlabel";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import PSelect from '../GeneralUI/PSelect'
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import AutoComplete from '../GeneralUI/AutoComplete';

import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';
import { errorSetter } from '../../Libs';

class Vehiculo extends Component {

  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    fields: {
      vehiculoCodigo: null,
      vehiculoPlaca: null,
      tipafiCodigo: null,
      vehiculoArticulado: null,

      trailerCodigo: null,
      paisCodigo: null,
      vehiculoEstado: null,

      terceroCodigoPropietario: null,
      terceroCodigoPoseedor: null,
      terceroCodigoConductor: null,

      convehCodigo: null,
      marvehCodigo: null,
      clalinCodigo: null,

      vehiculoModelo: null,
      vehiculoModeloRepotenciado: 0,
      colorCodigo: null,

      vehiculoEjes: null,
      vehiculoCapacidad: null,
      vehiucloPesoBruto: null,

      vehiculoPesoVacio: null,
      vehiculoRegistroNacional: null,
      vehiculoVigenciaRegistroNacional: null,

      vehiculoMultiempresa: null,
      vehiculoMotor: null,
      carroceriaCodigo: null,

      aseguradoraCodigo: null,
      vehiculoFechaVencimientoPoliza: null,
      vehiculoNumeroPoliza: null,

      aseguradoraCodigoSoat: null,
      vehiculoFechaVencimientoSoat: null,
      vehiculoSoat: null,

      vehiculoChasis: null,
      vehiculoCertificadoGases: null,
      vehiculoTarjetaPropiedad: null,

      vehiculoAlto: null,
      vehiculoAncho: null,
      vehiculoLargo: null,

      progpsCodigo: null,
      vehiculoPaginaGps: null,
      vehiculoPaginaUsuario: null,

      vehiculoPaginaClave: null,
      vehiculoCertificadoHabilitacion: null,
      vehiculoCertificadoHabilitacionFechaVencimiento: null,
      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime()
    },
    responseForm: { Message: '', typeMessage: '' },
    formValidations: {
      view: ['terceroCodigoPropietario', 'terceroCodigoPoseedor', 'terceroCodigoConductor']
    },

    formErrors: {
      terceroCodigoPropietario: {},
      terceroCodigoPoseedor: {},
      terceroCodigoConductor: {}
    }
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'vehiculoCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }
    if (fields[event.target.id] === '') {

      fields[event.target.id] = null;
      
    }

    this.setState({ fields });
  }

  save = (response) => {
    this.setState({
      fields: response.data,
      responseForm: response.responseForm
    });
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }


  handleAuto = (autoCompleteValue) => {
    this.setState({ colorAutoComplete: autoCompleteValue });
  }

  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>

            <InputLabel id="vehiculoCodigo"
              className={this.props.classes.tittleLabelForm}>Vehiculo
            </InputLabel>

            <Grid item xs={6} className={this.props.classes.chipTitle}>
              {(this.state.fields.vehiculoCodigo !== null) ?
                (<Chip
                  label={this.state.fields["vehiculoCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoPlaca" label="Placa (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoPlaca"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Tipoafiliado (*)"
                id="tipafiCodigo"
                namelineInput="tipoafiliado"
                fieldVisible="tipafiNombre"
                fieldCode="tipafiCodigo"
                Table="TipoAfiliado"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["tipafiCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoArticulado" label="Articulado (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoArticulado"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="trailerCodigo"
                tableName='Trailer'
                fields={['trailerCodigo', 'trailerPlaca']}
                primaryField='trailerCodigo'
                aliases={['ID', 'Nombre']}
                searchField='trailerPlaca'
                placeHolder='Seleccionar Trailer'
                onSelect={this.handleChange}
                value={this.state.fields["trailerCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Pais (*)"
                id="paisCodigo"
                namelineInput="pais"
                fieldVisible="paisNombre"
                fieldCode="paisCodigo"
                Table="Pais"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["paisCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="vehiculoEstado"
                useChildren={true}
                value={this.state.fields["vehiculoEstado"]}
                onChange={this.handleChange}
                namelabel="Estado (*)"
              >
                <option value="ACTIVO">ACTIVO</option>
                <option value="INACTIVO">INACTIVO</option>
              </PSelect>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="terceroCodigoPropietario"
                tableName='Tercero'
                fields={['terceroCodigo', 'terceroDocumento', 'terceroRazonSocial']}
                primaryField='terceroCodigo'
                aliases={[{ value: 'ID', size: 1 }, { value: 'Documento', size: 2 }, { value: 'terceroRazonSocial', size: 9 }]}
                searchField='terceroRazonSocial'
                placeHolder='Seleccionar TerceroPropietario'
                onSelect={this.handleChange}
                value={this.state.fields["terceroCodigoPropietario"]}
                size='xx'
                formErrors={this.state.formErrors['terceroCodigoPropietario']}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
<AutoComplete
                id="terceroCodigoPoseedor"
                tableName='Tercero'
                fields={['terceroCodigo', 'terceroDocumento', 'terceroRazonSocial']}
                primaryField='terceroCodigo'
                aliases={[{ value: 'ID', size: 1 }, { value: 'Documento', size: 2 }, { value: 'terceroRazonSocial', size: 9 }]}
                searchField='terceroRazonSocial'
                placeHolder='Seleccionar TerceroPoseedor'
                onSelect={this.handleChange}
                value={this.state.fields["terceroCodigoPoseedor"]}
                size='xx'
                formErrors={this.state.formErrors['terceroCodigoPoseedor']}
              />

            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
            <AutoComplete
                id="terceroCodigoConductor"
                tableName='Tercero'
                fields={['terceroCodigo', 'terceroDocumento', 'terceroRazonSocial']}
                primaryField='terceroCodigo'
                aliases={[{ value: 'ID', size: 1 }, { value: 'Documento', size: 2 }, { value: 'terceroRazonSocial', size: 9 }]}
                searchField='terceroRazonSocial'
                placeHolder='Seleccionar TerceroConductor'
                onSelect={this.handleChange}
                value={this.state.fields["terceroCodigoConductor"]}
                size='xx'
                formErrors={this.state.formErrors['terceroCodigoConductor']}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="convehCodigo"
                tableName='ConfiguracionVehiculo'
                fields={['convehCodigo', 'convehNombre', 'convehAlias']}
                primaryField='convehCodigo'
                aliases={['ID', 'Nombre', 'Alias']}
                searchField='convehNombre'
                placeHolder='Configuracion Vehiculo'
                onSelect={this.handleChange}
                value={this.state.fields["convehCodigo"]}
              />

            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="marvehCodigo"
                tableName='MarcaVehiculo'
                fields={['marvehCodigo', 'marvehNombre']}
                primaryField='marvehCodigo'
                aliases={['ID', 'Nombre']}
                searchField='marvehNombre'
                placeHolder='Seleccionar MarcaVehiculo'
                onSelect={this.handleChange}
                value={this.state.fields["marvehCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="clalinCodigo"
                tableName='ClaseLinea'
                fields={['clalinCodigo', 'clalinNombre']}
                primaryField='clalinCodigo'
                aliases={['ID', 'Nombre']}
                searchField='clalinNombre'
                placeHolder='Seleccionar ClaseLinea'
                onSelect={this.handleChange}
                value={this.state.fields["clalinCodigo"]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoModelo" label="Modelo (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoModelo"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoModeloRepotenciado" label="Modelo Repotenciado (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoModeloRepotenciado"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="colorCodigo"
                tableName='Color'
                fields={['colorCodigo', 'colorNombre']}
                primaryField='colorCodigo'
                aliases={['ID', 'Nombre']}
                searchField='colorNombre'
                placeHolder='Seleccionar Color'
                onSelect={this.handleChange}
                value={this.state.fields["colorCodigo"]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoEjes" label="Ejes (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoEjes"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoCapacidad" label="Capacidad (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoCapacidad"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiucloPesoBruto" label="Peso Bruto (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiucloPesoBruto"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoPesoVacio" label="Peso Vacio (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoPesoVacio"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoRegistroNacional" label="Registro Nacional (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoRegistroNacional"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoVigenciaRegistroNacional"
                label="Vigencia Registro Nacional (*)"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                type="date"
                value={this.state.fields["vehiculoVigenciaRegistroNacional"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoMultiempresa" label="Multiempresa (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoMultiempresa"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoMotor" label="Motor (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoMotor"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>


              <AutoComplete
                id="carroceriaCodigo"
                tableName='Carroceria'
                fields={['carroceriaCodigo', 'carroceriaNombre']}
                primaryField='carroceriaCodigo'
                aliases={['ID', 'Nombre']}
                searchField='carroceriaNombre'
                placeHolder='Seleccionar Carroceria'
                onSelect={this.handleChange}
                value={this.state.fields["carroceriaCodigo"]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="aseguradoraCodigo"
                tableName='Aseguradora'
                fields={['aseguradoraCodigo', 'aseguradoraRazonSocial']}
                primaryField='aseguradoraCodigo'
                aliases={['ID', 'Nombre']}
                searchField='aseguradoraRazonSocial'
                placeHolder='Seleccionar Aseguradora'
                onSelect={this.handleChange}
                value={this.state.fields["aseguradoraCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoFechaVencimientoPoliza"
                label="Fechavencimiento Poliza (*)"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                type="date"
                placeholder=""
                value={this.state.fields["vehiculoFechaVencimientoPoliza"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoNumeroPoliza" label="Numero Poliza (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoNumeroPoliza"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="aseguradoraCodigoSoat"
                tableName='Aseguradora'
                fields={['aseguradoraCodigo', 'aseguradoraRazonSocial']}
                primaryField='aseguradoraCodigo'
                aliases={['ID', 'Nombre']}
                searchField='aseguradoraRazonSocial'
                placeHolder='Seleccionar Aseguradora Soat'
                onSelect={this.handleChange}
                value={this.state.fields["aseguradoraCodigoSoat"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoFechaVencimientoSoat"
                label="Fechavencimiento Soat (*)"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                type="date"
                placeholder=""
                value={this.state.fields["vehiculoFechaVencimientoSoat"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoSoat" label="Numero Soat (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoSoat"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoChasis" label="Chasis (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoChasis"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoCertificadoGases" label="Certificado Gases (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoCertificadoGases"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoTarjetaPropiedad" label="Tarjeta Propiedad (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoTarjetaPropiedad"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoAlto" label="Alto (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoAlto"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoAncho" label="Ancho (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoAncho"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoLargo" label="Largo (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoLargo"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>

              <AutoComplete
                id="progpsCodigo"
                tableName='ProveedorGps'
                fields={['progpsCodigo', 'progpsNombre']}
                primaryField='progpsCodigo'
                aliases={['ID', 'Nombre']}
                searchField='progpsNombre'
                placeHolder='Seleccionar ProveedorGps'
                onSelect={this.handleChange}
                value={this.state.fields["progpsCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoPaginaGps" label="Pagina GPS (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoPaginaGps"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoPaginaUsuario" label="Pagina Usuario (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoPaginaUsuario"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoPaginaClave" label="Pagina Clave (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoPaginaClave"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoCertificadoHabilitacion" label="Certificado Habilitacion (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["vehiculoCertificadoHabilitacion"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="vehiculoCertificadoHabilitacionFechaVencimiento" label="Certificado Vigencia (*)"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                type="date"
                value={this.state.fields["vehiculoCertificadoHabilitacionFechaVencimiento"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Usuario" value={Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Creacion" value={this.state.fields.createdAt} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Modificacion" value={this.state.fields.updatedAt} />
            </Grid>
          </Grid>


        </form>
        <div><Buttoncontrol
          saveData={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          primaryFields={['vehiculoCodigo']}
          formValidations={this.state.formValidations}
          errorHandler={this.setErrors}
        /></div>
      </Paper>
    );
  }
}

Vehiculo.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(Vehiculo)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}

    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;