import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import Buttoncontrol from "../../../GeneralUI/Buttoncontrol";
import { withRouter } from 'react-router';
import { saveCustom } from './../../../../Actions/General'
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter } from '../../../../Libs/';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import PSelect from '../../../GeneralUI/PSelect'
import Utils from '../../../../Libs/utils';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

class InventarioActualizar  extends Component {

  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {   
    fields: {
      productoCodigo: null,
      almacenCodigo: null,
      clienteCodigo: null,
      bodegaCodigo: null, 
      ubicacionCodigo: null, 
      Cantidad:null, 
      costoUnitario: null, 
      Lote: null, 
      Cubicaje: null, 
      ValorDeclarado: null, 
      ValorSeguro: null, 
      Estado: null, 
      DuracionBodega: null,
      AliasBodega: null, 
      FechaIngresoBodega: null,
      movinvObservacion:null,
      movinvPrecintoActual:null,
      movinvPrecintoAnterior:null,
      movinvMotivo:null,
      clienteDocumento:null
    },
    fieldsCopy:{
      productoCodigo: null,
      almacenCodigo: null,
      clienteCodigo: null,
      bodegaCodigo: null, 
      ubicacionCodigo: null, 
      Cantidad:null, 
      costoUnitario: null, 
      Lote: null, 
      Cubicaje: null, 
      ValorDeclarado: null, 
      ValorSeguro: null, 
      Estado: null, 
      DuracionBodega: null,
      AliasBodega: null, 
      FechaIngresoBodega: null,
      movinvObservacion:null,
      movinvPrecintoActual:null,
      movinvPrecintoAnterior:null,
      movinvMotivo:null
    },
    fieldsDisabled: {
      costoUnitario: false,
      ValorDeclarado: false,
      ValorSeguro: false
    },
    responseForm: { Message: '', typeMessage: '' },
    formValidations: {
      view: ['movinvMotivo','productoCodigo','almacenCodigo','clienteCodigo','bodegaCodigo','ubicacionCodigo','Cantidad']
    },
    formErrors: {
      movinvMotivo:{},
      productoCodigo:{},
      almacenCodigo:{},
      clienteCodigo:{},
      bodegaCodigo:{},
      ubicacionCodigo:{},
      Cantidad:{},
    }
  };

  componentDidMount = () => {
    console.log("3. aquiiii");
    const params = this.props.match.params;
    this.setFieldDisabled();     
    if (!params.id) {
      return;
    }       
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = async (event) => {

    let { fields } = this.state;
    const caret = event.target.selectionStart;
    const element = event.target;
    if (element.hasOwnProperty('setSelectionRange')) {
      window.requestAnimationFrame(() => {
        element.setSelectionRange(caret, caret);
      });
    }

    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }

    if (fields[event.target.id] === '') {
      fields[event.target.id] = null;
    }

    if (event.target.id === 'productoCodigo') {
      await this.getInventario(fields[event.target.id]);
    }else{
      this.setState({ fields });
    }   
  }

  getInventario = async (field) => {

    let { fields, fieldsCopy } = this.state;

    await saveCustom({value:field, key:field}, '/processes/getInventario').then(data => {
      console.log("INVENTARIO");
      console.log(data);
      fields["bodegaCodigo"] = data.bodegaCodigo;
      fields["productoCodigo"] = data.productoCodigo;
      fields["ubicacionCodigo"] = data.ubicacionCodigo;  
      fields["Cantidad"] = data.inventarioCantidad;
      fields["costoUnitario"] = data.inventarioCostoUnitario;
      fields["Lote"] = data.inventarioLote;
      fields["Estado"] = data.inventarioEstado;
      fields["Cubicaje"] = data.inventarioCaracteristica1;       
      fields["ValorDeclarado"] = data.inventarioCaracteristica2;
      fields["ValorSeguro"] = data.inventarioCaracteristica6;
      fields["DuracionBodega"] = data.inventarioCaracteristica3;
      fields["AliasBodega"] = data.inventarioCaracteristica4;
      fields["FechaIngresoBodega"] = data.inventarioCaracteristica5;
      fields["clienteDocumento"] = data.cliente.clienteDocumento;

      fieldsCopy["bodegaCodigo"] = data.bodegaCodigo;
      fieldsCopy["productoCodigo"] = data.productoCodigo;
      fieldsCopy["ubicacionCodigo"] = data.ubicacionCodigo;  
      fieldsCopy["Cantidad"] = data.inventarioCantidad;
      fieldsCopy["costoUnitario"] = data.inventarioCostoUnitario;
      fieldsCopy["Lote"] = data.inventarioLote;
      fieldsCopy["Estado"] = data.inventarioEstado;
      fieldsCopy["Cubicaje"] = data.inventarioCaracteristica1;       
      fieldsCopy["ValorDeclarado"] = data.inventarioCaracteristica2;
      fieldsCopy["ValorSeguro"] = data.inventarioCaracteristica6;
      fieldsCopy["DuracionBodega"] = data.inventarioCaracteristica3;
      fieldsCopy["AliasBodega"] = data.inventarioCaracteristica4;
      fieldsCopy["FechaIngresoBodega"] = data.inventarioCaracteristica5;

      this.setState({ fields, fieldsCopy});

    }).catch(error => {
      console.log(error);
      this.setState({
        responseForm: { Message: "Error buscando inventario." + error.errorMessage, typeMessage: "error" }
      })
    });
  }

  setFieldDisabled = () => {
    let { fieldsDisabled} = this.state;

    if ( (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'JENNY PAOLA ROCHA'
        && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'DIANA KATHERINE GONZALEZ OSORIO' 
        && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'ALEX BONILLA'  
        && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'CRISTIAN ANDRES SUAREZ MELO'        
        && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'APRENDIZ CONTABLE BODEHOGAR' 
        && (Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto) !== 'LUIS NABOR MARINEZ ANGULO'
      ) {
        fieldsDisabled["costoUnitario"]=true;
        fieldsDisabled["ValorDeclarado"]=true;
        fieldsDisabled["ValorSeguro"]=true;
        this.setState({fieldsDisabled});
    }  
  }

  handleBlur = (event) => {
    if (event.target.id === 'porcentajeSeguro') {
      this.calculoSeguro();
    }
  }

  messageValidation = (type, message) => {
    this.message(type, message);
  }

  save = (response) => {
    this.setState({
      fields: response.data,
      responseForm: response.responseForm
    }
    );

    this.message(this.state.responseForm['typeMessage'], this.state.responseForm['Message']);
  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }
  
  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>
            <InputLabel className={this.props.classes.tittleLabelForm}>
               Actualizar Datos de Inventario.
            </InputLabel>
          </Grid>


          <Grid container spacing={2}> 
          <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="movinvMotivo"
                useChildren={true}
                value={this.state.fields["movinvMotivo"]}
                onChange={this.handleChange}
                namelabel="Motivo"
                formErrors={this.state.formErrors['movinvMotivo']}
              >                
                <option value="INGRESO PARCIAL FACTURABLE">INGRESO PARCIAL FACTURABLE</option>                
                <option value="PROCEDIMIENTO INTERNO">PROCEDIMIENTO INTERNO</option>  
                <option value="INGRESO PARCIAL NO FACTURABLE">INGRESO PARCIAL NO FACTURABLE</option>
                <option value="VISITA CLIENTE">VISITA CLIENTE</option>
                <option value="RETIRO PARCIAL NO FACTURABLE">RETIRO PARCIAL NO FACTURABLE</option>   
                <option value="TRASLADO">TRASLADO</option>             
                <option value="RETIRO PARCIAL FACTURABLE">RETIRO PARCIAL FACTURABLE</option>
                
              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="almacenCodigo"
                  tableName='Almacen'
                  fields={['almacenCodigo', 'almacenNombre']}
                  primaryField='almacenCodigo'
                  aliases={['ID', 'Nombre']}
                  searchField='almacenNombre'
                  placeHolder='Seleccionar Almacen'
                  onSelect={this.handleChange}
                  value={this.state.fields["almacenCodigo"]}
                  formErrors={this.state.formErrors['almacenCodigo']}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>            
                <AutoComplete
                  id="clienteCodigo"
                  tableName='cliente'
                  fields={['clienteCodigo', 'clienteRazonSocial','clienteDocumento']}
                  aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 7 }, { value: 'Documento', size: 4 }]}
                  size='xx'
                  primaryField='clienteCodigo'
                  searchField='clienteRazonSocial'
                  placeHolder='Seleccionar Cliente'
                  onSelect={this.handleChange}
                  value={this.state.fields["clienteCodigo"]}
                  formErrors={this.state.formErrors['clienteCodigo']}
                />
              </Grid>              
          </Grid>

          <Grid container spacing={2}> 
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField
                  id="clienteDocumento"
                  label="Cliente Documento"
                  type="text"
                  fullWidth margin="normal"
                  onChange={this.handleChange}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={this.state.fields["clienteDocumento"] || undefined}
                  disabled
                />
              </Grid>  
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="productoCodigo"
                  tableName='vw_inventario'
                  fields={['inventario_codigo','producto_nombre', 'bodega_nombre', 'ubicacion_nombre', 'cantidad', 'estado']}
                  isView={true}
                  aliases={[{ value: 'ID', size: 1 },{ value: 'Producto', size: 3 },{ value: 'Bodega', size: 2 },{ value: 'Ubicacion', size: 2 },{ value: 'Cant', size: 1 },{ value: 'Estado', size: 3 }]}
                  size='xx'
                  primaryField='inventario_codigo'
                  searchField='producto_nombre'
                  placeHolder='Seleccionar Producto'
                  onSelect={this.handleChange}
                  where={{                    
                    cliente_codigo: this.state.fields["clienteCodigo"] ,
                    almacen_codigo: this.state.fields["almacenCodigo"]  
                  }}
                  value={this.state.fields["productoCodigo"]}
                  formErrors={this.state.formErrors['productoCodigo']}
                />
              </Grid>  
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="bodegaCodigo"
                  tableName='vw_bodega_search'
                  fields={['bodegaCodigo', 'bodegaNombre', 'almacenNombre']}
                  isView={true}
                  aliases={[{ value: 'ID', size: 1 }, { value: 'Bodega', size: 5 }, { value: 'Almacen', size: 6}]}
                  size='xx'
                  primaryField='bodegaCodigo'
                  searchField='bodegaNombre'
                  placeHolder='Seleccionar Bodega'
                  onSelect={this.handleChange}
                  where={{                    
                    almacenCodigo: this.state.fields["almacenCodigo"] 
                  }}
                  value={this.state.fields["bodegaCodigo"] || undefined}
                  formErrors={this.state.formErrors['bodegaCodigo']}
                />
              </Grid>
          </Grid>

          <Grid container spacing={4}>            
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="ubicacionCodigo"
                  tableName='vw_ubicacion_search'
                  fields={['ubicacionCodigo', 'ubicacionNombre', 'bodegaNombre', 'almacenNombre']}
                  isView={true}
                  aliases={[{ value: 'ID', size: 1 }, { value: 'Ubicacion', size: 3 }, { value: 'Bodega', size: 4 }, { value: 'Almacen', size: 4 }]}
                  size='xx'
                  primaryField='ubicacionCodigo'
                  searchField='ubicacionNombre'
                  placeHolder='Seleccionar Ubicacion'
                  onSelect={this.handleChange}
                  where={{    
                    almacenCodigo: this.state.fields["almacenCodigo"] 
                  }}
                  value={this.state.fields["ubicacionCodigo"] || undefined }
                  formErrors={this.state.formErrors['ubicacionCodigo']}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="Cantidad" label="Cantidad | Area Utilizada"  type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["Cantidad"] || undefined}
                  InputLabelProps={{ shrink: true }}
                  formErrors={this.state.formErrors['Cantidad']}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="costoUnitario" label="Costo Unitario" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["costoUnitario"] || undefined}
                  InputLabelProps={{ shrink: true }}
                  disabled={this.state.fieldsDisabled["costoUnitario"]}
                />
              </Grid> 
          </Grid>

          <Grid container spacing={2}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField
                  id="Lote"
                  label="Lote"
                  type="text"
                  fullWidth margin="normal"
                  onChange={this.handleChange}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={this.state.fields["Lote"] || undefined}
                />
              </Grid>   
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="Cubicaje" label="Area Contratada" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["Cubicaje"] || undefined}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>   
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="ValorDeclarado" label="Valor Declarado" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["ValorDeclarado"] || undefined}
                  InputLabelProps={{ shrink: true }}
                  disabled={this.state.fieldsDisabled["ValorDeclarado"]}
                />
              </Grid>        
          </Grid>

          <Grid container spacing={2}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="ValorSeguro" label="Valor Seguro" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["ValorSeguro"] || undefined}
                  InputLabelProps={{ shrink: true }}
                  disabled={this.state.fieldsDisabled["ValorSeguro"]}
                />
              </Grid>  
              <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="Estado" 
                useChildren={true}
                onChange={this.handleChange}
                namelabel="Estado"
                value={this.state.fields["Estado"] || undefined}                
              >
                <option value="DISPONIBLE">DISPONIBLE</option>
                <option value="AVERIADO">AVERIADO</option>
                <option value="RESERVADO">RESERVADO</option>
              </PSelect>
              </Grid>  
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="DuracionBodega" label="Duracion Bodega" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["DuracionBodega"] || undefined}
                  InputLabelProps={{ shrink: true }}                  
                />
              </Grid>            
          </Grid>
          
          <Grid container spacing={2}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="AliasBodega" label="Alias Bodega" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["AliasBodega"] || undefined}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="FechaIngresoBodega"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["FechaIngresoBodega"] || undefined}
                  type="date"
                  label="Fecha Ingreso Bodega"
                  InputLabelProps={{ shrink: true }}                  
                />
              </Grid>

              <Grid container spacing={2}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                    <TextField id="movinvPrecintoActual" label="Precinto Actual" type="text"
                      fullWidth margin="normal" variant="outlined"
                      onChange={this.handleChange}
                      value={this.state.fields["movinvPrecintoActual"]}
                      InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                    <TextField id="movinvPrecintoAnterior" label="Precinto Anterior" type="text"
                      fullWidth margin="normal" variant="outlined"
                      onChange={this.handleChange}
                      value={this.state.fields["movinvPrecintoAnterior"]}
                      InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <TextareaAutosize id="movinvObservacion" label="Observacion" type="text"
                    placeholder="Observacion" variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.fields["movinvObservacion"] || undefined}
                    rows="4"
                    cols="30"
                  />
                </Grid>
              </Grid>
          </Grid>
        </form>
        <div id='buttonContainer'>
          <Buttoncontrol
            saveData={this.state}
            saveDataValidation={this.state.fields}
            moduleName='inventario'
            save={this.save}
            messageValidation={this.messageValidation}
            primaryFields={['inventarioCodigo']}
            formValidations={this.state.formValidations}
            errorHandler={this.setErrors}
            url='/processes/inventario_actualizar'
            isFormLite={true}
          />
        </div>
      </Paper >
    );
  }
}

InventarioActualizar.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};


const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(InventarioActualizar )));

function IntegrationNotistack() {
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }
  return (

    <SnackbarProvider maxSnack={1} autoHideDuration={360000}
      ref={notistackRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      action={(key) => (
        <Button variant="text" color="primary" size="small" onClick={onClickDismiss(key)}>
          {'X'}
        </Button>
      )}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;