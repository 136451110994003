import { combineReducers } from 'redux';
import authReducer from './auth/reducer';
import userReducer from './user/reducer';
import formReducer from './form/reducer';

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  form: formReducer
});
