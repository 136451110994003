import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import _ from 'lodash';
import PSelect from '../GeneralUI/PSelect';
import AutoComplete from '../GeneralUI/AutoComplete';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../GeneralUI/Styles';

class Input extends Component {

  state = {
    fieldsValues: {}
  };

  renderComponents = (classes) => {
    const fields = _.cloneDeep(this.props.rowsSearchable);
    const rows = [];
    for (let i = 0; fields.length > 0; i++) {
      const fieldsToRender = fields.splice(0, 3);
      rows.push(this.renderRow(fieldsToRender, i, classes));
    }

    return rows;
  }

  renderRow = (row, index, classes) => {
    return (
      <Grid key={'row-' + index} container spacing={4} >
        {
          row.map((field, index) => {
            return (
              <Grid key={'input-' + index} item xs={3} className={classes.gridForm}>
                {this.renderComponent(this.getRowDefinition(field))}
              </Grid>
            );
          })
        }
      </Grid>
    );
  }

  getRowDefinition = (fieldName) => {
    const rawField = _.find(this.props.rowsDefinition, { name: fieldName });

    return rawField;
  }

  renderComponent = (field) => {
    if (!field) {
      return null;
    }

    switch (field.type) {
      case "int":
        return this.renderInput(field);
      case "string":
        return this.renderInput(field);
      case "text":
        return this.renderInput(field, 'text');
      case "date":
        return this.renderInput(field, 'date');
      default:
        return null;
    }
  }

  renderInput(field, type) {
    const composedField = _.find(this.props.composedFields, { field: field.name });

    if (composedField) {
      return this.renderComposedField(composedField);
    } else {
      return this.renderBasicInput(field, type);
    }
  }

  renderComposedField = (fieldData) => {
    switch (fieldData.type) {
      case 'pselect':
        return (<PSelect
          id={fieldData.field}
          useChildren={fieldData.definition.useChildren}
          onChange={this.props.handleInputChange}
          namelabel={fieldData.definition.namelabel}
        >
          {fieldData.definition.children ? fieldData.definition.children.map((child, index) => {
            return (<option key={'pselect' + fieldData.field + index} value={child.value}>{child.label}</option>);
          }) : null}
        </PSelect>);
      case 'autocomplete':
        return (<AutoComplete
          id={fieldData.field}
          tableName={fieldData.definition.tableName}
          fields={fieldData.definition.fields}
          primaryField={fieldData.definition.primaryField}
          aliases={fieldData.definition.aliases}
          searchField={fieldData.definition.searchField}
          placeHolder={fieldData.definition.placeHolder}
          onSelect={this.props.handleInputChange}
        />);
      default:
        return null;
    }
  }

  renderBasicInput = (field, type) => {
    return (
      <TextField
        id={field.name}
        type={type}
        label={field.prettyName}
        margin="normal"
        variant="outlined"
        onChange={this.props.handleInputChange}
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
    );
  }

  render() {
    return (
      <div>
        {this.renderComponents(this.props.classes)}
      </div>
    )
  }
}

Input.propTypes = {
  rowsDefinition: PropTypes.array.isRequired
}

export default withStyles(Styles, { withTheme: true })(Input);