import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './Styles';
//import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

class Textfieldlabel extends React.Component {

  render() {
    return (

      <TextField className={this.props.margin}
        InputLabelProps={
          {
            shrink: true,
            disabled: false,
            classes: {
              root: this.props.classes.formLabelRoot,
              disabled: this.props.formLabelRoot,
            },

          }}
        InputProps={{
          classes: {
            disabled: this.props.classes.cssDisabled,
            notchedOutline: this.props.classes.notchedOutline,
          },
        }}
        disabled={true}
        value={this.props.value}
        label={this.props.label}
        variant="outlined"
        id={this.props.id}
      />
    );
  }
}

export default withStyles(Styles, { withTheme: true })(Textfieldlabel);