import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import Buttoncontrol from "../../../GeneralUI/Buttoncontrol";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { TextField, Button } from '@material-ui/core';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter, combineStyles } from '../../../../Libs/';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import Datagrid from '../../../GeneralUI/PDatagrid/'
import CustomizedExpansionPanel from '../../../GeneralUI/PExpansionpanels'
import to from 'await-to-js';
import { search } from '../../../../Actions/General';

const VISOR_FIELDS = [{
  name: 'pedido',
  alias: 'pedido_codigo',
}, {
  name: 'cliente',
  alias: 'cliente'
}, {
  name: 'sucursales',
  alias: 'sucursales'
},{
  name: 'fecha_servicio',
  alias: 'fecha_servicio',
}, {
  name: 'servicios',
  alias: 'servicios'
},{
  name: 'valorunitario',
  alias: 'valorunitario'
},
 {
  name: 'descuento',
  alias: 'descuento'
}, {
  name: 'pedido_total',
  alias: 'pedido_total'
}
];

const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: 'none',
  }
});

class FacturaAutomatica extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    data: [],
    fields: {
      clienteCodigo: null,
      facturaFecha: null,
      sucursalCodigo: null,
      valorTotal:null,
      facturaObservacion:null,
      facturaFechaVencimiento:null,
      facturaDocumentoSoporte:null
    },
    responseForm: { Message: '', typeMessage: '' },
    formValidations: {
      view: ['clienteCodigo', 'facturaFecha', 'facturaFechaVencimiento', 'valorTotal']
    },
    formErrors: {
      clienteCodigo: {},
      facturaFecha: {},
      facturaFechaVencimiento:{},
      valorTotal:{}
    },
    dataDatagridFacturaOtrosConceptos:[{
      sucursal: { 'value': null, 'key': null,'visible':true  },
      tipoTarifa: { 'value': null, 'key': null,'visible':true  },
      Descripcion: { 'value': null, 'key': null,'visible':true  },
      valorTotal: { 'value': null, 'key': null,'visible':true  },
    }],
    dataDatagridFacturaServicios:[{
      Codigo: { 'value': null, 'key': null,'visible':true  },
      Sucursales: { 'value': null, 'key': null,'visible':true  },
      Fecha: { 'value': null, 'key': null,'visible':true  },
      Servicios: { 'value': null, 'key': null,'visible':true  },
      sinDescuento: { 'value': null, 'key': null,'visible':true  },
      Descuento: { 'value': null, 'key': null, 'visible':true },
      Total: { 'value': null, 'key': null, 'visible':true }
    }],
    fieldsDisabled: {
      clienteCodigo: false
    },
    filters: { },
    operatorCustom: []
  };

  componentDidMount() {
    const params = this.props.match.params;
    if (!params.id) {
      return;
    }
  }

  handleDatagrid = (data) => {
    const detalle = data.setComponente;

    switch (detalle) {
      case 'facturaOtrosConceptos':
        this.setState({ dataDatagridFacturaOtrosConceptos: data });
        break;
      case 'facturaServicios':
        this.setState({ dataDatagridFacturaServicios: data });
        break;
      default:
        return null;
    }
    this.calculoValores();
  }

  calculoValores = () => {
    var valorTotal = 0;
    var auxField = this.state.fields;
    this.state.dataDatagridFacturaOtrosConceptos.map((item) => {
      if (item.valorTotal.value > 0) {
        valorTotal += parseFloat(item.valorTotal.value);
      }
      return item;
    })

    this.state.dataDatagridFacturaServicios.map((item) => {
      if (item.Total.value > 0) {
        valorTotal += parseFloat(item.Total.value);
      }
      return item;
    })

    auxField.valorTotal = parseFloat(valorTotal);
    this.setState(auxField);
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  search = async () =>{

    this.state.operatorCustom = [];

    if (this.state.fields.sucursalCodigo !== null && this.state.fields.sucursalCodigo !== '') {
      this.state.operatorCustom.push("SUCURSAL_CODIGO = " + this.state.fields.sucursalCodigo + "");
    }

    if (this.state.fields.clienteCodigo !== null && this.state.fields.clienteCodigo !== '') {
      this.state.operatorCustom.push("CLIENTE_CODIGO = "  + this.state.fields.clienteCodigo + "");
    } 

    const [err, data] = await to(search({
      fields: VISOR_FIELDS.map(row => row.name),
      filters: this.state.filters,
      operatorCustom: this.state.operatorCustom,
      isView: true,
      options: {
        limit: this.state.rowsPerPage,
        skip: this.state.page * this.state.rowsPerPage,
        order: { createdAt: 'DESC' }
      }
    }, '/modules/vw_factura_automatica_servicios/list'));

    if (err) {
      return;
    }

    this.state.fieldsDisabled.clienteCodigo=true;
    this.builddetailInterfaceFacturaServicios(data.rows);
    this.calculoValores();
  }

  builddetailInterfaceFacturaServicios = (data) => {
    let rows = data.map(row => {
      return {
        Codigo: { 'value': row.pedido, 'key': row.pedido, 'visible':true },
        Sucursales: { 'value': row.sucursales, 'key': row.sucursales, 'visible':true },
        Fecha: { 'value': row.fecha_servicio, 'key': row.fecha_servicio, 'visible':true },
        Servicios: { 'value': row.servicios, 'key': row.servicios, 'visible':true },
        sinDescuento: { 'value': row.valorunitario, 'key': row.valorunitario, 'visible':true },
        Descuento: { 'value': row.descuento, 'key': row.descuento, 'visible':true },
        Total: { 'value': row.pedido_total, 'key': row.pedido_total, 'visible':true }
      };
    });
    this.setState({ dataDatagridFacturaServicios:rows });
  }

  handleChange = (event) => {
    let { fields } = this.state;
    fields[event.target.id] = event.target.value;
    this.setState({ fields });
  }

  save = (response) => {

    let newState={
      data: response.data,
      responseForm: response.responseForm
    };

    this.setState(newState);

    this.message(newState.responseForm['typeMessage'], newState.responseForm['Message']);
  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }

  messageValidation = (type, message) => {
    this.message(type, message);
  }

  validaData = () => {
    let isvalid = true, message = 'El registro no ha sido guardado.', type = "warning";
    if (!isvalid) {
      this.message(type, message);
    }
    return isvalid;
  }

  render() {
    const { classes } = this.props;

    return (

      <Paper elevation={5} className={classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={classes.gridForm}>

            <InputLabel className={classes.tittleLabelForm}>
              Factura Automatica
            </InputLabel>
          </Grid>

          <Grid container spacing={4}>
          <Grid item xs={3} className={classes.gridForm}>
          <AutoComplete
                id="clienteCodigo"
                tableName='cliente'
                fields={['clienteCodigo', 'clienteRazonSocial']}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
                size='xl'
                primaryField='clienteCodigo'
                searchField='clienteRazonSocial'
                placeHolder='Cliente Buscar/Grabar'
                onSelect={this.handleChange}
                value={this.state.fields["clienteCodigo"]}
                formErrors={this.state.formErrors['clienteCodigo']}
                disabled={this.state.fieldsDisabled['clienteCodigo']}
              />
            </Grid>
            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="facturaFecha"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["facturaFecha"]}
                type="date"
                label="Fecha Grabar"
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['facturaFecha'].error}
                helperText={this.state.formErrors['facturaFecha'].errorMessage}
              />
            </Grid>
            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="facturaFechaVencimiento"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["facturaFechaVencimiento"]}
                type="date"
                label="Fecha Vencimiento Grabar"
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['facturaFechaVencimiento'].error}
                helperText={this.state.formErrors['facturaFechaVencimiento'].errorMessage}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
          <Grid item xs={3} className={classes.gridForm}>
              <AutoComplete
                id="sucursalCodigo"
                tableName='Sucursal'
                fields={['sucursalCodigo', 'sucursalNombre']}
                primaryField='sucursalCodigo'
                aliases={['ID', 'Nombre']}
                searchField='sucursalNombre'
                placeHolder='Sucursal Buscar'
                onSelect={this.handleChange}
                value={this.state.fields["sucursalCodigo"]}
                formErrors={this.state.formErrors['sucursalCodigo']}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="facturaDocumentoSoporte"
                label="Documento Soporte"
                type="text"
                fullWidth margin="normal"
                onChange={this.handleChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={this.state.fields["facturaDocumentoSoporte"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="valorTotal" label="Valor Total" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  disabled
                  value={this.state.fields["valorTotal"] || undefined}
                  InputLabelProps={{ shrink: true }}
                  error={this.state.formErrors['valorTotal'].error}
                  helperText={this.state.formErrors['valorTotal'].errorMessage}
                  formErrors={this.state.formErrors['valorTotal']}
                />
              </Grid>
          </Grid>
          <Grid container spacing={2}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextareaAutosize id="facturaObservacion" label="Observacion" type="text"
                  placeholder="Observacion Factura"
                    onChange={this.handleChange}
                    value={this.state.fields["facturaObservacion"] || undefined}
                    rows="4"
                    cols="450"
                  />
              </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Button color='primary' variant='contained' onClick={(event) => this.search()}>Buscar</Button>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Button color='primary' variant='contained' onClick={(event) => this.calculoValores()}>Calcular Total</Button>
            </Grid>
          </Grid>

          <Grid>
            <CustomizedExpansionPanel tittle="Otros Conceptos +++">
              <Datagrid datosHeader={this.state.fields} dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridFacturaOtrosConceptos} setComponente="facturaOtrosConceptos" controlData={true} />
            </CustomizedExpansionPanel>
          </Grid>

          <Grid  >
            <CustomizedExpansionPanel tittle="Servicios A Facturar">
              <Datagrid datosHeader={this.state.fields} dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridFacturaServicios} setComponente="facturaServicios" controlData={false} />
            </CustomizedExpansionPanel>
          </Grid>

        </form>

        {!this.state.data.length ? null :
          <Grid container spacing={10} xs={12} className={this.props.classes.gridResultSearch}>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell align='center'>
                    <b>Factura codigo</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.getTableRows()}
              </TableBody>
            </Table>
          </Grid>
        }

        <div id='buttonContainer'>
          <Buttoncontrol
            saveData={this.state}
            saveDataValidation={this.state.fields}
            moduleName='factura'
            save={this.save}
            validaData={this.validaData}
            messageValidation={this.messageValidation}

            primaryFields={['facturaCodigo']}
            formValidations={this.state.formValidations}
            errorHandler={this.setErrors}
            url='/processes/factura_automatica'
            isFormLite={true}
          />
        </div>

      </Paper>
    );
  }

  getTableRows = () => {
    return this.state.data.map((row, index) => {
      return (
        <TableRow hover key={200 + index} className={this.props.classes.tableRow}>
          <TableCell key={300 + 300} align='center'>
            <a
              href={`/home/factura/${row.facturaCodigo}/edit`}
              target='_blank'
              rel='noopener noreferrer'>
              {row.facturaCodigo}
            </a>
          </TableCell>
        </TableRow>
      );
    });
  }
}



FacturaAutomatica.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(combineStyles(styles, Styles))(FacturaAutomatica)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;