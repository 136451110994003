import {
  utils
} from '../Libs/';

import {
  USER as USER_ACTIONS
} from '../Config/actionTypes';

import {
  history
} from '../Libs';


export const loadUserInfo = () => dispatch => {
  let userInfo = utils.getFromLocalStorage('userInfo');
  if (!userInfo) {
    //TODO: we should try to get user info from request, using da cookies
    console.log('8 >  === no user info found!');
    dispatch({
      type: USER_ACTIONS.INITIAL_STATE
    });
    return history.push('/');
  }

  dispatch({
    type: USER_ACTIONS.LOAD_USER_INFO,
    payload: userInfo
  });
};

export const toggleFavorites = () => dispatch => {
  dispatch({
    type: USER_ACTIONS.HIDE_FAVORITES
  });
};

export const showFavorites = () => dispatch => {
  dispatch({
    type: USER_ACTIONS.SHOW_FAVORITES
  });
};