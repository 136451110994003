import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Styles from './Styles';
import PasswordPaper from './password.jsx';
import LoginPaper from './username.jsx';
import PropTypes from 'prop-types';

class Login extends Component {
  state = {
    goNext: false,
    userName: '',
    password: ''
  };

  componentDidMount() {
    this.props.validateToken();
  }

  goNext = (userName) => {
    this.props.validateUserName(userName);
  }

  submitLogin = (password) => {
    this.props.login(this.props.usuarioNombreUsuario, password);
  }

  render() {
    return (
      <main className={this.props.classes.main}>
        <CssBaseline />
        {
          (this.props.isValidated) ?
            <PasswordPaper onClick={this.submitLogin}
              error={this.props.error}
              errorMessage={this.props.errorMessage}
              waitForResponse={this.props.waitForResponse}
              usuarioNombreUsuario={this.props.usuarioNombreUsuario}
            /> :
            <LoginPaper onClick={this.goNext}
              error={this.props.error}
              errorMessage={this.props.errorMessage}
              waitForResponse={this.props.waitForResponse}
              usuarioNombreUsuario={this.props.usuarioNombreUsuario} />
        }
      </main >
    );
  }
}

Login.propTypes = {
  validateUserName: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  validateToken: PropTypes.func.isRequired,
  isValidated: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  waitForResponse: PropTypes.bool.isRequired,
  usuarioNombreUsuario: PropTypes.string
};

export default withStyles(Styles, { withTheme: true })(Login);