import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Textfieldlabel from "../GeneralUI/Textfieldlabel";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import PSelect from '../GeneralUI/PSelect'
import CustomizedExpansionPanel from './../GeneralUI/PExpansionpanels'

import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';

class Pqrs extends Component {

  state = {
    fields: {
      pqrsCodigo: null,
      tippqrCodigo: null,
      pqrsNombre: null,

      pqrsDireccion: null,
      pqrsPretenciones: null,
      pqrsRespuesta: null,

      pqrsEmail: null,
      pqrsFechaServicio:null,
      pqrsTelefono:null,

      pqrsNotas:null,
      pqrsEstado:null,
      
      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime()
    },
    responseForm: { Message: '', typeMessage: '' }
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'pqrsCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }
    this.setState({ fields });
  }

  save = (response) => {
    this.setState({
      fields: response.data,
      responseForm: response.responseForm
    });
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }


  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>

            <InputLabel id="pqrsCodigo"
              className={this.props.classes.tittleLabelForm}>PQRS
            </InputLabel>

            <Grid item xs={6} className={this.props.classes.chipTitle}>
              {(this.state.fields.pqrsCodigo !== null) ?
                (<Chip
                  label={this.state.fields["pqrsCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>

          <Grid  >
            <CustomizedExpansionPanel tittle="Datos Basicos"></CustomizedExpansionPanel>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Tipo Pqrs (*)"
                id="tippqrCodigo"
                namelineInput="tipopqrs"
                fieldVisible="tippqrNombre"
                fieldCode="tippqrCodigo"
                Table="TipoPqrs"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["tippqrCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pqrsFechaServicio"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pqrsFechaServicio"] || undefined}
                type="date"
                label="Fecha Servicio"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pqrsNombre" label="Nombre Completo" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pqrsNombre"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pqrsDireccion" label="Direccion | Incluir ciudad" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pqrsDireccion"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pqrsEmail" label="Email" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pqrsEmail"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pqrsTelefono" label="Telefono" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pqrsTelefono"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
           <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="pqrsEstado"
                useChildren={true}
                value={this.state.fields["pqrsEstado"]}
                onChange={this.handleChange}
                namelabel="Estado"
              >
                <option value="ABIERTA">ABIERTA</option>
                <option value="ESPERANDO RESPUESTA CLIENTE">ESPERANDO RESPUESTA CLIENTE</option>
                <option value="ESPERANDO RESPUESTA EMPRESA">ESPERANDO RESPUESTA EMPRESA</option>
                <option value="CERRADO">CERRADO</option>
              </PSelect>
            </Grid>  

            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="pqrsNotas" label="Indique lo ocurrido de la manera mas detallada posible" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["pqrsNotas"] || undefined}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  rows="5"
                  cols="100"
                />
              </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="pqrsPretenciones" label="Indique que solución propone para remediar la situación ocurrida" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["pqrsPretenciones"] || undefined}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  rows="5"
                  cols="100"
                />
              </Grid>
          </Grid>

           <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="pqrsRespuesta" label="Respuesta" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["pqrsRespuesta"] || undefined}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  rows="5"
                  cols="100"
                />
              </Grid>
          </Grid>

          <Grid  >
            <CustomizedExpansionPanel tittle="Trazabilidad"></CustomizedExpansionPanel>
          </Grid>

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Usuario" value={Utils.getFromLocalStorage('userInfo').pqrsNombreCompleto} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Creacion" value={this.state.fields.createdAt} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Modificacion" value={this.state.fields.updatedAt} />
            </Grid>
          </Grid>


        </form>
        <div><Buttoncontrol
          saveData={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          primaryFields={['pqrsCodigo']}
        /></div>
      </Paper>
    );
  }
}

Pqrs.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(Pqrs)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}

    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;