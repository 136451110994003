import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { search } from '../../../../Actions/General';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter } from '../../../../Libs/';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import DataGridVisor from '../../../GeneralUI/DataGridVisor';
import to from 'await-to-js';
import PSelect from '../../../GeneralUI/PSelect';
import _ from 'lodash';
import CustomizedExpansionPanel from './../../../GeneralUI/PExpansionpanels'


const VISOR_FIELDS = [{
  name: 'pedidoCodigo',
  alias: 'Codigo',
  isPrimary: true
},
{
  name: 'pedidoRazonSocial',
  alias: 'Cliente'
},
{
  name: 'comercialNombreCompleto',
  alias: 'Comercial'
},
{
  name: 'pedidoVisita',
  alias: 'TipoVisita'
},
{
  name: 'pedidoFechaVisita',
  alias: 'VisitaFecha'
}, {
  name: 'pedidoHoraVisita',
  alias: 'VisitaHora'
},
{
  name: 'pedidoContactoDireccion',
  alias: 'ClienteDireccion'
}, {
  name: 'pedidoContactoTelefono',
  alias: 'ClienteTelefono'
},
{
  name: 'pedidoEstado',
  alias: 'Estado',
}, 
{
  name: 'pedidoEstadoVisita',
  alias: 'EstadoVisita',
  colorize: true
}, 

{
  name: 'tipserNombre',
  alias: 'TipoServicio'
}, {
  name: 'pedidoFechaServicio',
  alias: 'ServicioFecha'
}, {
  name: 'pedidoHoraServicio',
  alias: 'ServicioHora'
}, {
  name: 'pedidoFechacreacion',
  alias: 'Fechacreacion'
}, {
  name: 'tiempoFaltante',
  alias: 'TiempoFaltante'
}];

const VISOR_COLORS = {
  PROGRAMADA: '#4A86E8',
  REPROGRAMADA: '#93C47D',
  CUMPLIDA: '#E69138',
  INCUMPLIDA: '#CC0000',
  CANCELADA: '#A64D79'
}

class VisorVisitas extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    page: 0,
    rowsPerPage: 10,
    data: {
      rows: [],
      count: 0
    },
    filters: {
      pedidoCodigo: null,
      pedidoRazonSocial: null,
      pedidoContactoTelefono: null,
      tipserCodigo: null,
      uninegCodigo: null,
      clienteCodigo: null,
      sucursalCodigo: null,
      pedidoEstado: null,
      pedidoFechaServicio: null,
      createdAt: null,
      pedidoFechaVisita: null,
      comercialCodigo: null,
      pedidoEstadoVisita: null,
      pedidoVisita: null,
    },
    operatorCustom: [],
    responseForm: { Message: '', typeMessage: '' },
    formErrors: {
    }
  };

  componentDidMount() {
    this.props.toggleFavorites();
  }

  componentWillUnmount() {
    this.props.toogleFavoritesShow();
  }

  search = async (page, rowsPerPage) => {
    if (page !== undefined) {
      await to(new Promise((resolve) => {
        this.setState({
          page,
          rowsPerPage
        }, resolve)
      }));
    }

    this.state.operatorCustom = [];
    let filters = {};

    this.state.operatorCustom.push("\"pedidoVisita\" in('PRESENCIAL','VIRTUAL')");


    _.each(_.keys(this.state.filters), (key) => {
      const value = this.state.filters[key];
      if (value !== null && value !== '') {
        filters[key] = value;
      }
    });

    const [err, data] = await to(search({
      fields: VISOR_FIELDS.map(row => row.name),
      filters: filters,
      operatorCustom: this.state.operatorCustom,
      isView: true,
      options: {
        limit: this.state.rowsPerPage,
        skip: this.state.page * this.state.rowsPerPage,
        order: { createdAt: 'DESC' }
      }
    }, '/modules/vw_pedido/list'));

    if (err) {
      return;
    }

    this.setState({
      data: data
    });
  }

  handleChange = (event) => {
    let { filters } = this.state;
    filters[event.target.id] = event.target.value;
    this.setState({ filters });
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={5} className={`${classes.mainPaper} ${classes.fullWidthPaper}`}>
        <Grid container spacing={4}>
          <Grid item xs={3} className={classes.gridForm}>
            <InputLabel className={classes.tittleLabelForm}>
              Visor Visitas
              </InputLabel>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs className={classes.gridForm}>
            <TextField
              id='pedidoCodigo'
              type='text'
              label='Codigo'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs className={classes.gridForm}>
            <TextField
              id='pedidoRazonSocial'
              type='text'
              label='Nombre'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs className={classes.gridForm}>
            <TextField
              id='pedidoContactoTelefono'
              type='text'
              label='Telefono'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs className={this.props.classes.gridForm}>
            <AutoComplete
              id="tipserCodigo"
              tableName='Tiposervicio'
              fields={['tipserCodigo', 'tipserNombre']}
              aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
              size='xl'
              primaryField='tipserCodigo'
              searchField='tipserNombre'
              placeHolder='Tipo servicio'
              onSelect={this.handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs className={this.props.classes.gridForm}>
            <PSelect
              namelabel="Unidad negocio"
              id="uninegCodigo"
              namelineInput="unidadnegocio"
              fieldVisible="uninegNombre"
              fieldCode="uninegCodigo"
              Table="Unidadnegocio"
              Vista="true"
              Where="true"
              useChildren={false}
              onChange={this.handleChange}
            />
          </Grid>

          <Grid item xs className={this.props.classes.gridForm}>
            <AutoComplete
              id="clienteCodigo"
              tableName='cliente'
              fields={['clienteCodigo', 'clienteRazonSocial']}
              aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
              size='xl'
              primaryField='clienteCodigo'
              searchField='clienteRazonSocial'
              placeHolder='Seleccionar Cliente'
              onSelect={this.handleChange}
            />
          </Grid>

          <Grid item xs className={this.props.classes.gridForm}>
            <AutoComplete
              id="sucursalCodigo"
              tableName='Sucursal'
              fields={['sucursalCodigo', 'sucursalNombre']}
              primaryField='sucursalCodigo'
              aliases={['ID', 'Nombre']}
              searchField='sucursalNombre'
              placeHolder='Seleccionar Sucursal'
              onSelect={this.handleChange}
            />
          </Grid>

          <Grid item xs className={this.props.classes.gridForm}>
            <PSelect
              id="pedidoEstado"
              useChildren={true}
              onChange={this.handleChange}
              namelabel="Estado"
            >
              <option value="PENDIENTE">PENDIENTE</option>
              <option value="CONFIRMADO">CONFIRMADO</option>
              <option value="DESPACHADO">DESPACHADO</option>
              <option value="COTIZADO">COTIZADO</option>

              <option value="FACTURADO">FACTURADO</option>
              <option value="ANULADO">ANULADO</option>
            </PSelect>
          </Grid>
        </Grid>

        <Grid container spacing={2}>


          <Grid item xs className={classes.gridForm}>
            <TextField
              id='pedidoFechaServicio'
              type='date'
              label='Fecha servicio'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs className={classes.gridForm}>
            <TextField
              id='createdAt'
              type='date'
              label='Fecha creacion'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs className={classes.gridForm}>
            <TextField
              id='pedidoFechaVisita'
              type='date'
              label='Fecha Visita'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Comercial"
                id="comercialCodigo"
                namelineInput="Vendedor"
                fieldVisible="comercial_nombre_completo"
                fieldCode="comercial_codigo"
                Table="comercial"
                isView={true}
                where={{
                  comercial_estado: 'ACTIVO'
                }}
                useChildren={false}
                onChange={this.handleChange}
              />
            </Grid>
        </Grid>

        <Grid container spacing={2}>

          <Grid item className={this.props.classes.gridForm}>
            <PSelect
              id="pedidoEstadoVisita"
              useChildren={true}
              onChange={this.handleChange}
              namelabel="Estado Visita"
            >
              <option value="PROGRAMADA">PROGRAMADA</option>
              <option value="REPROGRAMADA">REPROGRAMADA</option>
              <option value="CUMPLIDA">CUMPLIDA</option>
              <option value="INCUMPLIDA">INCUMPLIDA</option>
              <option value="CANCELADA">CANCELADA</option>
            </PSelect>
          </Grid>
        </Grid>


        <Grid container spacing={10} >
          <Grid item xs={3} className={this.props.classes.gridForm}>

            <Button
              color='primary'
              variant='contained'
              onClick={(event) => this.search()}
            >
              Buscar
              </Button>

          </Grid>
        </Grid>

        <CustomizedExpansionPanel tittle="Cuadro de convenciones.">
          <Grid container spacing={2} >
            <Grid item xs className={this.props.classes.gridVisitaProgramada}>PROGRAMADA</Grid>
            <Grid item xs className={this.props.classes.gridVisitaReprogramada}>REPROGRAMADA</Grid>
            <Grid item xs className={this.props.classes.gridVisitaCumplida}>CUMPLIDA</Grid>
            <Grid item xs className={this.props.classes.gridVisitaIncumplida}>INCUMPLIDA</Grid>
            <Grid item xs className={this.props.classes.gridVisitaCancelada}>CANCELADA</Grid>
          </Grid>
        </CustomizedExpansionPanel>

        <DataGridVisor
          search={this.search}
          data={this.state.data}
          fields={VISOR_FIELDS}
          colors={VISOR_COLORS}
          moduleUrl='pedido'
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
        />

      </Paper>
    );
  }
}

VisorVisitas.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  toggleFavorites: PropTypes.func.isRequired,
  toogleFavoritesShow: PropTypes.func.isRequired
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(VisorVisitas)));

function IntegrationNotistack(props) {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp {...props} />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;