import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Textfieldlabel from "../GeneralUI/Textfieldlabel";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import PSelect from '../GeneralUI/PSelect'
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import AutoComplete from '../GeneralUI/AutoComplete';

import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';

class Trailer extends Component {

  state = {
    fields: {
      trailerCodigo: null,

      trailerPlaca: null,
      trailerModelo: null,
      trailerEstado: null,

      tipafiCodigo: null,
      carroceriaCodigo: null,
      terceroCodigo: null,

      trailerEjes: null,
      trailerChasis: null,
      trailerRegistronacional: null,

      trailerVigenciaregistro: null,
      trailerCertificadoHabilitacion: null,
      trailerCertificadoHabilitacionFechaVencimiento: null,

      trailerPesoneto: null,
      trailerPesovacio: null,
      trailerPesobruto: null,

      trailerAlto: null,
      trailerAncho: null,
      trailerLargo: null,
      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime()
    },
    responseForm: { Message: '', typeMessage: '' }
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'trailerCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }
    this.setState({ fields });
  }

  save = (response) => {
    this.setState({
      fields: response.data,
      responseForm: response.responseForm
    });
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  handleAuto = (autoCompleteValue) => {
    this.setState({ colorAutoComplete: autoCompleteValue });
  }

  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>

            <InputLabel id="trailerCodigo"
              className={this.props.classes.tittleLabelForm}>Trailer
            </InputLabel>

            <Grid item xs={6} className={this.props.classes.chipTitle}>
              {(this.state.fields.trailerCodigo !== null) ?
                (<Chip
                  label={this.state.fields["trailerCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="trailerPlaca" label="Placa (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["trailerPlaca"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="trailerModelo" label="Modelo (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["trailerModelo"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="trailerEstado"
                useChildren={true}
                value={this.state.fields["trailerEstado"]}
                onChange={this.handleChange}
                namelabel="Estado (*)"
              >
                <option value="ACTIVO">ACTIVO</option>
                <option value="INAACTIVO">INACTIVO</option>
              </PSelect>
            </Grid>

          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Tipo Afiliado (*)"
                id="tipafiCodigo"
                namelineInput="tipoafiliado"
                fieldVisible="tipafiNombre"
                fieldCode="tipafiCodigo"
                Table="TipoAfiliado"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["tipafiCodigo"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>

              <AutoComplete
                id="carroceriaCodigo"
                tableName='Carroceria'
                fields={['carroceriaCodigo', 'carroceriaNombre']}
                primaryField='carroceriaCodigo'
                aliases={['ID', 'Nombre']}
                searchField='carroceriaNombre'
                placeHolder='Seleccionar carroceria'
                onSelect={this.handleChange}
                value={this.state.fields["carroceriaCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="terceroCodigo"
                tableName='Tercero'
                fields={['terceroCodigo', 'terceroNombre1', 'terceroNombre2']}
                primaryField='terceroCodigo'
                aliases={['ID', 'Nombre1', 'Nombre2']}
                searchField='terceroNombre1'
                placeHolder='Seleccionar tercero'
                onSelect={this.handleChange}
                value={this.state.fields["terceroCodigo"]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="trailerEjes" label="Ejes (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["trailerEjes"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="trailerChasis" label="Chasis (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["trailerChasis"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="trailerRegistronacional" label="Registro Nacional (*)" type="text"

                fullWidth margin="normal" variant="outlined" onChange={this.handleChange}
                value={this.state.fields["trailerRegistronacional"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="trailerVigenciaregistro" type="date"
                label="Vigencia Registro"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["trailerVigenciaregistro"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="trailerCertificadoHabilitacion" label="Certificado Habilitacion (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["trailerCertificadoHabilitacion"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="trailerCertificadoHabilitacionFechaVencimiento"
                type="date"
                label="Certificado Fecha Vencimiento"
                fullWidth margin="normal" variant="outlined" onChange={this.handleChange}
                value={this.state.fields["trailerCertificadoHabilitacionFechaVencimiento"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>


          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="trailerPesoneto" label="Peso Neto (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["trailerPesoneto"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="trailerPesovacio" label="Peso Vacio (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["trailerPesovacio"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="trailerPesobruto" label="Peso Bruto (*)" type="text"

                fullWidth margin="normal" variant="outlined" onChange={this.handleChange}
                value={this.state.fields["trailerPesobruto"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="trailerAlto" label="Alto (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["trailerAlto"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="trailerAncho" label="Ancho (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["trailerAncho"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="trailerLargo" label="Largo (*)" type="text"

                fullWidth margin="normal" variant="outlined" onChange={this.handleChange}
                value={this.state.fields["trailerLargo"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Usuario" value={Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Creacion" value={this.state.fields.createdAt} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Modificacion" value={this.state.fields.updatedAt} />
            </Grid>
          </Grid>


        </form>
        <div><Buttoncontrol
          saveData={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          primaryFields={['trailerCodigo']}
        /></div>
      </Paper>
    );
  }
}

Trailer.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(Trailer)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}

    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;