import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { search } from '../../../../Actions/General';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {  Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter } from '../../../../Libs/';
import DataGridVisor from '../../../GeneralUI/DataGridVisor';
import to from 'await-to-js';
import _ from 'lodash';

const VISOR_FIELDS = [
{
  name: 'sucursal',
  alias: 'Sucursal'
},
{
  name: 'serie',
  alias: 'Serie'
},
{
  name: 'folio_inicial',
  alias: 'Folio Inicial'
},
{
  name: 'folio_faltantes',
  alias: 'Folios Faltantes'
}, {
  name: 'folio_final',
  alias: 'Folio Actual'
}];

const VISOR_COLORS = {
}

class VisorResolucionesFaltantes extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    page: 0,
    rowsPerPage: 10,
    data: {
      rows: [],
      count: 0
    },
    filters: {

    },
    operatorCustom: [],
    responseForm: { Message: '', typeMessage: '' },
    formErrors: {
    }
  };

  componentDidMount() {
    this.props.toggleFavorites();
    this.search();
  }

  componentWillUnmount() {
    this.props.toogleFavoritesShow();
  }

  search = async (page, rowsPerPage) => {
    if (page !== undefined) {
      await to(new Promise((resolve) => {
        this.setState({
          page,
          rowsPerPage
        }, resolve)
      }));
    }

    this.state.operatorCustom = [];
    let filters = {};

    _.each(_.keys(this.state.filters), (key) => {
      const value = this.state.filters[key];
      if (value !== null && value !== '') {
        filters[key] = value;
      }
    });

    const [err, data] = await to(search({
      fields: VISOR_FIELDS.map(row => row.name),
      filters: filters,
      operatorCustom: this.state.operatorCustom,
      isView: true,
      options: {
        limit: this.state.rowsPerPage,
        skip: this.state.page * this.state.rowsPerPage,
      }
    }, '/modules/consecutivo_faltante_factura()/list'));

    if (err) {
      return;
    }

    this.setState({
      data: data
    });
  }

  handleChange = (event) => {
    let { filters } = this.state;
    filters[event.target.id] = event.target.value;
    this.setState({ filters });
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={5} className={`${classes.mainPaper} ${classes.fullWidthPaper}`}>
        <Grid container spacing={4}>
          <Grid item xs={3} className={classes.gridForm}>
            <InputLabel className={classes.tittleLabelForm}>
              Visor Resoluciones Faltantes
              </InputLabel>
          </Grid>
        </Grid>

        <DataGridVisor
          search={this.search}
          data={this.state.data}
          fields={VISOR_FIELDS}
          colors={VISOR_COLORS}
          moduleUrl='pedido'
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
        />

      </Paper>
    );
  }
}

VisorResolucionesFaltantes.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  toggleFavorites: PropTypes.func.isRequired,
  toogleFavoritesShow: PropTypes.func.isRequired
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(VisorResolucionesFaltantes)));

function IntegrationNotistack(props) {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp {...props} />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;