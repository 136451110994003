import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import Buttoncontrol from "../../../GeneralUI/Buttoncontrol";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter, combineStyles } from '../../../../Libs/';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import PSelect from '../../../GeneralUI/PSelect'
import Utils from '../../../../Libs/utils';


const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: 'none',
  }
});

class ReenviarEncuesta extends Component {
  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    data: [],
    fields: {
      TipoEvento: null,
      FechaInicial:null,
      FechaFinal:null,
      clienteCodigo:null
    },
    responseForm: { Message: '', typeMessage: '' },
    formValidations: {view: []},
    formErrors: {    }
  };

  componentDidMount() {
    const params = this.props.match.params;
    if (!params.id) {
      return;
    }
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }

    if (fields[event.target.id] === '') {
      fields[event.target.id] = null;
    }

    this.setState({ fields });
  }

  save = (response) => {

    let newState={
      data: response.data,
      responseForm: response.responseForm
    };

    this.setState(newState);

    this.message(newState.responseForm['typeMessage'], newState.responseForm['Message']);
  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }

  messageValidation = (type, message) => {
    this.message(type, message);
  }


  validaData = () => {
    let isvalid = true, message = 'El registro no ha sido guardado.', type = "warning",res,fechados,fechauno;

    if ((this.state.fields.TipoEvento == null || !String(this.state.fields.TipoEvento).trim())) {
      message = "Debe diligenciar El tipo de envio.";
      isvalid = false;
    }else if(this.state.fields.TipoEvento=='CERRAR ENCUESTA' && (this.state.fields.clienteCodigo == null || !String(this.state.fields.clienteCodigo).trim() || this.state.fields.TipoNovedad == null || !String(this.state.fields.TipoNovedad).trim() || this.state.fields.Observacion == null || !String(this.state.fields.Observacion).trim() )){    
      message = "Debe diligenciar el cliente, el tipo de novedad y la observacion.";
      isvalid = false;
    }else if(this.state.fields.TipoEvento=='REENVIAR ENCUESTAS' && (this.state.fields.FechaInicial == null || !String(this.state.fields.FechaInicial).trim() || this.state.fields.FechaFinal == null || !String(this.state.fields.FechaFinal).trim()) && (this.state.fields.clienteCodigo == null || !String(this.state.fields.clienteCodigo).trim())){
      message = "Debe diligenciar las fechas o el campo cliente.";
      isvalid = false;
    }else if(this.state.fields.TipoEvento=='CERRAR ENCUESTA' && (this.state.fields.Observacion == null || !String(this.state.fields.Observacion).trim() || this.state.fields.Observacion.length < 20)){
      message = "La Observacion debe tener mas de 20 caracteres.";
      isvalid = false;
    }else{

      if(this.state.fields.length>0 && this.state.fields.FechaFinal.length>0){
        res = (this.state.fields.FechaInicial || '').split("-");
        fechauno = new Date(res[0], res[1] - 1, res[2], 0, 0, 0);
    
        res = (this.state.fields.FechaFinal || '').split("-");
        fechados = new Date(res[0], res[1] - 1, res[2], 0, 0, 0);

        if ((!Utils.onlyComparateDate(fechauno, fechados)) || 
        (this.state.fields.FechaInicial == null || 
        !String(this.state.fields.FechaInicial).trim()) || 
        (this.state.fields.FechaFinal == null || 
        !String(this.state.fields.FechaFinal).trim())) {
          message = "Verifique LAS FECHAS.";
          isvalid = false;
        }
      }
    }

    if (!isvalid) {
      this.message(type, message);
    }
    return isvalid;

  }

  render() {
    const { classes } = this.props;

    return (

      <Paper elevation={5} className={classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={classes.gridForm}>
            <InputLabel className={classes.tittleLabelForm}>
              Reenviar / Cerrar Encuestas 
            </InputLabel>
          </Grid>

          
          <Grid container spacing={2}>  
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <PSelect
                  id="TipoEvento"
                  useChildren={true}
                  value={this.state.fields["TipoEvento"]}
                  onChange={this.handleChange}
                  namelabel="Tipo Evento"
                >
                  <option value="REENVIAR ENCUESTAS">REENVIAR ENCUESTAS</option>
                  <option value="CERRAR ENCUESTA">CERRAR ENCUESTA</option>
                </PSelect>
            </Grid> 
            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="FechaInicial"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["FechaInicial"]}
                type="date"
                label="Fecha Inicial"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="FechaFinal"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["FechaFinal"]}
                type="date"
                label="Fecha Final"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>      
          </Grid>

          <Grid container spacing={4}>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="clienteCodigo"
                tableName='cliente'
                fields={['clienteCodigo', 'clienteRazonSocial']}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
                size='xl'
                primaryField='clienteCodigo'
                searchField='clienteRazonSocial'
                placeHolder='Seleccionar Cliente'
                onSelect={this.handleChange}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <PSelect
                  id="TipoNovedad"
                  useChildren={true}
                  value={this.state.fields["TipoNovedad"]}
                  onChange={this.handleChange}
                  namelabel="Tipo Novedad"
                >
                  <option value="REMATE">REMATE</option>
                  <option value="FALLECIMIENTO">FALLECIMIENTO</option>
                  <option value="CLIENTE NO CONTESTA">CLIENTE NO CONTESTA</option>
                </PSelect> 
            </Grid> 
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextareaAutosize id="Observacion" label="Observacion" type="text"
                placeholder="Observacion Novedad"
                onChange={this.handleChange}
                value={this.state.fields["Observacion"] || undefined}
                rows="4"
                cols="30"
              />
            </Grid>
          </Grid>
        </form>

        <div id='buttonContainer'>
          <Buttoncontrol
            saveData={this.state}
            saveDataValidation={this.state.fields}
            moduleName='encuesta_pedido'
            save={this.save}
            validaData={this.validaData}
            messageValidation={this.messageValidation}
            primaryFields={['encpedCodigo']}
            formValidations={this.state.formValidations}
            errorHandler={this.setErrors}
            url='/processes/encuesta_pedido'
            isFormLite={true}
          />
        </div>

      </Paper>
    );
  }
}



ReenviarEncuesta.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(combineStyles(styles, Styles))(ReenviarEncuesta)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;