import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import PSelect from '../GeneralUI/PSelect'
import { SnackbarProvider, withSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { errorSetter } from '../../Libs';
import _ from 'lodash';
import Utils from '../../Libs/utils';
import { saveCustom, getDocument, handlerPselect } from '../../Actions/General'
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';

class EncuestaTransporte extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    fields: {
      encbodCodigo:null,
      encbodNombre:'ENCUESTA BODEGA',
      encbodObservacion:null,
      encbodCartera:null,
      encbodMotivoServicio:null,
      encbodPresentacionBodega:'5',
      encbodJefeBodega:'5',
      encbodCodificacion:'5',
      encbodMercancia:'5', 
      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime()
    },
    responseForm: { Message: '', typeMessage: '' },
    urlSave:'/processes/save_encuesta_bodega',
    urlGet:'/processes/get_encuesta_bodega',
    formErrors: {
      encbodServicio: {},
      encbodCartera: {},
    },

    responseForm: { Message: '', typeMessage: '' },

    formValidations: {
      view: ['encbodServicio', 'encbodCartera']
    }

  };

  componentDidMount() {

    // recuperamos el querystring
    const querystring = window.location.search
    const params = new URLSearchParams(querystring)
   
    if (!params.has("Codigo")) {
      return;
    }
  
    this.fillFormData(params.get('Codigo'));   
    this.props.toggleFavorites();
  }


  fillFormData  (id) {

      let document, data; 

      if (!id) {
        return;
      }

      document=saveCustom({codigo:id}, this.state.urlGet);

      document.then(data => {
          this.setState({ fields: data });
      }).catch(error => {
          this.message("error","El Registro NO ha sido Guardado." + error.errorMessage);
      });        

      /*console.log("SALÍ"); 
      await saveCustom({ value: 78351	}, '/adminsxxx').then(data => {
        console.log("id:");
        console.log(data);
      }).catch(error => {
       console.log("error:"+error);
      });*/

      /*getDocument({
        documentId: id,
        fieldName: 'encbodCodigo',
        moduleName: 'Encuesta_Bodega'
      }).then(document => {
        if (!document) { 
          return;
        }
        this.setState({ fields: document });
      });*/
  }

  componentWillUnmount() {
    this.props.toogleFavoritesShow();
  }
 
  handleChange = (event) => {
    let { fields } = this.state;
    const caret = event.target.selectionStart;
    const element = event.target;
    if (element.hasOwnProperty('setSelectionRange')) {
      window.requestAnimationFrame(() => {
        element.setSelectionRange(caret, caret);
      });
    }

    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'radio') {
      fields[event.target.name] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }

    if (fields[event.target.id] === '') {
      fields[event.target.id] = null;
    }
    this.setState({ fields });
  }

  getSaveFunction() {
      return saveCustom(this.state, this.state.urlSave);
  }

  save = () => {
    if(this.validaData()){
      this.getSaveFunction().then(data => {
        this.message("success","El Registro ha sido Guardado.");
      }).catch(error => {
        this.message("error","El Registro NO ha sido Guardado." + error.errorMessage);
      });        
    }
    
  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }

  validaData = () => {
    let isvalid = true, message = '', type = "";

    if ((this.state.fields.encbodServicio == null || !String(this.state.fields.encbodServicio).trim())) {
      message = " DEBE DILIGENCIAR LA SEGUNDA PREGUNTA.";
      type = "error";
      isvalid = false;
    }

    if ((this.state.fields.encbodCartera == null || !String(this.state.fields.encbodCartera).trim())) {
      message = " DEBE DILIGENCIAR LA TERCERA PREGUNTA.";
      type = "error";
      isvalid = false;
    }
    this.message(type,message);
    return isvalid;
  }

  render() {
    const { classes } = this.props; 

    return (
      <Paper elevation={5} className={`${classes.mainPaper} ${classes.fullWidthPaper}`}>
        <Grid container spacing={4}>
          <Grid item xs={3} className={classes.gridForm}>
            <img alt='background' src="/bodehogar2.png" width="40%" height="70%" />
          </Grid>
          <Grid item xs className={classes.gridForm}>
            <InputLabel className={classes.tittleLabelForm}>
              ENCUESTA TRANSPORTE.
            </InputLabel>

              <Grid item xs={3} className={this.props.classes.chipTitle}>
              {(this.state.fields.encbodCodigo !== null) ?
                (<Chip
                  label={this.state.fields["encbodCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs className={classes.gridForm}>
                <InputLabel className={classes.tittleLabelEncuesta}>
                    1. ¿Declara valor de bienes superior a 100 millones?
                </InputLabel>
                <FormControl variant="outlined" fullWidth >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="encpedVisitaClaridadInformacion"
                    onChange={this.handleChange}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs className={classes.gridForm}>
                <InputLabel className={classes.tittleLabelEncuesta}>
                    2. ¿Acepta tomar seguro de transporte?
                </InputLabel>
                <FormControl variant="outlined" fullWidth >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    onChange={this.handleChange}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs className={classes.gridForm}>
                <InputLabel className={classes.tittleLabelEncuesta}>
                    3. ¿Se revisaron pantallas?
                </InputLabel>
                <FormControl variant="outlined" fullWidth >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    onChange={this.handleChange}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  </RadioGroup>
                </FormControl>
            </Grid>
          </Grid> 
      
          <Grid container spacing={2}>
            <Grid item xs className={classes.gridForm}>
                <InputLabel className={classes.tittleLabelEncuesta}>
                    4. ¿Solicito traslado de sus bienes sin empaque?
                </InputLabel>
                <FormControl variant="outlined" fullWidth >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    onChange={this.handleChange}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs className={classes.gridForm}>
                <InputLabel className={classes.tittleLabelEncuesta}>
                    5. ¿Solicitó traslado de bienes no empacados por Bodehogar?
                </InputLabel>
                <FormControl variant="outlined" fullWidth >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    onChange={this.handleChange}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  </RadioGroup>
                </FormControl>
            </Grid>            
          </Grid> 

          <Grid container spacing={2}>
            <Grid item xs className={classes.gridForm}>
                <InputLabel className={classes.tittleLabelEncuesta}>
                   ¿Acepta los terminos y condiciones?
                </InputLabel>
                <FormControl variant="outlined" fullWidth >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    onChange={this.handleChange}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  </RadioGroup>
                </FormControl>
            </Grid>            
          </Grid> 
          
        <Grid container spacing={10} >
          <Grid item xs={3} className={this.props.classes.gridForm}>              
              {(Utils.getFromLocalStorage('userInfo')===null) ?
                (<Button
                  color='primary'
                  variant='contained'
                  onClick={(event) => this.save()}
                >
                Enviar
              </Button>
                ) : ''
              }
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

EncuestaTransporte.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  toggleFavorites: PropTypes.func.isRequired,
  toogleFavoritesShow: PropTypes.func.isRequired
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(EncuestaTransporte)));

function IntegrationNotistack(props) {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp {...props} />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;