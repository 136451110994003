import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import PSelect from '../GeneralUI/PSelect'
import { SnackbarProvider, withSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { errorSetter } from '../../Libs';
import _ from 'lodash';
import Utils from '../../Libs/utils';
import { saveCustom, getDocument } from '../../Actions/General'
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import queryString from 'querystring';

class EncuestaPedido extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    fields: {
      encpedCodigo:null,
      encpedNombre:'ENCUESTA PEDIDO',      

      encpedPuntualidad:'5',
      encpedCuidadoEnseres:'5' ,
      encpedDisciplina:'5' ,
      encpedPresentacionVehiculo:'5',
      encpedVisitaClaridadInformacion:'SI' ,		
      encpedVisitaRespetuoso:'SI',		
      encpedVisitaPuntualidad:'SI',	
      encpedRecomendacionServicio:'SI',	
      encpedVehiculoVacio:'SI' ,
      encpedObservacion:null,
      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime()
    },
    responseForm: { Message: '', typeMessage: '' },
    url:'/processes/save_encuesta_pedido',
    formErrors: {
      encpedServicio: {},
      encpedCartera: {},
    },

    responseForm: { Message: '', typeMessage: '' },

    formValidations: {
      view: ['encpedServicio', 'encpedCartera']
    }

  };

  componentDidMount() {
    let params;
    const query = queryString.parse(this.props.location.search.replace('?', ''));
    
    if (!query.Codigo) {
      return;
    }
  
    this.fillFormData(query.Codigo);    
    this.props.toggleFavorites();
  }


  fillFormData = (id) => {

      if (!id) {
        return;
      }

      getDocument({
        documentId: id,
        fieldName: 'encpedCodigo',
        moduleName: 'Encuesta_Pedido'
      }).then(document => {
        if (!document) { 
          return;
        }
        this.setState({ fields: document });
      });
  }

  componentWillUnmount() {
    this.props.toogleFavoritesShow();
  }
 
  handleChange = (event) => {
    let { fields } = this.state;
    const caret = event.target.selectionStart;
    const element = event.target;
    if (element.hasOwnProperty('setSelectionRange')) {
      window.requestAnimationFrame(() => {
        element.setSelectionRange(caret, caret);
      });
    }

    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'radio') {
      fields[event.target.name] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }

    if (fields[event.target.id] === '') {
      fields[event.target.id] = null;
    }
    this.setState({ fields });
  }

  getSaveFunction() {
      return saveCustom(this.state, this.state.url);
  }

  save = () => {
    if(this.validaData()){
      this.getSaveFunction().then(data => {

        /*this.setState({
          fields: data,
          responseForm: { Message: "El Registro ha sido Guardado.", typeMessage: "success" }
        })*/
        this.message("success","El Registro ha sido Guardado.");
      }).catch(error => {

        /*this.setState({
          responseForm: { Message: "El Registro NO ha sido Guardado." + error.errorMessage, typeMessage: "error" }
        })*/

        this.message("error","El Registro NO ha sido Guardado." + error.errorMessage);

      });        
    }
    
  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }

  validaData = () => {
    let isvalid = true, message = '', type = "";

    this.message(type,message);
    return isvalid;
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={5} className={`${classes.mainPaper} ${classes.fullWidthPaper}`}>
        <Grid container spacing={4}>
          <Grid item xs={3} className={classes.gridForm}>
            <img alt='background' src="/bodehogar2.png" width="40%" height="70%" />
          </Grid>
          <Grid item xs className={classes.gridForm}>
            <InputLabel className={classes.tittleLabelForm}>
              ENCUESTA DE SATISFACCION DEL CLIENTE
            </InputLabel>

              <Grid item xs={3} className={this.props.classes.chipTitle}>
              {(this.state.fields.encpedCodigo !== null) ?
                (<Chip
                  label={this.state.fields["encpedCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
            <Grid item xs className={this.props.classes.gridForm}>
                <InputLabel className={classes.tittleLabelEncuesta}>
                1. Durante el servicio , el equipo humano de nuestra organizacion cumplio con los siguientes aspectos?
                </InputLabel>
            </Grid>
        </Grid>              
  
        <Grid container spacing={2}>
            <Grid item xs className={classes.gridForm}>
              <InputLabel className={classes.tittleLabelEncuesta}>
                 a. Puntualidad en el servicio.
              </InputLabel>
              <FormControl variant="outlined" fullWidth >
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="encpedPuntualidad"
                    onChange={this.handleChange}
                    value={this.state.fields["encpedPuntualidad"] || undefined}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="1" />
                    <FormControlLabel value="2" control={<Radio />} label="2" />
                    <FormControlLabel value="3" control={<Radio />} label="3" />
                    <FormControlLabel value="4" control={<Radio />} label="4" />
                    <FormControlLabel value="5" control={<Radio />} label="5" />
                  </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs className={classes.gridForm}>      
                <InputLabel className={classes.tittleLabelEncuesta}>
                  b. Organizacion en el trabajo y cuidado de los enseres.
                </InputLabel>
                <FormControl variant="outlined" fullWidth >
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="encpedCuidadoEnseres"
                      onChange={this.handleChange}
                      value={this.state.fields["encpedCuidadoEnseres"] || undefined}
                    >
                      <FormControlLabel value="1" control={<Radio />} label="1" />
                      <FormControlLabel value="2" control={<Radio />} label="2" />
                      <FormControlLabel value="3" control={<Radio />} label="3" />
                      <FormControlLabel value="4" control={<Radio />} label="4" />
                      <FormControlLabel value="5" control={<Radio />} label="5" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs className={classes.gridForm}>
                <InputLabel className={classes.tittleLabelEncuesta}>
                    c. Disciplina, respeto, presentacion personal.
                </InputLabel>
                <FormControl variant="outlined" fullWidth >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="encpedDisciplina"
                    onChange={this.handleChange}
                    value={this.state.fields["encpedDisciplina"] || undefined}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="1" />
                    <FormControlLabel value="2" control={<Radio />} label="2" />
                    <FormControlLabel value="3" control={<Radio />} label="3" />
                    <FormControlLabel value="4" control={<Radio />} label="4" />
                    <FormControlLabel value="5" control={<Radio />} label="5" />
                  </RadioGroup>
                </FormControl>
            </Grid>

            <Grid item xs className={classes.gridForm}>
                <InputLabel className={classes.tittleLabelEncuesta}>
                    d. Presentacion del vehiculo.
                </InputLabel>
                <FormControl variant="outlined" fullWidth >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="encpedPresentacionVehiculo"
                    onChange={this.handleChange}
                    value={this.state.fields["encpedPresentacionVehiculo"] || undefined}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="1" />
                    <FormControlLabel value="2" control={<Radio />} label="2" />
                    <FormControlLabel value="3" control={<Radio />} label="3" />
                    <FormControlLabel value="4" control={<Radio />} label="4" />
                    <FormControlLabel value="5" control={<Radio />} label="5" />
                  </RadioGroup>
                </FormControl>
            </Grid>
          </Grid> 


          <Grid container spacing={2}>
            <Grid item xs className={this.props.classes.gridForm}>
                <InputLabel className={classes.tittleLabelEncuesta}>
                2. El asesor comercial en la visita fue?
                </InputLabel>
            </Grid>
        </Grid> 

          <Grid container spacing={2}>
            <Grid item xs className={classes.gridForm}>
                <InputLabel className={classes.tittleLabelEncuesta}>
                    a. Claro con la informacion.
                </InputLabel>
                <FormControl variant="outlined" fullWidth >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="encpedVisitaClaridadInformacion"
                    onChange={this.handleChange}
                    value={this.state.fields["encpedVisitaClaridadInformacion"] || undefined}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs className={classes.gridForm}>
                <InputLabel className={classes.tittleLabelEncuesta}>
                    b. Respetuoso.
                </InputLabel>
                <FormControl variant="outlined" fullWidth >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="encpedVisitaRespetuoso"
                    onChange={this.handleChange}
                    value={this.state.fields["encpedVisitaRespetuoso"] || undefined}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs className={classes.gridForm}>
                <InputLabel className={classes.tittleLabelEncuesta}>
                    c. Puntual al momento de realizar la visita.
                </InputLabel>
                <FormControl variant="outlined" fullWidth >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="encpedVisitaPuntualidad"
                    onChange={this.handleChange}
                    value={this.state.fields["encpedVisitaPuntualidad"] || undefined}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs className={classes.gridForm}></Grid>

          </Grid> 

          <Grid container spacing={2}>

            <Grid item xs className={classes.gridForm}>
                <InputLabel className={classes.tittleLabelEncuesta}>
                  3. ¿Recomendaria usetd nuestro servicio? 
                </InputLabel>
                <FormControl variant="outlined" fullWidth >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="encpedRecomendacionServicio"
                    onChange={this.handleChange}
                    value={this.state.fields["encpedRecomendacionServicio"] || undefined}
                  >
                    <FormControlLabel value="SI" control={<Radio />} label="SI" />
                    <FormControlLabel value="NO" control={<Radio />} label="NO" />
                  </RadioGroup>
                </FormControl>
            </Grid>

          </Grid> 

          <Grid container spacing={2}> 
                <Grid item xs className={this.props.classes.gridForm}>
                    <InputLabel className={classes.tittleLabelEncuesta}>
                      4. Con el proposito de mejorar nuestro servicio que sugerencia nos da a conocer.
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth >
                    <TextareaAutosize  id="encpedObservacion"  onChange={this.handleChange} type="text" 
                    value={this.state.fields["encpedObservacion"] || undefined}                 
                      rows="4"
                      cols="100"
                  />
                    </FormControl>
                </Grid>
            </Grid>


          <Grid container spacing={10} >
              <Grid item xs={3} className={this.props.classes.gridForm}>
                  {(Utils.getFromLocalStorage('userInfo')===null) ?
                    (<Button
                      color='primary'
                      variant='contained'
                      onClick={(event) => this.save()}
                    >
                    Enviar
                  </Button>
                    ) : ''
                  }
              </Grid>
          </Grid>
      </Paper>
    );
  }
}

EncuestaPedido.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  toggleFavorites: PropTypes.func.isRequired,
  toogleFavoritesShow: PropTypes.func.isRequired
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(EncuestaPedido)));

function IntegrationNotistack(props) {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp {...props} />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;