import React, { Component } from 'react';
import Header from '../GeneralUI/Header/';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Route, Switch } from "react-router-dom";
//import Redirect from "react-router-dom";
import Search from '../../Containers/Search';
import Myfavourites from "../GeneralUI/Myfavourites";
import Loader from './loader';

const Styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 0,
    backgroundColor: 'f1f1f1',
    height: '100%'
  },
  routerContent: {
    height: '75%'
  },
  routerContentWrapper: {
    height: '100%'
  }
});

class Home extends Component {
  componentDidMount() {
    this.props.loadUserInfo();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Header
          usuarioNombreCompleto={this.props.usuarioNombreCompleto}
          usuarioAvatar={this.props.usuarioAvatar}
        />

        <Grid container>
          <Grid item xs={this.props.showFavorites ? 10 : 12}>
            <div id='mainContainer' className={classes.routerContentWrapper}>
              <Switch>
                <Route
                  path="/home/:module"
                  exact
                  // component={Loader}
                  render={(props) => <Loader {...props}
                    toggleFavorites={this.props.toggleFavorites}
                    toogleFavoritesShow={this.props.toogleFavoritesShow}
                  />}
                />
                <Route path="/home/:module/:id/edit" exact component={Loader} />
                <Route path='/home/:module/search' exact component={Search} />

                {/*<Redirect from="/" to="/home/dashboard" exact />*/}
              </Switch>
            </div>
          </Grid>

          {this.props.showFavorites ?
            <Grid item xs={2}>
              <Myfavourites />
            </Grid> : null}

        </Grid>
      </div>
    );
  }
}

Home.propTypes = {
  usuarioNombreCompleto: PropTypes.string.isRequired,
  usuarioAvatar: PropTypes.string.isRequired,
  loadUserInfo: PropTypes.func.isRequired,
  toggleFavorites: PropTypes.func,
  toogleFavoritesShow: PropTypes.func,
  showFavorites: PropTypes.bool
}

export default withStyles(Styles, { withTheme: true })(Home);
