import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Textfieldlabel from "../GeneralUI/Textfieldlabel";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import PSelect from '../GeneralUI/PSelect'
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import AutoComplete from '../GeneralUI/AutoComplete';

import Visibility from '@material-ui/icons/Visibility';
import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';

class Cliente extends Component {

  state = {
    fields: {
      clienteCodigo: null,
      clienteNombre1: null,
      clienteNombre2: null,
      clienteApellido1: null,

      clienteApellido2: null,
      tipdocCodigo: null,
      clienteDocumento: null,

      tipterCodigo: null,
      actecoCodigo: null,
      clienteCelular: null,
      clienteRut: null,

      clienteTelefono: null,
      clienteCorreoElectronico: null,
      clienteCorreoElectronicoFacturacion: null,
      ciudadCodigoRadicacion: null,
      clienteDireccion: null,

      clienteFacturaInventario: null,
      clienteDiaFacturacion: null,
      clienteCierreFactura: null,

      clienteDiasVencimiento: 0,

      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime()
    },
    responseForm: { Message: '', typeMessage: '' }
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'clienteCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      console.log("documents:", document);
      this.setState({ fields: document });
    });

  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }
    this.setState({ fields });
  }

  save = (response) => {
    this.setState({
      fields: response.data,
      responseForm: response.responseForm
    });
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  handleAuto = (autoCompleteValue) => {
    this.setState({ colorAutoComplete: autoCompleteValue });
  }

  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>
          <Grid container spacing={3} className={this.props.classes.gridForm}>
            <InputLabel id="clienteCodigo"
              className={this.props.classes.tittleLabelForm}>Cliente</InputLabel>
            <Grid item xs={6} className={this.props.classes.chipTitle}>
              {(this.state.fields.clienteCodigo !== null) ?
                (<Chip
                  label={this.state.fields["clienteCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="clienteNombre1" label="Primer Nombre (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["clienteNombre1"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="clienteNombre2" label="Segundo Nombre (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["clienteNombre2"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="clienteApellido1" label="Primer Apellido (*)" type="text"

                fullWidth margin="normal" variant="outlined" onChange={this.handleChange}
                value={this.state.fields["clienteApellido1"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="clienteApellido2" label="Segundo Apellido (*)" type="text"

                fullWidth margin="normal" variant="outlined" onChange={this.handleChange}
                value={this.state.fields["clienteApellido2"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>

              <PSelect
                namelabel="TipoDocumento (*)"
                id="tipdocCodigo"
                namelineInput="tipodocumento"
                fieldVisible="tipdocNombre"
                fieldCode="tipdocCodigo"
                Table="Tipodocumento"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["tipdocCodigo"]}
              />

            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="clienteDocumento" label="Documento (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["clienteDocumento"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="clienteRut"
                useChildren={true}
                value={this.state.fields["clienteRut"]}
                onChange={this.handleChange}
                namelabel="RUT"
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="ciudadCodigoRadicacion"
                tableName='vw_ciudad'
                fields={['ciudad_codigo','ciudad_nombre', 'departamento_nombre']}
                isView={true}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Ciudad', size: 5 }, { value: 'Departamento', size: 6 }]}
                size='xx'
                primaryField='ciudad_codigo'
                searchField='ciudad_nombre'
                placeHolder='Seleccionar Ciudad'
                onSelect={this.handleChange}
                value={this.state.fields["ciudadCodigoRadicacion"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="clienteDireccion" label="Direccion (*)" type="text"
                fullWidth margin="normal" variant="outlined" onChange={this.handleChange}
                value={this.state.fields["clienteDireccion"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="clienteTelefono" label="Telefono (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["clienteTelefono"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="clienteCelular" label="Celular (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["clienteCelular"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="clienteCorreoElectronico" label="Correo Electronico (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["clienteCorreoElectronico"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>          
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="clienteCorreoElectronicoFacturacion" label="Correo Electronico Facturacion(*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["clienteCorreoElectronicoFacturacion"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid> 

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="clienteFacturaInventario"
                useChildren={true}
                value={this.state.fields["clienteFacturaInventario"]}
                onChange={this.handleChange}
                namelabel="Factura Inventario"
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </PSelect>
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="clienteDiaFacturacion" label="Dia Facturacion" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["clienteDiaFacturacion"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="clienteCierreFactura"
                useChildren={true}
                value={this.state.fields["clienteCierreFactura"]}
                onChange={this.handleChange}
                namelabel="Cierre Factura"
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="clienteDiasVencimiento" label="Dias Vencimiento Factura (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["clienteDiasVencimiento"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid> 
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Tipo Tercero (*)"
                id="tipterCodigo"
                namelineInput="tipotercero"
                fieldVisible="tipterNombre"
                fieldCode="tipterCodigo"
                Table="TipoTercero"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["tipterCodigo"]}
              />
            </Grid>

           
          </Grid>

          <Grid container spacing={4}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="actecoCodigo"
                  tableName='ActividadEconomica'
                  fields={['actecoCodigo', 'actecoDescripcion']}
                  primaryField='actecoCodigo'
                  aliases={[{ value: 'ID', size: 2 }, { value: 'Descripcion', size: 10 }]}
                  size='xx'
                  searchField='actecoDescripcion'
                  placeHolder='Seleccionar ActividadEconomica'
                  onSelect={this.handleChange}
                  value={this.state.fields["actecoCodigo"]}
                />
              </Grid>
          </Grid>

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Usuario" value={Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Creacion" value={this.state.fields.createdAt} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Modificacion" value={this.state.fields.updatedAt} />
            </Grid>
          </Grid>


        </form>
        <div><Buttoncontrol

          saveData={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          primaryFields={['clienteCodigo']}

        /></div>
      </Paper>
    );
  }
}

Cliente.propTypes = {
  onClick: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(Cliente)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}

    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;