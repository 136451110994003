import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Styles from './Styles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import PSelect from '../../../GeneralUI/PSelect'
import ListItemText from '@material-ui/core/ListItemText';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import { errorSetter } from '../../../../Libs';


class Detallefacturaotrosconceptos extends Component {

  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    errorForm: false,
    errorMessage: '',
    fields: {
      sucursal: { 'value': null, 'key': null,'visible':true },
      tipoTarifa: { 'value': null, 'key': null,'visible':true },
      descripcion: { 'value': null, 'key': null,'visible':true },
      valorTotal: { 'value': null, 'key': null,'visible':true }
    },
    formValidations: {
      view: ['sucursal', 'tipoTarifa', 'descripcion', 'valorTotal']
    },
    formErrors: {
      sucursal: {},
      tipoTarifa: {},
      descripcion: {},
      valorTotal: {}
    }
  };


  componentDidMount = () => {
    if (this.props.editData) {
      this.fillFormData(this.props.editData, this.props.setIndex)
    }
    if (this.props.datosHeader) {
      this.setState(this.props.datosHeader);
    }
    console.log(this.props.datosHeader);
  }

  fillFormData = (data, index) => {

    let { fields } = this.state;

    fields["sucursal"] = ({ 'value': data[index].sucursal.value, 'key': data[index].sucursal.key,'visible':true });
    fields["tipoTarifa"] = ({ 'value': data[index].tipoTarifa.value, 'key': data[index].tipoTarifa.key,'visible':true });
    fields["descripcion"] = ({ 'value': data[index].descripcion.value, 'key': data[index].descripcion.key,'visible':true });
    fields["valorTotal"] = ({ 'value': data[index].valorTotal.value, 'key': data[index].valorTotal.key,'visible':true });

    this.setState({ fields });

  }

  handleChange = (event) => {
    let { fields } = this.state; this.setState({ fields });
    fields[event.target.id].value= event.target.value;
    fields[event.target.id].key= event.target.hasOwnProperty('text') ? event.target.text : event.target.value
    this.setState({ fields });
  }

  validateLogicaForm = async () => {
  }

  setDataValidation = (errorForm, errorMessage) => {
    this.setState({ errorForm: errorForm, errorMessage: errorMessage });
  }

  setData = async () => {
    const validations = errorSetter.validateFormFields(this.state.fields, this.state.formValidations);
    this.setState({ formErrors: validations.errors });
    await this.validateLogicaForm();
    if (validations.isValid && !this.state.errorForm) {
      this.props.setData(this.state.fields, this.props.editData ? this.props.setIndex : null);
    }
  }

  render = () => {
    return (
      <Paper elevation={1} className={this.props.classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>
          <Grid container direction='row' className={this.props.classes.paperGridTitle}>
            <List component="nav">
              <ListItem>
                <ListItemText primary="Otros Conceptos" classes={{ primary: this.props.classes.titlelabelformwhite }} />
              </ListItem>
              {(this.state.errorForm) ?
                <ListItem>
                  <ListItemText primary={this.state.errorMessage} classes={{ primary: this.props.classes.titlelabelerrorform }} />
                </ListItem> : ''
              }
            </List>
          </Grid>

          <Grid container spacing={2}>
          <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="sucursal"
                tableName='Sucursal'
                fields={['sucursalCodigo', 'sucursalNombre']}
                primaryField='sucursalCodigo'
                aliases={['ID', 'Nombre']}
                searchField='sucursalNombre'
                placeHolder='Seleccionar Sucursal'
                onSelect={this.handleChange}
                value={this.state.fields["sucursal"].value || undefined}
                formErrors={this.state.formErrors['sucursal']}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                namelabel="Tipo Tarifa"
                id="tipoTarifa"
                namelineInput="tipotarifa"
                fieldVisible="tiptarNombre"
                fieldCode="tiptarCodigo"
                Table="Tipotarifa"
                Vista="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["tipoTarifa"].value || undefined}
                InputLabelProps={{ shrink: true }}
                formErrors={this.state.formErrors['tipoTarifa']}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="descripcion"
                label="Descripcion"
                type="text"
                fullWidth margin="normal"
                onChange={this.handleChange}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                value={this.state.fields["descripcion"].value || undefined}
                error={this.state.formErrors['descripcion'].error}
                helperText={this.state.formErrors['descripcion'].errorMessage}
                formErrors={this.state.formErrors['descripcion']}
              />
            </Grid>
          </Grid>


          <Grid container spacing={2}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="valorTotal" label="Valor Total" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["valorTotal"].value || undefined}
                  InputLabelProps={{ shrink: true }}
                  error={this.state.formErrors['valorTotal'].error}
                  helperText={this.state.formErrors['valorTotal'].errorMessage}
                  formErrors={this.state.formErrors['valorTotal']}
                />
              </Grid>
          </Grid>

          <Grid container spacing={2} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Button
                color='primary'
                onClick={this.setData}
              >
                Adicionar
              </Button>
            </Grid>
          </Grid>


        </form>
      </Paper>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(Detallefacturaotrosconceptos);