import createStyles from '@material-ui/core/styles/createStyles';

const styles = theme => createStyles({
  mainPaper: {
    marginTop: theme.spacing (10),
    marginLeft: theme.spacing (0),
    marginBottom: theme.spacing (20),
    padding: theme.spacing(),
    paddingLeft: '0px',
    paddingTop: '0px',
    width: '82%',
    height: 'auto',
    minHeight: '53%',
    position: 'absolute',
    display: 'block',
    left: 7,
    alignItems: 'left',
    backgroundColor: '#fff',
  },
  fullWidthPaper: {
    width: '99%',
    paddingRight: '1.5em'
  },
  gridForm: {
    marginLeft: theme.spacing (4),
    display: 'flex',
    flexWrap: 'wrap'
  },
  gridFormLite: {
    marginLeft: theme.spacing (4),
    alignItems: 'center'
  },
  chipTitle: {
    marginLeft: theme.spacing (4),
    display: 'flex',
    alignItems: 'center'
  },
  gridButtonSearch: {
    marginTop: theme.spacing (2),
    marginBottom: theme.spacing (2),
    display: 'block',
    alignItems: 'left',
  },
  gridFormSearch: {
    marginLeft: theme.spacing (4),
    paddingBottom: '20px',
    display: 'block',
    alignItems: 'center',
  },
  gridResultSearch: {
    margin: 0,
    padding: 0,
    display: 'block',
    alignItems: 'center',
    overflowX: 'scroll'
  },
  mainGrid: {
    height: '95%',
    minHeight: '100%',
    paddingLeft: theme.spacing (2)
  },
  tittleLabelForm: {
    marginTop: theme.spacing (4),
    borderSpacing: 0,
    borderBottom: 0,
    marginBottom: 30,
    color: '#2196f3',
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  formControl: {
    margin: theme.spacing (1),
    marginTop: '1em',
    minWidth: 120,
  },
  gridVisitaProgramada: {
    background: '#4A86E8',
  },
  gridVisitaReprogramada: {
    background: '#93C47D'
  },
  gridVisitaCumplida: {
    background: '#E69138'
  },
  gridVisitaIncumplida: {
    background: '#CC0000'
  },
  gridVisitaCancelada: {
    background: '#A64D79'
  },

  gridPedidoConfirmado: {
    background: '#92C47D'
  },
  gridPedidoPendiente: {
    background: '#FE9900'
  },
  gridPedidoDespacho: {
    background: '#FFFF00'
  },
  gridPedidoCerrado: {
    background: '#999999'
  },
  gridPedidoAnulado: {
    background: '#FE0000'
  },
  gridVisorDianAlertaAlta: {
    background: '#FF3933'
  },
  gridVisorDianAlertaMedia: {
    background: '#FF9033'
  },
  gridVisorDianAlertaBaja: {
    background: '#3380FF'
  },
  tittleLabelEncuesta: {
    marginTop: theme.spacing (1),
    borderSpacing: 0,
    borderBottom: 0,
    marginBottom: 10,
    marginRight: 20,
    color: '#807D7D',
    fontSize: '1.2rem',
    fontWeight: '',
  },
  tittleLabelObservacion: {
    marginTop: theme.spacing (1),
    borderSpacing: 0,
    borderBottom: 0,
    marginBottom: 10,
    marginRight: 20,
    color: '#807D7D',
    fontSize: '0.9rem',
    fontWeight: '',
  }
});

export default styles;