import { connect } from 'react-redux';
import Login from '../Components/Login';
import { validateUserName, login, validateToken } from '../Actions/Login';

const mapStateToProps = (state) => ({
  isValidated: state.auth.isValidated,
  error: state.auth.error,
  errorMessage: state.auth.errorMessage,
  waitForResponse: state.auth.waitForResponse,
  usuarioNombreUsuario: state.auth.usuarioNombreUsuario
});

const mapDispatchToProps = (dispatch) => {
  return {
    validateUserName: (userName) => dispatch(validateUserName(userName)),
    login: (userName, password) => dispatch(login(userName, password)),
    validateToken: () => dispatch(validateToken(3000))
  };
};



export default connect(mapStateToProps, mapDispatchToProps)(Login);