import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/Avatar';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './Styles';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import Utils from '../../Libs/utils';
import FaceIcon from '@material-ui/icons/Face';

class Password extends Component {
  state = {
    isValid: true,
    errorMessage: "",
    password: "",
    isValidating: false,
    showPassword: false
  }

  handleChange = (event) => {
    this.setState({ password: event.target.value.trim() })
  }

  validatePassword = () => {
    const validateText = Utils.validateText(this.state.password, {
      minLength: 5,
      required: true,
      excludeSpace: false,
      excludeSymbols: false
    });

    this.setState({
      isValid: validateText.isValid,
      errorMessage: validateText.errorMessage
    });

    if (validateText.isValid) {
      this.setState({
        isValidating: true
      });

      this.props.onClick(this.state.password);
    }
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={5} className={classes.paper}>
        <Avatar src="/Images/key.png" className={classes.bigAvatar} sizes="5" />
        <InputLabel id="bienvenido" className={this.props.classes.tittleLabelForm}>
          Te damos la bienvenida
        </InputLabel>
        <Chip
          avatar={
            <Avatar>
              <FaceIcon />
            </Avatar>
          }
          label={this.props.usuarioNombreUsuario}
          clickable
          className={this.props.classes.chip}
          color="primary"
        />

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>
          <FormControl margin="normal" fullWidth className={this.props.classes.gridForm}>
            <TextField autoFocus={true}
              type={this.state.showPassword ? 'text' : 'password'}
              error={this.props.error || !this.state.isValid}
              label='Contraseña'
              value={this.state.userName} onChange={this.handleChange}
              disabled={this.props.waitForResponse}
              margin="normal" variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton className={classes.iconButton}
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }} />

            <FormHelperText error={true}>{this.props.errorMessage || this.state.errorMessage}</FormHelperText>
          </FormControl>

          <Button className={classes.submit}
            onClick={this.validatePassword} fullWidth
            variant='contained'
            color='primary' disabled={this.props.waitForResponse}>
            Ingresar
          </Button>
        </form>
      </Paper>
    );
  }
}

Password.propTypes = {
  onClick: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  waitForResponse: PropTypes.bool.isRequired
};

export default withStyles(Styles, { withTheme: true })(Password);
//export default Login;