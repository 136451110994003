import React, { Component } from 'react'
import { AppBar, Toolbar, Avatar, IconButton } from '@material-ui/core';
import { Home, Menu } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './Styles';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Link } from "react-router-dom";
import obj from './../../../Libs/menuheader.json';
import Divider from '@material-ui/core/Divider';
import { history } from '../../../Libs/';
import Cookies from 'universal-cookie';
import AutoSuggest from './autosuggest';

const cookie = new Cookies();

class Header extends Component {

  state = {
    isNameAvatar: false,
    nameAvatarContent: '',
    open: false,
    searchText: ''
  };

  handleTextChange = (event) => {
    console.log('33 > event.target.value === ', event.target.value);
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  componentDidUpdate = () => {
    if (!this.props.usuarioAvatar && !this.state.isNameAvatar) {
      const name = String(this.props.usuarioNombreCompleto);
      const shortName = name.split(" ").map(word => word.substr(0, 1)).slice(0, 2).join('').toUpperCase();
      this.setState({ isNameAvatar: true, nameAvatarContent: shortName });
    }
  }

  getMenuItem = (item) => {
    if (item.id === 6) {
      return (
        <div>
          <MenuItem id={item.id} onClick={this.logout} className={this.props.classes.valuelabelform}>{item.name}</MenuItem>
        </div>
      );
    }
    return (
      <Link to={item.link} className={this.props.classes.link}>
        <MenuItem id={item.id} onClick={this.handleClose} className={this.props.classes.valuelabelform}>{item.name}</MenuItem>
        <Divider />
      </Link>
    );
  }

  logout() {
    localStorage.removeItem('userInfo');
    cookie.remove('crmpymes-token', { path: '/' });
    cookie.remove('crmpymes-usuarioNombreUsuario', { path: '/' });
    history.push('/');
  }

  render = () => {
    const { classes } = this.props;
    const { open } = this.state;

    return (
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>

          <Link to="/">
            <IconButton>
              <Avatar src='https://crmpymes.co/coldesoft.png'/>              
            </IconButton>
          </Link>
          <div className={classes.birthDay}>
              <p>1 AÑO +</p>
          </div>

          <div className={classes.search}>
            <AutoSuggest />
          </div>

          <div className={classes.grow} />

          <div className={classes.iconContainer}>
            <div className={classes.userName}>
              <p>
                {this.props.usuarioNombreCompleto}
              </p>
            </div>

            <IconButton>
              {
                (this.state.isNameAvatar) ?
                  <Avatar className={classes.avatar}>{this.state.nameAvatarContent}</Avatar> :
                  <Avatar className={classes.avatar} src={this.props.usuarioAvatar} />
              }
            </IconButton>

            <IconButton>
              <Avatar src='https://crmpymes.co/bodehogar2.png' />
            </IconButton>

            <Link to="/home/dashboard">
              <IconButton>
                <Home fontSize='large' className={classes.icon} />
              </IconButton>
            </Link>

            <IconButton
              buttonRef={node => {
                this.anchorEl = node;
              }}
              aria-owns={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={this.handleToggle}
            >
              <Menu fontSize='large' className={classes.icon} />
            </IconButton>
            <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper className={this.props.classes.paperMenu}>
                    <InputLabel id="il_configuraciones" className={this.props.classes.titlelabelform}>
                      Configuraciones:
                        </InputLabel>
                    <Divider />
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList className={classes.valuelabelform}>
                        {obj.data[0].page.map((item) => this.getMenuItem(item))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Toolbar>
      </AppBar>
    )
  }
}

Header.propTypes = {
  usuarioNombreCompleto: PropTypes.string.isRequired,
  usuarioAvatar: PropTypes.string.isRequired
}

export default withStyles(Styles, { withTheme: true })(Header);