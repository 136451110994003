import React, { Component } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { MenuItem, MenuList, ListSubheader, Grid, InputAdornment, Typography } from '@material-ui/core';
import { autoCompleteSearch } from '../../../Actions/General';
import { SkipNext, SkipPrevious, FirstPage, LastPage, CheckCircle, CheckCircleOutline } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash';
const MINIMUM_INPUT_LENGTH = 3;

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, fields, primaryField, classes, idFieldSize, fieldSize, props }) {
  const isHighlighted = highlightedIndex === index;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion[primaryField]}
      selected={isHighlighted}
      component="div"
    >
      {
        fields.map((value, index) => {
          const fieldConfig = getColumnDefinition(index, props.aliases[index], idFieldSize, fieldSize);
          return (
            <Grid
              item
              key={index} xs={fieldConfig.size}
              className={index === 0 ? classes.primaryField : classes.field}
            >
              <Typography noWrap>
                {suggestion[value]}
              </Typography>
            </Grid>
          );
        })
      }

      {/*suggestion.label*/}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};


function getColumnDefinition(index, value, idFieldSize, fieldSize) {
  if (_.isPlainObject(value)) {
    return value;
  }

  return {
    value,
    size: index === 0 ? idFieldSize : fieldSize
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: '15px'
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  containerSize: {
    width: '150%'
  },
  containerSizeXL: {
    width: '200%'
  },
  containerSizeXX: {
    width: '300%'
  },

  paperLeftNormal:{
    left: 0 
  },

  paperLeft400:{
    left: -400
  },

  paper: {
    position: 'absolute',
    zIndex: 999,
    marginTop: theme.spacing(),
    right: 0,
    backgroundColor: '#fff'
  },
  
  chip: {
    margin: `${theme.spacing() / 2}px ${theme.spacing() / 4}px`,
  },
  inputRoot: {
    flexWrap: 'no-wrap',
    marginButton: theme.spacing(16),
  },
  inputInput: {
    width: '100%',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
  primaryField: {
    textAlign: 'right',
    paddingRight: theme.spacing()
  },
  field: {
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing()
  },
  navGrid: {
    textAlign: 'center'
  },
  navButton: {
    '&:focus': {
      outline: 'none'
    },
    cursor: 'hand'
  },
  checkIcon: {
    '&:focus': {
      outline: 'none'
    },
    cursor: 'hand'
  },
  emptyCheckIcon: {
    paddingRight: 0
  }
});

class AutoComplete extends Component {
  constructor(props) {
    super(props);

    this.autoCompleteInput = React.createRef();

    this.MINIMAL_MILLI = 3000;
    this.lastStrokeAt = null;

    this.selectedValue = null;
    this.selectedText = null;
    this.currentInputVal = null;
    this.currentPage = 0;
    this.totalPages = 0;
    this.limit = 5;
    this.isLoaded = false;

    this.idFieldSize = 2;
    this.fieldSize = 4;
    if (props.fields && props.fields.length <= 2) {
      this.fieldSize = 6;
    }

    this.isView = props.isView ? true : false;

  }

  state = {
    suggestions: [],
    isWaiting: false,
    inputValue: ''
  };

  componentDidMount = () => {

    if (!this.isLoaded && this.props.value) {
      this.isLoaded = true;      
      this.loadInitialValue(this.props.value);
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (!this.isLoaded && (nextProps.value !== this.props.value)) {
      this.isLoaded = true;
      this.loadInitialValue(nextProps.value);
    }

    return true;
  }

  loadInitialValue = (value) => {
    this.setState({ isWaiting: false });

    let { primaryField, tableName, fields, where } = this.props;
    autoCompleteSearch(value, primaryField, fields, tableName, 1, 0, this.isView, where).then(data => {
      const row = lodash.get(data, 'rows[0]');
      if (row === undefined) {
        return this.setState({
          isWaiting: false
        });
      }

      this.selectedValue = row[this.props.primaryField];
      this.currentInputVal = row[this.props.textField]? row[this.props.textField]:row[this.props.searchField];

      this.setState({
        isWaiting: false,
        isFilled: true,
        inputValue: this.currentInputVal==null? 0 : this.currentInputVal.toUpperCase()
      });
    });
  }

  shouldContinue = () => {
    const currentTime = new Date();
    let isValid = false;

    if (!this.lastStrokeAt) {
      isValid = true;
    } else if ((currentTime - this.lastStrokeAt) < 3000) {
      isValid = false;
    } else {
      isValid = true;
    }

    this.lastStrokeAt = currentTime;
    return isValid;
  }

  checkWildCard(text) {
    if (String(text).match(/%$/)) {
      text = String(text).replace(/%$/, '');
      return true;
    } else {
      return false;
    }
  }

  getSuggestions = (event) => {
    if (lodash.get(event, 'target.value') === undefined) {
      return;
    }
    this.setState({ inputValue: event.target.value });
    if (event.target.id !== undefined) {
      this.currentPage = 0;
      this.totalPages = 0;
    }

    let inputValue = lodash.deburr(event.target.value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let returnFocusToInput = false;
    let mininumInputLength=this.props.minInputLength ? this.props.minInputLength  : 3;

    if (inputLength === 0) {
      return this.setState({ suggestions: [] });
    }

    if ((inputLength >= mininumInputLength || this.checkWildCard(inputValue)) && !this.setState.isWaiting) {
      this.setState({ isWaiting: false });
      this.currentInputVal = inputValue;

      let { searchField, tableName, fields, where } = this.props;
      autoCompleteSearch(inputValue, searchField, fields, tableName,
        this.limit, (this.currentPage * this.limit), this.isView, where).then(data => {

          if (data.count !== undefined) {
            this.totalPages = Math.ceil(data.count / this.limit);
          }

          if (lodash.get(this.autoCompleteInput, 'current.focus')) {
            returnFocusToInput = true;
          }

          this.setState({
            isWaiting: false,
            suggestions: data.rows ? data.rows : data
          }, () => {
            if (returnFocusToInput) {
              this.autoCompleteInput.current.focus();
            }
          });
        });
    }
  }


  renderInput = (inputProps) => {
    const { InputProps, classes, ref, placeHolder, ...other } = inputProps;

    return (
      <TextField
        type='text'
        id={this.props.id}
        variant='outlined'        
        error={this.props.formErrors && this.props.formErrors.error ? this.props.formErrors.error : null}
        helperText={this.props.formErrors && this.props.formErrors.errorMessage ? this.props.formErrors.errorMessage : null}
        label={placeHolder}
        inputRef={(input) => { this[this.props.id] = input; }}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          ...InputProps,
        }}
        {...other}        
      />
    );
  }

  renderHeader = () => {
    return (
      <ListSubheader component="div">
        <Grid container>
          {
            this.props.aliases.map((value, index) => {
              const field = getColumnDefinition(index, value, this.idFieldSize, this.fieldSize);

              return (
                <Grid item key={index} xs={field.size}>
                  {field.value}
                </Grid>
              );
            })
          }
        </Grid>
      </ListSubheader>
    );
  }

  gotToPage = (event) => {
    if (!this.shouldNavigate(event)) {
      return;
    }

    switch (event) {
      case 'first':
        this.currentPage = 0;
        break;
      case 'previous':
        this.currentPage = this.currentPage - 1;
        break;
      case 'next':
        this.currentPage = this.currentPage + 1;
        break;
      case 'last':
        this.currentPage = this.totalPages - 1;
        break;
      default:
        break;
    }

    this.getSuggestions({ target: { value: this.currentInputVal } });
  }

  shouldNavigate = (event) => {
    if ((event === 'first' || event === 'previous') && this.currentPage === 0) {
      return false;
    }

    if ((event === 'next' || event === 'last') && this.currentPage === this.totalPages) {
      return false;
    }

    return true;
  }

  goFirst = () => {
    this.gotToPage('first');
  }

  goPrevious = () => {
    this.gotToPage('previous');
  }

  goNext = () => {
    this.gotToPage('next');
  }

  goLast = () => {
    this.gotToPage('last');
  }

  selectValue = (selectedItem) => {

    this.selectedValue = selectedItem[this.props.primaryField];
    this.selectedText = selectedItem[this.props.textField? this.props.textField : this.props.searchField];

    this.setState({
      inputValue: String(selectedItem[this.props.textField? this.props.textField : this.props.searchField]).toUpperCase(),
      isFilled: true
    });

    if (this.props.onSelect) {
      this.props.onSelect(this.formatHandlerResponse(this.selectedValue, this.selectedText));
    }
  }

  handleRemoveValue = () => {
    this.setState({
      isFilled: false,
      inputValue: ''
    });

    if (this.props.onSelect) {
      this.props.onSelect(this.formatHandlerResponse(null, null));
    }
  }

  formatHandlerResponse = (value, text) => {
    return {
      target: {
        id: this.props.id,
        value: value,
        text: text,
        type: 'number'
      }
    };
  }

  getContainerClassLeft() {
    if (!this.props.paperLeft) {
      return this.props.classes.paperLeftNormal;
    }

    if (this.props.paperLeft == '400') {
      return this.props.classes.paperLeft400;
    } else {
      return this.props.classes.paperLeftNormal;
    }
  }

  getContainerClassSize() {
    if (!this.props.size) {
      return this.props.classes.containerSize;
    }

    if (this.props.size === 'l') {
      return this.props.classes.containerSize;
    } else if (this.props.size === 'xl') {
      return this.props.classes.containerSizeXL;
    } else {
      return this.props.classes.containerSizeXX;
    }
  }

  render() {
    const { classes, fields, primaryField, searchField, placeHolder } = this.props;

    return (
      <div className={classes.root}>
        <Downshift id="autoComplete"
          onChange={this.selectValue}
          itemToString={item => (item ? item[searchField] : '')}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            inputValue,
            isOpen,
            selectedItem
          }) => (
              <div className={classes.container}>
                {this.renderInput({
                  fullWidth: true,
                  classes,
                  placeHolder: placeHolder,
                  inputRef: this.autoCompleteInput,
                  InputProps: getInputProps({
                    onChange: this.getSuggestions,
                    disabled: this.props.disabled,
                    value: this.state.inputValue,
                    endAdornment: (
                      <InputAdornment position="end">
                        {this.state.isFilled ?
                          <IconButton
                            className={classes.checkIcon}
                            aria-label="Remover"
                            onClick={this.handleRemoveValue}>
                            <CheckCircle />
                          </IconButton> :
                          <div className={classes.emptyCheckIcon}>
                            <CheckCircleOutline />
                          </div>
                        }
                      </InputAdornment>
                    )
                  })
                })}
                <div {...getMenuProps()} className={this.getContainerClassSize()}>
                  {isOpen ? (
                    <Paper className={`${classes.paper} ${this.getContainerClassLeft()} ${this.getContainerClassSize()}`} square>
                      <MenuList
                        component="nav"
                        subheader={this.renderHeader()}>
                        {this.state.suggestions.map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({ key: suggestion[primaryField], item: suggestion }),
                            highlightedIndex,
                            fields,
                            primaryField,
                            classes,
                            idFieldSize: this.idFieldSize,
                            fieldSize: this.fieldSize,
                            props: this.props
                          })
                        )}
                      </MenuList >

                      <Grid container className={classes.navGrid}>
                        <Grid item xs={1}>
                          <IconButton
                            aria-label="Primera pagina"
                            className={classes.navButton}
                            onClick={this.goFirst}
                            disabled={this.state.isWaiting}>
                            <FirstPage />
                          </IconButton>
                        </Grid>

                        <Grid item xs={1}>
                          <IconButton
                            aria-label="Pagina anterior"
                            className={classes.navButton}
                            onClick={this.goPrevious}
                            disabled={this.state.isWaiting}>
                            <SkipPrevious />
                          </IconButton>
                        </Grid>

                        <Grid item xs={1}>
                          <IconButton
                            aria-label="Siguiente pagina"
                            className={classes.navButton}
                            onClick={this.goNext}
                            disabled={this.state.isWaiting}>
                            <SkipNext />
                          </IconButton>
                        </Grid>

                        <Grid item xs={1}>
                          <IconButton
                            aria-label="Ultima pagina"
                            className={classes.navButton}
                            onClick={this.goLast}
                            disabled={this.state.isWaiting}>
                            <LastPage />
                          </IconButton>

                        </Grid>
                      </Grid>
                    </Paper>
                  ) : null}
                </div>
              </div>
            )}
        </Downshift>

      </div>
    );
  }
}

AutoComplete.propTypes = {
  tableName: PropTypes.string.isRequired,
  fields: PropTypes.array,
  searchField: PropTypes.string.isRequired,
  primaryField: PropTypes.string.isRequired,
  aliases: PropTypes.array.isRequired,
  placeHolder: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  value: PropTypes.number,
  id: PropTypes.string,
  where: PropTypes.object,
  isView: PropTypes.bool,
  formErrors: PropTypes.object,
  size: PropTypes.string
};

export default withStyles(styles)(AutoComplete);