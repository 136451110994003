import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Textfieldlabel from "../GeneralUI/Textfieldlabel";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import Datagrid from '../GeneralUI/PDatagrid/'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import CustomizedExpansionPanel from './../GeneralUI/PExpansionpanels'
import AutoComplete from '../GeneralUI/AutoComplete';
import PSelect from '../GeneralUI/PSelect'

import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';

import moment from 'moment';


class MovimientoInventario extends Component {

  state = {
    fields: {
      movinvCodigo: null,
      almacenCodigo: null,
      clienteCodigo: null,
      movinvMotivo: null,
      movinvEstado: 'CONFIRMADO',
      movinvTipoMovimiento:null,
      movinvTipoDocumentoRemitente: null,
      movinvDocumento: '0',
      movinvPlaca: null,
      movinvConductor: null,
      movinvConductorCedula: null,
      movinvConductorEmpresa: null,
      movinvTipoVehiculo: 'PARTICULAR',
      movinvObservacion:null,
      movinvCodigoManual: null,
      movinvPrecintoActual: null,
      movinvPrecintoAnterior: null,        
      movinvPeriodoFacturacion:null,
      movinvFechaPazSalvo:null,
      movinvFechaFinContrato:null,
      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime()
    },

    dataDatagridMovimientoInventarioEntrada: [{
      bodegaCodigo: { 'value': null, 'key': null ,'visible':true},
      ubicacionCodigo: { 'value': null, 'key': null,'visible':true },
      productoCodigo: { 'value': null, 'key': null,'visible':true },
      Cantidad: { 'value': null, 'key': null,'visible':true },
      costoUnitario: { 'value': null, 'key': null,'visible':true },
      Lote: { 'value': null, 'key': null,'visible':true }, 
      Estado: { 'value': null, 'key': null,'visible':true }, 
      
      Cubicaje: { 'value': null, 'key': null,'visible':true }, 
      ValorDeclarado: { 'value': null, 'key': null,'visible':true }, 
      ValorSeguro: { 'value': null, 'key': null,'visible':true }, 
      DuracionBodega: { 'value': null, 'key': null,'visible':true }, 
      AliasBodega: { 'value': null, 'key': null,'visible':true }, 
      FechaIngresoBodega: { 'value': null, 'key': null,'visible':true }, 
    }],

    dataDatagridMovimientoInventarioSalida: [{
      bodegaCodigo: { 'value': null, 'key': null ,'visible':true},
      ubicacionCodigo: { 'value': null, 'key': null,'visible':true },
      productoCodigo: { 'value': null, 'key': null,'visible':true },
      Cantidad: { 'value': null, 'key': null,'visible':true },
      costoUnitario: { 'value': null, 'key': null,'visible':true },
      Lote: { 'value': null, 'key': null,'visible':true }, 
      Estado: { 'value': null, 'key': null,'visible':true }, 
      
      Cubicaje: { 'value': null, 'key': null,'visible':true }, 
      ValorDeclarado: { 'value': null, 'key': null,'visible':true }, 
      ValorSeguro: { 'value': null, 'key': null,'visible':true }, 
      DuracionBodega: { 'value': null, 'key': null,'visible':true }, 
      AliasBodega: { 'value': null, 'key': null,'visible':true }, 
      FechaIngresoBodega: { 'value': null, 'key': null,'visible':true }, 
    }],

    dataDatagridTrazabilidad: [
      {
        Usuario: '',
        DocumentoPrincipal: '',
        DocumentoAsociado: '',
        Evento: '',
        FechaHora: '',
        UsuarioExterno: ''
      }
    ],
    responseForm: { Message: '', typeMessage: '' },
    formErrors: {
    }
  };

  componentDidMount = () => {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'movinvCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }else{

      }

      this.setState({ fields: document });
      
      getDocument({
        documentId: params.id,
        fieldName: 'movinv_codigo',
        moduleName: 'vw_detalle_movimiento_inventario',
        isView: true,
        fields: ['movinvdet_codigo',
          'movinv_codigo',
          'bodega_codigo',
          'bodega_nombre',
          'ubicacion_codigo',
          'ubicacion_nombre',
          'producto_codigo',
          'producto_nombre',
          'cantidad',
          'costo_unitario',
          'lote',
          'estado',
          'movinvdet_caracteristica1',
          'movinvdet_caracteristica2',
          'movinvdet_caracteristica3',
          'movinvdet_caracteristica4',
          'movinvdet_caracteristica5',
          'movinvdet_caracteristica6'
        ],
        Limit: 50
      }).then(document => {
        if (!document) {
          return;
        }

        if(this.state.fields.movinvTipoMovimiento==='ENTRADA'){
          this.buildDetailMovimientoInventarioEntradaInterface(document);
        }else{
          this.buildDetailMovimientoInventarioSalidaInterface(document);
        }
        
      }).catch(ex => {
        console.log('index.jsx -- 137 > ex === ', ex);
      });

    }).catch(ex => {
      console.log('index.jsx -- 140 > ex === ', ex);
    });

    getDocument({
      documentId: params.id,
      fieldName: 'pk',
      moduleName: 'vw_detalle_pedido_trazabilidad',
      isView: true,
      fields: ['docseg_codigo',
        'usuario',
        'usuario_externo',
        'fecha_hora',
        'pk',
        'fk',
        'evento'
      ],
      Limit: 100
    }).then(document => {
      if (!document) {
        return;
      }
      this.builddetailInterfaceTrazabilidad(document);
    }).catch(ex => {
      console.log('index.jsx -- 137 > ex === ', ex);
    })

  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }
    this.setState({ fields });
  }

  handleDatagrid = (data) => {
    const detalle = data.setComponente;
    switch (detalle) {
      case 'movimientoInventarioEntrada':
        this.setState({ dataDatagridMovimientoInventarioEntrada: data });
        break;
      case 'movimientoInventarioSalida':
        this.setState({ dataDatagridMovimientoInventarioSalida: data });
        break;
      case 'trazabilidad':
        this.setState({ dataDatagridTrazabilidad: data });
        break;
      default:
        return null;
    }
  }


  builddetailInterfaceTrazabilidad = (data) => {

    let linkModulePrint, linkModule, linkVisible=true, fechaHora='', rows = data.map(row => {

      if (row.documento_codigo_fk===12) {
        linkModulePrint = '/#' + row.fk;
        linkModule = '/home/movimientoinventario/' + row.fk + '/edit';
        linkVisible=true;
      }else if(row.documento_codigo_fk===8){
        linkModulePrint = '/#' + row.fk;
        linkModule = '/home/listaempaque/' + row.fk + '/edit';
        linkVisible=true;
      }else if(row.documento_codigo_fk===5){
        linkModulePrint = '/#' + row.fk;
        linkModule = '/home/pedido/' + row.fk + '/edit';
        linkVisible=true;      
      }else{
        linkModulePrint = '';
        linkModule = '';
        linkVisible=''; 
      }

      fechaHora = moment(row.fechacreacion+'').format('YYYY-MM-DD HH:mm:ss');

      return {
        Usuario: { 'value': row.usuario, 'key': row.usuario, 'visible':true },
        DocumentoPrincipal: { 'value': row.pk, 'key': row.pk , 'visible':true},
        DocumentoAsociado: { 'value': row.fk, 'key': row.fk,'link': linkVisible, 'href': linkModule, 'visible':true },
        Evento: { 'value': row.evento, 'key': row.evento, 'visible':true },
        FechaHora: { 'value': fechaHora, 'key': fechaHora, 'visible':true },
      };

    });

    this.setState({ dataDatagridTrazabilidad: rows });
  }

  buildDetailMovimientoInventarioEntradaInterface = (data) => {
    let rows = data.map(row => {
      return {
        bodegaCodigo: { 'value': row.bodega_codigo, 'key': row.bodega_nombre, 'visible':true },
        ubicacionCodigo: { 'value': row.ubicacion_codigo, 'key': row.ubicacion_nombre, 'visible':true },
        productoCodigo: { 'value': row.producto_codigo, 'key': row.producto_nombre, 'visible':true },        
        Cantidad: { 'value': row.cantidad, 'key': parseFloat(row.cantidad).toLocaleString(), 'visible':true },
        costoUnitario: { 'value': row.costo_unitario, 'key': parseFloat(row.costo_unitario).toLocaleString(), 'visible':true },        
        Lote: { 'value': row.lote, 'key': row.lote, 'visible':true },
        Estado: { 'value': row.estado, 'key': row.estado, 'visible':true },

        Cubicaje: { 'value': row.movinvdet_caracteristica1, 'key': row.movinvdet_caracteristica1,'visible':true }, 
        ValorDeclarado: { 'value': row.movinvdet_caracteristica2, 'key': row.movinvdet_caracteristica2,'visible':true }, 
        ValorSeguro: { 'value': row.movinvdet_caracteristica6, 'key': row.movinvdet_caracteristica6,'visible':true },
        DuracionBodega: { 'value': row.movinvdet_caracteristica3, 'key': row.movinvdet_caracteristica3,'visible':true }, 
        AliasBodega: { 'value': row.movinvdet_caracteristica4, 'key': row.movinvdet_caracteristica4,'visible':true }, 
        FechaIngresoBodega: { 'value': row.movinvdet_caracteristica5, 'key': row.movinvdet_caracteristica5,'visible':true }, 
      };
    });
    this.setState({ dataDatagridMovimientoInventarioEntrada: rows });
  }


  buildDetailMovimientoInventarioSalidaInterface = (data) => {
    let rows = data.map(row => {
      return {
        bodegaCodigo: { 'value': row.bodega_codigo, 'key': row.bodega_nombre, 'visible':true },
        ubicacionCodigo: { 'value': row.ubicacion_codigo, 'key': row.ubicacion_nombre, 'visible':true },
        productoCodigo: { 'value': row.producto_codigo, 'key': row.producto_nombre, 'visible':true },        
        Cantidad: { 'value': row.cantidad, 'key': parseFloat(row.cantidad).toLocaleString(), 'visible':true },
        costoUnitario: { 'value': row.costo_unitario, 'key': parseFloat(row.costo_unitario).toLocaleString(), 'visible':true },        
        Lote: { 'value': row.lote, 'key': row.lote, 'visible':true },
        Estado: { 'value': row.estado, 'key': row.estado, 'visible':true },

        Cubicaje: { 'value': row.movinvdet_caracteristica1, 'key': row.movinvdet_caracteristica1,'visible':true }, 
        ValorDeclarado: { 'value': row.movinvdet_caracteristica2, 'key': row.movinvdet_caracteristica2,'visible':true }, 
        ValorSeguro: { 'value': row.movinvdet_caracteristica6, 'key': row.movinvdet_caracteristica6,'visible':true },
        DuracionBodega: { 'value': row.movinvdet_caracteristica3, 'key': row.movinvdet_caracteristica3,'visible':true }, 
        AliasBodega: { 'value': row.movinvdet_caracteristica4, 'key': row.movinvdet_caracteristica4,'visible':true }, 
        FechaIngresoBodega: { 'value': row.movinvdet_caracteristica5, 'key': row.movinvdet_caracteristica5,'visible':true },

      };
    });
    this.setState({ dataDatagridMovimientoInventarioSalida: rows });
  }

  messageValidation = (type, message) => {
    this.message(type, message);
  }

  save = (response) => {

    let fields=response.data.fields?response.data.fields:response.data;

    this.setState({
      fields,
      responseForm: response.responseForm
    }
    );

    this.message(this.state.responseForm['typeMessage'], this.state.responseForm['Message']);
  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }

  validaData = () => {
    let isvalid = true;
    let message = 'El registro no ha sido guardado.';
    let type = "warning";

    isvalid = true;

    if (!isvalid) {
      this.message(type, message);
    }
    return isvalid;
  }


  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>

            <InputLabel id="movinvCodigo"
              className={this.props.classes.tittleLabelForm}>Movimiento Inventario
            </InputLabel>

            <Grid item xs={6} className={this.props.classes.chipTitle}>
              {(this.state.fields.movinvCodigo !== null) ?
                (<Chip
                  label={this.state.fields["movinvCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="almacenCodigo"
                tableName='Almacen'
                fields={['almacenCodigo', 'almacenNombre']}
                primaryField='almacenCodigo'
                aliases={['ID', 'Nombre']}
                searchField='almacenNombre'
                placeHolder='Seleccionar Almacen'
                onSelect={this.handleChange}
                value={this.state.fields["almacenCodigo"]}
                formErrors={this.state.formErrors['almacenCodigo']}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>            
              <AutoComplete
                id="clienteCodigo"
                tableName='cliente'
                fields={['clienteCodigo', 'clienteRazonSocial']}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
                size='xl'
                primaryField='clienteCodigo'
                searchField='clienteRazonSocial'
                placeHolder='Seleccionar Cliente'
                onSelect={this.handleChange}
                value={this.state.fields["clienteCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="movinvTipoMovimiento"
                useChildren={true}
                value={this.state.fields["movinvTipoMovimiento"]}
                onChange={this.handleChange}
                namelabel="Tipo Movimiento"
              >
                <option value="ENTRADA">ENTRADA</option>
                <option value="SALIDA">SALIDA</option>
              </PSelect>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
          <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="movinvMotivo"
                useChildren={true}
                value={this.state.fields["movinvMotivo"]}
                onChange={this.handleChange}
                namelabel="Motivo"
              >
                <option value="NORMAL">NORMAL</option>
                <option value="TRASLADO">TRASLADO</option>                
                <option value="INGRESO PARCIAL FACTURABLE">INGRESO PARCIAL FACTURABLE</option>              
                <option value="ERROR DE DIGITACION">ERROR DE DIGITACION</option>                
                <option value="PROCEDIMIENTO INTERNO">PROCEDIMIENTO INTERNO</option>   
                <option value="INGRESO PARCIAL NO FACTURABLE">INGRESO PARCIAL NO FACTURABLE</option>
                <option value="INVENTARIO CICLICO">INVENTARIO CICLICO</option> 
                <option value="AJUSTE IPC">AJUSTE IPC</option>
                <option value="RETIRO PARCIAL NO FACTURABLE">RETIRO PARCIAL NO FACTURABLE</option>                
                <option value="RETIRO TOTAL">RETIRO TOTAL</option>              
                <option value="SALDO INICIAL">SALDO INICIAL</option>
                <option value="RETIRO PARCIAL FACTURABLE">RETIRO PARCIAL FACTURABLE</option>
                <option value="VISITA CLIENTE">VISITA CLIENTE</option>
              </PSelect>
            </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <PSelect
                  id="movinvTipoDocumentoRemitente"
                  useChildren={true}
                  value={this.state.fields["movinvTipoDocumentoRemitente"]}
                  onChange={this.handleChange}
                  namelabel="Tipo Documento Remitente"
                >
                  <option value="PEDIDO">PEDIDO</option>
                  <option value="ORDEN DE COMPRA">ORDEN DE COMPRA</option>
                  <option value="REMISION">REMISION</option>
                  <option value="FACTURA">FACTURA</option>
                  <option value="REMESA">REMESA</option>
                  <option value="NOTA INTERNA">NOTA INTERNA</option>
                  <option value="SALDO INICIAL">SALDO INICIAL</option>
                </PSelect>
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="movinvDocumento" label="Documento" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["movinvDocumento"]}
                  InputLabelProps={{ shrink: true }}
                />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="movinvConductorEmpresa" label="Empresa Conductor" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["movinvConductorEmpresa"]}
                  InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="movinvTipoVehiculo"
                useChildren={true}
                value={this.state.fields["movinvTipoVehiculo"]}
                onChange={this.handleChange}
                namelabel="Tipo Vehiculo"
              >
                <option value="BODEHOGAR">BODEHOGAR</option>
                <option value="PARTICULAR">PARTICULAR</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="movinvPlaca" label="Placa" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["movinvPlaca"]}
                  InputLabelProps={{ shrink: true }}
                />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="movinvConductor" label="Conductor" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["movinvConductor"]}
                  InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="movinvConductorCedula" label="Conductor Cedula" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["movinvConductorCedula"]}
                  InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="movinvCodigoManual" label="Codigo Manual" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["movinvCodigoManual"]}
                  InputLabelProps={{ shrink: true }}
                />
            </Grid>
          </Grid> 

          <Grid  >
            <CustomizedExpansionPanel tittle="Datos de PAZ Y SALVO"></CustomizedExpansionPanel>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="movinvFechaPazSalvo"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["movinvFechaPazSalvo"] || undefined}
                type="date"
                label="Fecha Paz Y Salvo"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="movinvFechaFinContrato"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["movinvFechaFinContrato"] || undefined}
                type="date"
                label="Fecha Fin Contrato"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="movinvPeriodoFacturacion" label="Periodo Facturacion" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["movinvPeriodoFacturacion"] || undefined}
                  InputLabelProps={{ shrink: true }}
                  multiline
                  rows="5"
                  cols="100"
                />
              </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <PSelect
                  id="movinvEstado"
                  useChildren={true}
                  value={this.state.fields["movinvEstado"]}
                  onChange={this.handleChange}
                  namelabel="Estado: "
                >
                  <option value="TRANSITO">TRANSITO</option>
                  <option value="CONFIRMADO">CONFIRMADO</option>
                </PSelect>
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                    <TextField id="movinvPrecintoActual" label="Precinto Actual" type="text"
                      fullWidth margin="normal" variant="outlined"
                      onChange={this.handleChange}
                      value={this.state.fields["movinvPrecintoActual"]}
                      InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                    <TextField id="movinvPrecintoAnterior" label="Precinto Anterior" type="text"
                      fullWidth margin="normal" variant="outlined"
                      onChange={this.handleChange}
                      value={this.state.fields["movinvPrecintoAnterior"]}
                      InputLabelProps={{ shrink: true }}
                    />
                </Grid>

            </Grid> 

            <Grid container spacing={4}>

            <Grid item xs={3} className={this.props.classes.gridForm}>
                  <TextareaAutosize id="movinvObservacion" label="Observacion" type="text"
                    placeholder="Observacion"
                    onChange={this.handleChange}
                    value={this.state.fields["movinvObservacion"] || undefined}
                    rows="4"
                    cols="30"
                  />
                </Grid>
            </Grid>

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Usuario" value={Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Creacion" value={this.state.fields.createdAt} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Modificacion" value={this.state.fields.updatedAt} />
            </Grid>
          </Grid>

          <Grid>
            <CustomizedExpansionPanel tittle="ENTRADA DE PRODUCTOS +++">
              <Datagrid datosHeader={this.state.fields} dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridMovimientoInventarioEntrada} setComponente="movimientoInventarioEntrada" controlData={true} />
            </CustomizedExpansionPanel>
          </Grid>

          <CustomizedExpansionPanel tittle="SALIDA DE PRODUCTOS +++">
              <Datagrid datosHeader={this.state.fields} dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridMovimientoInventarioSalida} setComponente="movimientoInventarioSalida" controlData={true} />
          </CustomizedExpansionPanel>

          <CustomizedExpansionPanel tittle="Trazabilidad">
            <Datagrid dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridTrazabilidad} />
          </CustomizedExpansionPanel>

        </form>
        <div><Buttoncontrol
          saveData={this.state}
          saveDataValidation={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          validaData={this.validaData}
          messageValidation={this.messageValidation}
          edit={(this.state.fields.movinvCodigo !== null) ? true : false}
          primaryFields={['movinvCodigo']}
          url='/processes/movimiento_inventario_save'
          formValidations={this.state.formValidations}
          errorHandler={this.setErrors}
        /></div>
      </Paper >
    );
  }
}

MovimientoInventario.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(MovimientoInventario)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;