import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { getDocument } from '../../../../Actions/General';
import Buttoncontrol from "../../../GeneralUI/Buttoncontrol";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import { combineStyles } from '../../../../Libs/';
import PSelect from '../../../GeneralUI/PSelect';


const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: 'none',
  }
});

class InformeLiquidacionAuxiliar extends Component {

  state = {
    data: [],
    fields: {

      FECHA_INICIAL_SERVICIO: null,
      FECHA_FINAL_SERVICIO: null,
      TIPO_INFORME:null,
      SUCURSAL_CODIGO_TERCERO:null,
      COMERCIAL_CODIGO: null,
      SUCURSAL_CODIGO: null,
    },
    paramsInforme: '/imprimir_informe/pedido/informe_liquidacion_auxiliar?FECHA_INICIAL_SERVICIO=' + null + '&FECHA_FINAL_SERVICIO=' + null + '&COMERCIAL_CODIGO=' + null + '&SUCURSAL_CODIGO=' + null + '&SUCURSAL_CODIGO_TERCERO=' + null,
    responseForm: { Message: '', typeMessage: '' },
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'pedidoCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });

  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    let params = '';
    fields[event.target.id] = event.target.value;
    this.setState({ fields });

      if(this.state.fields.TIPO_INFORME==='INFORME_AGRUPADO'){
        params = '/imprimir_informe/pedido/informe_liquidacion_auxiliar_agrupado?FECHA_INICIAL_SERVICIO=' + this.state.fields.FECHA_INICIAL_SERVICIO + '&FECHA_FINAL_SERVICIO=' + this.state.fields.FECHA_FINAL_SERVICIO + '&COMERCIAL_CODIGO=' + this.state.fields.COMERCIAL_CODIGO + '&SUCURSAL_CODIGO=' + this.state.fields.SUCURSAL_CODIGO + '&SUCURSAL_CODIGO_TERCERO=' + this.state.fields.SUCURSAL_CODIGO_TERCERO;      
      }else{
        params = '/imprimir_informe/pedido/informe_liquidacion_auxiliar?FECHA_INICIAL_SERVICIO=' + this.state.fields.FECHA_INICIAL_SERVICIO + '&FECHA_FINAL_SERVICIO=' + this.state.fields.FECHA_FINAL_SERVICIO + '&COMERCIAL_CODIGO=' + this.state.fields.COMERCIAL_CODIGO + '&SUCURSAL_CODIGO=' + this.state.fields.SUCURSAL_CODIGO + '&SUCURSAL_CODIGO_TERCERO=' + this.state.fields.SUCURSAL_CODIGO_TERCERO;
      }   
      this.setState({ paramsInforme: params }); 
    
    
  }

  save = (response) => {
    let newState = {
      openSnackbar: true,
      responseForm: response.responseForm
    };

    if (newState.responseForm['typeMessage'] === 'success') {
      newState.data = response.data;
    }

    this.setState(newState);
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  render() {
    const { classes } = this.props;

    return (

      <Paper elevation={5} className={classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={classes.gridForm}>

            <InputLabel className={classes.tittleLabelForm}>
              Informe Liquidacion Auxiliar
            </InputLabel>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={classes.gridForm}>
              <PSelect
                  id="TIPO_INFORME"
                  useChildren={true}
                  value={this.state.fields["TIPO_INFORME"]}
                  onChange={this.handleChange}
                  namelabel="Tipo Informe"
                >
                <option value="INFORME_DETALLADO">INFORME DETALLADO</option>
                <option value="INFORME_AGRUPADO">INFORME AGRUPADO</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={classes.gridForm}>
              <PSelect
                namelabel="Sucursal Tercero"
                id="SUCURSAL_CODIGO_TERCERO"
                namelineInput="sucursal"
                fieldVisible="sucursalNombre"
                fieldCode="sucursalCodigo"
                Table="Sucursal"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["SUCURSAL_CODIGO_TERCERO"]}
              />
            </Grid>
          </Grid>


          <Grid container spacing={4}>
            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="FECHA_INICIAL_SERVICIO"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["FECHA_INICIAL_SERVICIO"]}
                type="date"
                label="FechaServicio Inicial"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="FECHA_FINAL_SERVICIO"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["FECHA_FINAL_SERVICIO"]}
                type="date"
                label="FechaServicio Final"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={classes.gridForm}>
              <AutoComplete
                id="COMERCIAL_CODIGO"
                tableName='Comercial'
                fields={['comercialCodigo', 'comercialNombreCompleto']}
                primaryField='comercialCodigo'
                aliases={['ID', 'Nombre']}
                searchField='comercialNombreCompleto'
                placeHolder='Comercial'
                onSelect={this.handleChange}
                value={this.state.fields["COMERCIAL_CODIGO"]}
              />
            </Grid>
            <Grid item xs={3} className={classes.gridForm}>
              <AutoComplete
                id="SUCURSAL_CODIGO"
                tableName='Sucursal'
                fields={['sucursalCodigo', 'sucursalNombre']}
                primaryField='sucursalCodigo'
                aliases={['ID', 'Nombre']}
                searchField='sucursalNombre'
                placeHolder='Sucursal'
                onSelect={this.handleChange}
                value={this.state.fields["SUCURSAL_CODIGO"]}
              />
            </Grid>
          </Grid>
        </form>


        <div id='buttonContainer'>
          <Buttoncontrol
            saveData={this.state.fields}
            moduleName='informe_liquidacion_auxiliar'
            paramsInforme={this.state.paramsInforme}
            save={this.save}
            primaryFields={['pedidoCodigo']}
            url='/imprimir_informe/pedido/informe_liquidacion_auxiliar'
            isFormLite={true}
          />
        </div>

      </Paper>
    );
  }

}



InformeLiquidacionAuxiliar.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(combineStyles(styles, Styles))(InformeLiquidacionAuxiliar)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;