import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Styles from './Styles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import { errorSetter } from '../../../../Libs';


class Detallepedido extends Component {

  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    isWaiting: false,
    favorites: [],

    formValidations: {
      view: ['Empaque', 'Producto', 'Cantidad', 'Peso', 'ValorDeclarado', 'Volumen']
    },

    formErrors: {
      Empaque: {},
      Producto: {},
      Cantidad: {},
      Peso: {},
      ValorDeclarado: {},
      Volumen: {}
    }
  };


  componentDidMount = () => {

    if (this.props.editData) {
      this.fillForm(this.props.editData, this.props.setIndex)
    }
  }

  fillForm = (data, index) => {

    let { fields } = this.state;

    fields["Empaque"] = ({ 'value': data[index].Empaque.value ? data[index].Empaque.value : null, 'key': data[index].Empaque.key });
    fields["Producto"] = ({ 'value': data[index].Producto.value ? data[index].Producto.value : null, 'key': data[index].Producto.key });
    fields["Cantidad"] = ({ 'value': data[index].Cantidad.value, 'key': data[index].Cantidad.key });
    fields["Peso"] = ({ 'value': data[index].Peso.value, 'key': data[index].Peso.key });
    fields["ValorDeclarado"] = ({ 'value': data[index].ValorDeclarado.value, 'key': data[index].ValorDeclarado.key });
    fields["Volumen"] = ({ 'value': data[index].Volumen.value, 'key': data[index].Volumen.key });

    this.setState({ fields });

  }

  handleChange = (event) => {
    let { fields } = this.state;
    fields[event.target.id] = ({ 'value': event.target.value, 'key': event.target.hasOwnProperty('text') ? event.target.text : event.target.value });
    this.setState({ fields });

    if (event.target.id === 'Tarifa') {
      this.getTarifa(fields[event.target.id]);
    }
  }

  getTarifa = (field) => {

    // let { fieldsNotData } = this.state;
    // let { fields } = this.state;

    /*saveCustom(field, '/processes/calculo_tarifa').then(data => {

      fieldsNotData["Descuento1"] = data.tarifaDescuento1;
      fieldsNotData["Descuento2"] = data.tarifaDescuento2;
      fieldsNotData["Descuento3"] = data.tarifaDescuento3;

      fields["Cantidad"] = ({ 'value': 1, 'key': 1 });
      fields["VUnitario"] = ({ 'value': data.tarifaValor, 'key': data.tarifaValor });
      fields["VTotal"] = ({ 'value': data.tarifaValor, 'key': data.tarifaValor });
      this.setState({ fields });
      this.setState({ fieldsNotData });

    }).catch(error => {
      this.setState({
        data: this.props.saveData,
        responseForm: { Message: "Error buscando tarifa." + error.errorMessage, typeMessage: "error" }
      })
    });*/

  }

  calculateTotal = (event) => {
    /*let { fields } = this.state;
    fields[event.target.id] = ({ 'value': event.target.value, 'key': event.target.hasOwnProperty('text') ? event.target.text : event.target.value });

    if (fields.Cantidad.value && fields.VUnitario.value) {
      const cantidad = parseFloat(fields.Cantidad.value);
      const valor = parseFloat(fields.VUnitario.value);
      const total = cantidad * valor;

      if (!isNaN(total) && total > 0) {
        fields['VTotal'] = { 'value': String(total), 'key': String(total) };
        this.setState({ fieldsCalculoTarifa: { Total: total } });
      }
    }
    this.setState({ fields });*/
  }


  setData = () => {
    const validations = errorSetter.validateFormFields(this.state.fields, this.state.formValidations);
    this.setState({ formErrors: validations.errors });
    if (validations.isValid) {
      this.props.setData(this.state.fields, this.props.editData ? this.props.setIndex : null);
    }
  }

  render = () => {
    return (
      <Paper elevation={1} className={this.props.classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>
          <Grid container direction='row' className={this.props.classes.paperGridTitle}>
            <List component="nav">
              <ListItem>
                <ListItemText primary="Detalle de Pedido" classes={{ primary: this.props.classes.titlelabelformwhite }} />
              </ListItem>
            </List>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="Empaque"
                tableName='Empaque'
                fields={['empaqueCodigo', 'empaqueNombre']}
                primaryField='empaqueCodigo'
                aliases={['ID', 'Nombre']}
                searchField='empaqueNombre'
                placeHolder='Seleccionar Empaque'
                onSelect={this.handleChange}
                value={this.state.fields["Empaque"].value}
                formErrors={this.state.formErrors['Empaque']}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="Producto"
                tableName='Producto'
                fields={['productoCodigo', 'productoNombre']}
                primaryField='productoCodigo'
                aliases={['ID', 'Nombre']}
                searchField='productoNombre'
                placeHolder='Seleccionar Producto'
                onSelect={this.handleChange}
                value={this.state.fields["Producto"].value}
                formErrors={this.state.formErrors['Producto']}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="Cantidad"
                label="Cantidad Servicio(*)"
                type="number"
                fullWidth margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Cantidad"].value}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['Cantidad'].error}
                helperText={this.state.formErrors['Cantidad'].errorMessage}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="Peso"
                label="Peso (*)"
                type="number"
                fullWidth margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Peso"].value}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['Peso'].error}
                helperText={this.state.formErrors['Peso'].errorMessage}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="ValorDeclarado"
                label="Valor Declarado (*)"
                type="number"
                fullWidth margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["ValorDeclarado"].value}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['ValorDeclarado'].error}
                helperText={this.state.formErrors['ValorDeclarado'].errorMessage}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField
                id="Volumen"
                label="Volumen (*)"
                type="number"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                InputLabelProps={{ shrink: true }}
                value={this.state.fields["Volumen"].value}
                error={this.state.formErrors['Volumen'].error}
                helperText={this.state.formErrors['Volumen'].errorMessage}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4} >
            <Grid item xs={3} className={this.props.classes.gridForm}>

              <Button
                color='primary'
                onClick={this.setData}
              >
                Adicionar
              </Button>

            </Grid>
          </Grid>


        </form>
      </Paper>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(Detallepedido);