import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Textfieldlabel from "../GeneralUI/Textfieldlabel";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import PSelect from '../GeneralUI/PSelect'
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import Datagrid from '../GeneralUI/PDatagrid'
import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument, saveCustom } from '../../Actions/General';
import Utils from '../../Libs/utils';
import { errorSetter } from '../../Libs';
import CustomizedExpansionPanel from '../GeneralUI/PExpansionpanels'
import FormControl from '@material-ui/core/FormControl';

class PedidoCumplido extends Component {

  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    
    dataDatagridPedidoCumplidoAuxiliares: [{
      Tercero: { 'value': null, 'key': null,'visible':true  },     
      tipoTercero: { 'value': null, 'key': null,'visible':true  },
      cargueNacional: { 'value': null, 'key': null,'visible':true  },

      descargueNacional: { 'value': null, 'key': null,'visible':true  },
      cargueUrbano: { 'value': null, 'key': null,'visible':true  },
      descargueUrbano: { 'value': null, 'key': null,'visible':true  },

      Empaque: { 'value': null, 'key': null,'visible':true  },
      movimientoEspeciales: { 'value': null, 'key': null,'visible':true  },
      movimientoBodega: { 'value': null, 'key': null,'visible':true  },

      CeroestresOrigen: { 'value': null, 'key': null,'visible':true  },
      CeroestresDestino: { 'value': null, 'key': null,'visible':true  },

      Huacal: { 'value': null, 'key': null,'visible':true  },
      ValorLiquidacion: { 'value': null, 'key': null,'visible':true  }
    }],   

    fieldsCopy: {},
    fields: {
      pedcumCodigo:null, 
      pedidoCodigo:null, 
      pedcumEstado:null, 
      pedcumTipoAfiliado:null,
      pedcumNumeroAuxiliaresDescargue:0,
      pedcumNumeroAuxiliares:0, 
      pedcumNumeroAuxiliaresEmpaque:0, 
      pedcumNumeroAuxiliaresMovimientoEspeciales:0, 
      pedcumNumeroAuxiliaresMovimientoBodega:0, 
      pedcumNumeroAuxiliaresCeroEstresDestino:0,
      pedcumNumeroAuxiliaresCeroEstres:0,

      pedcumFechaLlegadaCargue:null, 
      pedcumHoraLlegadaCargue:null,

      pedcumFechaInicioCargue:null, 
      pedcumHoraInicioCargue:null, 

      pedcumFechaFinCargue:null,       
      pedcumHoraFinCargue:null, 

      pedcumFechaLlegadaDescargue:null, 
      pedcumHoraLlegadaDescargue:null, 

      pedcumFechaInicioDescargue:null,
      pedcumHoraInicioDescargue:null, 

      pedcumFechaFinDescargue:null, 
      pedcumHoraFinDescargue:null,      
       
      pedcumObservacion:null,
      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime(),
    },
    responseForm: { Message: '', typeMessage: '' },

    formValidations: {
      view: ['pedcumNumeroAuxiliares', 'pedcumNumeroAuxiliaresDescargue','pedcumNumeroAuxiliaresEmpaque', 'pedcumNumeroAuxiliaresMovimientoEspeciales', 'pedcumNumeroAuxiliaresMovimientoBodega', 'pedcumNumeroAuxiliaresCeroEstresDestino', 'pedcumNumeroAuxiliaresCeroEstres']
    },

    formErrors: {
      pedcumNumeroAuxiliares: {},
      pedcumNumeroAuxiliaresDescargue: {},
      pedcumNumeroAuxiliaresEmpaque: {},
      pedcumNumeroAuxiliaresMovimientoBodega: {},
      pedcumNumeroAuxiliaresMovimientoEspeciales: {},
      pedcumNumeroAuxiliaresCeroEstresDestino:{},
      pedcumNumeroAuxiliaresCeroEstres:{}
    }

  };

  componentDidMount = () => {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }
    this.fillFormData(params.id);    
  }


  fillFormData = (id) => {
    const params = this.props.match.params;

    if (!id) {
      return;
    }

    getDocument({
      documentId: id,
      fieldName: 'pedcumCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }

      this.setState({ fields: document });
      
      getDocument({
        documentId: id,
        fieldName: 'pedcum_codigo',
        moduleName: 'vw_detalle_pedido_cumplido_interfaz',
        isView: true,
        fields: ['pedcumauxdet_codigo',
          'pedcum_codigo',
          'tercero_codigo',
          'tercero_nombre_completo',
          'pedcumauxdet_tipo_tercero',
          'pedcumauxdet_cargue_nacional',
          'pedcumauxdet_descargue_nacional',
          'pedcumauxdet_cargue_urbano',
          'pedcumauxdet_descargue_urbano',

          'pedcumauxdet_empaque',
          'pedcumauxdet_movimiento_especiales',
          'pedcumauxdet_movimiento_bodega',          

          'pedcumauxdet_ceroestres',
          'pedcumauxdet_ceroestres_destino',
          'pedcumauxdet_huacal',
          'pedcumauxdet_valor_liquidacion'
        ],
        Limit: 50
      }).then(document => {
        if (!document) {
          return;
        }
        this.buildDetailPedidoCumplidoAuxiliarInterface(document);
      }).catch(ex => {
        console.log('index.jsx -- 137 > ex === ', ex);
      });

    }).catch(ex => {
      console.log('index.jsx -- 140 > ex === ', ex);
    });
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {

    let { fields } = this.state;
    const caret = event.target.selectionStart;
    const element = event.target;
    if (element.hasOwnProperty('setSelectionRange')) {
      window.requestAnimationFrame(() => {
        element.setSelectionRange(caret, caret);
      });
    }

    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }

    if (fields[event.target.id] === '') {
      fields[event.target.id] = null;
    }
    this.setState({ fields });
  }

  handleDatagrid = (data) => {
    const detalle = data.setComponente;
    console.log(data);

    switch (detalle) {
      case 'pedidoCumplido':
        this.setState({ dataDatagridPedidoCumplidoAuxiliares: data });
        break;
      default:
        return null;
    }
  }

  buildDetailPedidoCumplidoAuxiliarInterface = (data) => {
    let rows = data.map(row => {
         
      return {
        Tercero: { 'value': row.tercero_codigo, 'key': row.tercero_nombre_completo, 'visible':true },
        tipoTercero: { 'value': row.pedcumauxdet_tipo_tercero, 'key': row.pedcumauxdet_tipo_tercero, 'visible':true },
        cargueNacional: { 'value': row.pedcumauxdet_cargue_nacional, 'key': row.pedcumauxdet_cargue_nacional, 'visible':true },        
        
        descargueNacional: { 'value': row.pedcumauxdet_descargue_nacional, 'key': row.pedcumauxdet_descargue_nacional, 'visible':true },        
        cargueUrbano: { 'value': row.pedcumauxdet_cargue_urbano, 'key': row.pedcumauxdet_cargue_urbano, 'visible':true },        
        descargueUrbano: { 'value': row.pedcumauxdet_descargue_urbano, 'key': row.pedcumauxdet_descargue_urbano, 'visible':true },   
        
        Empaque: { 'value': row.pedcumauxdet_empaque, 'key': row.pedcumauxdet_empaque, 'visible':true },        
        movimientoEspeciales: { 'value': row.pedcumauxdet_movimiento_especiales, 'key': row.pedcumauxdet_movimiento_especiales, 'visible':true },        
        movimientoBodega: { 'value': row.pedcumauxdet_movimiento_bodega, 'key': row.pedcumauxdet_movimiento_bodega, 'visible':true },   

        CeroestresOrigen: { 'value': row.pedcumauxdet_ceroestres, 'key': row.pedcumauxdet_ceroestres, 'visible':true },
        CeroestresDestino: { 'value': row.pedcumauxdet_ceroestres_destino, 'key': row.pedcumauxdet_ceroestres_destino, 'visible':true },

        Huacal: { 'value': row.pedcumauxdet_huacal, 'key': row.pedcumauxdet_huacal, 'visible':true },
        ValorLiquidacion: { 'value': row.pedcumauxdet_valor_liquidacion, 'key': row.pedcumauxdet_valor_liquidacion, 'visible':true }
      };
    });
    this.setState({ dataDatagridPedidoCumplidoAuxiliares: rows });
  }

  messageValidation = (type, message) => {
    this.message(type, message);
  }

  save = (response) => {

    let fields=response.data.fields?response.data.fields:response.data;

    this.setState({
      fields,
      responseForm: response.responseForm
    }
    );

    if(this.state.responseForm['typeMessage']==='success'){
      this.fillFormData(this.state.fields.pedcumCodigo); 
    }

    this.message(this.state.responseForm['typeMessage'], this.state.responseForm['Message']);
  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }

  validaData =  () => {
    let isvalid = true;
    let message = 'El registro no ha sido guardado.';
    let type = "warning";

    if(this.state.fields.pedcumEstado=='CUMPLIDO' && (
    (this.state.fields.pedcumFechaInicioCargue == null || !String(this.state.fields.pedcumFechaInicioCargue).trim()) ||
    (this.state.fields.pedcumHoraInicioCargue == null || !String(this.state.fields.pedcumHoraInicioCargue).trim()) ||
    
    (this.state.fields.pedcumFechaLlegadaCargue == null || !String(this.state.fields.pedcumFechaLlegadaCargue).trim()) ||
    (this.state.fields.pedcumHoraLlegadaCargue == null || !String(this.state.fields.pedcumHoraLlegadaCargue).trim()) ||
   
    (this.state.fields.pedcumFechaFinCargue == null || !String(this.state.fields.pedcumFechaFinCargue).trim()) ||
    (this.state.fields.pedcumHoraFinCargue == null || !String(this.state.fields.pedcumHoraFinCargue).trim()) ||
    
    (this.state.fields.pedcumFechaLlegadaDescargue == null || !String(this.state.fields.pedcumFechaLlegadaDescargue).trim()) ||
    (this.state.fields.pedcumHoraLlegadaDescargue == null || !String(this.state.fields.pedcumHoraLlegadaDescargue).trim()) ||
    
    (this.state.fields.pedcumFechaInicioDescargue == null || !String(this.state.fields.pedcumFechaInicioDescargue).trim()) ||
    (this.state.fields.pedcumHoraInicioDescargue == null || !String(this.state.fields.pedcumHoraInicioDescargue).trim()) ||

    (this.state.fields.pedcumFechaFinDescargue == null || !String(this.state.fields.pedcumFechaFinDescargue).trim()) ||
    (this.state.fields.pedcumHoraFinDescargue == null || !String(this.state.fields.pedcumHoraFinDescargue).trim()))
    && this.state.fields.pedcumTransporte=='SI' 
    )
    {
      message = "Los Campos de CARGUE Y DESCARGUE SON OBLIGATORIOS.";
      isvalid = false;
    }

    if (!isvalid) {
      this.message(type, message);
    }
    return isvalid;
  }

  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>

            <InputLabel id="pedcumCodigo"
              className={this.props.classes.tittleLabelForm}>Pedido Cumplido
            </InputLabel>

            <Grid item xs={3} className={this.props.classes.chipTitle}>
              {(this.state.fields.pedcumCodigo !== null) ?
                (<Chip
                  label={this.state.fields["pedcumCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>

          <Grid  >
            <CustomizedExpansionPanel tittle="Datos Generales Del Cumplido"></CustomizedExpansionPanel>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoCodigo" label="Pedido" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoCodigo"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="pedcumEstado"
                useChildren={true}
                value={this.state.fields["pedcumEstado"]}
                onChange={this.handleChange}
                namelabel="Estado"
              >
                <option value="PENDIENTE">PENDIENTE</option>
                <option value="CUMPLIDO">CUMPLIDO</option>
              </PSelect>
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="pedcumTipoAfiliado"
                useChildren={true}
                value={this.state.fields["pedcumTipoAfiliado"]}
                onChange={this.handleChange}
                disabled
                namelabel="Tipo Afiliado"
              >
                <option value="INTERNO">INTERNO</option>
                <option value="EXTERNO">EXTERNO</option>
              </PSelect>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedcumNumeroAuxiliares" label="Numero Auxiliares Origen" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedcumNumeroAuxiliares"] || undefined}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['pedcumNumeroAuxiliares'].error}
                helperText={this.state.formErrors['pedcumNumeroAuxiliares'].errorMessage}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedcumNumeroAuxiliaresDescargue" label="Numero Auxiliares Destino" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedcumNumeroAuxiliaresDescargue"] || undefined}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['pedcumNumeroAuxiliaresDescargue'].error}
                helperText={this.state.formErrors['pedcumNumeroAuxiliaresDescargue'].errorMessage}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedcumNumeroAuxiliaresEmpaque" label="Numero Auxiliares Empaque" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedcumNumeroAuxiliaresEmpaque"] || undefined}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['pedcumNumeroAuxiliaresEmpaque'].error}
                helperText={this.state.formErrors['pedcumNumeroAuxiliaresEmpaque'].errorMessage}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedcumNumeroAuxiliaresCeroEstres" label="Cantidad Auxiliares 0 Stress Origen" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedcumNumeroAuxiliaresCeroEstres"] || undefined}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['pedcumNumeroAuxiliaresCeroEstres'].error}
                helperText={this.state.formErrors['pedcumNumeroAuxiliaresCeroEstres'].errorMessage}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedcumNumeroAuxiliaresCeroEstresDestino" label="Cantidad Auxiliares 0 Stress Destino" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedcumNumeroAuxiliaresCeroEstresDestino"] || undefined}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['pedcumNumeroAuxiliaresCeroEstresDestino'].error}
                helperText={this.state.formErrors['pedcumNumeroAuxiliaresCeroEstresDestino'].errorMessage}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedcumNumeroAuxiliaresMovimientoEspeciales" label="Numero Auxiliares Movimiento Especiales" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedcumNumeroAuxiliaresMovimientoEspeciales"] || undefined}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['pedcumNumeroAuxiliaresMovimientoEspeciales'].error}
                helperText={this.state.formErrors['pedcumNumeroAuxiliaresMovimientoEspeciales'].errorMessage}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedcumNumeroAuxiliaresMovimientoBodega" label="Numero Auxiliares Movimiento Bodega" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedcumNumeroAuxiliaresMovimientoBodega"] || undefined}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['pedcumNumeroAuxiliaresMovimientoBodega'].error}
                helperText={this.state.formErrors['pedcumNumeroAuxiliaresMovimientoBodega'].errorMessage}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <InputLabel className={this.props.classes.tittleLabelObservacion}>
                Observacion Cumplido              
              </InputLabel>
              <FormControl variant="outlined" fullWidth >
                <TextareaAutosize id="pedcumObservacion" label="Observacion Cumplido" type="text"
                   title="Observacion Cumplido"
                  onChange={this.handleChange}
                  value={this.state.fields["pedcumObservacion"] || undefined}
                  rows="8"
                  cols="50"
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid  >
            <CustomizedExpansionPanel tittle="Datos De CARGUE del Cumplido"></CustomizedExpansionPanel>
          </Grid>     

          <Grid container spacing={4}>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <TextField id="pedcumFechaLlegadaCargue"
                    fullWidth margin="normal" variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.fields["pedcumFechaLlegadaCargue"] || undefined}
                    type="date"
                    label="Fecha Llegada Cargue"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <TextField id="pedcumHoraLlegadaCargue"
                    fullWidth margin="normal" variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.fields["pedcumHoraLlegadaCargue"] || undefined}
                    type="time"
                    label="Hora Llegada Cargue"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid> 
          </Grid> 

          <Grid container spacing={4}>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <TextField id="pedcumFechaInicioCargue"
                    fullWidth margin="normal" variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.fields["pedcumFechaInicioCargue"] || undefined}
                    type="date"
                    label="Fecha Inicio Cargue"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <TextField id="pedcumHoraInicioCargue"
                    fullWidth margin="normal" variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.fields["pedcumHoraInicioCargue"] || undefined}
                    type="time"
                    label="Hora Inicio Cargue"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid> 
          </Grid> 


          <Grid container spacing={4}>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <TextField id="pedcumFechaFinCargue"
                    fullWidth margin="normal" variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.fields["pedcumFechaFinCargue"] || undefined}
                    type="date"
                    label="Fecha Fin Cargue"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <TextField id="pedcumHoraFinCargue"
                    fullWidth margin="normal" variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.fields["pedcumHoraFinCargue"] || undefined}
                    type="time"
                    label="Hora Fin Cargue"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid> 
          </Grid> 

          <Grid  >
            <CustomizedExpansionPanel tittle="Datos De DESCARGUE del Cumplido"></CustomizedExpansionPanel>
          </Grid> 

          <Grid container spacing={4}>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <TextField id="pedcumFechaLlegadaDescargue"
                    fullWidth margin="normal" variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.fields["pedcumFechaLlegadaDescargue"] || undefined}
                    type="date"
                    label="Fecha Llegada Descargue"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <TextField id="pedcumHoraLlegadaDescargue"
                    fullWidth margin="normal" variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.fields["pedcumHoraLlegadaDescargue"] || undefined}
                    type="time"
                    label="Hora Llegada Descargue"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid> 
          </Grid> 

          <Grid container spacing={4}>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <TextField id="pedcumFechaInicioDescargue"
                    fullWidth margin="normal" variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.fields["pedcumFechaInicioDescargue"] || undefined}
                    type="date"
                    label="Fecha Inicio Descargue"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <TextField id="pedcumHoraInicioDescargue"
                    fullWidth margin="normal" variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.fields["pedcumHoraInicioDescargue"] || undefined}
                    type="time"
                    label="Hora Inicio Descargue"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid> 
          </Grid>      

          <Grid container spacing={4}>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <TextField id="pedcumFechaFinDescargue"
                    fullWidth margin="normal" variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.fields["pedcumFechaFinDescargue"] || undefined}
                    type="date"
                    label="Fecha Fecha Fin Descargue"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <TextField id="pedcumHoraFinDescargue"
                    fullWidth margin="normal" variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.fields["pedcumHoraFinDescargue"] || undefined}
                    type="time"
                    label="Hora Fin Descargue"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid> 
          </Grid> 

          <Grid container spacing={4} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Usuario" value={Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Creacion" value={this.state.fields.createdAt} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Modificacion" value={this.state.fields.updatedAt} />
            </Grid>
          </Grid>

          <Grid>
            <CustomizedExpansionPanel tittle="Detalle de Auxiliares +++">
              <Datagrid datosHeader={this.state.fields} dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridPedidoCumplidoAuxiliares} setComponente="pedidoCumplido" />
            </CustomizedExpansionPanel>
          </Grid>

        </form>
        <div><Buttoncontrol
          saveData={this.state}
          saveDataValidation={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          validaData={this.validaData}
          messageValidation={this.messageValidation}
          edit={(this.state.fields.pedcumCodigo !== null) ? true : false}
          primaryFields={['pedcumCodigo']}
          url='/processes/pedido_cumplido_save'
          formValidations={this.state.formValidations}
          errorHandler={this.setErrors}
        /></div>
      </Paper >
    );
  }
}

PedidoCumplido.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};


const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(PedidoCumplido)));

function IntegrationNotistack() {
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }
  return (

    <SnackbarProvider maxSnack={1} autoHideDuration={360000}
      ref={notistackRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      action={(key) => (
        <Button variant="text" color="primary" size="small" onClick={onClickDismiss(key)}>
          {'X'}
        </Button>
      )}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;