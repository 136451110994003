import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Styles from './Styles';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import { errorSetter } from '../../../../Libs';


class DetalleListaempaque extends Component {

  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    errorForm: false,
    errorMessage: '',
    fields: {
      tarifaCodigo: { 'value': null, 'key': null ,'visible':false},
      tarifaNombre: { 'value': null, 'key': null,'visible':false },
      productoCodigo: { 'value': null, 'key': null,'visible':true },
      unidadMedida: { 'value': null, 'key': null,'visible':true },
      Factor: { 'value': null, 'key': null,'visible':false },
      Cantidad: { 'value': null, 'key': null,'visible':true },
      Devolucion: { 'value': null, 'key': null,'visible':true },

      TotalDevolucion: { 'value': null, 'key': null,'visible':true },
      TotalDevolucionHidden: { 'value': null, 'key': null,'visible':false },

      Adicional: { 'value': null, 'key': null,'visible':true },

      TotalAdicional: { 'value': null, 'key': null,'visible':true },
      TotalAdicionalHidden: { 'value': null, 'key': null,'visible':false },

      valorUnitario: { 'value': null, 'key': null,'visible':false },
      Real: { 'value': null, 'key': null,'visible':true },
    },
    formValidations: {
      view: ['tarifaCodigo', 'productoCodigo', 'tarifaNombre', 'unidadMedida', 'Factor', 'Cantidad','Devolucion','Adicional']
    },
    formErrors: {
      tarifaCodigo: {},
      tarifaNombre: {},
      productoCodigo: {},
      unidadMedida: {},
      Factor: {},
      Cantidad: {},
      Devolucion: {},
      Adicional: {},
    }
  };


  componentDidMount = () => {
    console.log(this.props.editData);
    if (this.props.editData) {
      this.fillForm(this.props.editData, this.props.setIndex)
    }
    if (this.props.datosHeader) {
      this.setState(this.props.datosHeader);
    }
  }

  fillForm = (data, index) => {
    let valorReal,{ fields } = this.state;

    valorReal=(data[index].Cantidad.value + ((parseFloat(data[index].TotalAdicional.value) + parseFloat(data[index].Adicional.value)) - (parseFloat(data[index].TotalDevolucion.value) + parseFloat(data[index].Devolucion.value))));

    fields["tarifaCodigo"] = ({ 'value': data[index].tarifaCodigo.value, 'key': data[index].tarifaCodigo.key,'visible':false });
    fields["tarifaNombre"] = ({ 'value': data[index].tarifaNombre.value, 'key': data[index].tarifaNombre.key,'visible':false });
    fields["productoCodigo"] = ({ 'value': data[index].productoCodigo.value ? data[index].productoCodigo.value : null, 'key': data[index].productoCodigo.key ,'visible':true});
    fields["unidadMedida"] = ({ 'value': data[index].unidadMedida.value, 'key': data[index].unidadMedida.key,'visible':true });
    fields["Factor"] = ({ 'value': data[index].Factor.value, 'key': data[index].Factor.key,'visible':false });
    fields["Cantidad"] = ({ 'value': data[index].Cantidad.value, 'key': data[index].Cantidad.key,'visible':true });
    fields["Devolucion"] = ({ 'value': data[index].Devolucion.value, 'key': data[index].Devolucion.key,'visible':true });
    fields["TotalDevolucion"] = ({ 'value': data[index].TotalDevolucion.value, 'key': data[index].TotalDevolucion.key,'visible':true });
   
    fields["TotalDevolucionHidden"] = ({ 'value': data[index].TotalDevolucionHidden.value, 'key': data[index].TotalDevolucionHidden.key,'visible':false });

   
    fields["Adicional"] = ({ 'value': data[index].Adicional.value, 'key': data[index].Adicional.key,'visible':true });
    fields["TotalAdicional"] = ({ 'value': data[index].TotalAdicional.value, 'key': data[index].TotalAdicional.key,'visible':true });
    
    fields["TotalAdicionalHidden"] = ({ 'value': data[index].TotalAdicionalHidden.value, 'key': data[index].TotalAdicionalHidden.key,'visible':false });

    fields["valorUnitario"] = ({ 'value': data[index].valorUnitario.value, 'key': data[index].valorUnitario.key,'visible':false });
    fields["Real"] = ({ 'value': valorReal, 'key': parseFloat(valorReal).toLocaleString(),'visible':true });

 
    this.setState({ fields });
  }

  handleChange = (event) => {
    let { fields } = this.state, totaladicional=0, totaldevolucion=0;

    fields[event.target.id].value =event.target.value;
    fields[event.target.id].key =event.target.hasOwnProperty('text') ? event.target.text : event.target.value;    
    
    
    if (event.target.id === 'Adicional') {
      totaladicional=parseFloat(parseFloat(fields["TotalAdicional"].value)+parseFloat(event.target.value));
      fields["TotalAdicional"]={ 'value': totaladicional, 'key': totaladicional,'visible':true};
    }

    if (event.target.id === 'Devolucion') {
      totaldevolucion=parseFloat(fields["TotalDevolucion"].value)+parseFloat(event.target.value);
      fields["TotalDevolucion"]={ 'value': totaldevolucion, 'key': totaldevolucion,'visible':true};
    }
    
    this.setState({ fields });

  }

 
  setDataValidation = (errorForm, errorMessage) => {
    this.setState({ errorForm: errorForm, errorMessage: errorMessage });
  }

  setData = async () => {
    let { fields } = this.state, valorReal=0; 
    const validations = errorSetter.validateFormFields(this.state.fields, this.state.formValidations);
    valorReal = (parseFloat(this.state.fields.Cantidad.value)+parseFloat(this.state.fields.TotalAdicional.value))-parseFloat(this.state.fields.TotalDevolucion.value);

    fields["Real"] = ({ 'value': valorReal, 'key': parseFloat(valorReal).toLocaleString(),'visible':true  });
    this.setState({ formErrors: validations.errors,fields });


   // if (validations.isValid && !this.state.errorForm) {
      this.props.setData(this.state.fields, this.props.editData ? this.props.setIndex : null);
    //}
  }

  render = () => {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>
          <Grid container direction='row' className={this.props.classes.paperGridTitle}>
            <List component="nav">
              <ListItem>
                <ListItemText primary="Detalle de Productos" classes={{ primary: this.props.classes.titlelabelformwhite }} />
              </ListItem>
              {(this.state.errorForm) ?
                <ListItem>
                  <ListItemText primary={this.state.errorMessage} classes={{ primary: this.props.classes.titlelabelerrorform }} />
                </ListItem> : ''
              }
            </List>
          </Grid>

            <Grid container spacing={2}>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <AutoComplete
                    id="productoCodigo"
                    tableName='Producto'
                    fields={['productoCodigo', 'productoNombre']}
                    aliases={[{ value: 'ID', size: 2 }, { value: 'Nombre', size: 10 }]}
                    size='xxl'
                    primaryField='productoCodigo' 
                    searchField='productoNombre'
                    placeHolder='Seleccionar Producto'
                    value={this.state.fields["productoCodigo"].value}
                    error={this.state.formErrors['productoCodigo'].error}
                    helperText={this.state.formErrors['productoCodigo'].errorMessage}
                  />
                </Grid>
                <Grid item xs={3} className={this.props.classes.gridForm}>
                  <TextField id="unidadMedida" label="unidadMedida" type="text"
                    fullWidth margin="normal" variant="outlined"
                    value={this.state.fields["unidadMedida"].value}
                    InputLabelProps={{ shrink: true }}
                    error={this.state.formErrors['unidadMedida'].error}
                    helperText={this.state.formErrors['unidadMedida'].errorMessage}
                  />
                </Grid>        
                <Grid item xs={3} className={this.props.classes.gridForm}>
                    <TextField id="Cantidad" label="Cantidad" type="text"
                      fullWidth margin="normal" variant="outlined"
                      onChange={this.handleChange}
                      value={this.state.fields["Cantidad"].value}
                      InputLabelProps={{ shrink: true }}
                      error={this.state.formErrors['Cantidad'].error}
                      helperText={this.state.formErrors['Cantidad'].errorMessage}
                    />
              </Grid>  
          </Grid>

          <Grid container spacing={2}>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Devolucion" label="Devolucion" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Devolucion"].value}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['Devolucion'].error}
                helperText={this.state.formErrors['Devolucion'].errorMessage}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Adicional" label="Adicional" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Adicional"].value}
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['Adicional'].error}
                helperText={this.state.formErrors['Adicional'].errorMessage}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} >
            <Grid item xs={3} className={this.props.classes.gridForm}>

              <Button
                color='primary'
                onClick={this.setData}
              >
                Adicionar
              </Button>

            </Grid>
          </Grid>


        </form>
      </Paper>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(DetalleListaempaque);