import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { Search } from '@material-ui/icons/';
import Grid from '@material-ui/core/Grid';
import Loaderdetalle from '../Bodymodals/Loaderdetalle/';
import Modal from '../Dialog'
import DeleteIcon from '@material-ui/icons/Delete';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 700,
  },
  gridIcon: {
    flexBasis: '0%',
    padding: '0px',
  },
  gridIconMain: {
    width: '2em !important'
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

class CustomizedTable extends Component {

  state = {
    rows: [{}]
  }

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.modalDetailsRef = React.createRef();
    this.modalDetailEditRef = React.createRef();
  }


  fillData = (rows) => {
    if (rows) {
      this.fillDataVisible(rows);
    }
  }

  fillDataVisible = (rows) => {
    this.setState({ rows });
  }


  handleData = (data, index) => {


    if (index !== null) {
      let rows = this.state.rows;
      rows[index] = data;
      this.setState({ rows });
    } else {
      this.state.rows.push(data);
    }

    this.renderDatagrid(this.state.rows);

    if (this.modalRef && this.modalRef.handleClose) {
      this.modalRef.handleClose();
    }

    if (this.modalDetailsRef && this.modalDetailsRef.handleClose) {
      this.modalDetailsRef.handleClose();
    }


    if (this.modalDetailEditRef && this.modalDetailEditRef.handleClose) {
      this.modalDetailEditRef.handleClose();
    }


  }

  deleteItem = (id) => {
    let rows = [];
    this.state.rows.forEach(function (valor, indice, array) {
      if (indice !== id) {
        rows.push(array[indice]);
      }
    });
    this.setState({ rows });
  }

  componentDidMount = () => {
    this.fillData(this.props.datos);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if ((nextState.rows.length !== this.state.rows.length)) {
      this.renderDatagrid(nextState.rows);     
    }
    return true;
  }

  componentWillReceiveProps(props, nextState, ref) {    
    this.fillDataVisible(props.datos);
  }

  renderDatagrid = (data) => {
    data.setComponente = this.props.setComponente;
    this.props.dataDatagrid(data);
  }

  closeModal() { }

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {this.props.setComponente ?
                <CustomTableCell component="th" scope="coll">
                  <Grid container spacing={4}>
                    <Grid className={classes.gridIcon} item xs="auto" >
                      <div className={classes.gridIconMain}>
                        <div id="grid_modal">

                          <Modal
                            innerRef={ref => this.modalRef = ref}
                            sizeIcon={20}
                            colorIcon="#2196f3"
                            icon="Configuration"
                          >
                            <Loaderdetalle
                              setData={this.handleData}
                              datosHeader={this.props.datosHeader}
                              setIndex={null}
                              setComponente={this.props.setComponente ? this.props.setComponente : null}
                            />
                          </Modal>

                        </div>
                      </div>
                    </Grid>

                    <Grid item xs="auto" >

                    </Grid>

                  </Grid>
                </CustomTableCell>
                : null}
              {                
                Object.keys(this.state.rows && this.state.rows.length ? this.state.rows[0] : []).map( 

                  (row, index, array) => {
                   
                   if((this.state.rows[0][array[index]]).hasOwnProperty('visible')){
                      if(this.state.rows[0][array[index]].visible){
                          return (<CustomTableCell component="th" scope="coll" key={index} >{row}</CustomTableCell>)
                      }else{
                        return (null)
                      }
                  }else{
                      return (null)
                  }
                                    
                }, this)
              }

            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.state.rows.map((row, index, array) => {
                return (
                  <TableRow className={classes.row} key={index}>
                    {this.props.setComponente ?
                      <CustomTableCell component="th" scope="coll">

                        <Grid container spacing={0} >
                          <Grid className={classes.gridIcon} item >
                            <Modal
                              innerRef={ref => this.modalDetailsRef = ref}
                              sizeIcon={20}
                              colorIcon="#2196f3"
                              icon="Configuration" 
                            >
                              <Loaderdetalle
                                setData={this.handleData}
                                datosHeader={this.props.datosHeader}
                                setComponente={this.props.setComponente ? this.props.setComponente : null} />
                            </Modal>
                          </Grid>
                          <Grid className={classes.gridIcon} item >
                            <Modal
                              innerRef={ref => this.modalDetailEditRef = ref}
                              sizeIcon={20}
                              colorIcon="#2196f3"
                              icon="Edit"
                            >
                              <Loaderdetalle
                                setData={this.handleData}
                                editData={this.props.datos}
                                datosHeader={this.props.datosHeader}
                                setIndex={index}
                                setComponente={this.props.setComponente ? this.props.setComponente : null} />
                            </Modal>
                          </Grid>

                          <Grid className={classes.gridIcon} item >
                            <DeleteIcon color="error" fontSize="small" onClick={() => this.deleteItem(index)} />
                          </Grid>
                          <Grid className={classes.gridIcon} item >
                            <Search />
                          </Grid>
                        </Grid>
                      </CustomTableCell>
                      : null}
                    {

                      Object.keys(this.state.rows[index]).map((field, idx) => {
                        return (
                          (array[index][field]).hasOwnProperty('link') ?
                            <CustomTableCell component="th" scope="row">
                              <Link href={(array[index][field]).href} target='_blank' rel="noopener">
                                {(array[index][field]).key}
                              </Link>
                            </CustomTableCell>
                            :
                            (array[index][field]).hasOwnProperty('visible') ?
                               (array[index][field].visible)?
                                <CustomTableCell component="th" scope="row" key={idx}>
                                  {(array[index][field]).key}
                                </CustomTableCell>
                              :null:null
                        );
                      })
                    }
                  </TableRow>)
              })}
          </TableBody>
        </Table>
      </Paper >
    );
  }
}

CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTable);