import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Styles from './Styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { errorSetter } from '../../../../Libs';


class Detallefacturaotrosconceptos extends Component {

  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    errorForm: false,
    errorMessage: '',
    fields: {
      sucursalCodigo: { 'value': null, 'key': null,'visible':true },
      tipoTarifa: { 'value': null, 'key': null,'visible':true },
      descripcion: { 'value': null, 'key': null,'visible':true },
      valorTotal: { 'value': null, 'key': null,'visible':true }
    },
    formValidations: {
      view: ['sucursalCodigo', 'tipoTarifa', 'descripcion', 'valorTotal']
    },
    formErrors: {
      sucursalCodigo: {},
      tipoTarifa: {},
      descripcion: {},
      valorTotal: {}
    }
  };


  componentDidMount = () => {
    if (this.props.editData) {
      this.fillFormData(this.props.editData, this.props.setIndex)
    }
    if (this.props.datosHeader) {
      this.setState(this.props.datosHeader);
    }
    console.log(this.props.datosHeader);
  }

  fillFormData = (data, index) => {

    let { fields } = this.state;

    fields["sucursalCodigo"] = ({ 'value': data[index].sucursalCodigo.value, 'key': data[index].sucursalCodigo.key,'visible':true });
    fields["tipoTarifa"] = ({ 'value': data[index].tipoTarifa.value, 'key': data[index].tipoTarifa.key,'visible':true });
    fields["descripcion"] = ({ 'value': data[index].descripcion.value, 'key': data[index].descripcion.key,'visible':true });
    fields["valorTotal"] = ({ 'value': data[index].valorTotal.value, 'key': data[index].valorTotal.key,'visible':true });

    this.setState({ fields });

  }

  handleChange = (event) => {
    let { fields } = this.state; this.setState({ fields });
    fields[event.target.id].value= event.target.value;
    fields[event.target.id].key= event.target.hasOwnProperty('text') ? event.target.text : event.target.value
    this.setState({ fields });
  }

  validateLogicaForm = async () => {
  }

  setDataValidation = (errorForm, errorMessage) => {
    this.setState({ errorForm: errorForm, errorMessage: errorMessage });
  }

  setData = async () => {
    const validations = errorSetter.validateFormFields(this.state.fields, this.state.formValidations);
    this.setState({ formErrors: validations.errors });
    await this.validateLogicaForm();
    if (validations.isValid && !this.state.errorForm) {
      this.props.setData(this.state.fields, this.props.editData ? this.props.setIndex : null);
    }
  }

  render = () => {
    return (
      <Paper elevation={1} className={this.props.classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>
          <Grid container direction='row' className={this.props.classes.paperGridTitle}>
            <List component="nav">
              <ListItem>
                <ListItemText primary="Servicios A Facturar" classes={{ primary: this.props.classes.titlelabelformwhite }} />
              </ListItem>
              {(this.state.errorForm) ?
                <ListItem>
                  <ListItemText primary={this.state.errorMessage} classes={{ primary: this.props.classes.titlelabelerrorform }} />
                </ListItem> : ''
              }
            </List>
          </Grid>

        </form>
      </Paper>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(Detallefacturaotrosconceptos);