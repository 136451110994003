import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { getDocument } from '../../../../Actions/General';
import Buttoncontrol from "../../../GeneralUI/Buttoncontrol";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import { combineStyles } from '../../../../Libs/';

const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: 'none',
  }
});

class InformeGeneralClientes extends Component {

  state = {
    data: [],
    fields: {
    },
    paramsInforme: '/imprimir_informe/cliente/informe_general_clientes',
    responseForm: { Message: '', typeMessage: '' },
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'clienteCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });

  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    let params = '';
    fields[event.target.id] = event.target.value;
    this.setState({ fields });

    params = '/imprimir_informe/cliente/informe_general_clientes';
    this.setState({ paramsInforme: params });
  }

  save = (response) => {
    let newState = {
      openSnackbar: true,
      responseForm: response.responseForm
    };

    if (newState.responseForm['typeMessage'] === 'success') {
      newState.data = response.data;
    }

    this.setState(newState);
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  render() {
    const { classes } = this.props;

    return (

      <Paper elevation={5} className={classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={classes.gridForm}>

            <InputLabel className={classes.tittleLabelForm}>
            Informe General Clientes
            </InputLabel>
          </Grid>

        </form>


        <div id='buttonContainer'>
          <Buttoncontrol
            saveData={this.state.fields}
            moduleName='informe_general_clientes'
            paramsInforme={this.state.paramsInforme}
            save={this.save}
            primaryFields={['clienteCodigo']}
            url='/imprimir_informe/cliente/informe_general_clientes'
            isFormLite={true}
          />
        </div>

      </Paper>
    );
  }

}



InformeGeneralClientes.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(combineStyles(styles, Styles))(InformeGeneralClientes)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;