import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { search } from '../../../../Actions/General';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter } from '../../../../Libs/';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import DataGridVisor from '../../../GeneralUI/DataGridVisor';
import to from 'await-to-js';
import PSelect from '../../../GeneralUI/PSelect';
import _ from 'lodash';
import CustomizedExpansionPanel from './../../../GeneralUI/PExpansionpanels'


const VISOR_FIELDS = [{
  name: 'pedidoCodigo',
  alias: 'Codigo',
  isPrimary: true,
  processUrl: '/home/formdespacho/',
  processTitle: 'Despachar',
  processWhen: {
    pedidoEstado: 'CONFIRMADO'
  }
}, {
  name: 'usuarioNombreUsuario',
  alias: 'Usuario'
}, {
  name: 'pedidoFechaServicio',
  alias: 'Fecha servicio'
}, {
  name: 'pedidoHoraServicio',
  alias: 'Hora servicio'
}, {
  name: 'pedidoRazonSocial',
  alias: 'Cliente'
}, {
  name: 'pedidoContactoTelefono',
  alias: 'ClienteTelefono'
},
{
  name: 'tipserNombre',
  alias: 'Tipo Servicio'
},
{
  name: 'origen',
  alias: 'Origen'
}, {
  name: 'destino',
  alias: 'Destino'
},
{
  name: 'pedidoCubicaje',
  alias: 'M3'
}, {
  name: 'pedidoPesoCaminadoCargue',
  alias: 'MtsC'
}, {
  name: 'pedidoPesoCaminadoDescargue',
  alias: 'MtsD'
}, {
  name: 'pedidoVisita',
  alias: 'Visita'
}, {
  name: 'comercialNombreCompleto',
  alias: 'Comercial'
}, {
  name: 'pedidoValorPrincipal',
  alias: 'Transporte'
}, {
  name: 'pedidoTotal',
  alias: 'Total'
}, {
  name: 'pedidoObservacion',
  alias: 'Observacion',
  tooltip: true
}, {
  name: 'pedidoEstado',
  alias: 'Estado',
  colorize: true
}];

const VISOR_COLORS = {
  CONFIRMADO: '#92C47D',
  PENDIENTE: '#FE9900',
  DESPACHADO: '#FFFF00',
  CERRADO: '#999999',
  ANULADO: '#FE0000'
}

class Visor extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    page: 0,
    rowsPerPage: 5,
    data: {
      rows: [],
      count: 0
    },
    filters: {
      pedidoCodigo: null,
      pedidoRazonSocial: null,
      pedidoContactoTelefono: null,
      pedidoEstado: null,
      clienteCodigo: null,
      tipserCodigo: null,
      tiprefCodigo: null,
      sucursalCodigo: null,
      pedidoFechaServicioInicial: null,
      pedidoFechaServicioFinal: null,
      createdAtInicial: null,
      createdAtFinal: null,
    },
    operatorCustom: [],
    responseForm: { Message: '', typeMessage: '' },
    formErrors: {
    }
  };

  componentDidMount() {
    this.props.toggleFavorites();
  }

  componentWillUnmount() {
    this.props.toogleFavoritesShow();
  }

  search = async (page, rowsPerPage) => {
    if (page !== undefined) {
      await to(new Promise((resolve) => {
        this.setState({
          page,
          rowsPerPage
        }, resolve)
      }));
    }

    this.state.operatorCustom = [];
    let filters = {};

    if (this.state.filters.pedidoFechaServicioInicial !== null && this.state.filters.pedidoFechaServicioInicial !== ''
      && this.state.filters.pedidoFechaServicioFinal !== null && this.state.filters.pedidoFechaServicioFinal !== '') {
      this.state.operatorCustom.push("\"pedidoFechaServicio\" between '" + this.state.filters.pedidoFechaServicioInicial + "' and '" + this.state.filters.pedidoFechaServicioFinal + "'");
    }

    if (this.state.filters.createdAtInicial !== null && this.state.filters.createdAtInicial !== ''
      && this.state.filters.createdAtFinal !== null && this.state.filters.createdAtFinal !== '') {
      this.state.operatorCustom.push("\"createdAt\" between '" + this.state.filters.createdAtInicial + "' and '" + this.state.filters.createdAtFinal + "'");
    }

    _.each(_.keys(this.state.filters), (key) => {
      const value = this.state.filters[key];
      if (value !== null && value !== ''
        && key !== 'pedidoFechaServicioInicial'
        && key !== 'pedidoFechaServicioFinal'
        && key !== 'createdAtInicial'
        && key !== 'createdAtFinal') {
        filters[key] = value;
      }
    });


    const [err, data] = await to(search({
      fields: VISOR_FIELDS.map(row => row.name),
      filters: filters,
      operatorCustom: this.state.operatorCustom,
      isView: true,
      options: {
        limit: this.state.rowsPerPage,
        skip: this.state.page * this.state.rowsPerPage,
        order: { createdAt: 'DESC' }
      }
    }, '/modules/vw_pedido/list'));

    if (err) {
      return;
    }

    this.setState({
      data: data
    });
  }

  handleChange = (event) => {
    let { filters } = this.state;
    filters[event.target.id] = event.target.value;
    this.setState({ filters });
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={5} className={`${classes.mainPaper} ${classes.fullWidthPaper}`}>
        <Grid container spacing={4}>
          <Grid item xs={3} className={classes.gridForm}>
            <InputLabel className={classes.tittleLabelForm}>
              Visor pedidos
              </InputLabel>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs className={classes.gridForm}>
            <TextField
              id='pedidoCodigo'
              type='text'
              label='Codigo'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs className={classes.gridForm}>
            <TextField
              id='pedidoRazonSocial'
              type='text'
              label='Nombre'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs className={classes.gridForm}>
            <TextField
              id='pedidoContactoTelefono'
              type='text'
              label='Telefono'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs className={this.props.classes.gridForm}>
            <PSelect
              id="pedidoEstado"
              useChildren={true}
              onChange={this.handleChange}
              namelabel="Estado"
            >
              <option value="PENDIENTE">PENDIENTE</option>
              <option value="CONFIRMADO">CONFIRMADO</option>
              <option value="DESPACHADO">DESPACHADO</option>
              <option value="COTIZADO">COTIZADO</option>

              <option value="FACTURADO">FACTURADO</option>
              <option value="ANULADO">ANULADO</option>
            </PSelect>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs className={classes.gridForm}>
            <PSelect
              namelabel="Medio de Contacto (*)"
              id="tiprefCodigo"
              namelineInput="tiporeferencia"
              fieldVisible="tiprefNombre"
              fieldCode="tiprefCodigo"
              Table="TipoReferencia"
              Vista="true"
              Where="true"
              useChildren={false}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs className={classes.gridForm}>
            <PSelect
              namelabel="Tipo Servicio (*)"
              id="tipserCodigo"
              namelineInput="tiposervicio"
              fieldVisible="tipserNombre"
              fieldCode="tipserCodigo"
              Table="TipoServicio"
              Vista="true"
              Where="true"
              useChildren={false}
              onChange={this.handleChange}
            />
          </Grid>

          <Grid item xs className={classes.gridForm}>
            <AutoComplete
              id="sucursalCodigo"
              tableName='Sucursal'
              fields={['sucursalCodigo', 'sucursalNombre']}
              primaryField='sucursalCodigo'
              aliases={['ID', 'Nombre']}
              searchField='sucursalNombre'
              placeHolder='Seleccionar Sucursal'
              onSelect={this.handleChange}
            />
          </Grid>

          <Grid item xs={3} className={this.props.classes.gridForm}>
            <AutoComplete
              id="clienteCodigo"
              tableName='cliente'
              fields={['clienteCodigo', 'clienteRazonSocial']}
              aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
              size='xl'
              primaryField='clienteCodigo'
              searchField='clienteRazonSocial'
              placeHolder='Seleccionar Cliente'
              onSelect={this.handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs className={classes.gridForm}>
            <TextField
              id='pedidoFechaServicioInicial'
              type='date'
              label='Fecha servicio Inicial'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs className={classes.gridForm}>
            <TextField
              id='pedidoFechaServicioFinal'
              type='date'
              label='Fecha servicio Final'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs className={classes.gridForm}>
            <TextField
              id='createdAtInicial'
              type='date'
              label='Fecha creacion Inicial'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs className={classes.gridForm}>
            <TextField
              id='createdAtFinal'
              type='date'
              label='Fecha creacion Final'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={10} >
          <Grid item xs={3} className={this.props.classes.gridForm}>

            <Button
              color='primary'
              variant='contained'
              onClick={(event) => this.search()}
            >
              Buscar
              </Button>

          </Grid>
        </Grid>

        <CustomizedExpansionPanel tittle="Cuadro de convenciones.">
          <Grid container spacing={2} >
            <Grid item xs className={this.props.classes.gridPedidoConfirmado}>CONFIRMADO</Grid>
            <Grid item xs className={this.props.classes.gridPedidoPendiente}>PENDIENTE</Grid>
            <Grid item xs className={this.props.classes.gridPedidoDespachadoo}>DESPACHADO</Grid>
            <Grid item xs className={this.props.classes.gridPedidoCerrado}>CERRADO</Grid>
            <Grid item xs className={this.props.classes.gridPedidoAnulado}>ANULADO</Grid>
          </Grid>
        </CustomizedExpansionPanel>
        <DataGridVisor
          search={this.search}
          data={this.state.data}
          fields={VISOR_FIELDS}
          colors={VISOR_COLORS}
          moduleUrl='pedido'
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
        />

      </Paper>
    );
  }
}

Visor.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  toggleFavorites: PropTypes.func.isRequired,
  toogleFavoritesShow: PropTypes.func.isRequired
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(Visor)));

function IntegrationNotistack(props) {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp {...props} />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;