import createStyles from '@material-ui/core/styles/createStyles';
import purple from '@material-ui/core/colors/purple';

const styles = theme => createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    marginBottom: 'auto',
  },
  cssLabel: {
    '&$cssFocused': {
      color: purple[500],
    },
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: purple[500],
    },
  },
  cssFocused: {

  },
  cssDisabled: {
    borderColor: purple[500],
    color: purple[500],
  },
  notchedOutline: {
    borderColor: purple[500],
  },
  formLabelRoot: {
    color: '#fff',
    backgroundColor: '#006fd0',
    zIndex: 0
  },
});

export default styles;