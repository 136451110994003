import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import IconLoader from './Icons/LoaderIconsSvg';

const styles = theme => ({
  paper: {
    height: '100%',
    widt: '100%',
    color: theme.palette.text.secondary,
  },
});


class Modal extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <ButtonBase size="small" onClick={this.handleClickOpen} >
          <IconLoader size={this.props.sizeIcon} color={this.props.colorIcon} icon={this.props.icon} />
        </ButtonBase>
        <Dialog
          maxWidth="xl"
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.open}
          className={classes.paper}
        >
          {this.props.children}
        </Dialog>
      </Paper>
    );
  }
}

export default withStyles(styles)(Modal);
