import React, { Component } from 'react';
import { Paper, Button, InputLabel, Grid } from '@material-ui/core';
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination } from '@material-ui/core';
import { Link } from "react-router-dom";
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../GeneralUI/Styles';
import PropTypes from 'prop-types';
import Input from './input';
import _ from 'lodash';
import Util from './util';

/*const customTableStyles = theme => ({
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(33, 150, 243, 0.2) !important'
    }
  },

  tablePagination: {
    width: '30%',
  }
});*/

class Search extends Component {

  state = {
    moduleName: this.props.match.params.module,
    moduleNameSource: this.props.match.params.module,
    isViewSource: false,
    page: 0,
    rowsPerPage: 5,
    fieldsValues: {}
  };

  componentDidMount = () => {
    this.props.clearData();
    let tableName=Util.getSource(this.state.moduleNameSource);
    this.setState({ moduleNameSource: tableName.length>0?tableName:this.state.moduleName,
      isViewSource:tableName.length>0?true:false });
    this.props.getModuleDefinition(this.state.moduleName, this.initializeInputStates);
  }

  getTableHeader = () => {
    if (!this.props.data.length) {
      return;
    }

    /*return this.props.docFields.map((row, index) => {
      if (this.props.visibleFields.indexOf(row.name) === -1) return null;

      return (
        <TableCell align='left' key={100 + index}>
          <b>{row.prettyName}</b>
        </TableCell>
      );
    });*/

    return this.props.visibleFields.map((row, index) => {
      return (
        <TableCell align='left' key={100 + index}>
          <b>{this.getPrettyName(row)}</b>
        </TableCell>
      );
    });
  }

  getPrettyName = (fieldName) => {
    const rawField = _.find(this.props.docFields, { name: fieldName });
    if (rawField) {
      return rawField.prettyName;
    } else {
      return fieldName;
    }
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, this.search);
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({
      page: 0,
      rowsPerPage: event.target.value
    }, this.search);
  }

  handleInputChange = (event) => {
    console.log('index.jsx -- 83 > event.target.id === ', event.target.id);
    console.log('index.jsx -- 84 > event.target.value === ', event.target.value);

    let fieldData = { ...this.state.fieldsValues };
    fieldData[event.target.id] = String(event.target.value).trim()
    this.setState({ fieldsValues: fieldData });
  }

  initializeInputStates = () => {
    let fields = {};
    this.props.docFields.map(field => field[field.name] = undefined);
    this.setState({
      fieldsValues: fields
    });
  }

  search = () => {
    let filters = {};
    _.keys(this.state.fieldsValues).forEach(field => {
      if (this.state.fieldsValues[field] !== undefined && String(this.state.fieldsValues[field]).trim()) {
        filters[field] = this.state.fieldsValues[field];
      }
    });

    this.props.search({
      isView:this.state.isViewSource,
      fields: this.props.visibleFields,
      filters,
      options: {
        limit: this.state.rowsPerPage,
        skip: this.state.page * this.state.rowsPerPage,
        order: { createdAt: 'DESC' }
      }
    }, this.state.moduleNameSource);
  }

  getTableRows = () => {
    const fields = this.props.visibleFields;
    const docFields = {};
    this.props.docFields.forEach(field => docFields[field.name] = field);

    return this.props.data.map((row, index) => {
      return (
        <TableRow hover key={200 + index} className={this.props.classes.tableRow}>
          {
            fields.map((field, idx) => {
              return (
                <TableCell key={300 + idx} align='left'>
                  {
                    (docFields[field] && docFields[field].isPrimary) ?
                      <Link to={this.getEditUrl(row[field])}>
                        {row[field]}
                      </Link>
                      : row[field]
                  }
                </TableCell>
              );
            })
          }
        </TableRow>
      );
    });
  }

  getEditUrl = (id) => {
    return `/home/${this.state.moduleName}/${id}/edit`;
  }

  getTablePagination = () => {

    if (!this.props.rowCount) {
      return null;
    }
    return (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        className={this.props.classes.tablePagination}
        count={this.props.rowCount}
        rowsPerPage={this.state.rowsPerPage}
        page={this.state.page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <Grid container spacing={0} className={this.props.classes.gridFormSearch}>
          <Link to={`/home/${this.state.moduleName}/`} className={classes.link}>
            <InputLabel className={classes.tittleLabelForm}>
              Buscar {this.props.docName}
            </InputLabel>
          </Link>
          <Input
            rowsDefinition={this.props.docFields}
            rowsSearchable={this.props.searchableFields}
            composedFields={this.props.composedFields}
            handleInputChange={this.handleInputChange}
          />
          <div>
            <Grid item xs={12} >
              <Grid container  className={this.props.classes.gridForm}>
                <Grid item xs={12}>
                  <Button
                    onClick={this.search}
                    variant='contained'
                    color='primary' disabled={this.props.waitForResponse}
                  >
                    Buscar
              </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid container spacing={0} xs={12} className={this.props.classes.gridResultSearch}>
          <Table >
            <TableHead>
              <TableRow>
                {this.getTableHeader()}
              </TableRow>
            </TableHead>

            <TableBody>
              {this.getTableRows()}
            </TableBody>

          </Table>
          {this.getTablePagination()}
        </Grid>


      </Paper>

    );
  }
}

Search.propTypes = {
  data: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  docFields: PropTypes.array.isRequired,
  searchableFields: PropTypes.array.isRequired,
  visibleFields: PropTypes.array.isRequired,
  composedFields: PropTypes.array.isRequired,
  docName: PropTypes.string.isRequired,
  search: PropTypes.func.isRequired,
  getModuleDefinition: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  waitForResponse: PropTypes.bool.isRequired
}

export default withStyles(Styles, { withTheme: true })(Search);