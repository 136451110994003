import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { getDocument } from '../../../../Actions/General';
import Buttoncontrol from "../../../GeneralUI/Buttoncontrol";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import { TableRow, TableCell } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter, combineStyles } from '../../../../Libs/';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import PSelect from '../../../GeneralUI/PSelect'
import { Link } from "react-router-dom";
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: 'none',
  }
});

class InterfazContable extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    data: null,
    fields: {
      fechaInicial: null,
      fechaFinal: null,
      tipoInterfaz: null,
      sucursalCodigo: null,
    },
    responseForm: { Message: '', typeMessage: '' },
    formValidations: {
      view: ['tipoInterfaz', 'fechaInicial', 'fechaFinal']
    },
    formErrors: {
      tipoInterfaz: {},
      fechaInicial: {},
      fechaFinal: {}
    }
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'facturaCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });

  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    fields[event.target.id] = event.target.value;
    this.setState({ fields });
  }

  handleCapture = ({ target }) => {
    let { fields } = this.state;
    fields['csvFile'] = target.files[0];

    this.setState({
      inputFileName: target.files[0].name,
      fields
    });
  }

  save = (response) => {

    let newState = {
      openSnackbar: true,
      responseForm: response.responseForm,
    };

    if (newState.responseForm['typeMessage'] === 'success') {
      newState.data = response.data;
    }


    this.setState(newState);
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  render() {
    const { classes } = this.props;

    return (

      <Paper elevation={5} className={classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={classes.gridForm}>

            <InputLabel className={classes.tittleLabelForm}>
              Facturacion Interfaz Contable
            </InputLabel>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={classes.gridForm}>
              <AutoComplete
                id="sucursalCodigo"
                tableName='Sucursal'
                fields={['sucursalCodigo', 'sucursalNombre']}
                primaryField='sucursalCodigo'
                aliases={['ID', 'Nombre']}
                searchField='sucursalNombre'
                placeHolder='Sucursal (*)'
                onSelect={this.handleChange}
                value={this.state.fields["sucursalCodigo"]}
                formErrors={this.state.formErrors['sucursalCodigo']}
              />
            </Grid>

            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="fechaInicial"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["fechaInicial"]}
                type="date"
                label="Fecha inicial (*)"
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['fechaInicial'].error}
                helperText={this.state.formErrors['fechaInicial'].errorMessage}
              />
            </Grid>

            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="fechaFinal"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["fechaFinal"]}
                type="date"
                label="Fecha final (*)"
                InputLabelProps={{ shrink: true }}
                error={this.state.formErrors['fechaFinal'].error}
                helperText={this.state.formErrors['fechaFinal'].errorMessage}
              />
            </Grid>

            <Grid item xs={3} className={classes.gridForm}>
              <PSelect
                id="tipoInterfaz"
                useChildren={true}
                onChange={this.handleChange}
                namelabel="Tipo Interfaz (*)"
              >
                <option value="GENERACION">GENERACION</option>
                <option value="LIBERACION">LIBERACION</option>
              </PSelect>
            </Grid>

            <Grid item xs={3} className={classes.gridForm}>
              <PSelect
                id="facturaTipoFactura"
                useChildren={true}
                onChange={this.handleChange}
                namelabel="Tipo Interfaz (*)"
              >
                <option value="NORMAL">NORMAL</option>
                <option value="BODEHOGAR">BODEHOGAR</option>
              </PSelect>
            </Grid>

          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              {(this.state.data !== null) ?
                (<Link to={this.state.data.urlLink} target="_blank"><Chip
                  label={"Descargar Archivo:"}
                  clickable
                  className={this.props.classes.chip}
                  color="primary"
                /></Link>) : ''
              }
            </Grid>
          </Grid>

          <Grid container spacing={4}>

          </Grid>
        </form>


        <div id='buttonContainer'>
          <Buttoncontrol
            saveData={this.state.fields}
            moduleName='factura'
            save={this.save}
            primaryFields={['facturaCodigo']}
            formValidations={this.state.formValidations}
            errorHandler={this.setErrors}
            url='/processes/interfaz_contable_factura'
            isFormLite={true}
          />
        </div>

      </Paper>
    );
  }

  getTableRows = () => {
    return this.state.data.map((row, index) => {
      return (
        <TableRow hover key={200 + index} className={this.props.classes.tableRow}>
          <TableCell key={300 + 300} align='left'>
            <a
              href={`/home/factura/${row.facturaCodigo}/edit`}
              target='_blank'
              rel='noopener noreferrer'>
              {row.facturaCodigo}
            </a>
          </TableCell>
        </TableRow>
      );
    });
  }
}



InterfazContable.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(combineStyles(styles, Styles))(InterfazContable)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;