import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import { Paper } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import customStyles from './Styles';
import { combineStyles } from '../../../../Libs';
import { withStyles } from '@material-ui/core/styles';
import { searchPage } from '../../../../Actions/General';


const styles = theme => ({
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  }
});

class IntegrationAutosuggest extends React.Component {
  state = {
    single: '',
    popper: '',
    suggestions: [],
    isWaiting: false
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    if (this.state.isWaiting) {
      return;
    }

    this.setState({ isWaiting: true });
    searchPage(value, 3).then(data => {
      this.setState({
        isWaiting: false,
        suggestions: data,
      });
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue,
    });
  };

  getSuggestionValue = (suggestion) => {
    return suggestion.label;
  }

  handleClick = (event) => {
    if (!event.target.id) {
      return;
    }

    const id = String(event.target.id).replace('main-', '');
    this.props.addFavorite(id).then(() => {
      this.setState({
        single: '',
        suggestions: [],
        isWaiting: false
      });
    });
  }

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
      <MenuItem id={'main-' + suggestion.id} selected={isHighlighted} component="div" onClick={this.handleClick}>
        <div>
          {parts.map((part, index) =>
            part.highlight ? (
              <span key={String(index)}
                id={suggestion.id}
                style={{ fontWeight: 500 }}>
                {part.text}
              </span>
            ) : (
                <strong key={String(index)}
                  id={suggestion.id}
                  style={{ fontWeight: 300 }}>
                  {part.text}
                </strong>
              ),
          )}
        </div>
      </MenuItem>
    );
  }

  renderInputComponent = (inputProps) => {
    const { classes, inputRef = () => { }, ref, ...other } = inputProps;
    const endAdornment = (
      <InputAdornment position="end" variant='filled' className={classes.adornment} >
        <SearchIcon className={classes.searchIcon} />
      </InputAdornment >
    );

    return (<TextField
      fullWidth
      className={classes.textField}
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          root: classes.inputRoot
        },
        endAdornment: endAdornment
      }}
      {...other}
    />);
  }

  getSuggestions = (suggestions, value) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
        const keep =
          count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
  }

  render() {
    const { classes } = this.props;

    const autosuggestProps = {
      renderInputComponent: this.renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion: this.renderSuggestion,
    };

    return (
      <div className={classes.root}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder: 'Buscar',
            value: this.state.single,
            onChange: this.handleChange('single'),
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}

IntegrationAutosuggest.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(combineStyles(styles, customStyles))(IntegrationAutosuggest);