import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Textfieldlabel from "../GeneralUI/Textfieldlabel";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import PSelect from '../GeneralUI/PSelect'
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import AutoComplete from '../GeneralUI/AutoComplete';
import CustomizedExpansionPanel from './../GeneralUI/PExpansionpanels'
import Datagrid from '../GeneralUI/PDatagrid/'

import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';

class Comercial extends Component {

  state = {

    dataDatagridComercialComision: [{
      tipoTarifa: { 'value': null, 'key': null,'visible':true  },     
      tipoComision: { 'value': null, 'key': null,'visible':true  },
      Valor: { 'value': null, 'key': null,'visible':true  }
    }],  

    dataDatagridTrazabilidad: [
      {
        Usuario: '',
        DocumentoPrincipal: '',
        DocumentoAsociado: '',
        Evento: '',
        FechaHora: '',
        UsuarioExterno: ''
      }
    ],  

    fieldsCopy: {},

    fields: {
      comercialCodigo: null,
      comercialNombre1: null,
      comercialNombre2: null,
      comercialApellido1: null,
      comercialApellido2: null,
      ciudadCodigo: null,
      tipdocCodigo: null,
      comercialDocumento: null,
      comercialEstado: null,
      comercialCelular: null,
      comercialTelefono: null,
      comercialCargo: null,
      comercialDireccion: null,
      comercialCorreoElectronico: null,
      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime()
    },

    responseForm: { Message: '', typeMessage: '' }

  };


  componentDidMount = () => {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }
    this.fillFormData(params.id);    
  }


  fillFormData = (id) => {
    const params = this.props.match.params;

    if (!id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'comercialCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }

      this.setState({ fields: document });

      getDocument({
        documentId: id,
        fieldName: 'comercial_codigo',
        moduleName: 'vw_detalle_comercial_comision_interfaz',
        isView: true,
        fields: ['comcomdet_codigo',
          'comercial_codigo',
          'tiptar_codigo',
          'tiptar_nombre',
          'comcomdet_tipo_comision',
          'comcomdet_valor'
        ],
        Limit: 50
      }).then(document => {
        if (!document) {
          return;
        }
        this.buildDetailComercialComisionInterface(document);
      }).catch(ex => {
        console.log('index.jsx -- 137 > ex === ', ex);
      });

    }).catch(ex => {
      console.log('index.jsx -- 140 > ex === ', ex);
    });
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {

    let { fields } = this.state;
    const caret = event.target.selectionStart;
    const element = event.target;
    if (element.hasOwnProperty('setSelectionRange')) {
      window.requestAnimationFrame(() => {
        element.setSelectionRange(caret, caret);
      });
    }

    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }

    if (fields[event.target.id] === '') {
      fields[event.target.id] = null;
    }
    this.setState({ fields });
  }

  handleDatagrid = (data) => {
    const detalle = data.setComponente;
    switch (detalle) {
      case 'comercial':
        this.setState({ dataDatagridComercialComision: data });
        break;
      default:
        return null;
    }
  }

  buildDetailComercialComisionInterface = (data) => {
    let rows = data.map(row => {
     
      return {
        tipoTarifa: { 'value': row.tiptar_codigo, 'key': row.tiptar_nombre,'visible':true  },     
        tipoComision: { 'value': row.comcomdet_tipo_comision, 'key': row.comcomdet_tipo_comision,'visible':true  },
        Valor: { 'value': row.comcomdet_valor, 'key': row.comcomdet_valor,'visible':true  }
      };

    });
    this.setState({ dataDatagridComercialComision: rows });
  }

  messageValidation = (type, message) => {
    this.message(type, message);
  }

  save = (response) => {

    let fields=response.data.fields?response.data.fields:response.data;

    this.setState({
      fields,
      responseForm: response.responseForm
    }
    );

    if(this.state.responseForm['typeMessage']==='success'){
      this.fillFormData(this.state.fields.comercialCodigo); 
    }

    this.message(this.state.responseForm['typeMessage'], this.state.responseForm['Message']);
  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }

  validaData = () => {
    let isvalid = true;
    let message = 'El registro no ha sido guardado.';
    let type = "warning";

    isvalid = true;

    if (!isvalid) {
      this.message(type, message);
    }
    return isvalid;
  }

  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>

            <InputLabel id="comercialCodigo"
              className={this.props.classes.tittleLabelForm}>Comercial
            </InputLabel>

            <Grid item xs={6} className={this.props.classes.chipTitle}>
              {(this.state.fields.comercialCodigo !== null) ?
                (<Chip
                  label={this.state.fields["comercialCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="comercialNombre1" label="Primer Nombre (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["comercialNombre1"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="comercialNombre2" label="Segundo Nombre (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["comercialNombre2"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="comercialApellido1" label="Primer Apellido (*)" type="text"

                fullWidth margin="normal" variant="outlined" onChange={this.handleChange}
                value={this.state.fields["comercialApellido1"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="comercialApellido2" label="Segundo Apellido (*)" type="text"

                fullWidth margin="normal" variant="outlined" onChange={this.handleChange}
                value={this.state.fields["comercialApellido2"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>

              <PSelect
                namelabel="TipoDocumento (*)"
                id="tipdocCodigo"
                namelineInput="tipodocumento"
                fieldVisible="tipdocNombre"
                fieldCode="tipdocCodigo"
                Table="Tipodocumento"
                Vista="true"
                Where="true"
                useChildren={false}
                onChange={this.handleChange}
                value={this.state.fields["tipdocCodigo"]}
              />

            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="comercialDocumento" label="Documento (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["comercialDocumento"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
          <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="comercialEstado"
                useChildren={true}
                value={this.state.fields["comercialEstado"]}
                onChange={this.handleChange}
                namelabel="Estado"
              >
                <option value="ACTIVO">ACTIVO</option>
                <option value="INACTIVO">INACTIVO</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="ciudadCodigo"
                tableName='Ciudad'
                fields={['ciudadCodigo', 'ciudadNombre']}
                primaryField='ciudadCodigo'
                aliases={['ID', 'Nombre']}
                searchField='ciudadNombre'
                placeHolder='Seleccionar ciudad'
                onSelect={this.handleChange}
                value={this.state.fields["ciudadCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="comercialDireccion" label="Direccion (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["comercialDireccion"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="comercialCorreoElectronico" label="Correo Electronico (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["comercialCorreoElectronico"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="comercialCelular" label="Celular (*)" type="text"

                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["comercialCelular"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="comercialTelefono" label="Telefono (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["comercialTelefono"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="comercialCargo" label="Cargo (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["comercialCargo"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Usuario" value={Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Creacion" value={this.state.fields.createdAt} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Modificacion" value={this.state.fields.updatedAt} />
            </Grid>
          </Grid>

          <Grid>
            <CustomizedExpansionPanel tittle="Detalle de Comision +++">
              <Datagrid datosHeader={this.state.fields} dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridComercialComision} setComponente="comercial" />
            </CustomizedExpansionPanel>
          </Grid>


          <CustomizedExpansionPanel tittle="Trazabilidad">
            <Datagrid dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridTrazabilidad} />
          </CustomizedExpansionPanel>


        </form>
        <div><Buttoncontrol
          saveData={this.state}
          saveDataValidation={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          validaData={this.validaData}
          messageValidation={this.messageValidation}
          edit={(this.state.fields.comercialCodigo !== null) ? true : false}
          primaryFields={['comercialCodigo']}
          url='/processes/comercial_save'
          formValidations={this.state.formValidations}
          errorHandler={this.setErrors}
        /></div>
      </Paper>
    );
  }
}

Comercial.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(Comercial)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}

    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;