import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { search } from '../../../../Actions/General';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {  Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter } from '../../../../Libs';
import DataGridVisor from '../../../GeneralUI/DataGridVisor';
import to from 'await-to-js';
import PSelect from '../../../GeneralUI/PSelect';
import TextField from '@material-ui/core/TextField';




const VISOR_FIELDS = [
{
  name: 'año',
  alias: 'Año'
},
{
  name: 'mes',
  alias: 'Mes'
},
{
  name: 'flete_contratado',
  alias: 'Flete Contratado'
},
{
  name: 'transp_facturado',
  alias: 'Trans Facturado'
},
{
  name: 'cumplimiento',
  alias: 'Cumplimiento'
},
];
  
const VISOR_COLORS = {
ATRASADO: '#FF3933',
ATIEMPO: '#3380FF'
}

class CuadroIndicadorContratacion extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    page: 0,
    rowsPerPage: 10,
    data: {
      rows: [],
      count: 0
    },
    filters: {
      año: null,
      mes: null,
      cumplimiento: null
    },
    operatorCustom: [],
    responseForm: { Message: '', typeMessage: '' },
    formErrors: {
    }
  };

  componentDidMount() {
    this.props.toggleFavorites();
  }

  componentWillUnmount() {
    this.props.toogleFavoritesShow();
  }

  search = async (page, rowsPerPage) => {
    if (page !== undefined) {
      await to(new Promise((resolve) => {
        this.setState({
          page,
          rowsPerPage
        }, resolve)
      }));
    }

    this.state.operatorCustom = [];
    let filters = {};


    if (this.state.filters.año !== null && this.state.filters.año !== '') {
      this.state.operatorCustom.push("\"año\" = " + this.state.filters.año + "");
    }

    if (this.state.filters.mes !== null && this.state.filters.mes !== '') {
      this.state.operatorCustom.push("\"mes\" = " + this.state.filters.mes + "");
    }

    if (this.state.filters.cumplimiento !== null && this.state.filters.cumplimiento !== '') {
      this.state.operatorCustom.push("\"cumplimiento\" <= " + this.state.filters.cumplimiento + "");
    }  

     const [err, data] = await to(search({
      fields: VISOR_FIELDS.map(row => row.name),
      filters: filters,
      operatorCustom: this.state.operatorCustom,
      isView: true,
      options: {
        limit: this.state.rowsPerPage,
        skip: this.state.page * this.state.rowsPerPage,
        order: { año: 'DESC' }
      }
    }, '/modules/vw_cuadro_indicador_contratacion/list'));

    if (err) {
      return;
    }

    this.setState({
      data: data
    });
  }

  handleChange = (event) => {
    let { filters } = this.state;
    filters[event.target.id] = event.target.value;
    this.setState({ filters });
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={5} className={`${classes.mainPaper} ${classes.fullWidthPaper}`}>
        <Grid container spacing={4}>
          <Grid item xs={3} className={classes.gridForm}>
            <InputLabel className={classes.tittleLabelForm}>
              Cuadro Indicador Contratacion
              </InputLabel>
          </Grid>
        </Grid>
        

        <Grid container spacing={2}>
          <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="año"
                useChildren={true}
                value={this.state.filters["año"]}
                onChange={this.handleChange}
                namelabel="Año"
              >
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
                <option value="2030">2030</option>
                </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="mes"
                useChildren={true}
                value={this.state.filters["mes"]}
                onChange={this.handleChange}
                namelabel="Mes"
              >
                <option value="1">ENERO</option>
                <option value="2">FEBRERO</option>
                <option value="3">MARZO</option>
                <option value="4">ABRIL</option>
                <option value="5">MAYO</option>
                <option value="6">JUNIO</option>
                <option value="7">JULIO</option>
                <option value="8">AGOSTO</option>
                <option value="9">SEPTIEBRE</option>
                <option value="10">OCTUBRE</option>
                <option value="11">NOVIEMBRE</option>
                <option value="12">DICIEMBRE</option>                                
              </PSelect>
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="cumplimiento" label="Area" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.filters["cumplimiento"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
        </Grid>

        <Grid container spacing={10} >
          <Grid item xs={3} className={this.props.classes.gridForm}>

            <Button
              color='primary'
              variant='contained'
              onClick={(event) => this.search()}
            >
              Buscar
              </Button>

          </Grid>
        </Grid>

        <DataGridVisor
          search={this.search}
          data={this.state.data}
          fields={VISOR_FIELDS}
          colors={VISOR_COLORS}
          moduleUrl='manifiesto'
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
        />

      </Paper>
    );
  }
}

CuadroIndicadorContratacion.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  toggleFavorites: PropTypes.func.isRequired,
  toogleFavoritesShow: PropTypes.func.isRequired
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(CuadroIndicadorContratacion)));

function IntegrationNotistack(props) {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp {...props} />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;