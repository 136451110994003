import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { search } from '../../../../Actions/General';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter } from '../../../../Libs/';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import DataGridVisor from '../../../GeneralUI/DataGridVisor';
import to from 'await-to-js';
import PSelect from '../../../GeneralUI/PSelect';
import CustomizedExpansionPanel from './../../../GeneralUI/PExpansionpanels'


const VISOR_FIELDS = [{
  name: 'FACTURA_CODIGO',
  alias: 'Codigo',
  isPrimary: true
}, {
  name: 'RESOLUCION',
  alias: 'Resolucion'
}, {
  name: 'VALOR',
  alias: 'Valor'
}, {
  name: 'FECHA',
  alias: 'Fecha'
}, {
  name: 'ESTADO',
  alias: 'Estado'
}, {
  name: 'CLIENTE',
  alias: 'Cliente'
},
{
  name: 'SUCURSAL',
  alias: 'Sucursal'
},
{
  name: 'FACTURA_OBSERVACION',
  alias: 'Observacion'}, {
  name: 'CUFE',
  alias: 'Cufe',
  tooltip: true
},
{
  name: 'PROGRAMADA_DIAN',
  alias: 'PROGRAMADA DIAN'
}, {
  name: 'PENDIENTE_DIAN',
  alias: 'PENDIENTE DIAN'
}, {
  name: 'DIAS_FALTANTES',
  alias: 'DIAS FALTANTES'
}, {
  name: 'INDICADOR',
  alias: 'ALERTA',
  colorize: true
}];

const VISOR_COLORS = {
  BAJA: '#3380FF',
  ALTA: '#FF3933',
  MEDIA: '#FF9033',
}

class Visor extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    page: 0,
    rowsPerPage: 10,
    data: {
      rows: [],
      count: 0
    },
    filters: {
      facturaCodigo: null,
      facturaFechaCreacionA: null,
      facturaFechaCreacionB: null,
      facturaResolucion: null,
      facturaEstado: null,
      clienteCodigo: null,
      sucursalCodigo: null,
      programadaDian: null,
      pendienteDian: null,
      diasFaltantes: null,
    },
    operatorCustom: [],
    responseForm: { Message: '', typeMessage: '' },
    formErrors: {
    }
  };

  componentDidMount() {
    this.props.toggleFavorites();
  }

  componentWillUnmount() {
    this.props.toogleFavoritesShow();
  }

  search = async (page, rowsPerPage) => {
    if (page !== undefined) {
      await to(new Promise((resolve) => {
        this.setState({
          page,
          rowsPerPage
        }, resolve)
      }));
    }

    this.state.operatorCustom = [];
    let filters = {};

    if (this.state.filters.facturaCodigo !== null && this.state.filters.facturaCodigo !== '') {
      this.state.operatorCustom.push("\"FACTURA_CODIGO\" = '" + this.state.filters.facturaCodigo + "'");
    }

    if (this.state.filters.facturaFechaCreacionA !== null && this.state.filters.facturaFechaCreacionA !== '' &&
        this.state.filters.facturaFechaCreacionB !== null && this.state.filters.facturaFechaCreacionB !== '') {
      this.state.operatorCustom.push("\"FECHA\" between '" + this.state.filters.facturaFechaCreacionA + "' and '" + this.state.filters.facturaFechaCreacionB + "'");
    }

    if (this.state.filters.facturaResolucion !== null && this.state.filters.facturaResolucion !== '') {
      this.state.operatorCustom.push("\"RESOLUCION\" = '" + this.state.filters.facturaResolucion + "'");
    }

    if (this.state.filters.facturaEstado !== null && this.state.filters.facturaEstado !== '') {
      this.state.operatorCustom.push("\"ESTADO\" = '" + this.state.filters.facturaEstado + "'");
    }

    if (this.state.filters.clienteCodigo !== null && this.state.filters.clienteCodigo !== '') {
      this.state.operatorCustom.push("\"CLIENTE_CODIGO\" = " + this.state.filters.clienteCodigo + "");
    }

    if (this.state.filters.sucursalCodigo !== null && this.state.filters.sucursalCodigo !== '') {
      this.state.operatorCustom.push("\"SUCURSAL_CODIGO\" = " + this.state.filters.sucursalCodigo + "");
    }

    if (this.state.filters.programadaDian !== null && this.state.filters.programadaDian !== '') {
      this.state.operatorCustom.push("\"PROGRAMADA_DIAN\" = '" + this.state.filters.programadaDian + "'");
    }

    if (this.state.filters.pendienteDian !== null && this.state.filters.pendienteDian !== '') {
      this.state.operatorCustom.push("\"PENDIENTE_DIAN\" = '" + this.state.filters.pendienteDian + "'");
    }

    if (this.state.filters.diasFaltantes !== null && this.state.filters.diasFaltantes !== '') {
      this.state.operatorCustom.push("\"DIAS_FALTANTES\" <= " + this.state.filters.diasFaltantes + "");
    }

    const [err, data] = await to(search({
      fields: VISOR_FIELDS.map(row => row.name),
      filters: filters,
      operatorCustom: this.state.operatorCustom,
      isView: true,
      options: {
        limit: this.state.rowsPerPage,
        skip: this.state.page * this.state.rowsPerPage,
        order: { createdAt: 'DESC' }
      }
    }, '/modules/vw_factura_visor_dian/list'));

    if (err) {
      return;
    }

    this.setState({
      data: data
    });
  }

  handleChange = (event) => {
    let { filters } = this.state;
    filters[event.target.id] = event.target.value;
    this.setState({ filters });
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={5} className={`${classes.mainPaper} ${classes.fullWidthPaper}`}>
        <Grid container spacing={4}>
          <Grid item xs={3} className={classes.gridForm}>
            <InputLabel className={classes.tittleLabelForm}>
              Visor Factura DIAN
              </InputLabel>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs className={classes.gridForm}>
            <TextField
              id='facturaCodigo'
              type='text'
              label='Codigo'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs className={classes.gridForm}>
            <TextField
              id='facturaResolucion'
              type='text'
              label='Resolucion'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs className={classes.gridForm}>
            <TextField
              id='diasFaltantes'
              type='text'
              label='Dias Faltantes'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs className={this.props.classes.gridForm}>
            <PSelect
              id="facturaEstado"
              useChildren={true}
              onChange={this.handleChange}
              namelabel="Estado"
            >
              <option value="ACTIVO">ACTIVO</option>
              <option value="ANULADO">ANULADO</option>
            </PSelect>
          </Grid>
        </Grid>

        <Grid container spacing={2}>

          <Grid item xs className={classes.gridForm}>
            <AutoComplete
              id="sucursalCodigo"
              tableName='Sucursal'
              fields={['sucursalCodigo', 'sucursalNombre']}
              primaryField='sucursalCodigo'
              aliases={['ID', 'Nombre']}
              searchField='sucursalNombre'
              placeHolder='Seleccionar Sucursal'
              onSelect={this.handleChange}
            />
          </Grid>

          <Grid item xs={3} className={this.props.classes.gridForm}>
            <AutoComplete
              id="clienteCodigo"
              tableName='cliente'
              fields={['clienteCodigo', 'clienteRazonSocial']}
              aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
              size='xl'
              primaryField='clienteCodigo'
              searchField='clienteRazonSocial'
              placeHolder='Seleccionar Cliente'
              onSelect={this.handleChange}
            />
          </Grid>
          <Grid item xs className={classes.gridForm}>
            <TextField
              id='facturaFechaCreacionA'
              type='date'
              label='Fecha Creacion Inicial'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>

          <Grid item xs className={classes.gridForm}>
            <TextField
              id='facturaFechaCreacionB'
              type='date'
              label='Fecha Creacion Final'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs className={this.props.classes.gridForm}>
              <PSelect
                id="programadaDian"
                useChildren={true}
                onChange={this.handleChange}
                namelabel="Programda Dian"
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </PSelect>
          </Grid>
          <Grid item xs className={this.props.classes.gridForm}>
              <PSelect
                id="pendienteDian"
                useChildren={true}
                onChange={this.handleChange}
                namelabel="Pendiente Dian"
              >
                <option value="SI">SI</option>
                <option value="NO">NO</option>
              </PSelect>
          </Grid>
        </Grid>

        <Grid container spacing={10} >
          <Grid item xs={3} className={this.props.classes.gridForm}>

            <Button
              color='primary'
              variant='contained'
              onClick={(event) => this.search()}
            >
              Buscar
              </Button>

          </Grid>
        </Grid>

        <CustomizedExpansionPanel tittle="Cuadro de convenciones.">
          <Grid container spacing={2} >
            <Grid item xs className={this.props.classes.gridVisorDianAlertaAlta}>ALTA</Grid>
            <Grid item xs className={this.props.classes.gridVisorDianAlertaMedia}>MEDIA</Grid>
            <Grid item xs className={this.props.classes.gridVisorDianAlertaBaja}>BAJA</Grid>
          </Grid>
        </CustomizedExpansionPanel>
        <DataGridVisor
          search={this.search}
          data={this.state.data}
          fields={VISOR_FIELDS}
          colors={VISOR_COLORS}
          moduleUrl='factura'
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
        />

      </Paper>
    );
  }
}

Visor.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  toggleFavorites: PropTypes.func.isRequired,
  toogleFavoritesShow: PropTypes.func.isRequired
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(Visor)));

function IntegrationNotistack(props) {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp {...props} />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;