import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import Buttoncontrol from "../../../GeneralUI/Buttoncontrol";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import PSelect from '../../../GeneralUI/PSelect'
import { combineStyles } from '../../../../Libs/';


const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: 'none',
  }
});

class InformeGeneralInventario extends Component {

  state = {
    data: [],
    fields: {

      ALMACEN_CODIGO: null,
      PRODUCTO_CODIGO: null,
      CLIENTE_CODIGO: null,
      FECHA_INICIAL: null,
      FECHA_FINAL: null,
      TIPO_INVENTARIO: null,
    },
    paramsInforme: '/imprimir_informe/movimientoinventario/informe_kardex_almacen?ALMACEN_CODIGO=' + null + '&CLIENTE_CODIGO=' + null + '&PRODUCTO_CODIGO=' + null + '&FECHA_INICIAL=' + null + '&FECHA_FINAL=' + null + '&TIPO_INVENTARIO=' + null,
    responseForm: { Message: '', typeMessage: '' },
    formErrors: {
    }
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    let params = '';
    fields[event.target.id] = event.target.value;
    this.setState({ fields });

    params = '/imprimir_informe/movimientoinventario/informe_kardex_almacen?ALMACEN_CODIGO=' + this.state.fields.ALMACEN_CODIGO + '&CLIENTE_CODIGO=' + this.state.fields.CLIENTE_CODIGO + '&PRODUCTO_CODIGO=' + this.state.fields.PRODUCTO_CODIGO + '&FECHA_INICIAL='+ this.state.fields.FECHA_INICIAL + '&FECHA_FINAL='+ this.state.fields.FECHA_FINAL + '&TIPO_INVENTARIO='+ this.state.fields.TIPO_INVENTARIO;
    this.setState({ paramsInforme: params });
  }

  save = (response) => {
    let newState = {
      openSnackbar: true,
      responseForm: response.responseForm
    };

    if (newState.responseForm['typeMessage'] === 'success') {
      newState.data = response.data;
    }

    this.setState(newState);
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  render() {
    const { classes } = this.props;

    return (

      <Paper elevation={5} className={classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={classes.gridForm}>

            <InputLabel className={classes.tittleLabelForm}>
              Informe Kardex Almacen
            </InputLabel>
          </Grid>


          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="ALMACEN_CODIGO"
                tableName='Almacen'
                fields={['almacenCodigo', 'almacenNombre']}
                primaryField='almacenCodigo'
                aliases={['ID', 'Nombre']}
                searchField='almacenNombre'
                placeHolder='Seleccionar Almacen'
                onSelect={this.handleChange}
                value={this.state.fields["ALMACEN_CODIGO"]}
                formErrors={this.state.formErrors['almacenCodigo']}
              />
             </Grid>
             <Grid item xs={3} className={this.props.classes.gridForm}>            
              <AutoComplete
                id="CLIENTE_CODIGO"
                tableName='cliente'
                fields={['clienteCodigo', 'clienteRazonSocial']}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
                size='xl'
                primaryField='clienteCodigo'
                searchField='clienteRazonSocial'
                placeHolder='Seleccionar Cliente'
                onSelect={this.handleChange}
                value={this.state.fields["CLIENTE_CODIGO"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="PRODUCTO_CODIGO"
                  tableName='vw_producto_inventario'
                  fields={['producto_codigo','producto_nombre']}
                  isView={true}
                  aliases={[{ value: 'ID', size: 1 },{ value: 'Producto', size: 11 }]}
                  size='xx'
                  primaryField='producto_codigo'
                  searchField='producto_nombre'
                  placeHolder='Seleccionar Producto'
                  where={{
                    producto_tipo: 'INVENTARIO'
                  }}                  
                  onSelect={this.handleChange}
                  value={this.state.fields["PRODUCTO_CODIGO"]}
                />
              </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="TIPO_INVENTARIO"
                useChildren={true}
                value={this.state.fields["movinvMotivo"]}
                onChange={this.handleChange}
                namelabel="Tipo Inventario"
              >
                <option value="NORMAL">NORMAL</option>
                <option value="CLIENTES">CLIENTES</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="FECHA_INICIAL"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["FECHA_INICIAL"]}
                type="date"
                label="Fecha Inicial"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={classes.gridForm}>
              <TextField
                id="FECHA_FINAL"
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["FECHA_FINAL"]}
                type="date"
                label="Fecha Final"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </form>


        <div id='buttonContainer'>
          <Buttoncontrol
            saveData={this.state.fields}
            moduleName='informe_general_inventario'
            paramsInforme={this.state.paramsInforme}
            save={this.save}
            primaryFields={['pedidoCodigo']}
            url='/imprimir_informe/movimientoinventario/informe_kardex_almacen'
            isFormLite={true}
          />
        </div>

      </Paper>
    );
  }

}



InformeGeneralInventario.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(combineStyles(styles, Styles))(InformeGeneralInventario)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;