import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { search } from '../../../../Actions/General';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter } from '../../../../Libs';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import DataGridVisor from '../../../GeneralUI/DataGridVisor';
import to from 'await-to-js';
import PSelect from '../../../GeneralUI/PSelect';
import _ from 'lodash';
import CustomizedExpansionPanel from '../../../GeneralUI/PExpansionpanels'


const VISOR_FIELDS = [{
  name: 'Codigo',
  alias: 'Codigo',
  isPrimary: true,
  processUrl: '/encuesta_pedido/',
  processTitle: 'VER EncuestaPedido',
},
{
  name: 'Cliente',
  alias: 'Cliente'
},
{
  name: 'Ciudad',
  alias: 'Ciudad'
},
{
  name: 'Estado',
  alias: 'Estado',
  colorize: true
},
{
  name: 'Fecha',
  alias: 'Fecha'
},
{
  name: 'Puntualidad',
  alias: 'Puntualidad'
},
{
  name: 'CuidadoEnseres',
  alias: 'CuidadoEnseres'
}, 
{
  name: 'Disciplina',
  alias: 'Disciplina'
}, 
{
  name: 'PresentacionVehiculo',
  alias: 'PresentacionVehiculo'
}, 
{
  name: 'VisitaClaridadInformacion',
  alias: 'VisitaClaridadInformacion'
}, 
{
  name: 'VisitaRespetuoso',
  alias: 'VisitaRespetuoso'
},
{
  name: 'VisitaPuntualidad',
  alias: 'VisitaPuntualidad'
},
{
  name: 'RecomendacionServicio',
  alias: 'RecomendacionServicio'
},
{
  name: 'VehiculoVacio',
  alias: 'VehiculoVacio'
},
];
  
const VISOR_COLORS = {
PENDIENTE: '#FF3933',
DILIGENCIADA: '#3380FF'
}

class EncuestaPedidoVisor extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    page: 0,
    rowsPerPage: 10,
    data: {
      rows: [],
      count: 0
    },
    filters: {
      clienteCodigo: null,
      encpedEstado: null,
      fechaInicial: null,
      fechaFinal: null,
    },
    operatorCustom: [],
    responseForm: { Message: '', typeMessage: '' },
    formErrors: {
    }
  };

  componentDidMount() {
    this.props.toggleFavorites();
  }

  componentWillUnmount() {
    this.props.toogleFavoritesShow();
  }

  search = async (page, rowsPerPage) => {
    if (page !== undefined) {
      await to(new Promise((resolve) => {
        this.setState({
          page,
          rowsPerPage
        }, resolve)
      }));
    }

    this.state.operatorCustom = [];
    let filters = {};



    if (this.state.filters.clienteCodigo !== null && this.state.filters.clienteCodigo !== '') {
      this.state.operatorCustom.push("\"clienteCodigo\" = " + this.state.filters.clienteCodigo + "");
    }

    if (this.state.filters.encpedEstado !== null && this.state.filters.encpedEstado !== '') {
      this.state.operatorCustom.push("\"Estado\" = '" + this.state.filters.encpedEstado + "'");
    }

    if (this.state.filters.fechaInicial !== null && this.state.filters.fechaInicial !== '') {
      this.state.operatorCustom.push("\"Fecha\" >= '" + this.state.filters.fechaInicial + "'");
    }

    if (this.state.filters.fechaFinal !== null && this.state.filters.fechaFinal !== '') {
      this.state.operatorCustom.push("\"Fecha\" <= '" + this.state.filters.fechaFinal + "'");
    }

    const [err, data] = await to(search({
      fields: VISOR_FIELDS.map(row => row.name),
      filters: filters,
      operatorCustom: this.state.operatorCustom,
      isView: true,
      options: {
        limit: this.state.rowsPerPage,
        skip: this.state.page * this.state.rowsPerPage,
        order: { Fecha: 'DESC' }
      }
    }, '/modules/vw_encuesta_pedido_visor/list'));

    if (err) {
      return;
    }

    this.setState({
      data: data
    });
  }

  handleChange = (event) => {
    let { filters } = this.state;
    filters[event.target.id] = event.target.value;
    this.setState({ filters });
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={5} className={`${classes.mainPaper} ${classes.fullWidthPaper}`}>
        <Grid container spacing={4}>
          <Grid item xs={3} className={classes.gridForm}>
            <InputLabel className={classes.tittleLabelForm}>
              Visor Encuesta Pedido
              </InputLabel>
          </Grid>
        </Grid>

     

        <Grid container spacing={2}>
          <Grid item xs={3} className={this.props.classes.gridForm}>
            <AutoComplete
              id="clienteCodigo"
              tableName='cliente'
              fields={['clienteCodigo', 'clienteRazonSocial']}
              aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
              size='xl'
              primaryField='clienteCodigo'
              searchField='clienteRazonSocial'
              placeHolder='Seleccionar Cliente'
              onSelect={this.handleChange}
            />
          </Grid>
          <Grid item className={this.props.classes.gridForm}>
            <PSelect
                  id="encpedEstado"
                  useChildren={true}
                  onChange={this.handleChange}
                  namelabel="Estado"
                >
                  <option value="PENDIENTE">PENDIENTE</option>
                  <option value="DILIGENCIADA">DILIGENCIADA</option>
                </PSelect>
            </Grid>
              <Grid item xs className={classes.gridForm}>
                  <TextField
                    id='fechaInicial'
                    type='date'
                    label='Fecha Inicial'
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs className={classes.gridForm}>
                  <TextField
                    id='fechaFinal'
                    type='date'
                    label='Fecha Final'
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChange}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
          </Grid>

        <Grid container spacing={10} >
          <Grid item xs={3} className={this.props.classes.gridForm}>

            <Button
              color='primary'
              variant='contained'
              onClick={(event) => this.search()}
            >
              Buscar
              </Button>

          </Grid>
        </Grid>

        <CustomizedExpansionPanel tittle="Cuadro de convenciones.">
          <Grid container spacing={2} >
            <Grid item xs className={this.props.classes.gridVisorDianAlertaAlta}>PENDIENTE</Grid>
            <Grid item xs className={this.props.classes.gridVisorDianAlertaBaja}>DILIGENCIADA</Grid>
          </Grid>
        </CustomizedExpansionPanel>

        <DataGridVisor
          search={this.search}
          data={this.state.data}
          fields={VISOR_FIELDS}
          colors={VISOR_COLORS}
          moduleUrl='encuesta_pedido'
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
        />

      </Paper>
    );
  }
}

EncuestaPedidoVisor.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  toggleFavorites: PropTypes.func.isRequired,
  toogleFavoritesShow: PropTypes.func.isRequired
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(EncuestaPedidoVisor)));

function IntegrationNotistack(props) {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp {...props} />
    </SnackbarProvider>
  );
}

export default IntegrationNotistack;