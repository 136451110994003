import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "./../../assets/dashboard/scss/paper-dashboard.scss";
import "./../../assets/dashboard/demo/demo.css";

import indexRoutes from "./../../dashboard/routes/index.jsx";

const hist = createBrowserHistory();

class dashboard extends Component {
  render() {
    return (
      <Router history={hist}>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return <Route path={prop.path} key={key} component={prop.component} />;
          })}
        </Switch>
      </Router>
    );
  }
}

export default dashboard;

