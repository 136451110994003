import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './Styles';
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';

class Main extends Component {

    render() {
        const { classes } = this.props;
        return (
            <Paper elevation={1} className={classes.paper}>
                <Avatar src="/Images/landing_computer.png" className={classes.bigAvatar} />
                <form className={this.props.form} onSubmit={e => e.preventDefault()}>
                    <Link to="/Login">
                        <Button className={classes.submit}
                            fullWidth
                            variant='contained'
                            color='primary' disabled={this.props.waitForResponse}>
                            Start
                        </Button>
                    </Link>
                </form>
            </Paper>
        );
    }
}

export default withStyles(Styles, { withTheme: true })(Main);
//export default Login;