import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import Buttoncontrol from "../../../GeneralUI/Buttoncontrol";
import { withRouter } from 'react-router';
import { saveCustom } from './../../../../Actions/General'
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter } from '../../../../Libs/';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import PSelect from '../../../GeneralUI/PSelect'
import Utils from '../../../../Libs/utils';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

class PedidoPagos extends Component {

  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    fields: {
      pedidoCodigo: null,
      pedpagdetValor: null,
      pedpagdetConcepto: null,
      pedpagdetObservacion: null
    },
    responseForm: { Message: '', typeMessage: '' },
    formValidations: {
      view: ['pedidoCodigo', 'pedpagdetValor', 'pedpagdetConcepto' ]
    },
    formErrors: {
      pedidoCodigo: {},
      pedpagdetValor: {},
      pedpagdetConcepto: {}
    }
  };  

  componentDidMount = () => {
    const params = this.props.match.params;
    if (!params.id) {
      return;
    }       
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;

    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }

    if (fields[event.target.id] === '') {
      fields[event.target.id] = null;
    }

    this.setState({ fields });
  }

  messageValidation = (type, message) => {
    this.message(type, message);
  }

  save = (response) => {
    this.setState({
      fields: response.data,
      responseForm: response.responseForm
    });
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }

  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>
            <InputLabel
              className={this.props.classes.tittleLabelForm}>Pedido Pagos
            </InputLabel>
          </Grid>
        
          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id='pedidoCodigo'
                tableName='vw_pedido'
                fields={['pedido_codigo', 'pedido_razon_social', 'ruta']}
                primaryField='pedido_codigo'
                textField='pedido_razon_social'
                aliases={[{ value: 'ID', size: 2 }, { value: 'Nombre', size: 6 }, { value: 'Ruta', size: 4 }]}
                size='xx'
                searchField='codigo'
                placeHolder='Seleccionar Pedido'
                onSelect={this.handleChange}
                value={this.state.fields["pedidoCodigo"]}
                isView={true}
                formErrors={this.state.formErrors['pedidoCodigo']}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="pedpagdetConcepto"
                useChildren={true}
                value={this.state.fields["pedpagdetConcepto"]}
                onChange={this.handleChange}
                namelabel="Concepto"
                formErrors={this.state.formErrors['pedpagdetConcepto']}
              >
                <option value="ABONO PARCIAL">ABONO PARCIAL</option>
                <option value="ABONO TOTAL">ABONO TOTAL</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedpagdetValor" label="Valor (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedpagdetValor"]}
                formErrors={this.state.formErrors['pedpagdetValor']}
              />
            </Grid>
          </Grid>  

          <Grid container spacing={4}>
            <Grid item xs={10} className={this.props.classes.gridForm}>
              <TextField id="pedpagdetObservacion" label="Observacion (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedpagdetObservacion"]}
                InputLabelProps={{ shrink: true }}
                multiline
                rows="8"
                cols="80"
              />
            </Grid>
          </Grid>     

        </form>
        <div id='buttonContainer'>
          <Buttoncontrol
            saveData={this.state}
            saveDataValidation={this.state.fields}
            moduleName='pedido'
            save={this.save}
            messageValidation={this.messageValidation}
            primaryFields={['pedidoCodigo']}
            formValidations={this.state.formValidations}
            errorHandler={this.setErrors}
            url='/processes/save_pedido_pagos'
            isFormLite={true}
          />
        </div>
      </Paper>
    );
  }
}

PedidoPagos.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(PedidoPagos)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}

    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;