import React from 'react';

function Icon(props) {
  const {
    color,
    size,
  } = props
  return (
    <div>
    <svg
      fill={color}
      height={size}
      width={size}
      viewBox="0 0 24 24"
    >
      {props.children}
    </svg>
    </div>
  )
}

export default Icon