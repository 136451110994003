import _ from 'lodash';

class Utils {
  constructor() {
    this.defaultConfig = {
      minLength: 5,
      required: true,
      excludeSpace: true,
      excludeNewLine: true,
      excludeSymbols: true
    };
  }

  waitFor(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  getConfig(config) {
    let initialConfig = _.cloneDeep(this.defaultConfig);
    _.forIn(config, (val, key) => {
      initialConfig[key] = _.clone(val);
    });

    return initialConfig;
  }

  /**
   * @param  String text
   * @param {} config    
      minLength: 5,
      required: true,
      exclude: {
        space: true,
        newLine: true,
        allowSymbols: false
      }    
   */
  validateText(text, config) {
    config = this.getConfig(config);

    if (!config.required && !text) {
      return this.formatValidationResponse(true, null);
    }

    if (config.required && !text) {
      return this.formatValidationResponse(false, "Campo requerido");
    }

    if (String(text).length < config.minLength) {
      return this.formatValidationResponse(false, `Longitud minima: ${config.minLength}`);
    }

    let regex = [];
    if (config.excludeSpace) {
      regex.push({
        val: '\\s',
        msg: 'espacios'
      });
    }
    if (config.excludeNewLine) {
      regex.push({
        val: '\\n',
        msg: null
      });
    }
    if (config.excludeSymbols) {
      regex.push({
        val: '\\W',
        msg: 'simbolos'
      });
    }

    if (regex.length) {
      if (text.match(new RegExp(`[${regex.map(row => row.val).join('')}]+`, 'g'))) {
        const message = regex.map(row => row.msg).filter(Boolean);
        return this.formatValidationResponse(false, `Valor no valido: ${message.join(', ')}`);
      }
    }


    return this.formatValidationResponse(true);
  }

  formatValidationResponse(isValid, errorMessage) {
    return {
      isValid,
      errorMessage
    }
  }

  /**
   * save a js object to session storage, internally json stringified
   * 
   * @param {String} key 
   * @param {Object} val 
   */

  saveInLocalStorage(key, val) {
    localStorage.setItem(key, JSON.stringify(val));
  }

  /**
   * Get local storage value, parsed as js object if possible.
   * TODO: parse must be doe with try/catch or lodash, to avoid crashing
   * 
   * @param {String} key 
   */
  getFromLocalStorage(key) {
    let val = localStorage.getItem(key);

    if (val) {
      return JSON.parse(val);
    } else {
      return null;
    }
  }

  /**
 * Devuelve la fecha y hora actual en formato YYYY-MM-DDTHH:MM:SS
 *  
 * 
 */
  fullDateTime() {
    let fecha = new Date();

    let year = fecha.getFullYear();//el año se puede quitar de este ejemplo
    let mes = fecha.getMonth();//pero ya que estamos lo ponemos completo
    let dia = fecha.getDate();
    let hora = fecha.getHours();
    let minutos = fecha.getMinutes();
    let segundos = fecha.getSeconds();

    //aquí se hace lo 'importante'
    if (mes < 10) { mes = '0' + mes }
    if (dia < 10) { dia = '0' + dia }
    if (hora < 10) { hora = '0' + hora }
    if (minutos < 10) { minutos = '0' + minutos }
    if (segundos < 10) { segundos = '0' + segundos }

    return year + "-" + mes + "-" + dia + "T" + hora + ":" + minutos + ":" + segundos + "Z";
  }

    /**
 * Devuelve la fecha  actual en formato YYYY-MM-DD
 *  
 * 
 */
  getCurrentDate() {
  let fecha = new Date();

  let year = fecha.getFullYear();//el año se puede quitar de este ejemplo
  let mes = fecha.getMonth()+1;//pero ya que estamos lo ponemos completo
  let dia = fecha.getDate();

  //aquí se hace lo 'importante'
  if (mes < 10) { mes = '0' + mes }
  if (dia < 10) { dia = '0' + dia }

  return year + "-" + mes + "-" + dia;
}

  onlyComparateDate(fecha_actual, fecha) {
    
    var AnyoFecha = fecha.getFullYear();
    var MesFecha = fecha.getMonth();
    var DiaFecha = fecha.getDate();

    var AnyoHoy = fecha_actual.getFullYear();
    var MesHoy = fecha_actual.getMonth();
    var DiaHoy = fecha_actual.getDate();

    if (AnyoHoy > AnyoFecha) {
      return false;
    }
    else {
      if (AnyoFecha === AnyoHoy && MesHoy > MesFecha) {
        return false;
      }
      else {
        if (AnyoFecha === AnyoHoy && MesFecha === MesHoy && DiaHoy > DiaFecha) {
          return false;
        }
        else {
          if (AnyoFecha === AnyoHoy && MesFecha === MesHoy && DiaFecha === DiaHoy) {
            return true;
          }
          else {
            return true;
          }
        }
      }
    }
  }
}

export default (new Utils());