import { FORM as ACTIONS } from '../Config/actionTypes';
import { api } from '../Libs';
import { FORM as ENDPOINTS } from '../Config/endpoints';
const { SEARCH, GET_DEFINITION } = ENDPOINTS;

export const search = (params, moduleName) => dispatch => {
  dispatch({
    type: ACTIONS.BEFORE_API_REQUEST
  });

  api.doRequest({
    endpoint: SEARCH.url(moduleName),
    method: SEARCH.method
  }, params).then(data => {
    dispatch({
      type: ACTIONS.DATA_RECEIVED,
      payload: data
    });
  }).catch(error => {
    dispatch({
      type: ACTIONS.ERROR,
      payload: error
    });
  });
};

export const getModuleDefinition = (moduleName, callback) => dispatch => {
  dispatch({
    type: ACTIONS.BEFORE_API_REQUEST
  });

  api.doRequest({
    endpoint: GET_DEFINITION.url(moduleName),
    method: GET_DEFINITION.method
  }, {}).then(data => {
    dispatch({
      type: ACTIONS.FIELDS_RECEIVED,
      payload: data
    });
    return callback();
  }).catch(error => {
    dispatch({
      type: ACTIONS.ERROR,
      payload: error
    });
    return callback();
  });
};

export const clearData = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_DATA
  });
}
