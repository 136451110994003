export const AUTH = {
  LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  VALIDATE_USERNAME: 'AUTH_VALIDATE_USERNAME',
  LOGOUT: 'AUTH_LOGOUT',
  ERROR: 'AUTH_ERROR',
  BEFORE_VALIDATION: 'AUTH_BEFORE_VALIDATION',
  AFTER_VALIDATION: 'AUTH_AFTER_VALIDATION',
  INITIAL_STATE: 'USER_INITIAL_STATE'
}

export const USER = {
  BEFORE_VALIDATION: 'USER_BEFORE_VALIDATION',
  LOAD_USER_INFO: 'USER_LOAD_USER_INFO',
  LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  INITIAL_STATE: 'USER_INITIAL_STATE',
  HIDE_FAVORITES: 'HIDE_FAVORITES',
  SHOW_FAVORITES: 'SHOW_FAVORITES'
}

export const FORM = {
  BEFORE_VALIDATION: 'FORM_BEFORE_VALIDATION',
  AFTER_VALIDATION: 'FORM_AFTER_VALIDATION',
  FORM_SUBMIT: 'FORM_SUBMIT',
  FORM_QUERY: 'FORM_QUERY',
  BEFORE_API_REQUEST: 'BEFORE_API_REQUEST',
  AFTER_API_REQUEST: 'AFTER_API_REQUEST',
  DATA_RECEIVED: 'FORM_DATA_RECEIVED',
  FIELDS_RECEIVED: 'FORM_FIELDS_RECEIVED',
  CLEAR_DATA: 'CLEAR_DATA',
  ERROR: 'FORM_ERROR'
}

export const PSELECT = {
  BEFORE_API_REQUEST: 'BEFORE_API_REQUEST',
  AFTER_API_REQUEST: 'AFTER_API_REQUEST',
  DATA_RECEIVED: 'DATA_RECEIVED',
  FIELDS_RECEIVED: 'FIELDS_RECEIVED',
  ERROR: 'PSELECT_ERROR'
}