import _ from 'lodash';

class validations {
  setErrors(errors) {
    this.setState({
      formErrors: errors
    })
  }

  validateFormFields = (dataValidation, formValidations) => {
    let isValid = true;
    let errors = {};

    _.forEach(formValidations.view, key => {
      errors[key] = {};

      if (dataValidation[key].value === undefined) {
        errors[key] = { error: true, errorMessage: 'Valor requerido' };
        isValid = false;
      }

      if (dataValidation[key].value === null) {
        errors[key] = { error: true, errorMessage: 'Valor requerido' };
        isValid = false;
      }

      if (!String(dataValidation[key].value).trim()) {
        errors[key] = { error: true, errorMessage: 'Campo no puede estar vacio' };
        isValid = false;
      }

    });

    return { isValid, errors };
  }
}

export default (new validations());