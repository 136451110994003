import _ from 'lodash';

const ENDPOINTS = {
  BASE: '/api',
  USER: {
    LOGIN: {
      url: '/user/login',
      method: 'POST'
    },
    VALIDATE_USERNAME: {
      url: '/user/validateUserName',
      method: 'POST'
    },
    VALIDATE_TOKEN: {
      url: '/user/validateToken',
      method: 'POST'
    },
  },
  FAVORITES: {
    GET: {
      url: '/getFavorites',
      method: 'POST'
    },
    DELETE: {
      url: '/deleteFavorite',
      method: 'POST'
    },
    ADD: {
      url: '/addFavorite',
      method: 'POST'
    }
  },
  FORM: {
    SEARCH: {
      url: (...params) => compileUrl('/modules/:module/list', params),
      method: 'POST'
    },
    GET_DEFINITION: {
      url: (...params) => compileUrl('/modules/:module/definition', params),
      method: 'POST'
    },
    PSELECT: {
      url: (...params) => compileUrl('/modules/:module/list', params),
      method: 'POST'
    },
    SAVE: {
      url: (...params) => compileUrl('/modules/:module/save', params),
      method: 'POST'
    },
    EDIT: {
      url: (...params) => compileUrl('/modules/:module/edit', params),
      method: 'POST'
    },
    REMOVE: {
      url: (...params) => compileUrl('/modules/:module/delete', params),
      method: 'POST'
    }
  },
  SEARCH: {
    url: (...params) => compileUrl('/modules/:module/list', params),
    method: 'POST'
  },
}

function compileUrl(url, ...params) {
  let newUrl = _.clone(url);
  params = params.filter(Boolean);
  params.forEach(val => {
    newUrl = newUrl.replace(/:\w+/, val);
  });

  return newUrl;
}

export default ENDPOINTS;
export const USER = ENDPOINTS.USER;
export const FORM = ENDPOINTS.FORM;
export const FAVORITES = ENDPOINTS.FAVORITES;