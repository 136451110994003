import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './Style';
import Favorite from '@material-ui/icons/Favorite';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import Modalfavouries from '../Bodymodals/Favouries';
import Modal from '../Dialog'
import ListItem from '@material-ui/core/ListItem';
import { getFavorites } from '../../../Actions/General';

class Myfavourites extends Component {
  state = {
    isWaiting: false,
    favorites: []
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  componentDidMount() {
    if (this.state.isWaiting) {
      return;
    }

    this.setState({ isWaiting: true });
    getFavorites().then(rows => this.setState({ favorites: rows, isWaiting: false }));
  }

  changeFavorites = (rows) => {
    this.setState({ favorites: rows })
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={5} className={classes.paper}>

        <Grid container spacing={2} className={classes.paperGrid}>
          <Grid item xs={2}>
            <Favorite fontSize="small" className={classes.iconFavorite} />
          </Grid>
          <Grid item xs={6}>
            <InputLabel id="color_codigo" className={classes.titlelabelform}>
              Mis Favoritas
            </InputLabel>          </Grid>
          <Grid item xs={2}>
            <Modal
              sizeIcon={20}
              colorIcon="#2196f3"
              icon="Configuration"
            ><Modalfavouries onChange={this.changeFavorites} /></Modal>
          </Grid>
        </Grid>

        <Grid container direction='row' >
          {
            this.state.favorites.map((item, index) => {
              return (
                <ListItem key={index} className={classes.listitem} >
                  <Link to={`/home/${item['Pagina.paginaRuta']}`} className={classes.link}>
                    <Button id={item.usupagfavCodigo} className={classes.valuelabelform} fullWidth>
                      {item['Pagina.paginaNombre']}
                    </Button>
                  </Link>
                </ListItem>
              );
            })
          }
        </Grid>

      </Paper>
    );
  }
}

export default withStyles(Styles, { withTheme: true })(Myfavourites);