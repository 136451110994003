import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import Buttoncontrol from "../../../GeneralUI/Buttoncontrol";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter, combineStyles } from '../../../../Libs/';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import PSelect from '../../../GeneralUI/PSelect'


const styles = theme => ({
  button: {
    margin: theme.spacing(),
  },
  input: {
    display: 'none',
  }
});

class FacturaAnular extends Component {
  constructor(props) {
    super(props);
    this.setErrors = errorSetter.setErrors.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    data: [],
    fields: {
      facturaLiberarPedido: null,
      facturaAnularPedido: null,
      facturaLiberarResolucion: null,
      facturaCodigo:null
    },
    responseForm: { Message: '', typeMessage: '' },
    formValidations: {view: []},
    formErrors: {    }
  };

  componentDidMount() {
    const params = this.props.match.params;
    if (!params.id) {
      return;
    }
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }

    if (fields[event.target.id] === '') {
      fields[event.target.id] = null;
    }

    this.setState({ fields });
  }

  save = (response) => {

    let newState={
      data: response.data,
      responseForm: response.responseForm
    };

    this.setState(newState);

    this.message(newState.responseForm['typeMessage'], newState.responseForm['Message']);
  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }

  messageValidation = (type, message) => {
    this.message(type, message);
  }

  validaData = () => {
    let isvalid = true, message = 'El registro no ha sido guardado.', type = "warning";

    if ((this.state.fields.facturaCodigo == null || !String(this.state.fields.facturaCodigo).trim())) {
      message = "Debe diligenciar la factura.";
      isvalid = false;
    }

    if ((this.state.fields.facturaLiberarResolucion == null || !String(this.state.fields.facturaLiberarResolucion).trim())) {
      message = "Debe indicar si desea liberar o no la resolucion.";
      isvalid = false;
    }
    
    if (this.state.fields.facturaLiberarPedido === 'SI' && this.state.fields.facturaAnularPedido === 'SI'){
      message = "No es posible liberar y anular un pedido a la vez.";
      isvalid = false;
    }

    if (!isvalid) {
      this.message(type, message);
    }
    return isvalid;
  }

  render() {
    const { classes } = this.props;

    return (

      <Paper elevation={5} className={classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={classes.gridForm}>
            <InputLabel className={classes.tittleLabelForm}>
              Anular Factura.
            </InputLabel>
          </Grid>

          
          <Grid container spacing={2}>  
            <Grid item xs={3} className={classes.gridForm}>
              <AutoComplete
                id="facturaCodigo"
                tableName='vw_factura_visor_dian'
                fields={['FACTURA_CODIGO', 'RESOLUCION', 'CLIENTE']}
                aliases={[{ value: 'ID', size: 4 },  { value: 'RESOLUCION', size: 3 },  { value: 'Cliente', size: 5 }]}
                size='xl'
                primaryField='FACTURA_CODIGO'
                searchField='RESOLUCION'
                isView={true}
                placeHolder='Factura'
                onSelect={this.handleChange}
                value={this.state.fields["facturaCodigo"]}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <PSelect
                  id="facturaLiberarPedido"
                  useChildren={true}
                  value={this.state.fields["facturaLiberarPedido"]}
                  onChange={this.handleChange}
                  namelabel="Liberar Pedido"
                >
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </PSelect>
              </Grid>    
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <PSelect
                  id="facturaAnularPedido"
                  useChildren={true}
                  value={this.state.fields["facturaAnularPedido"]}
                  onChange={this.handleChange}
                  namelabel="Anular Pedido"
                >
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </PSelect>
              </Grid>      
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <PSelect
                  id="facturaLiberarResolucion"
                  useChildren={true}
                  value={this.state.fields["facturaLiberarResolucion"]}
                  onChange={this.handleChange}
                  namelabel="Liberar Resolucion"
                >
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </PSelect>
              </Grid>   
          </Grid>
        </form>

        <div id='buttonContainer'>
          <Buttoncontrol
            saveData={this.state}
            saveDataValidation={this.state.fields}
            moduleName='factura'
            save={this.save}
            validaData={this.validaData}
            messageValidation={this.messageValidation}
            primaryFields={['facturaCodigo']}
            formValidations={this.state.formValidations}
            errorHandler={this.setErrors}
            url='/processes/anular_factura'
            isFormLite={true}
          />
        </div>

      </Paper>
    );
  }
}



FacturaAnular.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(combineStyles(styles, Styles))(FacturaAnular)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;