import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Textfieldlabel from "../GeneralUI/Textfieldlabel";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';

import AutoComplete from '../GeneralUI/AutoComplete';

import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';
import PSelect from '../GeneralUI/PSelect';


class Ubicacion extends Component {

  state = {
    fields: {
      ubicacionCodigo: null,
      ubicacionNombre: null,
      ubicacionAlias: null,
      estructuraCodigo: null,
      ubicacionFila: null,
      ubicacionColumna: null,
      ubicacionProfundidad: null,
      ubicacionCapacidadPeso: null,
      ubicacionCapacidadVolumen: null,
      ubicacionEstado: null,      
      ubicacionTipo: null,
      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime()
    },
    responseForm: { Message: '', typeMessage: '' },
    formErrors: {
    }
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'ubicacionCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }
    this.setState({ fields });
  }

  save = (response) => {
    this.setState({
      fields: response.data,
      responseForm: response.responseForm
    });
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }


  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>

            <InputLabel id="ubicacionCodigo"
              className={this.props.classes.tittleLabelForm}>Ubicacion
            </InputLabel>

            <Grid item xs={6} className={this.props.classes.chipTitle}>
              {(this.state.fields.ubicacionCodigo !== null) ?
                (<Chip
                  label={this.state.fields["ubicacionCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="ubicacionNombre" label="Nombre" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["ubicacionNombre"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="ubicacionAlias" label="Alias" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["ubicacionAlias"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="almacenCodigo"
                tableName='Almacen'
                fields={['almacenCodigo', 'almacenNombre']}
                primaryField='almacenCodigo'
                aliases={['ID', 'Nombre']}
                searchField='almacenNombre'
                placeHolder='Seleccionar Almacen'
                onSelect={this.handleChange}
                value={this.state.fields["almacenCodigo"]}
                formErrors={this.state.formErrors['almacenCodigo']}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="bodegaCodigo"
                  tableName='vw_bodega_search'
                  fields={['bodegaCodigo', 'bodegaNombre' , 'almacenNombre']}
                  aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 4 }, { value: 'Almacen', size: 4 }]}
                  size='xx'
                  isView={true}
                  primaryField='bodegaCodigo'
                  searchField='bodegaNombre'
                  placeHolder='Seleccionar Bodega'
                  onSelect={this.handleChange}
                  value={this.state.fields["bodegaCodigo"]}
                  formErrors={this.state.formErrors['bodegaCodigo']}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                  id="estructuraCodigo"
                  tableName='vw_estructura_search'
                  fields={['estructuraCodigo', 'estructuraNombre' , 'almacenNombre', 'bodegaNombre']}
                  aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 4 }, { value: 'Almacen', size: 3 },{ value: 'Bodega', size: 4 }]}
                  size='xx'
                  isView={true}
                  primaryField='estructuraCodigo'
                  searchField='estructuraNombre'
                  placeHolder='Seleccionar Estructura'
                  onSelect={this.handleChange}
                  value={this.state.fields["estructuraCodigo"]}
                  formErrors={this.state.formErrors['estructuraCodigo']}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="ubicacionFila" label="Fila" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["ubicacionFila"]}
                  InputLabelProps={{ shrink: true }}
                />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="ubicacionColumna" label="Columna" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["ubicacionColumna"]}
                  InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="ubicacionProfundidad" label="Profundidad" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["ubicacionProfundidad"]}
                  InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="ubicacionCapacidadPeso" label="Capacidad Peso" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["ubicacionCapacidadPeso"]}
                  InputLabelProps={{ shrink: true }}
                />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="ubicacionCapacidadVolumen" label="Capacidad Volumen" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["ubicacionCapacidadVolumen"]}
                  InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="ubicacionTipo"
                useChildren={true}
                value={this.state.fields["ubicacionTipo"]}
                onChange={this.handleChange}
                namelabel="Tipo"
              >
                <option value="EXCLUSIVA">EXCLUSIVA</option>
                <option value="CONTROLADA">CONTROLADA</option>
              </PSelect>
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="ubicacionEstado"
                useChildren={true}
                value={this.state.fields["ubicacionEstado"]}
                onChange={this.handleChange}
                namelabel="Estado"
              >
                <option value="ACTIVO">ACTIVO</option>
                <option value="INACTIVO">INACTIVO</option>
              </PSelect>
            </Grid>
          </Grid>  

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Usuario" value={Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Creacion" value={this.state.fields.createdAt} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Modificacion" value={this.state.fields.updatedAt} />
            </Grid>
          </Grid>


        </form>
        <div><Buttoncontrol
          saveData={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          primaryFields={['ubicacionCodigo']}
        /></div>
      </Paper>
    );
  }
}

Ubicacion.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(Ubicacion)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;