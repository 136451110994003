import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Textfieldlabel from "../GeneralUI/Textfieldlabel";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import CustomizedExpansionPanel from './../GeneralUI/PExpansionpanels'
import AutoComplete from '../GeneralUI/AutoComplete';

import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';

class Manifiesto extends Component {

  state = {
    fields: {
      manifiestoCodigo: null,
      manifiestoNumeroMinisterio: null,
      rutaCodigo: null,

      vehiculoCodigo: null,
      terceroCodigoPoseedor: null,
      terceroCodigoConductor: null,

      terceroCodigoPropietario: null,
      manifiestoFlete: null,
      manifiestoAnticipo: null,

      manifiestoTotalImpuestos: null,
      manifiestoTotalDescuentos: null,
      manifiestoTotalAdicionales: null,

      manifiestoTotalNota_debito: null,
      manifiestoTotalNota_credito: null,
      manifiestoValorCancela: null,

      manifiestoObservacion: null,

      manifiestoErrorRndc: null,

      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime()
    },
    responseForm: { Message: '', typeMessage: '' }
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'manifiestoCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }
    this.setState({ fields });
  }

  save = (response) => {
    this.setState({
      fields: response.data,
      responseForm: response.responseForm
    });
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }

  handleAuto = (autoCompleteValue) => {
    this.setState({ colorAutoComplete: autoCompleteValue });
  }


  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>

            <InputLabel id="manifiestoCodigo"
              className={this.props.classes.tittleLabelForm}>Manifiesto
            </InputLabel>

            <Grid item xs={6} className={this.props.classes.chipTitle}>
              {(this.state.fields.manifiestoCodigo !== null) ?
                (<Chip
                  label={this.state.fields["manifiestoCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="manifiestoNumeroMinisterio" label="Peso Viaje (*)" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["manifiestoNumeroMinisterio"]}
                InputLabelProps={{ shrink: true }}
              /></Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="rutaCodigo"
                tableName='Ruta'
                fields={['rutaCodigo', 'rutaIndicacion']}
                primaryField='rutaCodigo'
                aliases={['ID', 'Nombre']}
                searchField='rutaIndicacion'
                placeHolder='Seleccionar Ruta'
                onSelect={this.handleChange}
                value={this.state.fields["rutaCodigo"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="vehiculoCodigo"
                tableName='Vehiculo'
                fields={['vehiculoCodigo', 'vehiculoPlaca']}
                primaryField='vehiculoCodigo'
                aliases={['ID', 'Nombre']}
                searchField='vehiculoPlaca'
                placeHolder='Seleccionar Placa'
                onSelect={this.handleChange}
                value={this.state.fields["vehiculoCodigo"]}
              />
            </Grid>


          </Grid>

          <Grid container spacing={4}>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="terceroCodigoConductor"
                tableName='Tercero'
                fields={['terceroCodigo', 'terceroDocumento', 'terceroNombre1', 'terceroApellido1']}
                primaryField='terceroCodigo'
                aliases={['ID', 'Nombre']}
                searchField='terceroNombre1'
                placeHolder='Seleccionar Conductor'
                onSelect={this.handleChange}
                value={this.state.fields["terceroCodigoConductor"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="terceroCodigoPoseedor"
                tableName='Tercero'
                fields={['terceroCodigo', 'terceroDocumento', 'terceroNombre1', 'terceroApellido1']}
                primaryField='terceroCodigo'
                aliases={['ID', 'Nombre']}
                searchField='terceroNombre1'
                placeHolder='Seleccionar Poseedor'
                onSelect={this.handleChange}
                value={this.state.fields["terceroCodigoPoseedor"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="terceroCodigoPropietario"
                tableName='Tercero'
                fields={['terceroCodigo', 'terceroDocumento', 'terceroNombre1', 'terceroApellido1']}
                primaryField='terceroCodigo'
                aliases={['ID', 'Nombre']}
                searchField='terceroNombre1'
                placeHolder='Seleccionar Propietario'
                onSelect={this.handleChange}
                value={this.state.fields["terceroCodigoPropietario"]}
              />
            </Grid>
          </Grid>


          <Grid container spacing={4}>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="manifiestoFlete" label="Flete (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["manifiestoFlete"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="manifiestoAnticipo" label="Anticipo (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["manifiestoAnticipo"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="manifiestoTotalImpuestos" label="Impuesto (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["manifiestoTotalImpuestos"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="manifiestoTotalDescuentos" label="Descuentos (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["manifiestoTotalDescuentos"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="manifiestoTotalAdicionales" label="Anticipo (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["manifiestoTotalAdicionales"]}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="manifiestoTotalNotaDebito" label="NotaDebito (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["manifiestoTotalNotaDebito"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>


          <Grid container spacing={4}>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="manifiestoTotalNotaCredito" label="NotaCredito (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["manifiestoTotalNotaCredito"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="manifiestoValorCancela" label="ValorCancela (*)" type="number"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["manifiestoValorCancela"]}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={10} className={this.props.classes.gridForm}>
              <TextField id="manifiestoObservacion" label="Observacion" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["manifiestoObservacion"]}
                InputLabelProps={{ shrink: true }}
                multiline
                rows="4"
                cols="100"
              />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={10} className={this.props.classes.gridForm}>
              <TextField id="manifiestoErrorRndc" label="Error Ministerio" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["manifiestoErrorRndc"]}
                InputLabelProps={{ shrink: true }}
                multiline
                rows="4"
                cols="100"
              />
            </Grid>
          </Grid>

 
          <Grid  >
            <CustomizedExpansionPanel tittle="DATOS CUMPLIDO MANIFIESTO ***CARGUE***">
            </CustomizedExpansionPanel>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Cita"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Cita"] || undefined}
                type="date"
                label="Fecha Cita Cargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="hORACita"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Cita"] || undefined}
                type="time"
                label="Hora Cita Cargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>         

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Llegada"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Llegada"] || undefined}
                type="date"
                label="Fecha Llegada Cargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Llegada"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Llegada"] || undefined}
                type="time"
                label="Hora Llegada Cargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>  

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Entrada"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Entrada"] || undefined}
                type="date"
                label="Fecha Entrada Cargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Entrada"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Entrada"] || undefined}
                type="time"
                label="Hora Entrada Cargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>  

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Salida"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Salida"] || undefined}
                type="date"
                label="Fecha Salida Cargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Salida"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Salida"] || undefined}
                type="time"
                label="Hora Salida Cargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>  

          <Grid  >
            <CustomizedExpansionPanel tittle="DATOS CUMPLIDO MANIFIESTO ***DESCARGUE***">
            </CustomizedExpansionPanel>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Cita"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Cita"] || undefined}
                type="date"
                label="Fecha Cita Descargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="hORACita"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Cita"] || undefined}
                type="time"
                label="Hora Cita Descargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>         

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Llegada"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Llegada"] || undefined}
                type="date"
                label="Fecha Llegada Descargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Llegada"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Llegada"] || undefined}
                type="time"
                label="Hora Llegada Descargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>  

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Entrada"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Entrada"] || undefined}
                type="date"
                label="Fecha Entrada Descargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Entrada"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Entrada"] || undefined}
                type="time"
                label="Hora Entrada Descargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>  

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Salida"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Salida"] || undefined}
                type="date"
                label="Fecha Salida Descargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="Salida"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["Salida"] || undefined}
                type="time"
                label="Hora Salida Descargue"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>  

          <Grid container spacing={10} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Usuario" value={Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Creacion" value={this.state.fields.createdAt} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Modificacion" value={this.state.fields.updatedAt} />
            </Grid>
          </Grid>


        </form>
        <div><Buttoncontrol
          saveData={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          edit={(this.state.fields.manifiestoCodigo !== null) ? true : false}
          primaryFields={['manifiestoCodigo']}
        /></div>
      </Paper>
    );
  }
}

Manifiesto.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(Manifiesto)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}

    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;