import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from './Style';
import Grid from '@material-ui/core/Grid';
import { Save, Autorenew, Print, Search, FilterNone } from '@material-ui/icons/'

import { save, saveCustom } from './../../../Actions/General'

import { Link } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import obj from './../../../Libs/menu_impresiones.json';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';

class Buttoncontrol extends Component {
  state = {
    menuPrint: {
      anchorEl: null,
      open: false,
      placement: null
    },
    menuNew: {
      anchorEl: null,
      open: false,
      placement: null
    }

  }

  validateFormFields = () => {
    let isValid = true;
    let errors = {};

    let dataValidation = this.getWhoisFields();

    _.forEach(this.props.formValidations.view, key => {
      errors[key] = {};

      if (dataValidation[key] === undefined) {
        errors[key] = { error: true, errorMessage: 'Valor requerido' };
        isValid = false;
      }

      if (dataValidation[key] === null) {
        errors[key] = { error: true, errorMessage: 'Valor requerido' };
        isValid = false;
      }

      if (!String(dataValidation[key]).trim()) {
        errors[key] = { error: true, errorMessage: 'Campo no puede estar vacio' };
        isValid = false;
      }

    });

    return { isValid, errors };
  }

  getSaveFunction() {
    if (this.props.url) {
      return saveCustom(this.props.saveData, this.props.url);
    } else {
      return save(this.props.saveData, this.props.moduleName, this.props.primaryFields);
    }
  }

  handlerSave = () => {
    if (this.props.formValidations && this.props.errorHandler) {
      const validations = this.validateFormFields();
      this.props.errorHandler(validations.errors);

      if (!validations.isValid && (this.props.messageValidation !== null && this.props.messageValidation !== undefined)) {
        this.props.messageValidation("warning", "Hay campos obligatorios sin llenar");
        return;
      }

    }

    if (this.props.validaData) {
      if (!this.props.validaData()) {
        return;
      }
    }

    this.getSaveFunction().then(data => {
      this.setState({
        data: data,
        responseForm: { Message: "El Registro ha sido Guardado.", typeMessage: "success" }
      })
      this.props.save(this.state)
    }).catch(error => {
      this.setState({
        data: this.props.saveData,
        responseForm: { Message: "El Registro NO ha sido Guardado." + error.errorMessage, typeMessage: "error" }
      })
      this.props.save(this.state);
    });
  }

  handleClick = (event) => {

    if (event.target.id === 'id_new') {
      this.setState({ menuNew: { anchorEl: event.currentTarget, placement: event.currentTarget } });
    } else {
      this.setState({ menuPrint: { anchorEl: event.currentTarget, placement: event.currentTarget } });
    }
  }


  getValuePk() {

    let fields = this.getWhoisFields();

    return fields[this.props.primaryFields];
  }

  handleClose = () => {
    this.setState({ menuPrint: { anchorEl: null, placement: null }, menuNew: { anchorEl: null, placement: null } });
  }

  getWhoisFields() {
    return this.props.saveDataValidation ? this.props.saveDataValidation : this.props.saveData;
  }

  getMenuItem = (item, index) => {
    let fields = this.getWhoisFields();
    return (
      <Link key={index} target="_blank" to={item.pk ? item.link +"&ID=" + fields[item.pk] : item.link } className={this.props.classes.link} >
        <MenuItem id={item.id} onClick={this.handleClose} className={this.props.classes.valuelabelform}>{item.name}</MenuItem>
        <Divider />
      </Link >
    );
  }


  render() {
    return (
      <Paper elevation={5} className={!this.props.isFormLite ? this.props.classes.paper : this.props.classes.paperLite} >

        <Grid container className={this.props.classes.paperGrid} >
          <Grid container className={this.props.classes.paperGrid}>
            {!this.props.paramsInforme ? (
              <Tooltip title="Save" placement="left-start">
                <Save classes={{ root: this.props.classes.iconGreen, }} onClick={this.handlerSave} />
              </Tooltip>
            ) : (
                <Link to={`${this.props.paramsInforme}`} target="_blank">
                  <Tooltip title="Imprimir" placement="left-start">
                    <FilterNone classes={{ root: this.props.classes.iconGreen, }} />
                  </Tooltip>
                </Link>
              )}
          </Grid>


          {!this.props.isFormLite ? (
            <Grid container className={this.props.classes.paperGrid}>
              <Tooltip title="Print" placement="left-start">
                <Print id="id_print" classes={{ root: this.props.classes.iconGray, }} onClick={this.handleClick} />
              </Tooltip>
              {
                obj[this.props.moduleName] && this.props.edit ? (
                  <Menu
                    id="simple-menu"
                    keepMounted
                    anchorEl={this.state.menuPrint.anchorEl}
                    onClose={this.handleClose}
                    open={Boolean(this.state.menuPrint.anchorEl)}
                    placement="left-start"
                  >
                    {obj[this.props.moduleName].map((item, index) => this.getMenuItem(item, index))}
                  </Menu>
                ) : null}
            </Grid>
          ) : null}

          <Grid container className={this.props.classes.paperGrid}>
            <Tooltip title="New" placement="left-start">
              <Autorenew id="id_new" classes={{ root: this.props.classes.iconBlue, }} onClick={this.handleClick} />
            </Tooltip>
            {
              obj[this.props.moduleName] && this.props.edit ? (
                <Menu
                  id="simple-menu-new"
                  keepMounted
                  anchorEl={this.state.menuNew.anchorEl}
                  onClose={this.handleClose}
                  open={Boolean(this.state.menuNew.anchorEl)}
                  placement="left-start"
                >
                  <Link key={0} target="_blank" to={`/home/${this.props.moduleName}/`} className={this.props.classes.link} >
                    <MenuItem id={0} onClick={this.handleClose} className={this.props.classes.valuelabelform}>New</MenuItem>
                    <Divider />
                  </Link >

                  <Link key={1} target="_blank" to={`/home/${this.props.moduleName}/?clone=true&${this.props.primaryFields}=${this.getValuePk()}`} className={this.props.classes.link} >
                    <MenuItem id={0} onClick={this.handleClose} className={this.props.classes.valuelabelform}>Clone</MenuItem>
                    <Divider />
                  </Link >

                </Menu>
              ) : null}
          </Grid>

          {!this.props.isFormLite ? (
            <Grid container className={this.props.classes.paperGrid}>
              <Link to={`/home/${this.props.moduleName}/search`}>
                <Tooltip title="Search" placement="left-start">
                  <Search classes={{ root: this.props.classes.iconGray, }} />
                </Tooltip>
              </Link>
            </Grid>
          ) : null}

        </Grid>
      </Paper>
    );
  }
}

Buttoncontrol.propTypes = {
  saveData: PropTypes.object.isRequired,
  moduleName: PropTypes.string.isRequired,
  primaryFields: PropTypes.array.isRequired,
  save: PropTypes.func.isRequired,
  formValidations: PropTypes.object,
  errorHandler: PropTypes.func,
  validaData: PropTypes.func,
  url: PropTypes.string,
  isFormLite: PropTypes.bool
}

export default withStyles(Styles, { withTheme: true })(Buttoncontrol);
