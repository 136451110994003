import createStyles from '@material-ui/core/styles/createStyles';

const styles = theme => createStyles({
  mainPaper: {
    minHeight: theme.spacing(45),
    backgroundColor: '#f1f1f1',
    /*display: 'inline-table',*/
  },
  rootTitlle: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    Top: 0,
    Left: 0,
    width: '50%',
    height: '10%',

  },
  rootContent: {
    margin: 0,
    padding: theme.spacing(2),
  },
  paper: {
    flexGrow: 1,
    padding: 0,
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 4,
    display: 'solid',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  paperList: {
    flexGrow: 1,
    padding: 0,
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 4,
    display: 'solid',
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(3),
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },
  paperGrid: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    width: '80%'
  },
  paperGridTitle: {
    backgroundColor: '#2196f3',
    marginLeft: 0,
    marginTop: 0,
    marginBottom: 0
  },


  titlelabelform: {
    color: '#2196f3',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    marginLeft: theme.spacing(1)
  },

  titlelabelformwhite: {
    color: '#ffffff',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    marginLeft: theme.spacing(1)
  },

  valuelabelform: {
    borderSpacing: 2,
    borderBottom: 0,
    color: '#5e5e5e',
    fontSize: '0.7rem',
    fontWeight: 'bold',
    verticalAlign: 'top',
  },
  link: {
    '&:focus': {
      backgroundColor: 'yellow',
      cursor: 'hand',
    },
    textDecoration: 'none',
    marginLeft: theme.spacing(2),
  },
  search: {
    width: '90%',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(),
    marginLeft: theme.spacing(),
    left: 5,
    border: 1,
    borderColor: '#C4C4C4',
    borderStyle: 'solid'
  },
  searchIcon: {
    width: theme.spacing(3),
    pointerEvents: 'none',
    marginLeft: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  adornment: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  inputRoot: {
    color: 'inherit',
    width: '90%',
  },
  gridDelete: {
    display: 'contents',
    verticalAlign: 'middle'
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    width: '90%',
    color: 'black',
  },
  iconContainer: {
    display: 'flex'
  },
  iconFavorite: {
    color: 'red'
  },
  iconDelete: {
    color: 'red',
    fontWeight: 'bold'
  },
  gridForm: {
    marginLeft: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
  },
  gridButtonSearch: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'block',
    alignItems: 'left',
  },
  listitem: {
    paddingTop: 0,
    paddingBottom: 0,
  }
});

export default styles;