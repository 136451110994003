import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { search } from '../../../../Actions/General';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter } from '../../../../Libs';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import DataGridVisor from '../../../GeneralUI/DataGridVisor';
import to from 'await-to-js';
import PSelect from '../../../GeneralUI/PSelect';
import _ from 'lodash';
import CustomizedExpansionPanel from '../../../GeneralUI/PExpansionpanels'


const VISOR_FIELDS = [{
  name: 'Codigo',
  alias: 'Codigo',
  isPrimary: true
},
{
  name: 'pedidoCodigo',
  alias: 'Pedido'
},
{
  name: 'Cliente',
  alias: 'Cliente'
},
{
  name: 'Sucursal',
  alias: 'Sucursal'
},
{
  name: 'listaEmpaqueEstado',
  alias: 'Lista Empaque Estado'
},
{
  name: 'pedidoEstado',
  alias: 'Pedido Estado'
},
{
  name: 'fechaServicio',
  alias: 'Fecha Servicio'
},
{
  name: 'fechaListaEmpaque',
  alias: 'Fecha Lista Empaque'
}, {
    name: 'diasTrascurridos',
    alias: 'Indicador',
    colorize: true
}];
  
const VISOR_COLORS = {
ATRASADO: '#FF3933',
ATIEMPO: '#3380FF'
}

class ListaEmpaqueVisor extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    page: 0,
    rowsPerPage: 10,
    data: {
      rows: [],
      count: 0
    },
    filters: {
      pedidoCodigo: null,
      clienteCodigo: null,
      sucursalCodigo: null,
      listaEmpaqueEstado: null,
      pedidoEstado: null,
      fechaServicio: null,
      fechaListaEmpaque: null,
      diasTrascurridos: null,
    },
    operatorCustom: [],
    responseForm: { Message: '', typeMessage: '' },
    formErrors: {
    }
  };

  componentDidMount() {
    this.props.toggleFavorites();
  }

  componentWillUnmount() {
    this.props.toogleFavoritesShow();
  }

  search = async (page, rowsPerPage) => {
    if (page !== undefined) {
      await to(new Promise((resolve) => {
        this.setState({
          page,
          rowsPerPage
        }, resolve)
      }));
    }

    this.state.operatorCustom = [];
    let filters = {};

    if (this.state.filters.pedidoCodigo !== null && this.state.filters.pedidoCodigo !== '') {
      this.state.operatorCustom.push("\"pedidoCodigo\" = " + this.state.filters.pedidoCodigo + "");
    }

    if (this.state.filters.clienteCodigo !== null && this.state.filters.clienteCodigo !== '') {
      this.state.operatorCustom.push("\"clienteCodigo\" = " + this.state.filters.clienteCodigo + "");
    }

    if (this.state.filters.sucursalCodigo !== null && this.state.filters.sucursalCodigo !== '') {
      this.state.operatorCustom.push("\"sucursalCodigo\" = " + this.state.filters.sucursalCodigo + "");
    }

    if (this.state.filters.listaEmpaqueEstado !== null && this.state.filters.listaEmpaqueEstado !== '') {
      this.state.operatorCustom.push("\"listaEmpaqueEstado\" = '" + this.state.filters.listaEmpaqueEstado + "'");
    }

    if (this.state.filters.pedidoEstado !== null && this.state.filters.pedidoEstado !== '') {
      this.state.operatorCustom.push("\"pedidoEstado\" = '" + this.state.filters.pedidoEstado + "'");
    }

    if (this.state.filters.fechaServicio !== null && this.state.filters.fechaServicio !== '') {
      this.state.operatorCustom.push("\"fechaServicio\" = '" + this.state.filters.fechaServicio + "'");
    }

    if (this.state.filters.fechaListaEmpaque !== null && this.state.filters.fechaListaEmpaque !== '') {
      this.state.operatorCustom.push("\"fechaListaEmpaque\" = '" + this.state.filters.fechaListaEmpaque + "'");
    }

    if (this.state.filters.diasTrascurridos !== null && this.state.filters.diasTrascurridos !== '') {
      this.state.operatorCustom.push("\"diasTrascurridos\" = '" + this.state.filters.diasTrascurridos + "'");
    }

    _.each(_.keys(this.state.filters), (key) => {
      const value = this.state.filters[key];
      if (value !== null && value !== '') {
        filters[key] = value;
      }
    });

    const [err, data] = await to(search({
      fields: VISOR_FIELDS.map(row => row.name),
      filters: filters,
      operatorCustom: this.state.operatorCustom,
      isView: true,
      options: {
        limit: this.state.rowsPerPage,
        skip: this.state.page * this.state.rowsPerPage,
        order: { fechaListaEmpaque: 'DESC' }
      }
    }, '/modules/vw_lista_empaque_visor/list'));

    if (err) {
      return;
    }

    this.setState({
      data: data
    });
  }

  handleChange = (event) => {
    let { filters } = this.state;
    filters[event.target.id] = event.target.value;
    this.setState({ filters });
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper elevation={5} className={`${classes.mainPaper} ${classes.fullWidthPaper}`}>
        <Grid container spacing={4}>
          <Grid item xs={3} className={classes.gridForm}>
            <InputLabel className={classes.tittleLabelForm}>
              Visor Lista Empaque
              </InputLabel>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs className={classes.gridForm}>
            <TextField
              id='pedidoCodigo'
              type='text'
              label='Pedido'
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3} className={this.props.classes.gridForm}>
            <AutoComplete
              id="clienteCodigo"
              tableName='cliente'
              fields={['clienteCodigo', 'clienteRazonSocial']}
              aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
              size='xl'
              primaryField='clienteCodigo'
              searchField='clienteRazonSocial'
              placeHolder='Seleccionar Cliente'
              onSelect={this.handleChange}
            />
          </Grid>
          <Grid item xs className={classes.gridForm}>
            <AutoComplete
              id="sucursalCodigo"
              tableName='Sucursal'
              fields={['sucursalCodigo', 'sucursalNombre']}
              primaryField='sucursalCodigo'
              aliases={['ID', 'Nombre']}
              searchField='sucursalNombre'
              placeHolder='Seleccionar Sucursal'
              onSelect={this.handleChange}
            />
          </Grid>
          <Grid item className={this.props.classes.gridForm}>
          <PSelect
                id="listaEmpaqueEstado"
                useChildren={true}
                onChange={this.handleChange}
                namelabel="Lista Empaque Estado"
              >
                <option value="PENDIENTE">PENDIENTE</option>
                <option value="CONFIRMACION SALIDA">CONFIRMACION SALIDA</option>
                <option value="CONFIRMACION DEVOLUCION">CONFIRMACION DEVOLUCION</option>
                <option value="CERRADO">CERRADO</option>
              </PSelect>
          </Grid>

        </Grid>

        <Grid container spacing={2}>
        <Grid item className={this.props.classes.gridForm}>
          <PSelect
                id="pedidoEstado"
                useChildren={true}
                onChange={this.handleChange}
                namelabel="Pedido Estado"
              >
                <option value="PENDIENTE">PENDIENTE</option>
                <option value="COTIZADO">COTIZADO</option>
                <option value="CONFIRMADO">CONFIRMADO</option>
                <option value="DESPACHADO">DESPACHADO</option>
                <option value="FACTURADO">FACTURADO</option>
                <option value="ANULADO">ANULADO</option>
                <option value="RECHAZADO">RECHAZADO</option>
              </PSelect>
          </Grid>
          <Grid item className={this.props.classes.gridForm}>
            <PSelect
                id="diasTrascurridos"
                useChildren={true}
                onChange={this.handleChange}
                namelabel="Indicador"
              >
                <option value="ATRASADO">ATRASADO</option>
                <option value="ATIEMPO">A TIEMPO</option>
              </PSelect>
            </Grid>

            <Grid item xs className={classes.gridForm}>
                <TextField
                  id='fechaServicio'
                  type='date'
                  label='Fecha Servicio'
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>

              <Grid item xs className={classes.gridForm}>
                <TextField
                  id='fechaListaEmpaque'
                  type='date'
                  label='Fecha Lista Empaque'
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
          </Grid>

        <Grid container spacing={10} >
          <Grid item xs={3} className={this.props.classes.gridForm}>

            <Button
              color='primary'
              variant='contained'
              onClick={(event) => this.search()}
            >
              Buscar
              </Button>

          </Grid>
        </Grid>

        <CustomizedExpansionPanel tittle="Cuadro de convenciones.">
          <Grid container spacing={2} >
            <Grid item xs className={this.props.classes.gridVisorDianAlertaAlta}>ATRASADO</Grid>
            <Grid item xs className={this.props.classes.gridVisorDianAlertaBaja}>A TIEMPO</Grid>
          </Grid>
        </CustomizedExpansionPanel>

        <DataGridVisor
          search={this.search}
          data={this.state.data}
          fields={VISOR_FIELDS}
          colors={VISOR_COLORS}
          moduleUrl='lista_empaque'
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
        />

      </Paper>
    );
  }
}

ListaEmpaqueVisor.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  toggleFavorites: PropTypes.func.isRequired,
  toogleFavoritesShow: PropTypes.func.isRequired
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(ListaEmpaqueVisor)));

function IntegrationNotistack(props) {
  return (
    <SnackbarProvider key={6} maxSnack={1}
      action={[
        <Button key={6} variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp {...props} />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;