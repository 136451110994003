import createStyles from '@material-ui/core/styles/createStyles';

const styles = theme => createStyles({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  tittleLabelForm: {
    marginTop: 0,
    borderSpacing: 0,
    borderBottom: 0,
    marginBottom: 30,
    color: '#000000',
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  bigAvatar: {
    margin: theme.spacing(),
    marginBottom: theme.spacing(3),
    width: 120,
    height: 120,
  },
  gridForm: {
    marginTop: 0,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  iconButton: {
    cursor: 'pointer'
  }
});

export default styles;