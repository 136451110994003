import createStyles from '@material-ui/core/styles/createStyles';

const styles = theme => createStyles({
    paper: {
        marginTop: theme.spacing(10),
        position: 'fixed',
        textAlign: 'center',
        wordWrap: 'break-word',
        width: '15%',
        height: 'auto',
        display: 'solid',
        alignItems: 'center',
        backgroundColor: '#fff'
    },
    paperGrid: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    titleform: {
        borderSpacing: 0,
        borderBottom: 0,
        color: '#006fd0',
        fontSize: '1.2rem',
        fontWeight: 'bold',
    },
    titlelabelform: {
        borderSpacing: 0,
        borderBottom: 0,
        marginBottom: 0,
        color: '#2196f3',
        fontSize: '0.9rem',
        fontWeight: 'bold',
    },
    valuelabelform: {
        borderSpacing: 2,
        borderBottom: 0,
        color: '#5e5e5e',
        fontSize: '0.7rem',
        fontWeight: 'bold',
        verticalAlign: 'top',
    },
    link: {
        textDecoration: 'none',
    },
    iconFavorite: {
        color: 'red'
    },
    listitem: {
        paddingTop: 0,
        paddingBottom: 0,
    }
});

export default styles;