import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Styles from '../../../GeneralUI/Styles';
import { Paper, InputLabel, Grid } from '@material-ui/core';
import { getDocument } from '../../../../Actions/General';
import Buttoncontrol from "../../../GeneralUI/Buttoncontrol";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { errorSetter } from '../../../../Libs/';
import AutoComplete from '../../../GeneralUI/AutoComplete';
import PSelect from '../../../GeneralUI/PSelect'

class InventarioTraslado extends Component {
  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
  }

  state = {
    data: null,
    fields: {
      fechaInicial: null,
      fechaFinal: null,
      tipoInterfaz: null,
      sucursalCodigo: null,
    },
    responseForm: { Message: '', typeMessage: '' },
    formValidations: {
      view: ['tipoInterfaz', 'fechaInicial', 'fechaFinal']
    },
    formErrors: {
      tipoInterfaz: {},
      fechaInicial: {},
      fechaFinal: {}
    }
  };

  componentDidMount() {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }

    getDocument({
      documentId: params.id,
      fieldName: 'inventarioCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }
      this.setState({ fields: document });
    });

  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {
    let { fields } = this.state;
    fields[event.target.id] = event.target.value;
    this.setState({ fields });
  }

  handleCapture = ({ target }) => {
    let { fields } = this.state;
    this.setState({
      fields
    });
  }

  save = (response) => {

    let newState = {
      openSnackbar: true,
      responseForm: response.responseForm,
    };

    if (newState.responseForm['typeMessage'] === 'success') {
      newState.data = response.data;
    }


    this.setState(newState);
    this.props.enqueueSnackbar(this.state.responseForm['Message'], { variant: this.state.responseForm['typeMessage'] });
  }


  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>

        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>
            <InputLabel className={this.props.classes.tittleLabelForm}>
              Inventario Actualizar
            </InputLabel>
          </Grid>

          <Grid container spacing={2}> 
            <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="almacenCodigo"
                  tableName='Almacen'
                  fields={['almacenCodigo', 'almacenNombre']}
                  primaryField='almacenCodigo'
                  aliases={['ID', 'Nombre']}
                  searchField='almacenNombre'
                  placeHolder='Seleccionar Almacen'
                  onSelect={this.handleChange}
                  value={this.state.fields["almacenCodigo"]}
                  formErrors={this.state.formErrors['almacenCodigo']}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>            
                <AutoComplete
                  id="clienteCodigo"
                  tableName='cliente'
                  fields={['clienteCodigo', 'clienteRazonSocial']}
                  aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
                  size='xl'
                  primaryField='clienteCodigo'
                  searchField='clienteRazonSocial'
                  placeHolder='Seleccionar Cliente'
                  onSelect={this.handleChange}
                  value={this.state.fields["clienteCodigo"]}
                />
              </Grid>             
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="productoCodigo"
                  tableName='vw_inventario'
                  fields={['inventario_codigo','producto_nombre', 'bodega_nombre', 'ubicacion_nombre', 'cantidad', 'estado']}
                  isView={true}
                  aliases={[{ value: 'ID', size: 1 },{ value: 'Producto', size: 3 },{ value: 'Bodega', size: 2 },{ value: 'Ubicacion', size: 2 },{ value: 'Cant', size: 1 },{ value: 'Estado', size: 3 }]}
                  size='xx'
                  primaryField='inventario_codigo'
                  searchField='producto_nombre'
                  placeHolder='Seleccionar Producto'
                  onSelect={this.handleChange}
                  where={{                    
                    cliente_codigo: this.state.clienteCodigo,
                    almacen_codigo: this.state.almacenCodigo
                  }}
                  value={this.state.fields["productoCodigo"].value}
                />
              </Grid>   
          </Grid>

          <Grid container spacing={2}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="bodegaCodigo"
                  tableName='vw_bodega_search'
                  fields={['bodegaCodigo', 'bodegaNombre', 'almacenNombre']}
                  isView={true}
                  aliases={[{ value: 'ID', size: 1 }, { value: 'Bodega', size: 5 }, { value: 'Almacen', size: 6}]}
                  size='xx'
                  primaryField='bodegaCodigo'
                  searchField='bodegaNombre'
                  placeHolder='Seleccionar Bodega'
                  onSelect={this.handleChange}
                  value={this.state.fields["bodegaCodigo"].value}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <AutoComplete
                  id="ubicacionCodigo"
                  tableName='vw_ubicacion_search'
                  fields={['ubicacionCodigo', 'ubicacionNombre', 'bodegaNombre', 'almacenNombre']}
                  isView={true}
                  aliases={[{ value: 'ID', size: 1 }, { value: 'Ubicacion', size: 3 }, { value: 'Bodega', size: 4 }, { value: 'Almacen', size: 4 }]}
                  size='xx'
                  primaryField='ubicacionCodigo'
                  searchField='ubicacionNombre'
                  placeHolder='Seleccionar Ubicacion'
                  onSelect={this.handleChange}
                  value={this.state.fields["ubicacionCodigo"].value}
                />
              </Grid>  
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="Cantidad" label="Cantidad | Area Utilizada" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["Cantidad"].value}
                  InputLabelProps={{ shrink: true }}
                  error={this.state.formErrors['Cantidad'].error}
                  helperText={this.state.formErrors['Cantidad'].errorMessage}
                />
              </Grid>              
          </Grid>


          <Grid container spacing={2}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="costoUnitario" label="Costo Unitario" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["costoUnitario"].value}
                  InputLabelProps={{ shrink: true }}
                  error={this.state.formErrors['costoUnitario'].error}
                  helperText={this.state.formErrors['costoUnitario'].errorMessage}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField
                  id="Lote"
                  label="Lote"
                  type="text"
                  fullWidth margin="normal"
                  onChange={this.handleChange}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  value={this.state.fields["Lote"].value}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="Cubicaje" label="Area Contratada" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["Cubicaje"].value}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>              
          </Grid>

          <Grid container spacing={2}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="ValorDeclarado" label="Valor Declarado" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["ValorDeclarado"].value}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="Estado"
                useChildren={true}
                onChange={this.handleChange}
                namelabel="Estado"
                value={this.state.fields["Estado"].value}
              >
                <option value="DISPONIBLE">DISPONIBLE</option>
                <option value="AVERIADO">AVERIADO</option>
                <option value="RESERVADO">RESERVADO</option>
              </PSelect>
              </Grid>              
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="DuracionBodega" label="Duracion Bodega" type="number"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["DuracionBodega"].value}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
          </Grid>

          <Grid container spacing={2}>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="AliasBodega" label="Alias Bodega" type="text"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["AliasBodega"].value}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3} className={this.props.classes.gridForm}>
                <TextField id="FechaIngresoBodega"
                  fullWidth margin="normal" variant="outlined"
                  onChange={this.handleChange}
                  value={this.state.fields["FechaIngresoBodega"].value}
                  type="date"
                  label="Fecha Ingreso Bodega"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
          </Grid>

        </form>
        <div id='buttonContainer'>
          <Buttoncontrol
            saveData={this.state.fields}
            moduleName='inventario'
            save={this.save}
            primaryFields={['inventarioCodigo']}
            formValidations={this.state.formValidations}
            errorHandler={this.setErrors}
            url='/processes/inventario_actualizar'
            isFormLite={true}
          />
        </div>
      </Paper >
    );
  }
}

InventarioTraslado.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(InventarioTraslado)));

function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={1}
      action={[
        <Button variant="text" color="primary" size="small">
          {'X'}
        </Button>
      ]}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;