import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Buttoncontrol from "../GeneralUI/Buttoncontrol";
import Textfieldlabel from "../GeneralUI/Textfieldlabel";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import PSelect from '../GeneralUI/PSelect'
import { SnackbarProvider, withSnackbar } from 'notistack';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Visibility from '@material-ui/icons/Visibility';
import AutoComplete from '../GeneralUI/AutoComplete';
import Datagrid from '../GeneralUI/PDatagrid'
import { withRouter } from 'react-router';
import Styles from '../GeneralUI/Styles';
import { getDocument } from '../../Actions/General';
import Utils from '../../Libs/utils';
import { errorSetter } from '../../Libs';
import CustomizedExpansionPanel from '../GeneralUI/PExpansionpanels'

class ListaEmpaque extends Component {

  constructor(props) {
    super(props);

    this.setErrors = errorSetter.setErrors.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  state = {
    dataDatagridListaEmpaque: [{
      tarifaCodigo: { 'value': null, 'key': null,'visible':false  },     
      tarifaNombre: { 'value': null, 'key': null,'visible':false  },
      productoCodigo: { 'value': null, 'key': null,'visible':true  },
      unidadMedida: { 'value': null, 'key': null,'visible':true  },
      Factor: { 'value': null, 'key': null,'visible':false  },     
      Cantidad: { 'value': null, 'key': null,'visible':true  },
      Devolucion: { 'value': null, 'key': null,'visible':true  },
      TotalDevolucion: { 'value': null, 'key': null,'visible':true  },
      TotalDevolucionHidden: { 'value': null, 'key': null,'visible':false  },

      Adicional: { 'value': null, 'key': null,'visible':true  },

      TotalAdicional: { 'value': null, 'key': null,'visible':true  },
      TotalAdicionalHidden: { 'value': null, 'key': null,'visible':false  },

      valorUnitario: { 'value': null, 'key': null,'visible':false  },
      Real: { 'value': null, 'key': null,'visible':true  }
    }],  
    dataDatagridTrazabilidad: [
      {
        Usuario: '',
        DocumentoPrincipal: '',
        DocumentoAsociado: '',
        Evento: '',
        FechaHora: '',
        UsuarioExterno: ''
      }
    ],  
    fieldsCopy: {},
    fields: {
      lisempCodigo:null, 
      pedidoCodigo:null, 
      lisempEstado:null, 
      clienteCodigo:null,
      lisempSumaImpresionListaEmpaque:null, 
      lisempFactorImpresionCajaListaEmpaque:null,
      createdAt: Utils.fullDateTime(),
      updatedAt: Utils.fullDateTime(),
    },

    responseForm: { Message: '', typeMessage: '' },

  };

  componentDidMount = () => {
    const params = this.props.match.params;

    if (!params.id) {
      return;
    }
    this.fillFormData(params.id);    
  }


  fillFormData = (id) => {
    const params = this.props.match.params;

    if (!id) {
      return;
    }

    getDocument({
      documentId: id,
      fieldName: 'lisempCodigo',
      moduleName: params.module
    }).then(document => {
      if (!document) {
        return;
      }

      this.setState({ fields: document });
      
      getDocument({
        documentId: id,
        fieldName: 'lisemp_codigo',
        moduleName: 'vw_detalle_lista_empaque_interfaz',
        isView: true,
        fields: ['lisempdet_codigo',
          'lisemp_codigo',
          'tarifa_codigo',
          'producto_codigo',
          'producto_nombre',
          'lisempdet_tarifa_nombre',
          'lisempdet_unidad_medida',
          'lisempdet_factor',
          'lisempdet_cantidad',
          'lisempdet_cantidad_devolucion',
          'lisempdet_cantidad_adicional',
          'lisempdet_cantidad_devolucion_total',
          'lisempdet_cantidad_adicional_total',
          'lisempdet_valor_unitario'
        ],
        Limit: 50
      }).then(document => {
        if (!document) {
          return;
        }
        this.buildDetailListaEmpaqueInterface(document);
      }).catch(ex => {
        console.log('index.jsx -- 137 > ex === ', ex);
      });

    }).catch(ex => {
      console.log('index.jsx -- 140 > ex === ', ex);
    });
  }

  handleSeguir = () => {
    alert('Seguimiento registrado.');
  }

  handleChange = (event) => {

    let { fields } = this.state;
    const caret = event.target.selectionStart;
    const element = event.target;
    if (element.hasOwnProperty('setSelectionRange')) {
      window.requestAnimationFrame(() => {
        element.setSelectionRange(caret, caret);
      });
    }

    if (event.target.type === 'time') {
      fields[event.target.id] = event.target.value;
    } else if (event.target.type === 'number') {
      fields[event.target.id] = event.target.value;
    } else {
      fields[event.target.id] = event.target.value.toUpperCase();
    }

    if (fields[event.target.id] === '') {
      fields[event.target.id] = null;
    }
    this.setState({ fields });
  }

  handleDatagrid = (data) => {
    const detalle = data.setComponente;
    console.log(data);

    switch (detalle) {
      case 'listaEmpaque':
        this.setState({ dataDatagridListaEmpaque: data });
        break;
      default:
        return null;
    }
  }

  buildDetailListaEmpaqueInterface = (data) => {
    let valorAdicional=0,valorAdicionalTotal=0,valorAdicionalTotalHidden=0,valorReal,rows = data.map(row => {
     
      if(row.lisempdet_cantidad_adicional>0 && row.lisempdet_cantidad_adicional_total===0){
        valorAdicional=row.lisempdet_cantidad_adicional;
        valorAdicionalTotal=valorAdicional;
        valorAdicionalTotalHidden=0;
      }else{
        valorAdicional=0;
        valorAdicionalTotalHidden=row.lisempdet_cantidad_adicional_total;
        valorAdicionalTotal=row.lisempdet_cantidad_adicional_total;
      }

      valorReal=(parseFloat(row.lisempdet_cantidad)+parseFloat(valorAdicionalTotal))-parseFloat(row.lisempdet_cantidad_devolucion_total);

     
      return {
        tarifaCodigo: { 'value': row.tarifa_codigo, 'key': row.tarifa_codigo, 'visible':false },
        tarifaNombre: { 'value': row.lisempdet_tarifa_nombre, 'key': row.lisempdet_tarifa_nombre, 'visible':false },
        productoCodigo: { 'value': row.producto_codigo, 'key': row.producto_nombre, 'visible':true },        
        unidadMedida: { 'value': row.lisempdet_unidad_medida, 'key': row.lisempdet_unidad_medida, 'visible':true },
        Factor: { 'value': row.lisempdet_factor, 'key': row.lisempdet_factor, 'visible':false },
        Cantidad: { 'value': row.lisempdet_cantidad, 'key': parseFloat(row.lisempdet_cantidad).toLocaleString(), 'visible':true },
        Devolucion: { 'value': 0, 'key': 0, 'visible':true },
        
        TotalDevolucion: { 'value': row.lisempdet_cantidad_devolucion_total, 'key': row.lisempdet_cantidad_devolucion_total, 'visible':true },
        TotalDevolucionHidden: { 'value': row.lisempdet_cantidad_devolucion_total, 'key': row.lisempdet_cantidad_devolucion_total, 'visible':false },

        Adicional: { 'value': valorAdicional, 'key': valorAdicional, 'visible':true },
       
        TotalAdicional: { 'value': valorAdicionalTotal, 'key': valorAdicionalTotal, 'visible':true },
        TotalAdicionalHidden: { 'value': valorAdicionalTotalHidden, 'key': valorAdicionalTotalHidden, 'visible':false },

        valorUnitario: { 'value': row.lisempdet_valor_unitario, 'key': row.lisempdet_valor_unitario, 'visible':false },

        Real: { 'value': valorReal, 'key': parseFloat(valorReal).toLocaleString(), 'visible':true }

      };
    });
    this.setState({ dataDatagridListaEmpaque: rows });
  }

  messageValidation = (type, message) => {
    this.message(type, message);
  }

  save = (response) => {

    let fields=response.data.fields?response.data.fields:response.data;

    this.setState({
      fields,
      responseForm: response.responseForm
    }
    );

    if(this.state.responseForm['typeMessage']==='success'){
      this.fillFormData(this.state.fields.lisempCodigo); 
    }

    this.message(this.state.responseForm['typeMessage'], this.state.responseForm['Message']);
  }

  message = (type, message) => {
    this.props.enqueueSnackbar(message, { variant: type });
  }

  validaData = () => {
    let isvalid = true;
    let message = 'El registro no ha sido guardado.';
    let type = "warning";

    isvalid = true;

    if (!isvalid) {
      this.message(type, message);
    }
    return isvalid;
  }

  render() {
    return (
      <Paper elevation={5} className={this.props.classes.mainPaper}>
        <form className={this.props.form} onSubmit={e => e.preventDefault()}>

          <Grid container spacing={3} className={this.props.classes.gridForm}>

            <InputLabel id="lisempCodigo"
              className={this.props.classes.tittleLabelForm}>Lista Empaque
            </InputLabel>

            <Grid item xs={3} className={this.props.classes.chipTitle}>
              {(this.state.fields.lisempCodigo !== null) ?
                (<Chip
                  label={this.state.fields["lisempCodigo"]}
                  clickable
                  color="primary"
                  onDelete={this.handleSeguir}
                  deleteIcon={<Tooltip title="Seguir" placement="right-start"><Visibility /></Tooltip>}
                />) : ''
              }
            </Grid>
          </Grid>

          <Grid  >
            <CustomizedExpansionPanel tittle="Datos Generales De La Lista De Empaque"></CustomizedExpansionPanel>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <TextField id="pedidoCodigo" label="Pedido" type="text"
                fullWidth margin="normal" variant="outlined"
                onChange={this.handleChange}
                value={this.state.fields["pedidoCodigo"] || undefined}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <PSelect
                id="lisempEstado"
                useChildren={true}
                value={this.state.fields["lisempEstado"]}
                onChange={this.handleChange}
                namelabel="Estado"
              >
                <option value="PENDIENTE">PENDIENTE</option>
                <option value="CONFIRMACION SALIDA">CONFIRMACION SALIDA</option>
                <option value="ADICIONALES">ADICIONALES</option>
                <option value="CONFIRMACION DEVOLUCION">CONFIRMACION DEVOLUCION</option>
                <option value="DEVOLUCIONES">DEVOLUCIONES</option>
                <option value="CERRADO">CERRADO</option>
              </PSelect>
            </Grid>

            <Grid item xs={3} className={this.props.classes.gridForm}>
              <AutoComplete
                id="clienteCodigo"
                tableName='cliente'
                fields={['clienteCodigo', 'clienteRazonSocial']}
                aliases={[{ value: 'ID', size: 1 }, { value: 'Nombre', size: 11 }]}
                size='xl'
                primaryField='clienteCodigo'
                searchField='clienteRazonSocial'
                placeHolder='Seleccionar Cliente'
                value={this.state.fields["clienteCodigo"]}
              />
            </Grid>
          </Grid>             

          <Grid container spacing={4} >
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Usuario" value={Utils.getFromLocalStorage('userInfo').usuarioNombreCompleto} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Creacion" value={this.state.fields.createdAt} />
            </Grid>
            <Grid item xs={3} className={this.props.classes.gridForm}>
              <Textfieldlabel label="Fecha Modificacion" value={this.state.fields.updatedAt} />
            </Grid>
          </Grid>

          <Grid>
            <CustomizedExpansionPanel tittle="Detalle de Productos +++">
              <Datagrid datosHeader={this.state.fields} dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridListaEmpaque} setComponente="listaEmpaque" />
            </CustomizedExpansionPanel>
          </Grid>


          <CustomizedExpansionPanel tittle="Trazabilidad">
            <Datagrid dataDatagrid={this.handleDatagrid} datos={this.state.dataDatagridTrazabilidad} />
          </CustomizedExpansionPanel>

        </form>
        <div><Buttoncontrol
          saveData={this.state}
          saveDataValidation={this.state.fields}
          moduleName={this.props.match.params.module}
          save={this.save}
          validaData={this.validaData}
          messageValidation={this.messageValidation}
          edit={(this.state.fields.lisempCodigo !== null) ? true : false}
          primaryFields={['lisempCodigo']}
          url='/processes/lista_empaque_save'
          formValidations={this.state.formValidations}
          errorHandler={this.setErrors}
        /></div>
      </Paper >
    );
  }
}

ListaEmpaque.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};


const MyApp = withRouter(withSnackbar(withStyles(Styles, { withTheme: true })(ListaEmpaque)));

function IntegrationNotistack() {
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }
  return (

    <SnackbarProvider maxSnack={1} autoHideDuration={360000}
      ref={notistackRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      action={(key) => (
        <Button variant="text" color="primary" size="small" onClick={onClickDismiss(key)}>
          {'X'}
        </Button>
      )}
    >
      <MyApp />
    </SnackbarProvider>
  );
}


export default IntegrationNotistack;